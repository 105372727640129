import React from 'react';
const BrushIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="43" height="57" viewBox="0 0 43 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M35.3953 0.627502H7.40708C3.54996 0.627502 0.410034 3.76673 0.410034 7.623V19.2792C0.410034 25.7063 5.64032 30.9355 12.0689 30.9355H15.4275C14.9814 33.9261 14.614 36.9778 14.4041 40.1083C14.203 43.2476 14.133 46.4393 14.1942 49.5872C14.2729 53.4435 17.5091 56.5827 21.3924 56.5827C25.2758 56.5827 28.5119 53.4435 28.5907 49.5872C28.6606 46.4393 28.5907 43.2563 28.3808 40.1083C28.1708 36.9778 27.8035 33.9261 27.3574 30.9355H30.716C37.1445 30.9355 42.3749 25.7063 42.3749 19.2792V7.623C42.3749 3.76673 39.2349 0.627502 35.3778 0.627502H35.3953ZM7.40708 5.29697H35.3953C36.681 5.29697 37.7306 6.34628 37.7306 7.6317V16.962H5.08056V7.6317C5.08056 6.34628 6.13012 5.29697 7.41583 5.29697H7.40708ZM21.4012 51.9307C20.028 51.9307 18.891 50.8377 18.8648 49.4998C18.8473 48.7565 18.8648 48.0132 18.8648 47.27H23.9376C23.9376 48.0132 23.9551 48.7652 23.9376 49.4998C23.9114 50.8377 22.7744 51.9307 21.4012 51.9307ZM23.7365 40.4231C23.7802 41.1489 23.7977 41.8747 23.8327 42.6005H18.9697C19.0047 41.8747 19.0134 41.1489 19.0659 40.4231C19.2846 37.1789 19.6694 34.0135 20.1505 30.9442H22.6607C23.1417 34.0135 23.5265 37.1789 23.7452 40.4231H23.7365ZM30.7335 26.2835H12.0776C9.04264 26.2835 6.47998 24.3247 5.50914 21.6227H37.302C36.3399 24.3335 33.7685 26.2835 30.7335 26.2835Z" fill={secondaryColor}/>
<path d="M12.0689 14.6185C13.3546 14.6185 14.4041 13.5779 14.4041 12.2837V9.94899C14.4041 8.66357 13.3633 7.61426 12.0689 7.61426C10.7744 7.61426 9.73361 8.65482 9.73361 9.94899V12.2837C9.73361 13.5691 10.7744 14.6185 12.0689 14.6185Z" fill={primaryColor}/>
<path d="M21.4012 14.6185C22.6869 14.6185 23.7365 13.5779 23.7365 12.2837V9.94899C23.7365 8.66357 22.6956 7.61426 21.4012 7.61426C20.1067 7.61426 19.0659 8.65482 19.0659 9.94899V12.2837C19.0659 13.5691 20.1067 14.6185 21.4012 14.6185Z" fill={primaryColor}/>
<path d="M30.7335 14.6185C32.0192 14.6185 33.0688 13.5779 33.0688 12.2837V9.94899C33.0688 8.66357 32.028 7.61426 30.7335 7.61426C29.439 7.61426 28.3982 8.65482 28.3982 9.94899V12.2837C28.3982 13.5691 29.439 14.6185 30.7335 14.6185Z" fill={primaryColor}/>
</svg>

);

export default BrushIcon;
