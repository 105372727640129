import React from 'react';
const CinemasIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M55.1726 11.6804C54.4466 6.59115 50.3533 2.53378 45.2193 1.808C34.0677 0.234009 22.6013 0.234018 11.4585 1.81675C6.36816 2.54253 2.30984 6.6349 1.58389 11.7679C0.796727 17.3293 0.394409 22.9869 0.394409 28.6008C0.394409 34.2147 0.796735 39.9422 1.59265 45.5299C2.31859 50.6191 6.41187 54.6765 11.546 55.4022C17.1086 56.1892 22.7762 56.5915 28.3826 56.5915C33.989 56.5915 39.7178 56.1893 45.3067 55.3935C50.3971 54.6678 54.4554 50.5754 55.1813 45.4424C55.9685 39.881 56.3708 34.2234 56.3708 28.6095C56.3708 22.9956 55.9685 17.268 55.1726 11.6804ZM50.572 44.7866C50.1347 47.8821 47.7032 50.3393 44.6595 50.7765C33.9453 52.3068 22.9249 52.3068 12.2019 50.7852C9.10574 50.348 6.64804 47.9171 6.21073 44.874C5.44105 39.5137 5.05616 34.0398 5.05616 28.6095C5.05616 23.1793 5.44104 17.7752 6.19322 12.4324C6.63053 9.33692 9.06202 6.87972 12.1057 6.4425C17.476 5.673 22.9511 5.28824 28.3739 5.28824C33.7966 5.28824 39.2105 5.67304 44.5545 6.42505C47.6507 6.86227 50.1084 9.29316 50.5457 12.3362C51.3154 17.6965 51.7002 23.1705 51.7002 28.6008C51.7002 34.031 51.3154 39.4351 50.5632 44.7779L50.572 44.7866Z" fill={secondaryColor}/>
<path d="M16.7237 7.62301C15.438 7.62301 14.3885 8.66357 14.3885 9.95774V14.6185H9.72665C8.44094 14.6185 7.39145 15.6591 7.39145 16.9532C7.39145 18.2474 8.43219 19.288 9.72665 19.288H14.3885V26.2835H9.72665C8.44094 26.2835 7.39145 27.324 7.39145 28.6182C7.39145 29.9124 8.43219 30.953 9.72665 30.953H14.3885V37.9485H9.72665C8.44094 37.9485 7.39145 38.9891 7.39145 40.2832C7.39145 41.5774 8.43219 42.618 9.72665 42.618H14.3885V47.2787C14.3885 48.5641 15.4292 49.6135 16.7237 49.6135C18.0182 49.6135 19.059 48.5729 19.059 47.2787V9.96649C19.059 8.68107 18.0182 7.63171 16.7237 7.63171V7.62301Z" fill={primaryColor}/>
<path d="M47.0472 19.2792C48.3329 19.2792 49.3825 18.2387 49.3825 16.9445C49.3825 15.6503 48.3417 14.6098 47.0472 14.6098H42.3855V9.94899C42.3855 8.66357 41.3446 7.61426 40.0501 7.61426C38.7557 7.61426 37.7148 8.65482 37.7148 9.94899V47.2612C37.7148 48.5466 38.7557 49.596 40.0501 49.596C41.3446 49.596 42.3855 48.5554 42.3855 47.2612V42.6005H47.0472C48.3329 42.6005 49.3825 41.5599 49.3825 40.2657C49.3825 38.9716 48.3417 37.931 47.0472 37.931H42.3855V30.9355H47.0472C48.3329 30.9355 49.3825 29.8949 49.3825 28.6008C49.3825 27.3066 48.3417 26.266 47.0472 26.266H42.3855V19.2705H47.0472V19.2792Z" fill={primaryColor}/>
</svg>

);

export default CinemasIcon;
