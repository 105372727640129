import React from 'react';
const ShieldIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="56" viewBox="0 0 57 56" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M56.7926 18.6877V18.0494C56.7926 14.7527 54.4923 11.8845 51.3262 11.22C49.5244 10.8439 47.7664 10.2844 46.1046 9.54986C42.991 8.17699 40.1747 6.20946 37.7519 3.70857C35.4517 1.3301 32.3467 0.0272217 29.0143 0.0272217H28.9007C25.5158 0.0272217 22.3409 1.36506 19.9619 3.80474C17.5567 6.26191 14.7753 8.19448 11.6966 9.54986C10.0348 10.2844 8.27685 10.8439 6.47511 11.22C3.30894 11.8845 1.00866 14.7527 1.00866 18.0494V18.539C0.75502 22.745 0.956149 33.5794 8.46049 43.4168C13.2535 49.6953 19.1135 53.0968 23.1893 54.8544C24.9473 55.6064 26.8715 55.9912 28.9007 55.9912C30.9298 55.9912 32.854 55.6064 34.612 54.8544C38.6878 53.1055 44.5566 49.6953 49.3408 43.4168C56.8539 33.5794 57.055 22.7363 56.7926 18.6789V18.6877ZM45.6411 40.5924C41.4603 46.0664 36.335 49.0394 32.7665 50.5696C30.4138 51.584 27.3613 51.584 25.0085 50.5696C21.4401 49.0394 16.3147 46.0576 12.1339 40.5924C5.57421 31.9967 5.40804 22.5177 5.63544 18.6877V18.0494C5.63544 16.9476 6.37889 16.0031 7.39346 15.7932C9.51007 15.356 11.583 14.6915 13.5421 13.8258C17.1631 12.2343 20.4342 9.96078 23.2593 7.07513C24.7637 5.53612 26.7578 4.69669 28.8832 4.69669H28.9969C31.061 4.69669 32.9764 5.50115 34.4021 6.9702C37.2534 9.90831 40.5595 12.2168 44.2242 13.8258C46.1833 14.6915 48.2562 15.3473 50.3729 15.7932C51.3962 16.0031 52.1309 16.9563 52.1309 18.0494V18.8276C52.367 22.509 52.192 31.9879 45.6323 40.5836L45.6411 40.5924Z" fill={secondaryColor}/>
<path d="M48.1338 17.6558C46.4632 17.2098 44.8364 16.6415 43.297 15.9594C39.3525 14.228 35.8014 11.7446 32.7402 8.58787C30.7723 6.55918 27.0726 6.51547 24.9298 8.7103C21.8948 11.8058 18.3788 14.2455 14.4954 15.9594C12.9473 16.6415 11.3206 17.2098 9.65876 17.647C8.66168 17.9094 7.96194 18.8013 7.92695 19.8331C7.8045 23.9167 8.42549 31.8742 13.9969 39.167C17.8715 44.2388 22.6295 47.002 25.9444 48.4273C26.8278 48.8033 27.8248 49.0045 28.9093 49.0045C29.9939 49.0045 30.991 48.812 31.8744 48.4273C35.1805 47.0107 39.9385 44.2475 43.8131 39.1758C49.3845 31.883 50.0055 23.9342 49.883 19.8419C49.8481 18.81 49.1483 17.9181 48.1512 17.6558H48.1338ZM40.1047 36.3425C36.8423 40.6185 32.8277 42.9445 30.0201 44.1425C29.4079 44.4049 28.3671 44.4049 27.7549 44.1425C24.9561 42.9358 20.9327 40.6099 17.6704 36.3339C13.6471 31.0697 12.6937 25.3684 12.5625 21.6608C13.8569 21.2498 15.1252 20.7688 16.3497 20.2354C20.784 18.2854 24.7811 15.5047 28.2359 11.9807C28.4196 11.7883 28.6382 11.6922 28.8831 11.6922C29.2505 11.6922 29.3554 11.7971 29.3991 11.8409C32.8802 15.4261 36.921 18.2505 41.4166 20.2267C42.6411 20.7688 43.9093 21.241 45.2037 21.6608C45.0813 25.3684 44.1192 31.0697 40.0959 36.3339L40.1047 36.3425Z" fill={primaryColor}/>
</svg>

);

export default ShieldIcon;
