import React from 'react';
const WalletIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="43" viewBox="0 0 57 43" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M46.1037 1.91153C34.3661 0.459965 22.3225 0.468726 10.5587 1.92904C4.77738 2.64608 0.412964 7.62161 0.412964 13.4978V30.1296C0.412964 36.0146 4.82985 40.9902 10.6986 41.7159C16.5499 42.4417 22.5062 42.8002 28.4012 42.8002C34.2962 42.8002 40.3399 42.433 46.2436 41.6984C52.025 40.9814 56.3894 36.0059 56.3894 30.1296V13.4978C56.3894 7.61286 51.9725 2.63731 46.1037 1.91153ZM40.06 31.144C34.9172 31.144 30.7277 26.9554 30.7277 21.8137C30.7277 16.672 34.9172 12.4835 40.06 12.4835H51.6226C51.6751 12.8158 51.7188 13.1568 51.7188 13.4978V30.1296C51.7188 30.4707 51.6751 30.8117 51.6226 31.144H40.0513H40.06ZM45.6664 37.0639C34.2787 38.4718 22.6199 38.4805 11.2671 37.0814C7.73363 36.6442 5.07475 33.6536 5.07475 30.1209V13.4891C5.07475 9.96509 7.68115 6.98327 11.1359 6.55479C16.8473 5.8465 22.6548 5.488 28.4012 5.488C34.1475 5.488 39.8763 5.84648 45.5352 6.53729C46.7422 6.68594 47.8355 7.14941 48.7626 7.81398H40.06C32.3457 7.81398 26.0659 14.0924 26.0659 21.805C26.0659 29.5175 32.3457 35.796 40.06 35.796H48.8063C47.8967 36.4518 46.8297 36.9153 45.6576 37.0639H45.6664Z" fill={secondaryColor}/>
<path d="M42.3953 14.8182C38.5382 14.8182 35.3982 17.9575 35.3982 21.8137C35.3982 25.67 38.5382 28.8092 42.3953 28.8092C46.2524 28.8092 49.3923 25.67 49.3923 21.8137C49.3923 17.9575 46.2524 14.8182 42.3953 14.8182ZM42.3953 24.1485C41.1096 24.1485 40.06 23.0992 40.06 21.8137C40.06 20.5283 41.1096 19.479 42.3953 19.479C43.681 19.479 44.7306 20.5283 44.7306 21.8137C44.7306 23.0992 43.681 24.1485 42.3953 24.1485Z" fill={primaryColor}/>
</svg>

);

export default WalletIcon;
