import React from 'react';
const LaptopIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="52" viewBox="0 0 57 52" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M47.7568 33.2115H43.3574C40.611 33.2115 38.0221 34.2783 36.0804 36.2283C35.0309 37.2776 33.579 37.881 32.1009 37.881H24.7015C23.2233 37.881 21.7715 37.2776 20.7219 36.2283C18.7802 34.287 16.1913 33.2115 13.445 33.2115H9.04557C4.28758 33.2115 0.412964 37.0852 0.412964 41.8422C0.412964 46.1094 3.59662 49.7908 7.82983 50.3942C14.617 51.3736 21.5441 51.8632 28.4012 51.8632C35.2583 51.8632 42.2291 51.3648 49.0512 50.3854C53.2407 49.7821 56.3894 46.1094 56.3894 41.8422C56.3894 37.0852 52.5148 33.2115 47.7568 33.2115ZM48.3865 45.7684C35.2058 47.6659 21.6578 47.6659 8.49456 45.7771C6.55288 45.4973 5.08349 43.8009 5.08349 41.8422C5.08349 39.6561 6.859 37.8722 9.05433 37.8722H13.4537C14.9318 37.8722 16.3837 38.4756 17.4333 39.5249C19.375 41.4662 21.9639 42.5417 24.7102 42.5417H32.1096C34.8559 42.5417 37.4449 41.4749 39.3865 39.5249C40.4361 38.4756 41.888 37.8722 43.3661 37.8722H47.7655C49.9521 37.8722 51.7363 39.6473 51.7363 41.8509C51.7363 43.8097 50.3019 45.4973 48.3952 45.7684H48.3865Z" fill={secondaryColor}/>
<path d="M7.40999 30.8767C8.6957 30.8767 9.74526 29.8362 9.74526 28.542V12.2162C9.74526 8.35994 12.8852 5.22071 16.7423 5.22071H40.0687C43.9258 5.22071 47.0658 8.35994 47.0658 12.2162V28.542C47.0658 29.8274 48.1066 30.8767 49.401 30.8767C50.6955 30.8767 51.7363 29.8362 51.7363 28.542V12.2162C51.7363 5.7891 46.506 0.559998 40.0775 0.559998H16.751C10.3225 0.559998 5.09222 5.7891 5.09222 12.2162V28.542C5.09222 29.8274 6.13303 30.8767 7.42749 30.8767H7.40999Z" fill={secondaryColor}/>
<path d="M23.7394 7.5555C22.4537 7.5555 21.4041 8.59606 21.4041 9.89023C21.4041 11.1844 22.4449 12.225 23.7394 12.225H33.0717C34.3574 12.225 35.407 11.1844 35.407 9.89023C35.407 8.59606 34.3661 7.5555 33.0717 7.5555H23.7394Z" fill={primaryColor}/>
</svg>

);

export default LaptopIcon;
