import React from 'react';
const SmileyIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M28.8919 0.630005C13.4634 0.630005 0.903687 13.1869 0.903687 28.612C0.903687 44.0371 13.4634 56.594 28.8919 56.594C44.3204 56.594 56.8801 44.0371 56.8801 28.612C56.8801 13.1869 44.3204 0.630005 28.8919 0.630005ZM28.8919 51.9333C16.0348 51.9333 5.56543 41.475 5.56543 28.612C5.56543 15.749 16.0261 5.29076 28.8919 5.29076C41.7577 5.29076 52.2183 15.749 52.2183 28.612C52.2183 41.475 41.7577 51.9333 28.8919 51.9333Z" fill={secondaryColor}/>
<path d="M20.0319 23.0156C20.8103 24.0474 22.2709 24.2573 23.2942 23.4791C24.3263 22.7095 24.5362 21.2405 23.7578 20.2174C21.4225 17.1131 17.9065 16.9557 17.2243 16.9557C13.3409 16.9557 11.1019 19.6753 10.6908 20.2174C9.92112 21.2492 10.1223 22.7095 11.1543 23.4791C11.5741 23.7939 12.064 23.9425 12.5538 23.9425C13.2622 23.9425 13.9619 23.619 14.4254 23.0069C14.4692 22.9456 15.4925 21.6078 17.2243 21.6078C17.2942 21.6078 18.9823 21.6252 20.0231 23.0069L20.0319 23.0156Z" fill={primaryColor}/>
<path d="M47.0842 20.2174C44.7489 17.1131 41.2329 16.9557 40.5507 16.9557C36.6673 16.9557 34.4283 19.6753 34.0172 20.2174C33.2475 21.2492 33.4487 22.7095 34.4807 23.4791C34.9005 23.7939 35.3904 23.9425 35.8802 23.9425C36.5886 23.9425 37.2883 23.619 37.7519 23.0069C37.7956 22.9456 38.8189 21.6078 40.5507 21.6078C40.6206 21.6078 42.3087 21.6252 43.3495 23.0069C44.1279 24.0387 45.5798 24.2486 46.6119 23.4703C47.644 22.7008 47.8539 21.2317 47.0754 20.2087L47.0842 20.2174Z" fill={primaryColor}/>
<path d="M36.3612 36.5432C33.6411 40.1721 29.3729 40.277 28.9006 40.277C24.37 40.277 21.7199 36.9279 21.44 36.5432C20.6703 35.5201 19.2097 35.3015 18.1776 36.0797C17.1456 36.8492 16.9356 38.3095 17.7141 39.3414C18.4138 40.277 22.2446 44.9378 28.9093 44.9378C30.0813 44.9378 36.1076 44.6667 40.1046 39.3414C40.8743 38.3095 40.6731 36.8492 39.6411 36.0797C38.609 35.3015 37.1484 35.5201 36.3787 36.5432H36.3612Z" fill={primaryColor}/>
</svg>

);

export default SmileyIcon;
