import React from 'react';
const Open_padlockIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="47" height="56" viewBox="0 0 47 56" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M36.7791 24.2316C28.0678 23.0598 19.1291 23.0598 10.4002 24.2491C4.65391 25.0361 0.324493 30.0029 0.324493 35.8091V43.5392C0.324493 49.3455 4.70639 54.321 10.5139 55.108C14.8521 55.6939 19.269 55.9912 23.6509 55.9912C28.0328 55.9912 32.5197 55.6851 36.9016 55.0905C42.6479 54.3035 46.9773 49.3367 46.9773 43.5305V35.8004C46.9773 29.9941 42.5954 25.0186 36.7879 24.2316H36.7791ZM42.3068 43.5392C42.3068 47.0195 39.7092 50.01 36.2631 50.4735C27.9541 51.6102 19.4352 51.6102 11.1349 50.491C7.62765 50.0188 4.98627 47.0282 4.98627 43.5392V35.8091C4.98627 32.3289 7.58392 29.3383 11.03 28.8749C15.202 28.3065 19.4439 28.018 23.6422 28.018C27.8404 28.018 32.0212 28.2977 36.1494 28.8574C39.6567 29.3296 42.298 32.3201 42.298 35.8091V43.5392H42.3068Z" fill={secondaryColor}/>
<path d="M23.6509 30.3439C19.7938 30.3439 16.6539 33.4832 16.6539 37.3394C16.6539 40.3737 18.613 42.9358 21.3156 43.9065V46.6697C21.3156 47.9551 22.3565 49.0045 23.6509 49.0045C24.9454 49.0045 25.9862 47.9639 25.9862 46.6697V43.9065C28.6975 42.9446 30.648 40.3737 30.648 37.3394C30.648 33.4832 27.508 30.3439 23.6509 30.3439ZM23.6509 39.6742C22.3652 39.6742 21.3156 38.6249 21.3156 37.3394C21.3156 36.054 22.3652 35.0048 23.6509 35.0048C24.9366 35.0048 25.9862 36.054 25.9862 37.3394C25.9862 38.6249 24.9366 39.6742 23.6509 39.6742Z" fill={primaryColor}/>
<path d="M9.65681 14.027C10.9425 14.027 11.9921 12.9864 11.9921 11.6922C11.9921 7.83595 15.132 4.69672 18.9891 4.69672H28.3214C32.1786 4.69672 35.3185 7.83595 35.3185 11.6922V18.6877C35.3185 19.9731 36.3593 21.0225 37.6537 21.0225C38.9482 21.0225 39.989 19.9819 39.989 18.6877V11.6922C39.989 5.26511 34.7587 0.0360107 28.3302 0.0360107H18.9979C12.5693 0.0360107 7.33902 5.26511 7.33902 11.6922C7.33902 12.9776 8.37984 14.027 9.6743 14.027H9.65681Z" fill={secondaryColor}/>
</svg>

);

export default Open_padlockIcon;
