import React from 'react';
const Video_cameraIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="48" viewBox="0 0 57 48" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M51.709 21.6857H48.6041C47.0735 21.6857 45.5779 22.1842 44.4058 23.0849L44.231 23.216C42.9802 18.7651 39.1756 15.416 34.4876 14.8389C35.0386 13.7371 35.3885 12.5129 35.3885 11.1925C35.3885 6.68915 31.7237 3.03401 27.2281 3.03401C25.3039 3.03401 23.5547 3.73355 22.1553 4.85283C20.476 2.35194 17.6334 0.699249 14.4061 0.699249C9.26322 0.699249 5.0737 4.88782 5.0737 10.0295C5.0737 12.2243 5.86961 14.2268 7.14657 15.8183C3.17575 17.6808 0.403198 21.6857 0.403198 26.3465V35.6767C0.403198 42.1038 5.63345 47.333 12.062 47.333H33.0531C38.3884 47.333 42.8578 43.7041 44.231 38.8072L44.3883 38.9297C45.5778 39.8391 47.0735 40.3375 48.6041 40.3375H51.709C54.2804 40.3375 56.3708 38.2476 56.3708 35.6767V26.3465C56.3708 23.7756 54.2804 21.6857 51.709 21.6857ZM27.2193 7.69475C29.1523 7.69475 30.7178 9.25999 30.7178 11.1925C30.7178 13.125 29.1523 14.6902 27.2193 14.6902C25.2864 14.6902 23.7208 13.125 23.7208 11.1925C23.7208 9.25999 25.2864 7.69475 27.2193 7.69475ZM14.3885 5.35999C16.96 5.35999 19.0503 7.44991 19.0503 10.0208C19.0503 12.5916 16.96 14.6815 14.3885 14.6815C11.8171 14.6815 9.72669 12.5916 9.72669 10.0208C9.72669 7.44991 11.8171 5.35999 14.3885 5.35999ZM40.0502 35.6767C40.0502 39.533 36.9103 42.6722 33.0531 42.6722H12.062C8.20487 42.6722 5.06494 39.533 5.06494 35.6767V26.3465C5.06494 22.4902 8.20487 19.351 12.062 19.351H33.0531C36.9103 19.351 40.0502 22.4902 40.0502 26.3465V35.6767ZM51.709 35.6767H48.6041C48.0881 35.6767 47.6071 35.5193 47.2047 35.2045L44.7119 33.342V28.6812L47.2221 26.8012C47.607 26.5039 48.0881 26.3465 48.6041 26.3465H51.709V35.6767Z" fill={secondaryColor}/>
<path d="M19.059 21.6857H14.3973C10.5401 21.6857 7.40021 24.8249 7.40021 28.6812V33.342C7.40021 37.1982 10.5401 40.3375 14.3973 40.3375H19.059C22.9161 40.3375 26.0561 37.1982 26.0561 33.342V28.6812C26.0561 24.8249 22.9161 21.6857 19.059 21.6857ZM21.3856 33.342C21.3856 34.6274 20.336 35.6767 19.0502 35.6767H14.3885C13.1028 35.6767 12.0532 34.6274 12.0532 33.342V28.6812C12.0532 27.3958 13.1028 26.3465 14.3885 26.3465H19.0502C20.336 26.3465 21.3856 27.3958 21.3856 28.6812V33.342Z" fill={primaryColor}/>
<path d="M33.0531 21.6857H30.7178C29.4321 21.6857 28.3826 22.7263 28.3826 24.0205C28.3826 25.3147 29.4233 26.3552 30.7178 26.3552H33.0531C34.3388 26.3552 35.3884 25.3147 35.3884 24.0205C35.3884 22.7263 34.3476 21.6857 33.0531 21.6857Z" fill={secondaryColor}/>
<path d="M33.0531 28.6812H30.7178C29.4321 28.6812 28.3826 29.7218 28.3826 31.016C28.3826 32.3102 29.4233 33.3507 30.7178 33.3507H33.0531C34.3388 33.3507 35.3884 32.3102 35.3884 31.016C35.3884 29.7218 34.3476 28.6812 33.0531 28.6812Z" fill={secondaryColor}/>
<path d="M33.0531 35.6767H30.7178C29.4321 35.6767 28.3826 36.7173 28.3826 38.0115C28.3826 39.3057 29.4233 40.3462 30.7178 40.3462H33.0531C34.3388 40.3462 35.3884 39.3057 35.3884 38.0115C35.3884 36.7173 34.3476 35.6767 33.0531 35.6767Z" fill={secondaryColor}/>
</svg>

);

export default Video_cameraIcon;
