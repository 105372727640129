import React from 'react';
const VideoIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M55.552 11.8871C54.8261 6.79788 50.7328 2.74051 45.5987 2.01473C34.4472 0.440742 22.9808 0.440737 11.838 2.02347C6.74762 2.74925 2.68929 6.84161 1.96335 11.9746C1.17618 17.536 0.773865 23.1936 0.773865 28.8075C0.773865 34.4214 1.17619 40.1489 1.97211 45.7366C2.69805 50.8258 6.79133 54.8832 11.9254 55.609C17.4881 56.396 23.1557 56.7982 28.7621 56.7982C34.3685 56.7982 40.0974 56.396 45.6863 55.6003C50.7766 54.8745 54.8348 50.7821 55.5608 45.6492C56.3479 40.0877 56.7503 34.4301 56.7503 28.8162C56.7503 23.2023 56.3479 17.4748 55.552 11.8871ZM50.9427 44.9846C50.5054 48.0801 48.074 50.5373 45.0303 50.9745C34.3161 52.5047 23.2956 52.5047 12.5726 50.9832C9.47644 50.546 7.01874 48.1151 6.58143 45.072C5.81175 39.7117 5.42696 34.2377 5.42696 28.8075C5.42696 23.3772 5.81174 17.9732 6.56392 12.6304C7.00124 9.53488 9.43272 7.07771 12.4764 6.64924C17.8467 5.88848 23.3219 5.49499 28.7447 5.49499C34.1674 5.49499 39.5813 5.87974 44.9253 6.63176C48.0215 7.06898 50.4792 9.49989 50.9165 12.5429C51.6862 17.9032 52.071 23.3772 52.071 28.8075C52.071 34.2377 51.6862 39.6418 50.9341 44.9846H50.9427Z" fill={secondaryColor}/>
<path d="M36.1352 32.725L25.7271 28.5626C23.9254 27.8456 21.8962 28.0642 20.2957 29.1485C18.6864 30.2328 17.733 32.0342 17.733 33.9754V42.3001C17.733 44.2326 18.6864 46.0427 20.2957 47.127C21.2753 47.7915 22.4123 48.1326 23.558 48.1326C24.2927 48.1326 25.0274 47.9926 25.7271 47.7128L36.1352 43.5505C38.3655 42.6586 39.7999 40.5337 39.7999 38.1377C39.7999 35.7418 38.3655 33.6169 36.1352 32.725ZM34.4035 39.222L23.9953 43.3844C23.4968 43.5855 23.1032 43.4019 22.9108 43.2707C22.7184 43.1395 22.3948 42.8422 22.3948 42.3088V33.9842C22.3948 33.442 22.7184 33.1447 22.9108 33.0223C23.042 32.9348 23.2694 32.8212 23.558 32.8212C23.6892 32.8212 23.8379 32.8474 23.9953 32.9086L34.4035 37.0709C35.0682 37.3333 35.1381 37.9191 35.1381 38.1552C35.1381 38.3913 35.0682 38.9685 34.4035 39.2395V39.222Z" fill={primaryColor}/>
<path d="M47.418 19.486H10.0974C8.81173 19.486 7.76213 20.5266 7.76213 21.8207C7.76213 23.1149 8.80298 24.1555 10.0974 24.1555H47.418C48.7037 24.1555 49.7532 23.1149 49.7532 21.8207C49.7532 20.5266 48.7124 19.486 47.418 19.486Z" fill={secondaryColor}/>
<path d="M14.7679 17.1512C15.3627 17.1512 15.9574 16.9239 16.421 16.4692L21.0828 11.8084C21.9924 10.899 21.9924 9.42122 21.0828 8.5118C20.1732 7.60239 18.6951 7.60239 17.7855 8.5118L13.1236 13.1725C12.214 14.082 12.214 15.5598 13.1236 16.4692C13.5784 16.9239 14.1732 17.1512 14.7767 17.1512H14.7679Z" fill={secondaryColor}/>
<path d="M26.4268 17.1512C27.0216 17.1512 27.6163 16.9239 28.0799 16.4692L32.7416 11.8084C33.6512 10.899 33.6512 9.42122 32.7416 8.5118C31.832 7.60239 30.3539 7.60239 29.4443 8.5118L24.7825 13.1725C23.8729 14.082 23.8729 15.5598 24.7825 16.4692C25.2373 16.9239 25.8321 17.1512 26.4356 17.1512H26.4268Z" fill={secondaryColor}/>
<path d="M38.0944 17.1512C38.6891 17.1512 39.2839 16.9239 39.7474 16.4692L44.4092 11.8084C45.3188 10.899 45.3188 9.42122 44.4092 8.5118C43.4996 7.60239 42.0214 7.60239 41.1118 8.5118L36.4501 13.1725C35.5404 14.082 35.5404 15.5598 36.4501 16.4692C36.9049 16.9239 37.4996 17.1512 38.1031 17.1512H38.0944Z" fill={secondaryColor}/>
</svg>

);

export default VideoIcon;
