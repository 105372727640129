import React from 'react';
const FingerprintIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="43" height="57" viewBox="0 0 43 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M42.3884 19.6989C41.7412 8.78596 32.6363 0.233948 21.6509 0.233948C10.6655 0.233948 1.53438 8.89091 0.8959 19.9438C0.738467 22.672 0.65976 25.4615 0.65976 28.2159C0.65976 30.9704 0.747203 33.9085 0.913383 36.733C1.56061 47.6459 10.6655 56.1979 21.6509 56.1979C32.6363 56.1979 41.7674 47.541 42.4059 36.4881C42.5634 33.7599 42.642 30.9704 42.642 28.2159C42.642 25.4615 42.5546 22.5234 42.3884 19.6989ZM37.7441 36.2171C37.2543 44.804 30.1873 51.5285 21.6509 51.5285C13.1145 51.5285 6.06497 44.9089 5.56643 36.4531C5.40899 33.7249 5.32153 30.9442 5.32153 28.2072C5.32153 25.4702 5.40026 22.8469 5.54894 20.2061C6.03874 11.6191 13.1058 4.89471 21.6422 4.89471C30.1786 4.89471 37.2281 11.5142 37.7266 19.97C37.884 22.6983 37.9715 25.479 37.9715 28.2159C37.9715 30.9529 37.8928 33.5763 37.7441 36.2171Z" fill={secondaryColor}/>
<path d="M25.3768 43.7635C24.1961 44.2794 22.9454 44.5417 21.6422 44.5417C16.4993 44.5417 12.3098 40.3532 12.3098 35.2115V30.5507C12.3098 29.2653 11.269 28.216 9.97459 28.216C8.68014 28.216 7.63931 29.2565 7.63931 30.5507V35.2115C7.63931 42.924 13.9192 49.2025 21.6334 49.2025C23.5751 49.2025 25.4643 48.809 27.231 48.0395C28.4118 47.5235 28.9541 46.1507 28.438 44.9702C27.922 43.7897 26.5489 43.2563 25.3681 43.7635H25.3768Z" fill={secondaryColor}/>
<path d="M21.6509 7.22946C13.9367 7.22946 7.6568 13.5079 7.6568 21.2205C7.6568 22.5059 8.6976 23.5552 9.99205 23.5552C11.2865 23.5552 12.3273 22.5146 12.3273 21.2205C12.3273 16.0788 16.5168 11.8902 21.6596 11.8902C26.8025 11.8902 30.9919 16.0788 30.9919 21.2205V23.5552C30.9919 24.8406 32.0328 25.89 33.3272 25.89C34.6217 25.89 35.6625 24.8494 35.6625 23.5552V21.2205C35.6625 13.5079 29.3826 7.22946 21.6684 7.22946H21.6509Z" fill={secondaryColor}/>
<path d="M26.3127 25.8812C27.5984 25.8812 28.648 24.8407 28.648 23.5465V21.2117C28.648 17.3554 25.508 14.2162 21.6509 14.2162C17.7938 14.2162 14.6539 17.3554 14.6539 21.2117V28.2072C14.6539 29.4926 15.6947 30.542 16.9891 30.542C18.2836 30.542 19.3244 29.5014 19.3244 28.2072V21.2117C19.3244 19.9263 20.3739 18.877 21.6596 18.877C22.9454 18.877 23.9949 19.9263 23.9949 21.2117V23.5465C23.9949 24.8319 25.0357 25.8812 26.3302 25.8812H26.3127Z" fill={primaryColor}/>
<path d="M21.6509 42.207C25.508 42.207 28.648 39.0677 28.648 35.2115V32.8767C28.648 31.5913 27.6071 30.542 26.3127 30.542C25.0182 30.542 23.9774 31.5826 23.9774 32.8767V35.2115C23.9774 36.4969 22.9279 37.5462 21.6422 37.5462C20.3564 37.5462 19.3069 36.4969 19.3069 35.2115C19.3069 33.9261 18.2661 32.8767 16.9716 32.8767C15.6772 32.8767 14.6364 33.9173 14.6364 35.2115C14.6364 39.0677 17.7763 42.207 21.6334 42.207H21.6509Z" fill={primaryColor}/>
</svg>

);

export default FingerprintIcon;
