import React from 'react';
const CameraIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="48" viewBox="0 0 57 48" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M45.0915 10.0208C43.4209 10.0208 41.9603 8.88397 41.5579 7.27501L41.488 6.98646C40.5609 3.27884 37.2461 0.699249 33.4327 0.699249H24.1003C20.2782 0.699249 16.9634 3.2876 16.0362 6.99521L15.9662 7.26628C15.5551 8.89274 14.1033 10.0208 12.4328 10.0208C6.00423 10.0208 0.773865 15.2499 0.773865 21.677V35.668C0.773865 42.0951 6.00423 47.3242 12.4328 47.3242H45.0827C51.5113 47.3242 56.7415 42.0951 56.7415 35.668V21.677C56.7415 15.2499 51.5113 10.0208 45.0827 10.0208H45.0915ZM52.0885 35.6767C52.0885 39.533 48.9486 42.6722 45.0915 42.6722H12.4415C8.5844 42.6722 5.44447 39.533 5.44447 35.6767V21.6857C5.44447 17.8295 8.5844 14.6902 12.4415 14.6902C16.2986 14.6902 19.5785 12.1019 20.5056 8.39429L20.5755 8.12321C20.9866 6.49676 22.4385 5.36874 24.1091 5.36874H33.4414C35.112 5.36874 36.5725 6.50552 36.9749 8.11449L37.0449 8.40304C37.972 12.1107 41.2868 14.6902 45.1002 14.6902C48.9136 14.6902 52.0973 17.8295 52.0973 21.6857V35.6767H52.0885Z" fill={secondaryColor}/>
<path d="M28.762 14.6902C22.3335 14.6902 17.1032 19.9194 17.1032 26.3465C17.1032 32.7736 22.3335 38.0027 28.762 38.0027C35.1906 38.0027 40.4209 32.7736 40.4209 26.3465C40.4209 19.9194 35.1906 14.6902 28.762 14.6902ZM28.762 33.342C24.9049 33.342 21.765 30.2027 21.765 26.3465C21.765 22.4902 24.9049 19.351 28.762 19.351C32.6192 19.351 35.7591 22.4902 35.7591 26.3465C35.7591 30.2027 32.6192 33.342 28.762 33.342Z" fill={primaryColor}/>
<path d="M45.0914 17.0162H42.7561C41.4704 17.0162 40.4209 18.0568 40.4209 19.351C40.4209 20.6451 41.4617 21.6857 42.7561 21.6857H45.0914C46.3772 21.6857 47.4267 20.6451 47.4267 19.351C47.4267 18.0568 46.3859 17.0162 45.0914 17.0162Z" fill={secondaryColor}/>
</svg>

);

export default CameraIcon;
