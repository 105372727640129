import React from 'react';
const SmartphoneIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="43" height="57" viewBox="0 0 43 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M41.2717 10.7735C40.5545 4.99345 35.5779 0.630005 29.7004 0.630005H13.0649C7.17861 0.630005 2.20196 5.04591 1.47601 10.9134C0.75007 16.7721 0.391479 22.727 0.391479 28.612C0.391479 34.497 0.758833 40.5568 1.49352 46.4505C2.21072 52.2306 7.18736 56.594 13.0649 56.594H29.7004C35.5866 56.594 40.5633 52.1781 41.2892 46.3106C42.0152 40.4519 42.3738 34.497 42.3738 28.612C42.3738 22.727 42.0064 16.6672 41.2717 10.7735ZM36.6624 45.7422C36.2251 49.275 33.2339 51.9333 29.7004 51.9333H13.0649C9.54011 51.9333 6.55757 49.3274 6.129 45.8734C5.42055 40.1721 5.06198 34.3658 5.06198 28.612C5.06198 22.8582 5.42054 17.1481 6.1115 11.4818C6.54881 7.94905 9.54012 5.29076 13.0736 5.29076H29.7091C33.2339 5.29076 36.2163 7.89658 36.6449 11.3506C37.3534 17.0519 37.7119 22.8582 37.7119 28.612C37.7119 34.3658 37.3534 40.0759 36.6624 45.7422Z" fill={secondaryColor}/>
<path d="M28.3796 44.9377H14.3855C13.0998 44.9377 12.0502 45.9783 12.0502 47.2725C12.0502 48.5667 13.0911 49.6072 14.3855 49.6072H28.3796C29.6653 49.6072 30.7148 48.5667 30.7148 47.2725C30.7148 45.9783 29.6741 44.9377 28.3796 44.9377Z" fill={primaryColor}/>
</svg>

);

export default SmartphoneIcon;
