import React from 'react';
const MinimizeIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M55.6819 11.2807C54.9559 6.19143 50.8627 2.13403 45.7286 1.40825C34.577 -0.165741 23.1193 -0.165732 11.959 1.417C6.86866 2.14278 2.81039 6.23514 2.08444 11.3681C1.29728 16.9295 0.894958 22.5871 0.894958 28.201C0.894958 33.8149 1.29728 39.5337 2.0932 45.1301C2.81914 50.2193 6.91242 54.2767 12.0465 55.0025C17.6092 55.7895 23.268 56.1918 28.8832 56.1918C34.4983 56.1918 40.2184 55.7895 45.816 54.9938C50.9064 54.268 54.9646 50.1756 55.6906 45.0427C56.4777 39.4813 56.8801 33.8237 56.8801 28.2098C56.8801 22.5959 56.4778 16.8771 55.6819 11.2807ZM51.0813 44.3869C50.644 47.4824 48.2125 49.9395 45.1688 50.3767C34.4458 51.8983 23.4342 51.907 12.7112 50.3855C9.61504 49.9483 7.1573 47.5173 6.71998 44.4743C5.9503 39.0965 5.56546 33.6313 5.56546 28.201C5.56546 22.7708 5.95034 17.3667 6.70252 12.0239C7.13984 8.92841 9.57127 6.47125 12.615 6.03403C17.994 5.26452 23.4605 4.87976 28.8919 4.87976C34.3234 4.87976 39.7286 5.26452 45.0726 6.01654C48.1688 6.45375 50.6265 8.88469 51.0638 11.9277C51.8335 17.3055 52.2183 22.7708 52.2183 28.201C52.2183 33.6313 51.8335 39.0353 51.0813 44.3781V44.3869Z" fill={secondaryColor}/>
<path d="M43.5682 10.2401L35.8889 17.9176V14.2188C35.8889 12.9333 34.8481 11.884 33.5536 11.884C32.2592 11.884 31.2184 12.9246 31.2184 14.2188V23.549C31.2184 24.8344 32.2592 25.8838 33.5536 25.8838H42.886C44.1717 25.8838 45.2212 24.8432 45.2212 23.549C45.2212 22.2548 44.1804 21.2143 42.886 21.2143H39.1863L46.8656 13.5367C47.7752 12.6273 47.7752 11.1495 46.8656 10.2401C45.9559 9.33066 44.4778 9.33066 43.5682 10.2401Z" fill={primaryColor}/>
<path d="M24.2301 30.5445H14.8978C13.6121 30.5445 12.5625 31.5851 12.5625 32.8793C12.5625 34.1734 13.6033 35.214 14.8978 35.214H18.5974L10.9182 42.8916C10.0086 43.801 10.0086 45.2788 10.9182 46.1882C11.8278 47.0976 13.3059 47.0976 14.2155 46.1882L21.8948 38.5107V42.2095C21.8948 43.4949 22.9356 44.5443 24.2301 44.5443C25.5245 44.5443 26.5653 43.5037 26.5653 42.2095V32.8793C26.5653 31.5938 25.5245 30.5445 24.2301 30.5445Z" fill={primaryColor}/>
</svg>

);

export default MinimizeIcon;
