import React from 'react';
const EyeIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="42" viewBox="0 0 57 42" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M52.1299 10.3673C47.4944 5.64536 39.6139 0.0227661 28.4012 0.0227661C17.1884 0.0227661 9.30796 5.64536 4.67241 10.3673C1.92607 13.1655 0.412964 16.9344 0.412964 21.0005V21.018C0.412964 25.0842 1.92607 28.8616 4.67241 31.6511C9.30796 36.3731 17.1884 41.9958 28.4012 41.9958C39.6139 41.9958 47.4944 36.3731 52.1299 31.6511C54.8763 28.8529 56.3894 25.0841 56.3894 21.0005C56.3894 16.9169 54.8763 13.1568 52.1299 10.3673ZM48.8063 28.3895C44.7917 32.4731 37.9959 37.3349 28.4012 37.3349C18.8065 37.3349 12.0106 32.4643 7.99602 28.3895C6.1418 26.5007 5.07475 23.8162 5.07475 21.0268V21.0093C5.07475 18.2111 6.1418 15.5265 7.99602 13.6465C12.0106 9.56284 18.8065 4.70098 28.4012 4.70098C37.9959 4.70098 44.7917 9.57158 48.8063 13.6465C50.6605 15.5352 51.7276 18.2198 51.7276 21.0268C51.7276 23.8337 50.6605 26.5094 48.8063 28.3895Z" fill={secondaryColor}/>
<path d="M28.4012 7.02701C20.6869 7.02701 14.4071 13.3055 14.4071 21.018C14.4071 28.7305 20.6869 35.009 28.4012 35.009C36.1154 35.009 42.3953 28.7305 42.3953 21.018C42.3953 13.3055 36.1154 7.02701 28.4012 7.02701ZM28.4012 30.3394C23.2583 30.3394 19.0688 26.1597 19.0688 21.0093C19.0688 15.8588 23.2583 11.679 28.4012 11.679C33.544 11.679 37.7335 15.8588 37.7335 21.0093C37.7335 26.1597 33.544 30.3394 28.4012 30.3394Z" fill={primaryColor}/>
<path d="M28.4012 15.1855C27.1155 15.1855 26.0659 16.2261 26.0659 17.5202C26.0659 18.8144 27.1067 19.8549 28.4012 19.8549C29.0484 19.8549 29.5644 20.3797 29.5644 21.018C29.5644 22.3034 30.6052 23.3527 31.8997 23.3527C33.1941 23.3527 34.235 22.3122 34.235 21.018C34.235 17.8001 31.6198 15.1855 28.4012 15.1855Z" fill={primaryColor}/>
</svg>

);

export default EyeIcon;
