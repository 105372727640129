import React from 'react';
const Crossed_eyeIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="56" height="42" viewBox="0 0 56 42" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M51.7504 10.3673C47.1149 5.64536 39.2344 0.0227661 28.0216 0.0227661C16.8089 0.0227661 8.92847 5.64536 4.29292 10.3673C1.54658 13.1655 0.0334473 16.9344 0.0334473 21.0005V21.018C0.0334473 25.0842 1.54658 28.8616 4.29292 31.6511C8.92847 36.3731 16.8089 41.9958 28.0216 41.9958C39.2344 41.9958 47.1149 36.3731 51.7504 31.6511C54.4967 28.8529 56.0098 25.0841 56.0098 21.0005C56.0098 16.9169 54.4967 13.1568 51.7504 10.3673ZM48.4268 28.3895C44.4123 32.4731 37.6164 37.3349 28.0216 37.3349C18.4269 37.3349 11.6311 32.4643 7.61651 28.3895C5.76229 26.5007 4.69525 23.8162 4.69525 21.0268V21.0093C4.69525 18.2111 5.76229 15.5265 7.61651 13.6465C11.6311 9.56284 18.4269 4.70098 28.0216 4.70098C37.6164 4.70098 44.4123 9.57158 48.4268 13.6465C50.281 15.5352 51.3481 18.2198 51.3481 21.0268C51.3481 23.8337 50.281 26.5094 48.4268 28.3895Z" fill={secondaryColor}/>
<path d="M28.0217 7.02701C24.9604 7.02701 22.1354 8.02381 19.8264 9.70273C18.9342 9.19556 17.806 9.28301 17.045 10.0438C16.2841 10.8045 16.1967 11.9414 16.704 12.8245C15.0334 15.133 14.0276 17.9575 14.0276 21.018C14.0276 28.7305 20.3074 35.009 28.0217 35.009C31.0829 35.009 33.9079 34.0121 36.217 32.3332C36.5756 32.5343 36.9517 32.6742 37.354 32.6742C37.9487 32.6742 38.5435 32.4468 39.0071 31.9921C39.768 31.2314 39.8554 30.0947 39.3481 29.2115C41.0187 26.903 42.0245 24.0785 42.0245 21.018C42.0245 13.3055 35.7447 7.02701 28.0304 7.02701H28.0217ZM37.354 21.018C37.354 22.7406 36.8554 24.3409 36.0333 25.7225L23.3162 13.0081C24.7068 12.1862 26.2986 11.6877 28.0217 11.6877C33.1645 11.6877 37.354 15.8676 37.354 21.018ZM18.6981 21.018C18.6981 19.2954 19.1966 17.6951 20.0188 16.3135L32.7359 29.0278C31.3453 29.8497 29.7534 30.3482 28.0304 30.3482C22.8876 30.3482 18.6981 26.1684 18.6981 21.018Z" fill={primaryColor}/>
</svg>

);

export default Crossed_eyeIcon;
