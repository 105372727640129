import {
  Button,
  TextField,
  Box,
  Grid,
  Paper,
  useMediaQuery,
} from '@mui/material';
import { Form, Formik } from 'formik';

const UserForm = (props: any) => {
  const { entity, onSubmit, errors, handleCancel } = props;
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};
        if (!values.fullName) {
          errors.fullName = 'Kötelező mező';
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        handleChange,
        errors: validationErrors,
      }) => (
        <Form>
          {' '}
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>
                Felhasználó {values.id > 0 ? 'szerkesztése' : 'létrehozása'}
              </h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error: string) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.fullName}
                  onChange={handleChange}
                  label="Megjelenítési név"
                  name="fullName"
                  fullWidth
                  autoFocus
                  error={!!touched.fullName && !!validationErrors.fullName}
                  helperText={
                    !!touched.fullName &&
                    !!validationErrors.fullName &&
                    (validationErrors.fullName as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.email}
                  onChange={handleChange}
                  label="Email"
                  name="UserEmail"
                  disabled
                  fullWidth
                  error={!!touched.email && !!validationErrors.email}
                  helperText={
                    !!touched.email &&
                    !!validationErrors.email &&
                    (validationErrors.email as string)
                  }
                />
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                <Grid item xs={12} sm={isSmallScreen ? 12 : 'auto'} p={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth={isSmallScreen}
                    disabled={isSubmitting}
                  >
                    Mentés
                  </Button>
                </Grid>

                <Grid item xs={12} sm={isSmallScreen ? 12 : 'auto'} p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth={isSmallScreen}
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default UserForm;
