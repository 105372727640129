import {
  Button,
  TextField,
  Box,
  Grid,
  Paper,
  Autocomplete,
  useMediaQuery,
} from '@mui/material';
import { Form, Formik } from 'formik';
import PermissionDialog from './PermissionDialog';
import React from 'react';

export const translateRoleName = (name: string) => {
  switch (name) {
    default:
      return name;
  }
};

const RoleForm = (props: any) => {
  const { entity, onSubmit, errors, handleCancel, permissions } = props;

  const [openPermissionDialog, setOpenPermissionDialog] = React.useState(false);
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );
  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};
        if (!values.name) {
          errors.name = 'Kötelező mező!';
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        handleChange,
        setFieldValue,
        errors: validationErrors,
      }) => (
        <Form>
          {' '}
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>
                Szerepkör {values.id > 0 ? 'szerkesztése' : 'létrehozása'}
              </h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error: string) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={translateRoleName(values.name)}
                  onChange={handleChange}
                  label="Megnevezés"
                  name="name"
                  fullWidth
                  disabled={values.isSystem}
                  autoFocus
                  error={!!touched.name && !!validationErrors.name}
                  helperText={
                    !!touched.name &&
                    !!validationErrors.name &&
                    (validationErrors.name as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.description}
                  onChange={handleChange}
                  label="Megjegyzés"
                  name="description"
                  fullWidth
                  autoFocus
                  error={
                    !!touched.description && !!validationErrors.description
                  }
                  helperText={
                    !!touched.description &&
                    !!validationErrors.description &&
                    (validationErrors.description as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  onClick={() => setOpenPermissionDialog(true)}
                >
                  Jogosultságok Változtatása
                </Button>
                <PermissionDialog
                  open={openPermissionDialog}
                  permissions={permissions}
                  selectedPermissions={values.permissions}
                  onClose={(selectedPermissions: any) => {
                    setFieldValue('permissions', selectedPermissions);
                    setOpenPermissionDialog(false);
                  }}
                />
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                <Grid item xs={12} sm={isSmallScreen ? 12 : 'auto'} p={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth={isSmallScreen}
                    disabled={isSubmitting}
                  >
                    Mentés
                  </Button>
                </Grid>

                <Grid item xs={12} sm={isSmallScreen ? 12 : 'auto'} p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth={isSmallScreen}
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default RoleForm;
