import React from 'react';
const ContrastIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M28.2714 9.35998C24.3356 9.35998 20.9158 12.1757 20.1373 16.0582C19.3502 19.9844 18.9478 24.0156 18.9478 28.0205C18.9478 32.0254 19.3502 36.0566 20.1373 39.9828C20.9158 43.8566 24.3356 46.6722 28.2714 46.6722C38.5571 46.6722 46.9273 38.3039 46.9273 28.0205C46.9273 17.7371 38.5571 9.36873 28.2714 9.36873V9.35998ZM28.2714 42.0027C26.5484 42.0027 25.0527 40.761 24.7116 39.0559C23.9769 35.427 23.6096 31.7106 23.6096 28.0117C23.6096 24.3129 23.9769 20.5965 24.7116 16.9764C25.0527 15.2625 26.5571 14.0207 28.2801 14.0207C35.9944 14.0207 42.2742 20.2992 42.2742 28.0117C42.2742 35.7243 35.9944 42.0027 28.2801 42.0027H28.2714Z" fill={primaryColor}/>
<path d="M53.9243 25.6857H53.8019C53.3208 20.3604 51.213 15.5073 47.9681 11.6161L48.0556 11.5286C48.9652 10.6192 48.9652 9.1414 48.0556 8.23198C47.1459 7.32257 45.6678 7.32257 44.7582 8.23198L44.6707 8.3194C40.7786 5.07524 35.9244 2.96786 30.5979 2.48692V2.36449C30.5979 1.07906 29.5571 0.0297546 28.2626 0.0297546C26.9682 0.0297546 25.9274 1.07032 25.9274 2.36449V2.48692C20.6009 2.96786 15.7467 5.07524 11.8546 8.3194L11.7671 8.23198C10.8575 7.32257 9.37935 7.32257 8.46973 8.23198C7.56012 9.1414 7.56012 10.6192 8.46973 11.5286L8.55723 11.6161C5.31235 15.5073 3.20445 20.3604 2.7234 25.6857H2.60099C1.31528 25.6857 0.265686 26.7263 0.265686 28.0205C0.265686 29.3147 1.30654 30.3552 2.60099 30.3552H2.7234C3.20445 35.6806 5.31235 40.5337 8.55723 44.425L8.46973 44.5124C7.56012 45.4218 7.56012 46.8996 8.46973 47.809C8.92454 48.2637 9.5193 48.4911 10.1228 48.4911C10.7263 48.4911 11.3123 48.2637 11.7759 47.809L11.8633 47.7216C15.7554 50.9657 20.6096 53.0732 25.9361 53.5541V53.6765C25.9361 54.9619 26.9769 56.0113 28.2714 56.0113C29.5659 56.0113 30.6066 54.9706 30.6066 53.6765V53.5541C35.9332 53.0732 40.7874 50.9657 44.6795 47.7216L44.7669 47.809C45.2217 48.2637 45.8165 48.4911 46.42 48.4911C47.0235 48.4911 47.6095 48.2637 48.0731 47.809C48.9827 46.8996 48.9827 45.4218 48.0731 44.5124L47.9856 44.425C51.2304 40.5337 53.3383 35.6806 53.8194 30.3552H53.9418C55.2275 30.3552 56.2771 29.3147 56.2771 28.0205C56.2771 26.7263 55.2363 25.6857 53.9418 25.6857H53.9243ZM28.2714 48.9983C16.7 48.9983 7.28025 39.5806 7.28025 28.0118C7.28025 16.4429 16.7 7.02525 28.2714 7.02525C39.8428 7.02525 49.2625 16.4429 49.2625 28.0118C49.2625 39.5806 39.8428 48.9983 28.2714 48.9983Z" fill={secondaryColor}/>
</svg>

);

export default ContrastIcon;
