import { useState } from 'react';
import useConfirmDialog from './useConfirmDialog';

const useConfirmDeleteDialog = () => {
  const { ConfirmDialog, setParams, params } = useConfirmDialog();

  const ConfirmDeleteDialog = (props: any) => {
    return <ConfirmDialog actionName="Törlés" dialogTitle={params.name} />;
  };
  return {
    ConfirmDeleteDialog,
    setParams: setParams,
  };
};
export default useConfirmDeleteDialog;
