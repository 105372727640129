import React from 'react';
const Dvd_playerIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M28.6509 0.831726C13.2224 0.831726 0.66272 13.3886 0.66272 28.8137C0.66272 44.2388 13.2224 56.7957 28.6509 56.7957C44.0794 56.7957 56.6391 44.2388 56.6391 28.8137C56.6391 13.3886 44.0794 0.831726 28.6509 0.831726ZM28.6509 52.135C15.7938 52.135 5.32449 41.6767 5.32449 28.8137C5.32449 15.9507 15.7851 5.49249 28.6509 5.49249C41.5167 5.49249 51.9773 15.9507 51.9773 28.8137C51.9773 41.6767 41.5167 52.135 28.6509 52.135Z" fill={secondaryColor}/>
<path d="M40.3097 28.8137C40.3097 22.3866 35.0795 17.1575 28.6509 17.1575C22.2224 17.1575 16.9921 22.3866 16.9921 28.8137C16.9921 35.2409 22.2224 40.47 28.6509 40.47C35.0795 40.47 40.3097 35.2409 40.3097 28.8137ZM28.6509 35.8092C24.7938 35.8092 21.6539 32.67 21.6539 28.8137C21.6539 24.9575 24.7938 21.8182 28.6509 21.8182C32.508 21.8182 35.648 24.9575 35.648 28.8137C35.648 32.67 32.508 35.8092 28.6509 35.8092Z" fill={secondaryColor}/>
<path d="M28.6509 22.9812C25.4323 22.9812 22.8171 25.5958 22.8171 28.8137C22.8171 32.0316 25.4323 34.6462 28.6509 34.6462C31.8696 34.6462 34.4847 32.0316 34.4847 28.8137C34.4847 25.5958 31.8696 22.9812 28.6509 22.9812ZM28.6509 29.9767C28.0037 29.9767 27.4876 29.4521 27.4876 28.8137C27.4876 28.1754 28.0124 27.6507 28.6509 27.6507C29.2894 27.6507 29.8142 28.1754 29.8142 28.8137C29.8142 29.4521 29.2894 29.9767 28.6509 29.9767Z" fill={secondaryColor}/>
<path d="M17.5518 17.9532C17.8929 18.8976 18.7938 19.4922 19.7471 19.4922C20.0095 19.4922 20.2806 19.4485 20.543 19.3523C21.75 18.9151 22.3798 17.5772 21.9425 16.3617L20.2457 11.701C19.8083 10.4943 18.4702 9.86468 17.2544 10.3019C16.0475 10.7391 15.4177 12.077 15.8638 13.2925L17.5605 17.9532H17.5518Z" fill={primaryColor}/>
<path d="M40.0474 10.3019C38.8404 9.8647 37.5022 10.4855 37.0561 11.701L35.3593 16.3618C34.922 17.5685 35.543 18.9064 36.7587 19.3523C37.0211 19.4485 37.2922 19.4922 37.5546 19.4922C38.508 19.4922 39.4001 18.9064 39.75 17.9532L41.4467 13.2925C41.8841 12.0858 41.2631 10.7479 40.0474 10.3019Z" fill={primaryColor}/>
<path d="M39.7412 39.6742C39.3039 38.4675 37.9657 37.8379 36.75 38.2752C35.543 38.7124 34.9133 40.0503 35.3506 41.2657L37.0474 45.9265C37.3885 46.8709 38.2894 47.4655 39.2427 47.4655C39.5051 47.4655 39.7762 47.4218 40.0386 47.3256C41.2456 46.8884 41.8753 45.5505 41.438 44.335L39.7412 39.6742Z" fill={primaryColor}/>
<path d="M20.5431 38.2839C19.3361 37.8467 17.9979 38.4675 17.5518 39.683L15.855 44.3438C15.4177 45.5505 16.0387 46.8884 17.2457 47.3343C17.5081 47.4305 17.7792 47.4742 18.0416 47.4742C18.995 47.4742 19.8871 46.8884 20.2369 45.9352L21.9337 41.2745C22.371 40.0677 21.7501 38.7299 20.5343 38.2839H20.5431Z" fill={primaryColor}/>
</svg>

);

export default Dvd_playerIcon;
