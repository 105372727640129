import React from 'react';
const Three_d_cubeIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M50.8558 11.8141L37.4127 3.00847C31.7538 -0.699144 24.0658 -0.66417 18.4419 3.08717L5.35748 11.8141C2.10385 13.9827 0.16217 17.6028 0.16217 21.5116V34.8467C0.16217 38.7555 2.10385 42.3844 5.35748 44.5443L18.4419 53.2711C21.2757 55.1599 24.5819 56.1568 27.9929 56.1568C31.404 56.1568 34.6139 55.1862 37.4214 53.3498L50.8645 44.553C54.1706 42.3931 56.1386 38.7467 56.1386 34.7943V21.564C56.1386 17.6203 54.1619 13.9739 50.8645 11.8141H50.8558ZM51.4593 34.803C51.4593 37.1727 50.2785 39.3588 48.2931 40.653L34.85 49.4586C30.7305 52.1606 25.1241 52.1344 21.0221 49.3974L7.93766 40.6705C5.98723 39.3676 4.82397 37.199 4.82397 34.8467V21.5116C4.82397 19.1681 5.98723 16.9907 7.93766 15.6878L21.0221 6.96093C23.0862 5.57932 25.4915 4.85353 27.9842 4.85353C30.4769 4.85353 32.8121 5.56182 34.85 6.89971L48.2931 15.7053C50.2785 16.9995 51.4593 19.1856 51.4593 21.5553V34.7855V34.803Z" fill={secondaryColor}/>
<path d="M45.4768 19.1943L34.8325 26.1461C30.7655 28.8306 25.1241 28.8219 21.0133 26.0849L10.8239 19.273C9.75683 18.556 8.30493 18.8445 7.58773 19.9201C6.87054 20.9956 7.15915 22.4385 8.2262 23.1555L18.4244 29.9674C20.6547 31.4539 23.1737 32.3633 25.8063 32.6956V46.844C25.8063 48.1294 26.8471 49.1788 28.1416 49.1788C29.436 49.1788 30.4768 48.1382 30.4768 46.844V32.6694C32.952 32.3109 35.3136 31.4189 37.3952 30.0461L48.0307 23.0943C49.1065 22.3947 49.4126 20.9432 48.7042 19.8676C48.0044 18.7921 46.5526 18.486 45.4768 19.1943Z" fill={primaryColor}/>
</svg>

);

export default Three_d_cubeIcon;
