import React from 'react';
const Shopping_cart_1Icon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="54" viewBox="0 0 57 54" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M53.7945 42.1744H16.474C15.1883 42.1744 14.1387 41.1251 14.1387 39.8397C14.1387 38.5543 15.1883 37.505 16.474 37.505H40.64C45.3193 37.505 49.5263 34.733 51.363 30.4483L55.6575 20.4359C55.9636 19.6664 56.1298 18.8445 56.1298 18.0575C56.1298 14.6385 53.3484 11.8577 49.9286 11.8577H16.2028L15.6781 9.41806C14.5323 4.07524 9.73936 0.201477 4.27291 0.201477H2.48863C1.20292 0.201477 0.153381 1.24207 0.153381 2.53624C0.153381 3.8304 1.19418 4.87097 2.48863 4.87097H4.27291C7.55278 4.87097 10.4303 7.19698 11.1125 10.3974L15.9317 32.9054C12.3282 33.1853 9.47693 36.1758 9.47693 39.8485C9.47693 43.7047 12.6169 46.844 16.474 46.844H18.8093C18.8093 50.7002 21.9492 53.8395 25.8063 53.8395C29.6635 53.8395 32.8034 50.7002 32.8034 46.844H37.4651C37.4651 50.7002 40.6051 53.8395 44.4622 53.8395C48.3193 53.8395 51.4592 50.7002 51.4592 46.844H53.7945C55.0802 46.844 56.1298 45.8034 56.1298 44.5092C56.1298 43.2151 55.089 42.1744 53.7945 42.1744ZM49.9199 16.5185C50.7858 16.5185 51.4592 17.1918 51.4592 18.0575C51.4592 18.2499 51.4068 18.4947 51.3456 18.6521L47.0774 28.612C45.9753 31.1828 43.4476 32.8442 40.64 32.8442H20.6897L17.1912 16.5185H49.9199ZM25.8063 49.1699C24.5206 49.1699 23.471 48.1206 23.471 46.8352H28.1328C28.1328 48.1206 27.0833 49.1699 25.7976 49.1699H25.8063ZM44.4622 49.1699C43.1765 49.1699 42.1269 48.1206 42.1269 46.8352H46.7887C46.7887 48.1206 45.7392 49.1699 44.4535 49.1699H44.4622Z" fill={secondaryColor}/>
<path d="M29.3049 27.0117H30.4681V28.1748C30.4681 29.4602 31.5089 30.5095 32.8034 30.5095C34.0978 30.5095 35.1386 29.4689 35.1386 28.1748V27.0117H36.3019C37.5876 27.0117 38.6372 25.9712 38.6372 24.677C38.6372 23.3828 37.5964 22.3423 36.3019 22.3423H35.1386V21.1792C35.1386 19.8938 34.0978 18.8445 32.8034 18.8445C31.5089 18.8445 30.4681 19.8851 30.4681 21.1792V22.3423H29.3049C28.0192 22.3423 26.9696 23.3828 26.9696 24.677C26.9696 25.9712 28.0104 27.0117 29.3049 27.0117Z" fill={primaryColor}/>
</svg>

);

export default Shopping_cart_1Icon;
