const initialState = {
  userInfo: {
    fullName: "Test Elek",
    email: "test@elek.hu",
    authorityInfo: {
      groups: [],
      permissions: [],
      roles: [],
    },
  },
};

export const userReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "LOAD_USER_INFO":
      return {
        ...state,
        userInfo: action.payload,
      };
    default:
      return state;
  }
};
