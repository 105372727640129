import React from 'react';
const EditIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M55.1726 11.4849C54.4466 6.39565 50.3533 2.33825 45.2193 1.61247C34.0677 0.0384831 22.6013 0.0384917 11.4585 1.62122C6.36816 2.34701 2.30984 6.43938 1.58389 11.5723C0.796727 17.1337 0.394409 22.7914 0.394409 28.4052C0.394409 34.0191 0.796735 39.7467 1.59265 45.3343C2.31859 50.4236 6.41187 54.481 11.546 55.2067C17.1086 55.9937 22.7762 56.396 28.3826 56.396C33.989 56.396 39.7178 55.9937 45.3067 55.198C50.3971 54.4722 54.4554 50.3798 55.1813 45.2469C55.9685 39.6855 56.3708 34.0279 56.3708 28.414C56.3708 22.8001 55.9685 17.0725 55.1726 11.4849ZM50.572 44.5911C50.1347 47.6866 47.7032 50.1437 44.6595 50.581C33.9453 52.1112 22.9249 52.1112 12.2019 50.5897C9.10574 50.1525 6.64804 47.7216 6.21073 44.6785C5.44105 39.3182 5.05616 33.8442 5.05616 28.414C5.05616 22.9837 5.44104 17.5797 6.19322 12.2369C6.63053 9.14137 9.06202 6.68421 12.1057 6.25573C17.476 5.49497 22.9511 5.10149 28.3739 5.10149C33.7966 5.10149 39.2105 5.48624 44.5545 6.23825C47.6507 6.67547 50.1084 9.10641 50.5457 12.1495C51.3154 17.5098 51.7002 22.9837 51.7002 28.414C51.7002 33.8442 51.3154 39.2483 50.5632 44.5911H50.572Z" fill={secondaryColor}/>
<path d="M42.5603 28.4927C45.7003 24.4965 45.3504 18.7602 41.6507 15.0613C38.0385 11.4586 32.3096 11.1001 28.3038 14.2394C26.126 15.9533 24.0269 17.8158 22.0677 19.7746C19.1464 22.6952 16.4701 25.8956 14.1173 29.2797C12.7704 31.2122 12.062 33.5032 12.062 35.9079V42.4137C12.062 43.6992 13.1028 44.7485 14.3973 44.7485H20.9045C23.3009 44.7485 25.5924 44.0402 27.5341 42.6936C30.9189 40.3326 34.1202 37.6568 37.0415 34.7449C39.0006 32.7862 40.8548 30.6963 42.5778 28.5102L42.5603 28.4927ZM38.4409 18.4454C40.0939 20.0981 40.4612 22.564 39.5166 24.5752L32.2135 17.2824C34.2339 16.3293 36.7178 16.714 38.4409 18.4454ZM16.7237 36.3714L20.4235 40.0702H16.7237V36.3714ZM25.3913 38.4438L18.3505 31.4046C20.4496 28.449 22.8024 25.6333 25.3651 23.0624C26.3709 22.0656 27.4118 21.095 28.4876 20.1506L36.6391 28.3003C35.6945 29.3759 34.7324 30.4164 33.7266 31.422C31.1552 33.9929 28.3476 36.3364 25.3826 38.4351L25.3913 38.4438Z" fill={primaryColor}/>
<path d="M42.3767 40.0703H35.3796C34.0939 40.0703 33.0443 41.1108 33.0443 42.405C33.0443 43.6992 34.0852 44.7397 35.3796 44.7397H42.3767C43.6624 44.7397 44.7119 43.6992 44.7119 42.405C44.7119 41.1108 43.6712 40.0703 42.3767 40.0703Z" fill={secondaryColor}/>
</svg>

);

export default EditIcon;
