import { Dialog, DialogContent } from '@mui/material';
import ImageBrowserGallery from './ImageBrowserGallery';

type ImageBrowserGalleryProps = {
  open: boolean;
  publisherId: number;
  handleClose: () => void;
  handleSelect: (item: any) => void;
};

const ImageBrowserGalleryDialog = (props: ImageBrowserGalleryProps) => {
  const { open, handleClose, publisherId, handleSelect } = props;

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogContent>
          <ImageBrowserGallery
            handleSelect={handleSelect}
            publisherId={publisherId}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ImageBrowserGalleryDialog;
