import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useCheckPermission from '../../hooks/useCheckPermission';
import faqCategoryService from '../../services/faqCategoryService';
import faqService from '../../services/faqService';
import FaqForm from './FaqForm';

const FaqCreate = (props: any) => {
  const { onSave = null, navigateBack = true, handleCancel = null } = props;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const [categories, setCategories] = useState<any[]>([]);

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    faqCategoryService.list().then((response: any) => {
      if (!response.hasError) setCategories(response.records);
      else
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
    });
  }, []);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    faqService
      .create(entity)
      .then((response) => {
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Success!', {
            variant: 'success',
          });

          if (onSave) onSave(response.result);
          if (navigateBack) navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        if (setSubmitting) setSubmitting(false);
      });
  };

  return (
    <FaqForm
      entity={{ faqCategoryId: '', Question: '', Answer: '' }}
      readonly={!checkPermission(['CreateFaq'])}
      onSubmit={onSubmit}
      errors={errors}
      handleCancel={() =>
        handleCancel != null ? handleCancel() : navigate(-1)
      }
      categories={categories}
    />
  );
};

export default FaqCreate;
