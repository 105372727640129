import React from 'react';
const SchoolIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="58" height="59" viewBox="0 0 58 59" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M2.82446 39.9797H1.94982C0.98773 39.9797 0.200562 39.1927 0.200562 38.2308V32.9842C0.200562 32.8093 0.226797 32.6344 0.279275 32.4683L3.7778 21.1006C4.0052 20.366 4.67867 19.8676 5.44835 19.8676H20.3171C20.3171 18.9057 21.1042 18.1187 22.0663 18.1187C23.0284 18.1187 23.8156 18.9057 23.8156 19.8676V21.6165C23.8156 22.5784 23.0284 23.3654 22.0663 23.3654H6.7428L3.69909 33.2465V36.718C4.22387 37.0241 4.57372 37.5837 4.57372 38.2308C4.57372 39.1927 3.78655 39.9797 2.82446 39.9797Z" fill={secondaryColor}/>
<path d="M56.1769 39.9797H55.3023C54.3402 39.9797 53.553 39.1927 53.553 38.2308C53.553 37.5837 53.9029 37.0153 54.4277 36.718V34.1035L51.3577 23.3654H36.0604C35.0983 23.3654 34.3112 22.5784 34.3112 21.6165V16.3699C34.3112 15.408 35.0983 14.621 36.0604 14.621C37.0225 14.621 37.8097 15.408 37.8097 16.3699V19.8676H52.6784C53.4568 19.8676 54.1478 20.3835 54.3577 21.1356L57.8562 33.3777C57.9 33.5351 57.9262 33.6925 57.9262 33.8586V38.2308C57.9262 39.1927 57.139 39.9797 56.1769 39.9797Z" fill={secondaryColor}/>
<path d="M37.8097 18.1187H20.3171C19.5911 18.1187 18.9439 17.6728 18.6815 16.9995C18.4191 16.3261 18.6028 15.5566 19.1451 15.0757L27.8914 7.20576C28.5561 6.61114 29.5707 6.61114 30.2354 7.20576L38.9817 15.0757C39.524 15.5566 39.7076 16.3261 39.4452 16.9995C39.1829 17.6728 38.5356 18.1187 37.8097 18.1187ZM24.8739 14.621H33.2528L29.0634 10.8522L24.8739 14.621Z" fill={secondaryColor}/>
<path d="M29.0634 10.2488C28.1013 10.2488 27.3141 9.46182 27.3141 8.49994V2.37888C27.3141 1.417 28.1013 0.630005 29.0634 0.630005C30.0255 0.630005 30.8127 1.417 30.8127 2.37888V8.49994C30.8127 9.46182 30.0255 10.2488 29.0634 10.2488Z" fill={secondaryColor}/>
<path d="M29.0634 8.49994H22.941C21.9789 8.49994 21.1917 7.71295 21.1917 6.75107V2.37888C21.1917 1.417 21.9789 0.630005 22.941 0.630005H29.0634C30.0255 0.630005 30.8127 1.417 30.8127 2.37888V6.75107C30.8127 7.71295 30.0255 8.49994 29.0634 8.49994ZM24.6902 5.00219H27.3141V4.12775H24.6902V5.00219Z" fill={secondaryColor}/>
<path d="M43.9322 58.3429H40.4336V38.2308C40.4336 37.2689 41.2208 36.482 42.1829 36.482H43.0575L29.0634 23.1555L15.0693 36.482H15.944C16.906 36.482 17.6932 37.2689 17.6932 38.2308V58.3429H14.1947V39.9797H10.6962C9.97897 39.9797 9.34049 39.5425 9.06935 38.8779C8.79821 38.2133 8.97314 37.4526 9.48917 36.9629L27.8564 19.4741C28.5299 18.8271 29.597 18.8271 30.2704 19.4741L48.6377 36.9629C49.1537 37.4526 49.3199 38.2133 49.0575 38.8779C48.7951 39.5425 48.1479 39.9797 47.4307 39.9797H43.9322V58.3429Z" fill={primaryColor}/>
<path d="M35.1858 56.594H31.6873V47.8496H26.4395V56.594H22.941V46.1007C22.941 45.1389 23.7281 44.3519 24.6902 44.3519H33.4366C34.3986 44.3519 35.1858 45.1389 35.1858 46.1007V56.594Z" fill={secondaryColor}/>
<path d="M4.57372 36.4819H1.0752V56.594H4.57372V36.4819Z" fill={secondaryColor}/>
<path d="M57.0516 36.4819H53.5531V56.594H57.0516V36.4819Z" fill={secondaryColor}/>
<path d="M29.0634 40.8541C26.1684 40.8541 23.8156 38.5019 23.8156 35.6075C23.8156 32.7131 26.1684 30.3609 29.0634 30.3609C31.9584 30.3609 34.3112 32.7131 34.3112 35.6075C34.3112 38.5019 31.9584 40.8541 29.0634 40.8541ZM29.0634 33.8586C28.1013 33.8586 27.3141 34.6456 27.3141 35.6075C27.3141 36.5694 28.1013 37.3564 29.0634 37.3564C30.0255 37.3564 30.8127 36.5694 30.8127 35.6075C30.8127 34.6456 30.0255 33.8586 29.0634 33.8586Z" fill={secondaryColor}/>
<path d="M17.6932 39.9797H15.9439C14.9818 39.9797 14.1946 39.1927 14.1946 38.2308C14.1946 37.2689 14.9818 36.4819 15.9439 36.4819H17.6932C18.6553 36.4819 19.4424 37.2689 19.4424 38.2308C19.4424 39.1927 18.6553 39.9797 17.6932 39.9797Z" fill={primaryColor}/>
<path d="M42.1829 39.9797H40.4336C39.4715 39.9797 38.6843 39.1927 38.6843 38.2308C38.6843 37.2689 39.4715 36.4819 40.4336 36.4819H42.1829C43.1449 36.4819 43.9321 37.2689 43.9321 38.2308C43.9321 39.1927 43.1449 39.9797 42.1829 39.9797Z" fill={primaryColor}/>
</svg>

);

export default SchoolIcon;
