import React from 'react';
const InfinityIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="36" viewBox="0 0 57 36" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M39.3875 0.324951C35.5653 0.324951 31.9094 1.56667 28.8919 3.84021C25.8744 1.56667 22.2185 0.324951 18.3963 0.324951C8.74913 0.324951 0.903687 8.16866 0.903687 17.8137C0.903687 27.4587 8.74913 35.3024 18.3963 35.3024C22.2359 35.3024 25.9007 34.052 28.9006 31.7872C31.9181 34.0607 35.5741 35.3024 39.3875 35.3024C49.0346 35.3024 56.8801 27.4587 56.8801 17.8137C56.8801 8.16866 49.0346 0.324951 39.3875 0.324951ZM39.3875 30.6417C36.816 30.6417 34.3583 29.8634 32.2592 28.4556C34.5857 25.4125 35.8889 21.6525 35.8889 17.8137H31.2271C31.2271 20.7081 30.2388 23.5412 28.4283 25.8235C28.0785 26.2782 27.6936 26.6893 27.2738 27.0653C24.8686 29.365 21.7112 30.6417 18.3876 30.6417C11.3118 30.6417 5.55673 24.8879 5.55673 17.8137C5.55673 10.7395 11.3118 4.98572 18.3876 4.98572C20.959 4.98572 23.4167 5.76392 25.5245 7.18051C23.2068 10.2148 21.8861 13.9662 21.8861 17.8137H26.5479C26.5479 14.3422 27.9822 10.9668 30.4924 8.56214C32.8977 6.26237 36.0551 4.98572 39.3787 4.98572C46.4545 4.98572 52.2095 10.7395 52.2095 17.8137C52.2095 24.8879 46.4545 30.6417 39.3787 30.6417H39.3875Z" fill={secondaryColor}/>
<path d="M39.3875 25.9722C34.8831 25.9722 31.2271 22.3083 31.2271 17.8137C31.2271 13.3191 34.8919 9.65521 39.3875 9.65521C43.8831 9.65521 47.5477 13.3191 47.5477 17.8137C47.5477 22.3083 43.8831 25.9722 39.3875 25.9722ZM39.3875 14.316C37.4545 14.316 35.8889 15.8812 35.8889 17.8137C35.8889 19.7462 37.4545 21.3115 39.3875 21.3115C41.3204 21.3115 42.886 19.7462 42.886 17.8137C42.886 15.8812 41.3204 14.316 39.3875 14.316Z" fill={primaryColor}/>
<path d="M18.3963 25.9722C13.892 25.9722 10.236 22.3083 10.236 17.8137C10.236 13.3191 13.9007 9.65521 18.3963 9.65521C22.8919 9.65521 26.5566 13.3191 26.5566 17.8137C26.5566 22.3083 22.8919 25.9722 18.3963 25.9722ZM18.3963 14.316C16.4634 14.316 14.8978 15.8812 14.8978 17.8137C14.8978 19.7462 16.4634 21.3115 18.3963 21.3115C20.3293 21.3115 21.8948 19.7462 21.8948 17.8137C21.8948 15.8812 20.3293 14.316 18.3963 14.316Z" fill={primaryColor}/>
</svg>

);

export default InfinityIcon;
