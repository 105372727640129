import React from 'react';
const Bell_silentIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="48" height="57" viewBox="0 0 48 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M46.9068 35.8261C45.2625 31.8037 43.9068 27.6151 42.8573 23.3916L42.5599 22.1674C41.1342 16.0114 36.8311 11.202 31.2597 8.98088V7.6255C31.2597 3.76923 28.1197 0.630005 24.2626 0.630005C20.4055 0.630005 17.2656 3.76923 17.2656 7.6255V8.98088C11.6942 11.202 7.391 16.0114 5.96535 22.1674L5.66795 23.3916C4.62714 27.6151 3.26271 31.8037 1.6184 35.8261C1.16359 36.9454 0.936218 38.1084 0.936218 39.2889C0.936218 43.8185 4.20735 47.631 8.7117 48.3568C10.8371 48.6978 12.9799 48.9514 15.1227 49.1525C15.9973 53.3936 19.7583 56.594 24.2626 56.594C28.767 56.594 32.5279 53.3936 33.4025 49.1525C35.5804 48.9514 37.7582 48.6891 39.9098 48.3393C44.3617 47.6223 47.589 43.8097 47.589 39.2889C47.589 38.1084 47.3616 36.9454 46.9068 35.8261ZM21.9361 7.6255C21.9361 6.34008 22.9857 5.29076 24.2714 5.29076C25.5571 5.29076 26.6066 6.34008 26.6066 7.6255V7.7829C25.837 7.68671 25.0673 7.6255 24.2714 7.6255C23.4755 7.6255 22.7058 7.68671 21.9361 7.7829V7.6255ZM24.2714 51.9333C22.5134 51.9333 21.0002 50.9451 20.2043 49.5111C21.56 49.5635 22.9157 49.5985 24.2714 49.5985C25.6271 49.5985 26.9828 49.5635 28.3384 49.5111C27.5425 50.9451 26.0294 51.9333 24.2714 51.9333ZM39.1664 43.7398C29.3705 45.3225 19.2597 45.3312 9.45512 43.7573C7.22481 43.3987 5.60672 41.5187 5.60672 39.2976C5.60672 38.7205 5.72043 38.1434 5.94784 37.5925C7.67961 33.3602 9.10525 28.953 10.2073 24.5109L10.5134 23.2167C12.0091 16.7809 17.6592 12.2863 24.2626 12.2863C30.8661 12.2863 36.525 16.7809 38.0119 23.2167L38.318 24.5109C39.4112 28.953 40.8456 33.3515 42.5774 37.5925C42.8048 38.1434 42.9185 38.7205 42.9185 39.2976C42.9185 41.5274 41.3354 43.3987 39.1576 43.7485L39.1664 43.7398Z" fill={secondaryColor}/>
<path d="M35.0381 33.4564C28.181 36.3158 20.3618 36.3158 13.5134 33.4564C12.3152 32.9579 10.9595 33.5263 10.461 34.7068C9.96245 35.8961 10.531 37.2602 11.7117 37.7586C15.7088 39.4288 19.9332 40.2682 24.2714 40.2682C28.6096 40.2682 32.834 39.42 36.8311 37.7586C38.0206 37.2602 38.5803 35.8961 38.0818 34.7068C37.5833 33.5176 36.2276 32.9579 35.0294 33.4564H35.0381Z" fill={primaryColor}/>
</svg>

);

export default Bell_silentIcon;
