import React from 'react';
const FloppyIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="52" height="57" viewBox="0 0 52 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M51.316 17.9763C51.0974 14.9071 49.6542 11.9952 47.3627 9.98397L39.5347 3.11961C37.4094 1.25705 34.6805 0.22522 31.8467 0.22522H13.0421C7.16462 0.22522 2.19673 4.58869 1.47079 10.3687C0.736098 16.2712 0.368744 22.2698 0.368744 28.2072C0.368744 34.1446 0.73608 40.0646 1.45328 45.9059C2.17922 51.7646 7.15587 56.1892 13.0421 56.1892H39.0012C44.8787 56.1892 49.8466 51.8258 50.5726 46.0458C51.3073 40.1433 51.6746 34.1446 51.6746 28.2072C51.6746 24.8144 51.5522 21.3692 51.3073 17.9676L51.316 17.9763ZM16.6981 4.89474H30.6922V9.55551C30.6922 10.8409 29.6427 11.8902 28.3569 11.8902H19.0246C17.7389 11.8902 16.6894 10.8409 16.6894 9.55551V4.89474H16.6981ZM45.9458 45.4774C45.5172 48.9314 42.5347 51.5372 39.0099 51.5372H13.0509C9.51739 51.5372 6.52616 48.879 6.08884 45.3462C5.38913 39.6886 5.03927 33.926 5.03927 28.216C5.03927 22.5059 5.39787 16.6647 6.10632 10.9546C6.49116 7.84159 8.96637 5.43688 12.0363 4.99091V9.56421C12.0363 13.4205 15.1762 16.5597 19.0334 16.5597H28.3657C32.2228 16.5597 35.3628 13.4205 35.3628 9.56421V5.8566C35.7476 6.08395 36.1237 6.33756 36.4648 6.64361L44.2927 13.5079C45.6746 14.7147 46.5405 16.4723 46.6717 18.3173C46.9079 21.6052 47.0216 24.9368 47.0216 28.2247C47.0216 33.9698 46.663 39.776 45.9545 45.4861L45.9458 45.4774Z" fill={secondaryColor}/>
<path d="M35.354 23.5465H16.6981C12.841 23.5465 9.70105 26.6857 9.70105 30.542V42.1982C9.70105 46.0545 12.841 49.1937 16.6981 49.1937H35.354C39.2111 49.1937 42.351 46.0545 42.351 42.1982V30.542C42.351 26.6857 39.2111 23.5465 35.354 23.5465ZM37.6893 42.207C37.6893 43.4924 36.6397 44.5417 35.354 44.5417H16.6981C15.4124 44.5417 14.3628 43.4924 14.3628 42.207V30.5507C14.3628 29.2653 15.4124 28.216 16.6981 28.216H35.354C36.6397 28.216 37.6893 29.2653 37.6893 30.5507V42.207Z" fill={secondaryColor}/>
<path d="M33.0187 30.542H19.0246C17.7389 30.542 16.6894 31.5826 16.6894 32.8767C16.6894 34.1709 17.7302 35.2115 19.0246 35.2115H33.0187C34.3044 35.2115 35.354 34.1709 35.354 32.8767C35.354 31.5826 34.3132 30.542 33.0187 30.542Z" fill={primaryColor}/>
<path d="M33.0187 37.5375H19.0246C17.7389 37.5375 16.6894 38.5781 16.6894 39.8722C16.6894 41.1664 17.7302 42.207 19.0246 42.207H33.0187C34.3044 42.207 35.354 41.1664 35.354 39.8722C35.354 38.5781 34.3132 37.5375 33.0187 37.5375Z" fill={primaryColor}/>
</svg>

);

export default FloppyIcon;
