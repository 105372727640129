import React, { useState } from "react";
import {
  Button,
  TextField,
  Box,
  Grid,
  Paper,
  Autocomplete,
  useMediaQuery,
} from "@mui/material";
import { Form, Formik } from "formik";
import { SketchPicker } from "react-color";

const TagForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    readonly = false,
    publishers,
  } = props;

  const [showColorPicker, setShowColorPicker] = useState(false);
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};
        if (!values.publisherId) {
          errors.name = "Required";
        }
        if (!values.content) {
          errors.name = "Required";
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        setFieldValue,
        handleChange,
        errors: validationErrors,
      }) => (
        <Form>
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>Címke {values.id > 0 ? "szerkesztés" : "létrehozás"}</h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error: string) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="publisherId"
                  disabled={readonly}
                  value={values.publisherId}
                  onChange={(event, value) => {
                    setFieldValue("publisherId", value);
                  }}
                  getOptionLabel={(option) =>
                    publishers?.find((g: any) => g.id === option)?.name ?? ""
                  }
                  options={publishers?.map((g: any) => g.id)}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label="Publikáló" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.content}
                  onChange={handleChange}
                  label="Tartalom"
                  name="content"
                  required
                  disabled={readonly}
                  fullWidth
                  autoFocus
                  error={!!touched.content && !!validationErrors.content}
                  helperText={
                    !!touched.content &&
                    !!validationErrors.content &&
                    (validationErrors.content as string)
                  }
                />
              </Grid>
              <Grid container item xs={12} alignItems="center" pb={2}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h3 style={{ marginRight: "10px" }}>Szín:</h3>
                  <div
                    onClick={() => setShowColorPicker(!showColorPicker)}
                    style={{
                      width: "30px",
                      height: "30px",
                      backgroundColor: values.color,
                      cursor: "pointer",
                      borderRadius: "50%",
                    }}
                  ></div>
                </div>
                {showColorPicker && (
                  <SketchPicker
                    color={values.color}
                    onChangeComplete={(newColor) =>
                      setFieldValue("color", newColor.hex)
                    }
                  />
                )}
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item xs={12} sm={isSmallScreen ? 12 : "auto"} p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth={isSmallScreen}
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12} sm={isSmallScreen ? 12 : "auto"} p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth={isSmallScreen}
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default TagForm;
