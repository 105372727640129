import React from 'react';
const StarIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="56" viewBox="0 0 57 56" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M54.6127 26.1729C55.7323 25.2197 56.3708 23.8381 56.3708 22.3691C56.3708 19.6408 54.1492 17.4023 51.4203 17.3761L39.7353 17.2887C38.7382 17.2887 37.8548 16.6415 37.5399 15.6884L33.674 4.07592C32.9131 1.80238 30.7878 0.272034 28.3913 0.272034C25.9948 0.272034 23.8694 1.80238 23.1085 4.07592L19.2426 15.6796C18.9277 16.624 18.0443 17.2712 17.0473 17.2799L5.3622 17.3673C2.63335 17.3848 0.411865 19.6321 0.411865 22.3603C0.411865 23.8294 1.05025 25.2111 2.16978 26.1642L11.6858 34.2614C12.403 34.8735 12.6829 35.8442 12.403 36.7449L8.71211 48.4099C7.86372 51.0856 9.14069 54.0063 11.6771 55.2043C12.438 55.5628 13.2602 55.7376 14.0824 55.7376C15.2194 55.7376 16.3564 55.3966 17.3184 54.7233L27.0706 47.9115C27.8665 47.3518 28.9423 47.3518 29.7382 47.9115L39.4903 54.7233C41.1434 55.8775 43.295 56.0612 45.1318 55.1955C47.6682 53.9975 48.9451 51.0769 48.0967 48.4011L44.4058 36.7361C44.1171 35.8355 44.4058 34.8649 45.1143 34.2528L54.639 26.1555L54.6127 26.1729ZM39.9364 38.1527L43.6273 49.8177C43.776 50.2811 43.5486 50.7795 43.1113 50.9894C42.7965 51.1381 42.4204 51.1031 42.1405 50.9107L32.3883 44.0988C29.9918 42.4199 26.7732 42.4199 24.3679 44.0988L14.6159 50.9107C14.3272 51.1119 13.9512 51.1381 13.645 50.9982C13.2077 50.7883 12.9802 50.2899 13.1289 49.8264L16.8198 38.1615C17.6682 35.4682 16.8374 32.5563 14.6858 30.72L5.16105 22.6227C5.09108 22.5615 5.04735 22.474 5.04735 22.3691C5.04735 22.1855 5.19603 22.0455 5.37096 22.0368L17.056 21.9494C20.0473 21.9231 22.6974 19.9994 23.642 17.1662L27.5079 5.56242C27.6304 5.19516 27.9802 4.94161 28.365 4.94161C28.7499 4.94161 29.0997 5.18641 29.2222 5.56242L33.0881 17.1662C34.0327 19.9994 36.674 21.9231 39.674 21.9494L51.3591 22.0368C51.5428 22.0368 51.6827 22.1855 51.6827 22.3691C51.6827 22.4653 51.639 22.5615 51.569 22.6227L42.0443 30.72C39.8927 32.5476 39.0617 35.4682 39.9101 38.1615L39.9364 38.1527Z" fill={secondaryColor}/>
<path d="M39.674 24.2929C35.6857 24.258 32.161 21.6958 30.9016 17.9095C30.4905 16.6853 29.1873 16.0295 27.9541 16.4405C26.7296 16.8428 26.0736 18.1632 26.4846 19.3874C28.3738 25.0625 33.6654 28.9099 39.6479 28.9537H39.6654C40.9423 28.9537 41.9831 27.9218 42.0006 26.6364C42.0093 25.351 40.9772 24.3017 39.6828 24.2842L39.674 24.2929Z" fill={primaryColor}/>
</svg>

);

export default StarIcon;
