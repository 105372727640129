import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { SketchPicker } from 'react-color';
import { ZoneConfigDays } from '../../../types/ZoneConfigDays';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { addYears, eachDayOfInterval, getDay, parseISO } from 'date-fns';
import { is } from 'date-fns/locale';
import { Warning } from '@mui/icons-material';

const ParkingZoneConfigForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    readonly = false,
    zones,
    zoneConfigs = [],
  } = props;

  const [showColorPicker, setShowColorPicker] = useState(false);
  const [lastVisibleDay, setLastVisibleDay] = useState<any>(null);
  const [isStartTimeNotValid, setIsStartTimeNotValid] = useState(false);
  const [isEndTimeNotValid, setIsEndTimeNotValid] = useState(false);
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );

  function intervalsIntersect(start1: any, end1: any, start2: any, end2: any) {
    // Convert inputs to Date objects if they are not already
    start1 = new Date(start1);
    end1 = end1 ? new Date(end1) : null;
    start2 = new Date(start2);
    end2 = end2 ? new Date(end2) : null;

    // Check if the intervals intersect
    return (end1 ? start2 <= end1 : true) && (end2 ? start1 <= end2 : true);
  }

  const hasOtherExceptionConfig = (
    day: ZoneConfigDays,
    startOfValidity: any,
    endOfValidity: any,
    setFieldValue: any,
    isException: boolean,
    zoneId: number
  ) => {
    let result = false;
    zoneConfigs.forEach((config: any) => {
      if (
        config.id !== entity.id &&
        (config.includedDays & day) > 0 &&
        config.isException &&
        config.zoneId === zoneId
      ) {
        const configStart = moment(config.startOfValidity);
        const configEnd = config.endOfValidity
          ? moment(config.endOfValidity)
          : null;

        const isIntersecting = intervalsIntersect(
          startOfValidity,
          endOfValidity,
          configStart,
          configEnd
        );
        if (isIntersecting) {
          result = true;
          if (isException) {
            setFieldValue('isException', false);
          }
          return result;
        }
      }
    });
    return result;
  };

  const getDisabledIntervals = (
    day: ZoneConfigDays,
    startOfValidity: any,
    endOfValidity: any,
    zoneId: number
  ) => {
    let disabledIntervals: { start: moment.Moment; end: moment.Moment }[] = [];

    zoneConfigs.forEach((config: any) => {
      if (
        config.id !== entity.id &&
        (config.includedDays & day) > 0 &&
        !config.isException &&
        config.zoneId === zoneId
      ) {
        const configStart = moment(config.startOfValidity);
        const configEnd = config.endOfValidity
          ? moment(config.endOfValidity)
          : null;

        const isIntersecting = intervalsIntersect(
          startOfValidity,
          endOfValidity,
          configStart,
          configEnd
        );

        if (isIntersecting) {
          disabledIntervals.push({
            start: moment(config.startOfOperatingTime),
            end: moment(config.endOfOperatingTime),
          });
        }
      }
    });

    return disabledIntervals;
  };

  const isTimeDisabled = (
    day: ZoneConfigDays,
    time: moment.Moment,
    startOfValidity: any,
    endOfValidity: any,
    isStartTime: boolean,
    startOfOperatingTime: any,
    zoneId: number
  ) => {
    const disabledIntervals = getDisabledIntervals(
      day,
      startOfValidity,
      endOfValidity,
      zoneId
    );
    if (!isStartTime) {
      disabledIntervals.some((interval) => {
        if (
          startOfOperatingTime &&
          startOfOperatingTime?.isSameOrBefore(interval.start, 'minute')
        ) {
          disabledIntervals.push({
            start: interval.start,
            end: moment(time).endOf('day'),
          });
        }
      });
    }
    let result = disabledIntervals.some((interval) => {
      const timeFormatted = time.format('HH:mm');
      const intervalStartFormatted = interval.start.format('HH:mm');
      const intervalEndFormatted = interval.end.format('HH:mm');

      return (
        timeFormatted > intervalStartFormatted &&
        timeFormatted < intervalEndFormatted
      );
    });

    return result;
  };

  const getCalendarEvents = (id: number, zoneId: number) => {
    let filteredConfigs = zoneConfigs?.filter(
      (x: any) => x.id !== id && x.zoneId === zoneId
    );
    let events: any = [];
    const currentDate = new Date();

    filteredConfigs.forEach((config: any) => {
      let intervalEnd = config.endOfValidity
        ? parseISO(config.endOfValidity)
        : lastVisibleDay;
      let intervalStart = parseISO(config.startOfValidity);
      if (!intervalEnd) return events;
      if (intervalEnd < intervalStart) {
        intervalEnd = addYears(currentDate, 1);
      }
      let interval = {
        start: intervalStart,
        end: intervalEnd,
      };
      if (!interval) return events;
      let days = eachDayOfInterval(interval);
      days.forEach((day) => {
        let dayOfWeek = getDay(day);
        let transofrmedDay = ZoneConfigDays.Monday;
        switch (dayOfWeek) {
          case 0:
            transofrmedDay = ZoneConfigDays.Sunday;
            break;
          case 1:
            transofrmedDay = ZoneConfigDays.Monday;
            break;
          case 2:
            transofrmedDay = ZoneConfigDays.Tuesday;
            break;
          case 3:
            transofrmedDay = ZoneConfigDays.Wednesday;
            break;
          case 4:
            transofrmedDay = ZoneConfigDays.Thursday;
            break;
          case 5:
            transofrmedDay = ZoneConfigDays.Friday;
            break;
          case 6:
            transofrmedDay = ZoneConfigDays.Saturday;
            break;
        }
        if ((config.includedDays & transofrmedDay) > 0) {
          events.push({
            title: `${new Date(
              config.startOfOperatingTime
            ).getHours()} - ${new Date(config.endOfOperatingTime).getHours()}`,
            start: day.toISOString(),
            end: day.toISOString(), // End date can be adjusted if needed
            color: config.color,
            extendedProps: {
              title: `${new Date(
                config.startOfOperatingTime
              ).toLocaleTimeString()} - ${new Date(
                config.endOfOperatingTime
              ).toLocaleTimeString()} - ${config.name}`,
            },
          });
        }
      });
    });

    return events;
  };

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};
        if (!values.name) {
          errors.name = 'Required';
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting, setFieldValue }) => {
        let newValues = { ...values };

        onSubmit(newValues, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        setFieldValue,
        handleChange,
        errors: validationErrors,
      }) => (
        <Form>
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>
                Parkoló zóna konfiguráció{' '}
                {values.id > 0 ? 'szerkesztése' : 'létrehozása'}{' '}
              </h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error: string) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item>
                <FormControlLabel
                  label="Kivétel"
                  disabled={
                    readonly ||
                    hasOtherExceptionConfig(
                      values.includedDays,
                      values.startOfValidity,
                      values.endOfValidity,
                      setFieldValue,
                      values.isException,
                      values.zoneId
                    )
                  }
                  labelPlacement="end"
                  control={
                    <Checkbox
                      value={values.isException}
                      checked={values.isException}
                      onChange={(e) => {
                        setFieldValue('isException', e.target.checked);
                      }}
                    ></Checkbox>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  value={values.zoneId}
                  onChange={(event, newValue) => {
                    setFieldValue('zoneId', newValue);
                  }}
                  options={zones?.map((zone: any) => zone.id)}
                  getOptionLabel={(option) => {
                    let found = zones.find((zone: any) => zone.id === option);
                    return found?.name || '';
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Zóna"
                      name="zoneId"
                      disabled={readonly}
                      fullWidth
                      required
                      error={!!touched.zoneId && !!validationErrors.zoneId}
                      helperText={
                        !!touched.zoneId &&
                        !!validationErrors.zoneId &&
                        (validationErrors.zoneId as string)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid container p={2}>
                <Grid item>
                  <FormControlLabel
                    label="P+R parkoló"
                    disabled={readonly}
                    labelPlacement="end"
                    control={
                      <Checkbox
                        value={values.isPR}
                        checked={values.isPR}
                        onChange={(e) => {
                          setFieldValue('isPR', e.target.checked);
                        }}
                      ></Checkbox>
                    }
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    label="Magán parkoló"
                    disabled={readonly}
                    labelPlacement="end"
                    control={
                      <Checkbox
                        value={values.isPrivate}
                        checked={values.isPrivate}
                        onChange={(e) => {
                          setFieldValue('isPrivate', e.target.checked);
                        }}
                      ></Checkbox>
                    }
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    label="Engedély köteles"
                    disabled={readonly}
                    labelPlacement="end"
                    control={
                      <Checkbox
                        value={values.isPermissionRequired}
                        checked={values.isPermissionRequired}
                        onChange={(e) => {
                          setFieldValue(
                            'isPermissionRequired',
                            e.target.checked
                          );
                        }}
                      ></Checkbox>
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.name}
                  onChange={handleChange}
                  label="Name"
                  name="name"
                  required
                  disabled={readonly}
                  fullWidth
                  autoFocus
                  error={!!touched.name && !!validationErrors.name}
                  helperText={
                    !!touched.name &&
                    !!validationErrors.name &&
                    (validationErrors.name as string)
                  }
                />
              </Grid>
              {!values.isPR && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      value={values.parkingLimitInMinutes}
                      onChange={handleChange}
                      label="Parkolási időkorlát percben"
                      name="parkingLimitInMinutes"
                      required
                      type="number"
                      disabled={readonly}
                      fullWidth
                      error={
                        !!touched.parkingLimitInMinutes &&
                        !!validationErrors.parkingLimitInMinutes
                      }
                      helperText={
                        !!touched.parkingLimitInMinutes &&
                        !!validationErrors.parkingLimitInMinutes &&
                        (validationErrors.parkingLimitInMinutes as string)
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={values.extensionLengthInMinutes}
                      onChange={handleChange}
                      label="Hosszabbítás időtartama percben"
                      name="extensionLengthInMinutes"
                      required
                      type="number"
                      disabled={readonly}
                      fullWidth
                      error={
                        !!touched.extensionLengthInMinutes &&
                        !!validationErrors.extensionLengthInMinutes
                      }
                      helperText={
                        !!touched.extensionLengthInMinutes &&
                        !!validationErrors.extensionLengthInMinutes &&
                        (validationErrors.extensionLengthInMinutes as string)
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={values.numberOfExtensions}
                      onChange={handleChange}
                      label="Hosszabbítások száma"
                      name="numberOfExtensions"
                      required
                      type="number"
                      disabled={readonly}
                      fullWidth
                      error={
                        !!touched.numberOfExtensions &&
                        !!validationErrors.numberOfExtensions
                      }
                      helperText={
                        !!touched.numberOfExtensions &&
                        !!validationErrors.numberOfExtensions &&
                        (validationErrors.numberOfExtensions as string)
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      value={values.parkingPrice}
                      onChange={handleChange}
                      label="Parkolási díj"
                      name="parkingPrice"
                      required
                      type="number"
                      disabled={readonly}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">Ft</InputAdornment>
                        ),
                      }}
                      error={
                        !!touched.parkingPrice &&
                        !!validationErrors.parkingPrice
                      }
                      helperText={
                        !!touched.parkingPrice &&
                        !!validationErrors.parkingPrice &&
                        (validationErrors.parkingPrice as string)
                      }
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <TextField
                  value={values.numberOfParkingPlaces}
                  onChange={handleChange}
                  label="Parkolóhelyek száma"
                  name="numberOfParkingPlaces"
                  required
                  type="number"
                  disabled={readonly}
                  fullWidth
                  error={
                    !!touched.numberOfParkingPlaces &&
                    !!validationErrors.numberOfParkingPlaces
                  }
                  helperText={
                    !!touched.numberOfParkingPlaces &&
                    !!validationErrors.numberOfParkingPlaces &&
                    (validationErrors.numberOfParkingPlaces as string)
                  }
                />
              </Grid>
              <Grid item container xs={12} sm={8} spacing={2}>
                <Grid item xs={6}>
                  <DatePicker
                    label="Érvényesség kezdete"
                    sx={{ width: '100%' }}
                    value={moment(values.startOfValidity)}
                    disabled={readonly}
                    onChange={(value) => {
                      setFieldValue('startOfValidity', value);
                    }}
                    format="YYYY-MM-DD"
                  />
                </Grid>{' '}
                <Grid item xs={6}>
                  <DatePicker
                    value={
                      values.endOfValidity ? moment(values.endOfValidity) : null
                    }
                    sx={{ width: '100%' }}
                    onChange={(value) => {
                      setFieldValue('endOfValidity', value);
                    }}
                    label="Érvényesség vége"
                    format="YYYY-MM-DD"
                    disabled={readonly}
                    componentsProps={{
                      actionBar: {
                        actions: ['clear'],
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TimePicker
                    label="Üzemidő kezdete"
                    value={moment(values.startOfOperatingTime)}
                    sx={{ width: '100%' }}
                    onChange={(value) =>
                      setFieldValue('startOfOperatingTime', value)
                    }
                    ampm={false}
                    disabled={readonly}
                    shouldDisableTime={(time) => {
                      if (!values.isException) {
                        let result = isTimeDisabled(
                          values.includedDays,
                          time,
                          values.startOfValidity,
                          values.endOfValidity,
                          true,
                          values.startOfOperatingTime,
                          values.zoneId
                        );
                        if (result) {
                          setIsStartTimeNotValid(true);
                        } else {
                          setIsStartTimeNotValid(false);
                        }
                        return result;
                      } else {
                        setIsStartTimeNotValid(false);

                        return false;
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TimePicker
                    label="Üzemidő vége"
                    value={moment(values.endOfOperatingTime)}
                    sx={{ width: '100%' }}
                    onChange={(value) =>
                      setFieldValue('endOfOperatingTime', value)
                    }
                    ampm={false}
                    disabled={readonly}
                    shouldDisableTime={(time) => {
                      if (!values.isException) {
                        let result = isTimeDisabled(
                          values.includedDays,
                          time,
                          values.startOfValidity,
                          values.endOfValidity,
                          false,
                          values.startOfOperatingTime,
                          values.zoneId
                        );
                        if (result) {
                          setIsEndTimeNotValid(true);
                        } else {
                          setIsEndTimeNotValid(false);
                        }
                        return result;
                      } else {
                        setIsEndTimeNotValid(false);

                        return false;
                      }
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FullCalendar
                  plugins={[dayGridPlugin, interactionPlugin]}
                  initialView="dayGridMonth"
                  events={getCalendarEvents(values.id, values.zoneId)}
                  eventContent={(eventInfo) => (
                    <Tooltip
                      title={eventInfo.event.extendedProps.title}
                      children={
                        <div
                          style={{
                            backgroundColor: eventInfo.event.backgroundColor,
                            color: 'white',
                            borderRadius: '5px',
                            padding: '5px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            maxWidth: '100%',
                          }}
                        >
                          {eventInfo.event.title}
                        </div>
                      }
                    />
                  )}
                  eventDisplay="block"
                  datesSet={(info) => {
                    setLastVisibleDay(info.end);
                  }}
                />
              </Grid>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Typography fontWeight={'bold'}>Üzemnapok:</Typography>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    disabled={readonly}
                    variant={
                      (values.includedDays & ZoneConfigDays.Monday) > 0
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={() => {
                      setFieldValue(
                        'includedDays',
                        values.includedDays ^ ZoneConfigDays.Monday
                      );
                    }}
                  >
                    Hétfő
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    disabled={readonly}
                    variant={
                      (values.includedDays & ZoneConfigDays.Tuesday) > 0
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={() => {
                      setFieldValue(
                        'includedDays',
                        values.includedDays ^ ZoneConfigDays.Tuesday
                      );
                    }}
                  >
                    Kedd
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    disabled={readonly}
                    variant={
                      (values.includedDays & ZoneConfigDays.Wednesday) > 0
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={() => {
                      setFieldValue(
                        'includedDays',
                        values.includedDays ^ ZoneConfigDays.Wednesday
                      );
                    }}
                  >
                    Szerda
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    disabled={readonly}
                    variant={
                      (values.includedDays & ZoneConfigDays.Thursday) > 0
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={() => {
                      setFieldValue(
                        'includedDays',
                        values.includedDays ^ ZoneConfigDays.Thursday
                      );
                    }}
                  >
                    Csütörtök
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    disabled={readonly}
                    variant={
                      (values.includedDays & ZoneConfigDays.Friday) > 0
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={() => {
                      setFieldValue(
                        'includedDays',
                        values.includedDays ^ ZoneConfigDays.Friday
                      );
                    }}
                  >
                    Péntek
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    disabled={readonly}
                    variant={
                      (values.includedDays & ZoneConfigDays.Saturday) > 0
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={() => {
                      setFieldValue(
                        'includedDays',
                        values.includedDays ^ ZoneConfigDays.Saturday
                      );
                    }}
                  >
                    Szombat
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    disabled={readonly}
                    variant={
                      (values.includedDays & ZoneConfigDays.Sunday) > 0
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={() => {
                      setFieldValue(
                        'includedDays',
                        values.includedDays ^ ZoneConfigDays.Sunday
                      );
                    }}
                  >
                    Vasárnap
                  </Button>
                </Grid>
              </Grid>

              <Grid container item xs={12} alignItems="center" pb={2}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h3 style={{ marginRight: '10px' }}>Szín:</h3>
                  <div
                    onClick={() => setShowColorPicker(!showColorPicker)}
                    style={{
                      width: '30px',
                      height: '30px',
                      backgroundColor: values.color,
                      cursor: 'pointer',
                      borderRadius: '50%',
                    }}
                  ></div>
                </div>
                {showColorPicker && (
                  <SketchPicker
                    color={values.color}
                    onChangeComplete={(newColor) =>
                      setFieldValue('color', newColor.hex)
                    }
                  />
                )}
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item xs={12} sm={isSmallScreen ? 12 : 'auto'} p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth={isSmallScreen}
                      disabled={
                        isSubmitting || isStartTimeNotValid || isEndTimeNotValid
                      }
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12} sm={isSmallScreen ? 12 : 'auto'} p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth={isSmallScreen}
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default ParkingZoneConfigForm;
