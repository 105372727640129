import {
  Paper,
  Grid,
  Box,
  Tab,
  Tabs,
  Badge,
  useMediaQuery,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useConfirmDeleteDialog from '../../hooks/useConfirmDeleteDialog';
import useCheckPermission from '../../hooks/useCheckPermission';
import officialService from '../../services/officialService';
import { MenuItemTree } from './MenuItemDesigner';
import menuItemService from '../../services/menuItemService';
import pageService from '../../services/pageService';
import { ApprovementStatus } from '../contents/ContentPage';
import { set } from 'date-fns';

const MenuItemPage = () => {
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [menuItems, setMenuItem] = useState<any[]>([]);
  const [deletedMenuItems, setDeletedMenuItems] = useState<any[]>([]);
  const isSmallScreen = useMediaQuery('(max-width:900px)');
  const [tabValue, setTabValue] = useState(0);

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();

  const { checkPermission } = useCheckPermission();

  const [pages, setPages] = useState<any[]>([]);

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    pageService
      .listLastApprovedPages()
      .then((response: any) => {
        if (!response.hasError) setPages(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  useEffect(() => {
    fetchMenuItems();
  }, []);

  const fetchMenuItems = () => {
    dispatch({ type: 'SHOW_QUERY' });
    menuItemService
      .list()
      .then((response) => {
        if (!response.hasError) {
          setMenuItem(
            response.records.filter(
              (row: any) => row.status !== ApprovementStatus.DeletedSecondLevel
            )
          );
          setDeletedMenuItems(
            response.records.filter(
              (row: any) => row.status === ApprovementStatus.DeletedSecondLevel
            )
          );
        } else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  };

  const handleDelete = (id: number) => {
    menuItemService.delete(id).then((response) => {
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés!', {
          variant: 'success',
        });
        fetchMenuItems();
      }
    });
  };
  const handleRecover = (id: number) => {
    menuItemService.recover(id).then((response) => {
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés!', {
          variant: 'success',
        });
        fetchMenuItems();
      }
    });
  };
  const handleReject = (id: number) => {
    menuItemService.reject(id).then((response) => {
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés!', {
          variant: 'success',
        });
        fetchMenuItems();
      }
    });
  };

  const handleAddNode = (entity: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    console.log(entity);
    menuItemService
      .create({ ...entity })
      .then((response) => {
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Success!', {
            variant: 'success',
          });
          fetchMenuItems();
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
      });
  };

  const handleUpdate = (entity: any) => {
    console.log('handleUpdate');
    console.log(entity);
    dispatch({ type: 'SHOW_SAVE' });
    menuItemService
      .update({ ...entity })
      .then((response) => {
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Success!', {
            variant: 'success',
          });
          fetchMenuItems();
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
      });
  };
  const updateOrder = (entity: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    menuItemService
      .updateOrder({ ...entity })
      .then((response) => {
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Success!', {
            variant: 'success',
          });
          fetchMenuItems();
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
      });
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Menütervező</h2>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              pb: 2,
              maxWidth: isSmallScreen ? '280px' : '100%',
            }}
          >
            <Tabs
              value={tabValue}
              onChange={(e, value) => setTabValue(value)}
              TabIndicatorProps={{ style: { background: '#1976d2' } }}
              sx={{
                maxWidth: isSmallScreen ? '280px' : '100%',
              }}
              variant="fullWidth"
            >
              <Tab
                value={0}
                label={
                  <Badge badgeContent={menuItems?.length ?? 0} color="primary">
                    <Box alignContent={'center'} sx={{ marginRight: '0.5rem' }}>
                      Elfogadva{' '}
                    </Box>
                  </Badge>
                }
                sx={{ fontWeight: 'bold' }}
              />
              <Tab
                value={1}
                label={
                  <Badge
                    badgeContent={deletedMenuItems?.length ?? 0}
                    color="primary"
                  >
                    <Box alignContent={'center'} sx={{ marginRight: '0.5rem' }}>
                      Archivált{' '}
                    </Box>
                  </Badge>
                }
                sx={{ fontWeight: 'bold' }}
              />
            </Tabs>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <MenuItemTree
            menuItems={tabValue === 0 ? menuItems : deletedMenuItems}
            updateOrder={updateOrder}
            onAddMenuItem={handleAddNode}
            handleDelete={(id: any) =>
              setParams({
                open: true,
                name: 'Figyelem a menüpont almenüi is törlődnek! Biztosan törölni szeretné?',
                onConfirm: async () => handleDelete(id),
              })
            }
            handleUpdate={handleUpdate}
            onPageAdded={(page: any) => {
              setPages([...pages, page]);
            }}
            pages={pages}
            onMoveMenuItem={function (
              menuItem: any,
              newParentId: number
            ): void {}}
            handleRecover={handleRecover}
            handleReject={handleReject}
          />
        </Grid>
        <ConfirmDeleteDialog />
      </Grid>
    </Paper>
  );
};

export default MenuItemPage;
