import {
  GridColDef,
  GridActionsCellItem,
  GridActionsColDef,
} from '@mui/x-data-grid';
import {
  Paper,
  IconButton,
  Grid,
  Tooltip,
  Checkbox,
  FormControlLabel,
  SpeedDial,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Visibility,
  HideImage,
  Image,
} from '@mui/icons-material';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import DataList from '../../../components/DataList';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';
import parkingZoneConfigService from '../../../services/parking/parkingZoneConfigService';
import moment from 'moment';

export interface ParkingZoneConfig {
  id: string;
  imageId: string;
  name: string;
}

const ParkingZoneConfigsPage = () => {
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [rows, setRows] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const [showAll, setShowAll] = useState<boolean>(false);

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    parkingZoneConfigService
      .list(showAll)
      .then((response) => {
        if (!response.hasError) setRows(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, [showAll]);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    let readonly =
      !checkPermission(['EditParkingZoneConfig']) || params.row.isModified;
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          readonly ? (
            <Tooltip title="View">
              <Visibility />
            </Tooltip>
          ) : (
            <Tooltip title="Edit">
              <EditIcon />
            </Tooltip>
          )
        }
        label={readonly ? 'View' : 'Edit'}
        onClick={() => navigate(`/parkingZoneConfig/edit/${params.row.id}`)}
      />
    );

    if (checkPermission(['DeleteParkingZoneConfig'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Delete">
              <DeleteIcon />
            </Tooltip>
          }
          label="Delete"
          onClick={() =>
            setParams({
              open: true,
              name: 'Are you sure to delete this item?',
              onConfirm: async () => handleDelete(params.row.id),
            })
          }
        />
      );
    }

    return actions;
  };

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Megnevezés', flex: 100 },
    {
      field: 'startOfValidity',
      headerName: 'Érvényesség kezdete',
      valueFormatter(params) {
        return params.value ? new Date(params.value).toLocaleDateString() : '';
      },
      flex: 100,
    },
    {
      field: 'endOfValidity',
      headerName: 'Érvényesség vége',
      valueFormatter(params) {
        return params.value
          ? new Date(params.value).toLocaleDateString()
          : 'Nincs';
      },
      flex: 100,
    },
    { field: 'zoneName', headerName: 'Zóna', flex: 100 },
    {
      field: 'parkingLimitInMinutes',
      headerName: 'Parkolási idő',
      flex: 100,
      valueFormatter(params) {
        return params.value + ' perc';
      },
    },
    {
      field: 'startOfOperatingTime',
      headerName: 'Üzemidő kezdete',
      flex: 100,
      valueFormatter(params) {
        return params.value ? new Date(params.value).toLocaleTimeString() : '';
      },
    },
    {
      field: 'endOfOperatingTime',
      headerName: 'Üzemidő vége',
      valueFormatter(params) {
        return params.value ? new Date(params.value).toLocaleTimeString() : '';
      },
      flex: 100,
    },
    { field: 'numberOfParkingPlaces', headerName: 'Helyek száma', flex: 100 },
    { field: 'parkingPrice', headerName: 'Ár', flex: 100 },
    {
      field: 'color',
      headerName: 'Szín',
      flex: 100,
      renderCell: (params) => (
        <div
          style={{
            width: '30px',
            height: '30px',
            backgroundColor: params.value,
            borderRadius: '50%',
          }}
        ></div>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      align: 'right',
      flex: 100,
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const handleDelete = (id: number) => {
    parkingZoneConfigService.delete(id).then((response) => {
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés!', {
          variant: 'success',
        });
        setRows(rows.filter((row) => row.id !== id));
      }
    });
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12} textAlign={{ xs: 'center', sm: 'left' }}>
          <h2>Parkoló zóna konfigurációk</h2>
        </Grid>
        <Grid container item xs={12} p={2} justifyContent="space-between">
          <Grid item>
            <FormControlLabel
              label="Nem aktív elemek mutatása"
              labelPlacement="start"
              control={
                <Checkbox
                  value={showAll}
                  checked={showAll}
                  onChange={(e) => {
                    setShowAll(e.target.checked);
                  }}
                ></Checkbox>
              }
            />
          </Grid>
          {checkPermission(['CreateParkingZoneConfig']) && (
            <Grid item sx={{ display: { xs: 'none', sm: 'fixed' } }}>
              <Tooltip title="Parkoló zóna konfiguráció létrehozása">
                <IconButton
                  component={RouterLink}
                  to={`/parkingZoneConfig/create`}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>

        <Grid item xs={12}>
          <DataList rows={rows} columns={columns} />
        </Grid>
        <ConfirmDeleteDialog />
        <SpeedDial
          ariaLabel="Okos kereső"
          onClick={() => {
            navigate('/parkingZoneConfig/create');
          }}
          sx={{
            display: { xs: 'fixed', sm: 'none' },
            position: 'fixed',
            bottom: 16,
            right: 16,
          }}
          icon={<AddIcon />}
        ></SpeedDial>
      </Grid>
    </Paper>
  );
};

export default ParkingZoneConfigsPage;
