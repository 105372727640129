import React from 'react';
const ShareIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="56" height="57" viewBox="0 0 56 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M46.6676 19.09C51.8105 19.09 56 14.9102 56 9.75974C56 4.6093 51.8192 0.429504 46.6676 0.429504C41.5161 0.429504 37.3354 4.6093 37.3354 9.75974C37.3354 14.9102 41.5161 19.09 46.6676 19.09ZM46.6676 5.09897C49.2391 5.09897 51.3295 7.18889 51.3295 9.75974C51.3295 12.3306 49.2391 14.4205 46.6676 14.4205C44.0962 14.4205 42.0059 12.3306 42.0059 9.75974C42.0059 7.18889 44.0962 5.09897 46.6676 5.09897Z" fill={secondaryColor}/>
<path d="M9.34719 19.09C4.20435 19.09 0.0148315 23.2698 0.0148315 28.4202C0.0148315 33.5706 4.19561 37.7505 9.34719 37.7505C14.4988 37.7505 18.6794 33.5706 18.6794 28.4202C18.6794 23.2698 14.4988 19.09 9.34719 19.09ZM9.34719 33.081C6.77577 33.081 4.68533 30.991 4.68533 28.4202C4.68533 25.8494 6.77577 23.7595 9.34719 23.7595C11.9186 23.7595 14.0089 25.8494 14.0089 28.4202C14.0089 30.991 11.9186 33.081 9.34719 33.081Z" fill={secondaryColor}/>
<path d="M46.6676 37.7417C41.5248 37.7417 37.3354 41.9215 37.3354 47.072C37.3354 52.2224 41.5161 56.4022 46.6676 56.4022C51.8192 56.4022 56 52.2224 56 47.072C56 41.9215 51.8192 37.7417 46.6676 37.7417ZM46.6676 51.7327C44.0962 51.7327 42.0059 49.6428 42.0059 47.072C42.0059 44.5011 44.0962 42.4112 46.6676 42.4112C49.2391 42.4112 51.3295 44.5011 51.3295 47.072C51.3295 49.6428 49.2391 51.7327 46.6676 51.7327Z" fill={secondaryColor}/>
<path d="M33.7144 37.9865L24.382 33.3258C23.2275 32.7574 21.8281 33.2209 21.2509 34.3664C20.6736 35.5207 21.1459 36.9197 22.2916 37.4969L31.624 42.1576C31.9563 42.3238 32.3149 42.4025 32.6647 42.4025C33.5219 42.4025 34.344 41.9303 34.7551 41.117C35.3324 39.9628 34.8601 38.5637 33.7144 37.9865Z" fill={primaryColor}/>
<path d="M31.6328 14.674L22.3005 19.3348C21.1459 19.9119 20.6824 21.311 21.2597 22.4652C21.6708 23.2785 22.4929 23.7507 23.3501 23.7507C23.6999 23.7507 24.0585 23.672 24.3908 23.5058L33.7232 18.8451C34.8777 18.268 35.3412 16.8689 34.764 15.7146C34.1867 14.5604 32.7873 14.0969 31.6328 14.674Z" fill={primaryColor}/>
</svg>

);

export default ShareIcon;
