import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';

export default function HelperDialog(props: any) {
  const { open, handleClose, helperText, helperPicture } = props;
  const [comment, setComment] = React.useState('');

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={'xl'} fullWidth>
      <DialogTitle>{'Súgó'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="h5">{helperText}</Typography>
        </DialogContentText>
        <img src={helperPicture} alt="helper" width={1500} />
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={handleClose}>
          Kilépés
        </Button>
      </DialogActions>
    </Dialog>
  );
}
