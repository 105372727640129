import {
  Button,
  TextField,
  Box,
  Grid,
  Paper,
  Autocomplete,
  Stack,
  Switch,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { useState, useEffect } from 'react';
import IconPicker from '../../../components/IconPicker';
import IconPickerToggle from '../../../components/IconPickerToggle';
import useImageSelect from '../../../hooks/useImageSelect';
import { ParkingPlaceType } from '../../../types/ParkingPlaceType';
import { translateParkingPlaceType } from '../../../utils/enumTranslaters';
import { useLocationPicker } from '../../../hooks/useLocationPicker';
import systemConfigurationService from '../../../services/systemConfigurationService';

const ParkingPlaceForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    readonly = false,
    zones,
  } = props;

  const { LocationPickerButton, LocationPickerDialog } = useLocationPicker();
  const [defaultLat, setDefaultLat] = useState<any>(1);
  const [defaultLong, setDefaultLong] = useState<any>(1);
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );

  useEffect(() => {
    systemConfigurationService
      .getByKey('MapCenterLatitude')
      .then((response) => {
        if (!response.hasError) {
          setDefaultLat(parseFloat(response.result.value));
        }
      });
    systemConfigurationService
      .getByKey('MapCenterLongitude')
      .then((response) => {
        if (!response.hasError) {
          setDefaultLong(parseFloat(response.result.value));
        }
      });
  }, []);

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};
        if (!values.name) {
          errors.name = 'Required';
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting, setFieldValue }) => {
        let newValues = { ...values };

        onSubmit(newValues, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        setFieldValue,
        handleChange,
        errors: validationErrors,
      }) => (
        <Form>
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>
                Parkoló hely {values.id > 0 ? 'szerkesztése' : 'létrehozása'}{' '}
              </h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error: string) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  value={values.type}
                  onChange={(event, newValue) => {
                    setFieldValue('type', newValue);
                  }}
                  options={Object.values(ParkingPlaceType).filter((x) =>
                    Number.isFinite(x)
                  )}
                  getOptionLabel={(option) => {
                    return translateParkingPlaceType(
                      option as ParkingPlaceType
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Típus"
                      name="type"
                      disabled={readonly}
                      fullWidth
                      error={!!touched.type && !!validationErrors.type}
                      helperText={
                        !!touched.type &&
                        !!validationErrors.type &&
                        (validationErrors.type as string)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.name}
                  onChange={handleChange}
                  label="Name"
                  name="name"
                  required
                  disabled={readonly}
                  fullWidth
                  autoFocus
                  error={!!touched.name && !!validationErrors.name}
                  helperText={
                    !!touched.name &&
                    !!validationErrors.name &&
                    (validationErrors.name as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.latitude}
                  onChange={handleChange}
                  label="Szélesség"
                  name="latitude"
                  type="number"
                  required
                  disabled={readonly}
                  fullWidth
                  error={!!touched.latitude && !!validationErrors.latitude}
                  helperText={
                    !!touched.latitude &&
                    !!validationErrors.latitude &&
                    (validationErrors.latitude as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.longitude}
                  onChange={handleChange}
                  label="Hosszúság"
                  name="longitude"
                  required
                  type="number"
                  disabled={readonly}
                  fullWidth
                  error={!!touched.longitude && !!validationErrors.longitude}
                  helperText={
                    !!touched.longitude &&
                    !!validationErrors.longitude &&
                    (validationErrors.longitude as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <LocationPickerButton />
                <LocationPickerDialog
                  zones={zones}
                  defaultLong={defaultLong}
                  defaultLat={defaultLat}
                  onLocationSelected={(location: {
                    lat: number;
                    lng: number;
                    address: string;
                  }) => {
                    setFieldValue('longitude', location.lng);
                    setFieldValue('latitude', location.lat);
                  }}
                />
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item xs={12} sm={isSmallScreen ? 12 : 'auto'} p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth={isSmallScreen}
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12} sm={isSmallScreen ? 12 : 'auto'} p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth={isSmallScreen}
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default ParkingPlaceForm;
