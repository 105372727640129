import React from 'react';

import Absorbed_inIcon from './absorbed_in.jsx';
import Add_colorIcon from './add_color.jsx';
import Add_documentIcon from './add_document.jsx';
import Add_filesIcon from './add_files.jsx';
import AircraftIcon from './aircraft.jsx';
import Air_supportIcon from './air_support.jsx';
import Alien_scienceIcon from './alien_science.jsx';
import Amusement_parkIcon from './amusement_park.jsx';
import AnimatingIcon from './animating.jsx';
import Appreciate_itIcon from './appreciate_it.jsx';
import ArticlesIcon from './articles.jsx';
import Attached_fileIcon from './attached_file.jsx';
import At_workIcon from './at_work.jsx';
import Audio_conversationIcon from './audio_conversation.jsx';
import Augmented_realityIcon from './augmented_reality.jsx';
import Automobile_repairIcon from './automobile_repair.jsx';
import AwardsIcon from './awards.jsx';
import BabyIcon from './baby.jsx';
import BasketballIcon from './basketball.jsx';
import Beach_dayIcon from './beach_day.jsx';
import BeerIcon from './beer.jsx';
import Beer_celebrationIcon from './beer_celebration.jsx';
import Before_dawnIcon from './before_dawn.jsx';
import Be_the_heroIcon from './be_the_hero.jsx';
import BibliophileIcon from './bibliophile.jsx';
import Birthday_cakeIcon from './birthday_cake.jsx';
import Blank_canvasIcon from './blank_canvas.jsx';
import BooksIcon from './books.jsx';
import Book_loverIcon from './book_lover.jsx';
import Book_readingIcon from './book_reading.jsx';
import BreakfastIcon from './breakfast.jsx';
import BuddiesIcon from './buddies.jsx';
import Building_blocksIcon from './building_blocks.jsx';
import BusinessmanIcon from './businessman.jsx';
import BusinesswomanIcon from './businesswoman.jsx';
import Business_shopIcon from './business_shop.jsx';
import Button_styleIcon from './button_style.jsx';
import CalculatorIcon from './calculator.jsx';
import CampfireIcon from './campfire.jsx';
import CampingIcon from './camping.jsx';
import CatIcon from './cat.jsx';
import CertificateIcon from './certificate.jsx';
import CertificationIcon from './certification.jsx';
import Character_drawingIcon from './character_drawing.jsx';
import Choosing_houseIcon from './choosing_house.jsx';
import Christmas_modeIcon from './christmas_mode.jsx';
import Circuit_boardIcon from './circuit_board.jsx';
import Clean_upIcon from './clean_up.jsx';
import Cloud_docsIcon from './cloud_docs.jsx';
import CoffeeIcon from './coffee.jsx';
import Coffee_with_friendsIcon from './coffee_with_friends.jsx';
import CollectingIcon from './collecting.jsx';
import Color_paletteIcon from './color_palette.jsx';
import CompletingIcon from './completing.jsx';
import Compose_musicIcon from './compose_music.jsx';
import ConferenceIcon from './conference.jsx';
import Conference_speakerIcon from './conference_speaker.jsx';
import Contact_usIcon from './contact_us.jsx';
import Content_structureIcon from './content_structure.jsx';
import Content_teamIcon from './content_team.jsx';
import ContractIcon from './contract.jsx';
import ConversationIcon from './conversation.jsx';
import ConvertIcon from './convert.jsx';
import Cookie_loveIcon from './cookie_love.jsx';
import CookingIcon from './cooking.jsx';
import CoolnessIcon from './coolness.jsx';
import CoupleIcon from './couple.jsx';
import Couple_loveIcon from './couple_love.jsx';
import Co_workersIcon from './co_workers.jsx';
import Co_workingIcon from './co_working.jsx';
import CreationIcon from './creation.jsx';
import Creation_processIcon from './creation_process.jsx';
import Creative_teamIcon from './creative_team.jsx';
import Creative_thinkingIcon from './creative_thinking.jsx';
import Creative_womanIcon from './creative_woman.jsx';
import CreativityIcon from './creativity.jsx';
import Customer_surveyIcon from './customer_survey.jsx';
import Day_offIcon from './day_off.jsx';
import Decorate_christmas_treeIcon from './decorate_christmas_tree.jsx';
import Delivery_truckIcon from './delivery_truck.jsx';
import Designer_girlIcon from './designer_girl.jsx';
import Design_inspirationIcon from './design_inspiration.jsx';
import Design_teamIcon from './design_team.jsx';
import Digital_nomadIcon from './digital_nomad.jsx';
import DoctorIcon from './doctor.jsx';
import DoctorsIcon from './doctors.jsx';
import DogIcon from './dog.jsx';
import Doll_playIcon from './doll_play.jsx';
import DragIcon from './drag.jsx';
import Dream_worldIcon from './dream_world.jsx';
import Drone_deliveryIcon from './drone_delivery.jsx';
import Dua_lipaIcon from './dua_lipa.jsx';
import Eating_togetherIcon from './eating_together.jsx';
import EditableIcon from './editable.jsx';
import EducationIcon from './education.jsx';
import EducatorIcon from './educator.jsx';
import Eiffel_towerIcon from './eiffel_tower.jsx';
import Election_dayIcon from './election_day.jsx';
import ElectricityIcon from './electricity.jsx';
import Electric_carIcon from './electric_car.jsx';
import Empty_streetIcon from './empty_street.jsx';
import EnergizerIcon from './energizer.jsx';
import Engineering_teamIcon from './engineering_team.jsx';
import Everyday_lifeIcon from './everyday_life.jsx';
import Everywhere_togetherIcon from './everywhere_together.jsx';
import ExamsIcon from './exams.jsx';
import Exciting_newsIcon from './exciting_news.jsx';
import Experience_designIcon from './experience_design.jsx';
import ExploreIcon from './explore.jsx';
import ExploringIcon from './exploring.jsx';
import Export_filesIcon from './export_files.jsx';
import FamilyIcon from './family.jsx';
import FansIcon from './fans.jsx';
import Farm_girlIcon from './farm_girl.jsx';
import Fashion_bloggingIcon from './fashion_blogging.jsx';
import Fast_loadingIcon from './fast_loading.jsx';
import FatherhoodIcon from './fatherhood.jsx';
import FeedbackIcon from './feedback.jsx';
import File_analysisIcon from './file_analysis.jsx';
import File_managerIcon from './file_manager.jsx';
import File_searchingIcon from './file_searching.jsx';
import Filing_systemIcon from './filing_system.jsx';
import Fill_formIcon from './fill_form.jsx';
import Financial_dataIcon from './financial_data.jsx';
import Fingerprint_loginIcon from './fingerprint_login.jsx';
import Finish_lineIcon from './finish_line.jsx';
import FireworksIcon from './fireworks.jsx';
import FirmwareIcon from './firmware.jsx';
import Fitting_pieceIcon from './fitting_piece.jsx';
import Fitting_piecesIcon from './fitting_pieces.jsx';
import FloatingIcon from './floating.jsx';
import Folder_filesIcon from './folder_files.jsx';
import FontIcon from './font.jsx';
import Forgot_passwordIcon from './forgot_password.jsx';
import FriendshipIcon from './friendship.jsx';
import FunctionsIcon from './functions.jsx';
import Fun_momentsIcon from './fun_moments.jsx';
import Futuristic_interfaceIcon from './futuristic_interface.jsx';
import Game_worldIcon from './game_world.jsx';
import GamingIcon from './gaming.jsx';
import GardeningIcon from './gardening.jsx';
import GeniusesIcon from './geniuses.jsx';
import GiftsIcon from './gifts.jsx';
import Going_upIcon from './going_up.jsx';
import Good_teamIcon from './good_team.jsx';
import GradesIcon from './grades.jsx';
import GrandmaIcon from './grandma.jsx';
import Grand_slamIcon from './grand_slam.jsx';
import HalloweenIcon from './halloween.jsx';
import HamburgerIcon from './hamburger.jsx';
import Happy_feelingIcon from './happy_feeling.jsx';
import Happy_new_yearIcon from './happy_new_year.jsx';
import Healthy_habitIcon from './healthy_habit.jsx';
import Healthy_lifestyleIcon from './healthy_lifestyle.jsx';
import Healthy_optionsIcon from './healthy_options.jsx';
import HologramIcon from './hologram.jsx';
import Home_cinemaIcon from './home_cinema.jsx';
import HookedIcon from './hooked.jsx';
import Ice_creamIcon from './ice_cream.jsx';
import Icon_designIcon from './icon_design.jsx';
import Ideas_flowIcon from './ideas_flow.jsx';
import InnovativeIcon from './innovative.jsx';
import Interior_designIcon from './interior_design.jsx';
import InterviewIcon from './interview.jsx';
import Investment_dataIcon from './investment_data.jsx';
import Investor_updateIcon from './investor_update.jsx';
import In_syncIcon from './in_sync.jsx';
import In_the_officeIcon from './in_the_office.jsx';
import Job_offersIcon from './job_offers.jsx';
import JoinIcon from './join.jsx';
import JourneyIcon from './journey.jsx';
import Key_pointsIcon from './key_points.jsx';
import Late_at_nightIcon from './late_at_night.jsx';
import LaunchingIcon from './launching.jsx';
import LearningIcon from './learning.jsx';
import Learning_sketchingIcon from './learning_sketching.jsx';
import Lightbulb_momentIcon from './lightbulb_moment.jsx';
import Live_collaborationIcon from './live_collaboration.jsx';
import Location_reviewIcon from './location_review.jsx';
import Love_is_in_the_airIcon from './love_is_in_the_air.jsx';
import Love_itIcon from './love_it.jsx';
import MailIcon from './mail.jsx';
import MailboxIcon from './mailbox.jsx';
import MaintenanceIcon from './maintenance.jsx';
import Maker_launchIcon from './maker_launch.jsx';
import Make_it_rainIcon from './make_it_rain.jsx';
import MapIcon from './map.jsx';
import MathematicsIcon from './mathematics.jsx';
import Medical_careIcon from './medical_care.jsx';
import Medical_researchIcon from './medical_research.jsx';
import MedicineIcon from './medicine.jsx';
import MeditationIcon from './meditation.jsx';
import MessengerIcon from './messenger.jsx';
import Mobile_analyticsIcon from './mobile_analytics.jsx';
import Mobile_applicationIcon from './mobile_application.jsx';
import Mobile_encryptionIcon from './mobile_encryption.jsx';
import Mobile_interfaceIcon from './mobile_interface.jsx';
import Mobile_testingIcon from './mobile_testing.jsx';
import Modern_designIcon from './modern_design.jsx';
import Modern_womanIcon from './modern_woman.jsx';
import Moment_to_relaxIcon from './moment_to_relax.jsx';
import Movie_nightIcon from './movie_night.jsx';
import Moving_forwardIcon from './moving_forward.jsx';
import MultitaskingIcon from './multitasking.jsx';
import My_answerIcon from './my_answer.jsx';
import My_code_snippetsIcon from './my_code_snippets.jsx';
import My_documentsIcon from './my_documents.jsx';
import My_filesIcon from './my_files.jsx';
import My_locationIcon from './my_location.jsx';
import My_personal_filesIcon from './my_personal_files.jsx';
import NewspaperIcon from './newspaper.jsx';
import New_ideasIcon from './new_ideas.jsx';
import New_messageIcon from './new_message.jsx';
import Notebook_ask4Icon from './notebook_ask4.jsx';
import Not_foundIcon from './not_found.jsx';
import Office_snackIcon from './office_snack.jsx';
import Off_roadIcon from './off_road.jsx';
import Online_artIcon from './online_art.jsx';
import Online_bankingIcon from './online_banking.jsx';
import Online_mediaIcon from './online_media.jsx';
import Online_organizerIcon from './online_organizer.jsx';
import Online_testIcon from './online_test.jsx';
import OpinionIcon from './opinion.jsx';
import Order_deliveredIcon from './order_delivered.jsx';
import Organizing_projectsIcon from './organizing_projects.jsx';
import Os_upgradeIcon from './os_upgrade.jsx';
import Outdoor_partyIcon from './outdoor_party.jsx';
import PancakesIcon from './pancakes.jsx';
import PartyingIcon from './partying.jsx';
import Passing_byIcon from './passing_by.jsx';
import PeopleIcon from './people.jsx';
import PercentagesIcon from './percentages.jsx';
import Performance_overviewIcon from './performance_overview.jsx';
import PersonalizationIcon from './personalization.jsx';
import Personal_dataIcon from './personal_data.jsx';
import Personal_documentsIcon from './personal_documents.jsx';
import Personal_fileIcon from './personal_file.jsx';
import Personal_financeIcon from './personal_finance.jsx';
import Personal_trainerIcon from './personal_trainer.jsx';
import Phone_callIcon from './phone_call.jsx';
import PhotocopyIcon from './photocopy.jsx';
import Photo_sessionIcon from './photo_session.jsx';
import Pie_graphIcon from './pie_graph.jsx';
import PlaylistIcon from './playlist.jsx';
import Positive_attitudeIcon from './positive_attitude.jsx';
import Predictive_analyticsIcon from './predictive_analytics.jsx';
import Preferences_popupIcon from './preferences_popup.jsx';
import PrideIcon from './pride.jsx';
import Product_explainerIcon from './product_explainer.jsx';
import ProgrammingIcon from './programming.jsx';
import ProjectionsIcon from './projections.jsx';
import Prototyping_processIcon from './prototyping_process.jsx';
import Proud_coderIcon from './proud_coder.jsx';
import Pull_requestIcon from './pull_request.jsx';
import PumpkinIcon from './pumpkin.jsx';
import Qa_engineersIcon from './qa_engineers.jsx';
import Quality_timeIcon from './quality_time.jsx';
import QuestionsIcon from './questions.jsx';
import Quitting_timeIcon from './quitting_time.jsx';
import QuizIcon from './quiz.jsx';
import ReactIcon from './react.jsx';
import Reading_timeIcon from './reading_time.jsx';
import Ready_for_wavesIcon from './ready_for_waves.jsx';
import Real_time_analyticsIcon from './real_time_analytics.jsx';
import Real_time_syncIcon from './real_time_sync.jsx';
import RelaxationIcon from './relaxation.jsx';
import ReminderIcon from './reminder.jsx';
import Reviewed_docsIcon from './reviewed_docs.jsx';
import Ride_a_bicycleIcon from './ride_a_bicycle.jsx';
import RisingIcon from './rising.jsx';
import Romantic_getawayIcon from './romantic_getaway.jsx';
import Running_wildIcon from './running_wild.jsx';
import Santa_clausIcon from './santa_claus.jsx';
import ScienceIcon from './science.jsx';
import ScientistIcon from './scientist.jsx';
import SculptingIcon from './sculpting.jsx';
import SearchingIcon from './searching.jsx';
import Search_enginesIcon from './search_engines.jsx';
import Secure_filesIcon from './secure_files.jsx';
import Secure_serverIcon from './secure_server.jsx';
import SecurityIcon from './security.jsx';
import Security_onIcon from './security_on.jsx';
import SelfieIcon from './selfie.jsx';
import ServerIcon from './server.jsx';
import Server_clusterIcon from './server_cluster.jsx';
import ServicesIcon from './services.jsx';
import Share_linkIcon from './share_link.jsx';
import Sharing_articlesIcon from './sharing_articles.jsx';
import Sharing_knowledgeIcon from './sharing_knowledge.jsx';
import ShoppingIcon from './shopping.jsx';
import Shopping_bagsIcon from './shopping_bags.jsx';
import Showing_supportIcon from './showing_support.jsx';
import Signal_searchingIcon from './signal_searching.jsx';
import SkateboardIcon from './skateboard.jsx';
import SliderIcon from './slider.jsx';
import Small_townIcon from './small_town.jsx';
import SmartwatchIcon from './smartwatch.jsx';
import Smart_resizeIcon from './smart_resize.jsx';
import Snow_gamesIcon from './snow_games.jsx';
import Social_girlIcon from './social_girl.jsx';
import Social_lifeIcon from './social_life.jsx';
import Social_sharingIcon from './social_sharing.jsx';
import Social_thinkingIcon from './social_thinking.jsx';
import Solution_mindsetIcon from './solution_mindset.jsx';
import Speed_testIcon from './speed_test.jsx';
import SpreadsheetsIcon from './spreadsheets.jsx';
import Spread_loveIcon from './spread_love.jsx';
import Stability_ballIcon from './stability_ball.jsx';
import Startup_lifeIcon from './startup_life.jsx';
import Start_buildingIcon from './start_building.jsx';
import Statistic_chartIcon from './statistic_chart.jsx';
import StudyingIcon from './studying.jsx';
import SuburbsIcon from './suburbs.jsx';
import SummerIcon from './summer.jsx';
import SunlightIcon from './sunlight.jsx';
import TargetIcon from './target.jsx';
import TeacherIcon from './teacher.jsx';
import Team_collaborationIcon from './team_collaboration.jsx';
import Team_spiritIcon from './team_spirit.jsx';
import Term_sheetIcon from './term_sheet.jsx';
import Text_filesIcon from './text_files.jsx';
import Things_to_sayIcon from './things_to_say.jsx';
import ThoughtsIcon from './thoughts.jsx';
import Three_d_modellingIcon from './three_d_modelling.jsx';
import Time_managementIcon from './time_management.jsx';
import Toy_carIcon from './toy_car.jsx';
import To_doIcon from './to_do.jsx';
import Transfer_filesIcon from './transfer_files.jsx';
import Transfer_moneyIcon from './transfer_money.jsx';
import TravelingIcon from './traveling.jsx';
import Travel_modeIcon from './travel_mode.jsx';
import Travel_plansIcon from './travel_plans.jsx';
import Treasure_ofIcon from './treasure_of.jsx';
import Tree_swingIcon from './tree_swing.jsx';
import TripIcon from './trip.jsx';
import True_friendsIcon from './true_friends.jsx';
import TypewriterIcon from './typewriter.jsx';
import Under_constructionIcon from './under_construction.jsx';
import Updated_resumeIcon from './updated_resume.jsx';
import UpgradeIcon from './upgrade.jsx';
import UploadIcon from './upload.jsx';
import UploadingIcon from './uploading.jsx';
import Users_per_minuteIcon from './users_per_minute.jsx';
import VaultIcon from './vault.jsx';
import Video_game_nightIcon from './video_game_night.jsx';
import Video_influencerIcon from './video_influencer.jsx';
import Visionary_technologyIcon from './visionary_technology.jsx';
import VisualizationIcon from './visualization.jsx';
import VotingIcon from './voting.jsx';
import Wait_in_lineIcon from './wait_in_line.jsx';
import Walking_in_rainIcon from './walking_in_rain.jsx';
import Walking_togetherIcon from './walking_together.jsx';
import Walk_in_the_cityIcon from './walk_in_the_city.jsx';
import WalletIcon from './wallet.jsx';
import Wandering_mindIcon from './wandering_mind.jsx';
import WarningIcon from './warning.jsx';
import Weather_notificationIcon from './weather_notification.jsx';
import Web_searchIcon from './web_search.jsx';
import WeddingIcon from './wedding.jsx';
import Well_doneIcon from './well_done.jsx';
import Winter_skatingIcon from './winter_skating.jsx';
import With_loveIcon from './with_love.jsx';
import WomanIcon from './woman.jsx';
import Word_of_mouthIcon from './word_of_mouth.jsx';
import WorkingIcon from './working.jsx';
import Working_outIcon from './working_out.jsx';
import Working_remotelyIcon from './working_remotely.jsx';
import Work_in_progressIcon from './work_in_progress.jsx';
import WriterIcon from './writer.jsx';
import YogaIcon from './yoga.jsx';
import Young_and_happyIcon from './young_and_happy.jsx';

const icons = {
  'absorbed_in': Absorbed_inIcon,
  'add_color': Add_colorIcon,
  'add_document': Add_documentIcon,
  'add_files': Add_filesIcon,
  'aircraft': AircraftIcon,
  'air_support': Air_supportIcon,
  'alien_science': Alien_scienceIcon,
  'amusement_park': Amusement_parkIcon,
  'animating': AnimatingIcon,
  'appreciate_it': Appreciate_itIcon,
  'articles': ArticlesIcon,
  'attached_file': Attached_fileIcon,
  'at_work': At_workIcon,
  'audio_conversation': Audio_conversationIcon,
  'augmented_reality': Augmented_realityIcon,
  'automobile_repair': Automobile_repairIcon,
  'awards': AwardsIcon,
  'baby': BabyIcon,
  'basketball': BasketballIcon,
  'beach_day': Beach_dayIcon,
  'beer': BeerIcon,
  'beer_celebration': Beer_celebrationIcon,
  'before_dawn': Before_dawnIcon,
  'be_the_hero': Be_the_heroIcon,
  'bibliophile': BibliophileIcon,
  'birthday_cake': Birthday_cakeIcon,
  'blank_canvas': Blank_canvasIcon,
  'books': BooksIcon,
  'book_lover': Book_loverIcon,
  'book_reading': Book_readingIcon,
  'breakfast': BreakfastIcon,
  'buddies': BuddiesIcon,
  'building_blocks': Building_blocksIcon,
  'businessman': BusinessmanIcon,
  'businesswoman': BusinesswomanIcon,
  'business_shop': Business_shopIcon,
  'button_style': Button_styleIcon,
  'calculator': CalculatorIcon,
  'campfire': CampfireIcon,
  'camping': CampingIcon,
  'cat': CatIcon,
  'certificate': CertificateIcon,
  'certification': CertificationIcon,
  'character_drawing': Character_drawingIcon,
  'choosing_house': Choosing_houseIcon,
  'christmas_mode': Christmas_modeIcon,
  'circuit_board': Circuit_boardIcon,
  'clean_up': Clean_upIcon,
  'cloud_docs': Cloud_docsIcon,
  'coffee': CoffeeIcon,
  'coffee_with_friends': Coffee_with_friendsIcon,
  'collecting': CollectingIcon,
  'color_palette': Color_paletteIcon,
  'completing': CompletingIcon,
  'compose_music': Compose_musicIcon,
  'conference': ConferenceIcon,
  'conference_speaker': Conference_speakerIcon,
  'contact_us': Contact_usIcon,
  'content_structure': Content_structureIcon,
  'content_team': Content_teamIcon,
  'contract': ContractIcon,
  'conversation': ConversationIcon,
  'convert': ConvertIcon,
  'cookie_love': Cookie_loveIcon,
  'cooking': CookingIcon,
  'coolness': CoolnessIcon,
  'couple': CoupleIcon,
  'couple_love': Couple_loveIcon,
  'co_workers': Co_workersIcon,
  'co_working': Co_workingIcon,
  'creation': CreationIcon,
  'creation_process': Creation_processIcon,
  'creative_team': Creative_teamIcon,
  'creative_thinking': Creative_thinkingIcon,
  'creative_woman': Creative_womanIcon,
  'creativity': CreativityIcon,
  'customer_survey': Customer_surveyIcon,
  'day_off': Day_offIcon,
  'decorate_christmas_tree': Decorate_christmas_treeIcon,
  'delivery_truck': Delivery_truckIcon,
  'designer_girl': Designer_girlIcon,
  'design_inspiration': Design_inspirationIcon,
  'design_team': Design_teamIcon,
  'digital_nomad': Digital_nomadIcon,
  'doctor': DoctorIcon,
  'doctors': DoctorsIcon,
  'dog': DogIcon,
  'doll_play': Doll_playIcon,
  'drag': DragIcon,
  'dream_world': Dream_worldIcon,
  'drone_delivery': Drone_deliveryIcon,
  'dua_lipa': Dua_lipaIcon,
  'eating_together': Eating_togetherIcon,
  'editable': EditableIcon,
  'education': EducationIcon,
  'educator': EducatorIcon,
  'eiffel_tower': Eiffel_towerIcon,
  'election_day': Election_dayIcon,
  'electricity': ElectricityIcon,
  'electric_car': Electric_carIcon,
  'empty_street': Empty_streetIcon,
  'energizer': EnergizerIcon,
  'engineering_team': Engineering_teamIcon,
  'everyday_life': Everyday_lifeIcon,
  'everywhere_together': Everywhere_togetherIcon,
  'exams': ExamsIcon,
  'exciting_news': Exciting_newsIcon,
  'experience_design': Experience_designIcon,
  'explore': ExploreIcon,
  'exploring': ExploringIcon,
  'export_files': Export_filesIcon,
  'family': FamilyIcon,
  'fans': FansIcon,
  'farm_girl': Farm_girlIcon,
  'fashion_blogging': Fashion_bloggingIcon,
  'fast_loading': Fast_loadingIcon,
  'fatherhood': FatherhoodIcon,
  'feedback': FeedbackIcon,
  'file_analysis': File_analysisIcon,
  'file_manager': File_managerIcon,
  'file_searching': File_searchingIcon,
  'filing_system': Filing_systemIcon,
  'fill_form': Fill_formIcon,
  'financial_data': Financial_dataIcon,
  'fingerprint_login': Fingerprint_loginIcon,
  'finish_line': Finish_lineIcon,
  'fireworks': FireworksIcon,
  'firmware': FirmwareIcon,
  'fitting_piece': Fitting_pieceIcon,
  'fitting_pieces': Fitting_piecesIcon,
  'floating': FloatingIcon,
  'folder_files': Folder_filesIcon,
  'font': FontIcon,
  'forgot_password': Forgot_passwordIcon,
  'friendship': FriendshipIcon,
  'functions': FunctionsIcon,
  'fun_moments': Fun_momentsIcon,
  'futuristic_interface': Futuristic_interfaceIcon,
  'game_world': Game_worldIcon,
  'gaming': GamingIcon,
  'gardening': GardeningIcon,
  'geniuses': GeniusesIcon,
  'gifts': GiftsIcon,
  'going_up': Going_upIcon,
  'good_team': Good_teamIcon,
  'grades': GradesIcon,
  'grandma': GrandmaIcon,
  'grand_slam': Grand_slamIcon,
  'halloween': HalloweenIcon,
  'hamburger': HamburgerIcon,
  'happy_feeling': Happy_feelingIcon,
  'happy_new_year': Happy_new_yearIcon,
  'healthy_habit': Healthy_habitIcon,
  'healthy_lifestyle': Healthy_lifestyleIcon,
  'healthy_options': Healthy_optionsIcon,
  'hologram': HologramIcon,
  'home_cinema': Home_cinemaIcon,
  'hooked': HookedIcon,
  'ice_cream': Ice_creamIcon,
  'icon_design': Icon_designIcon,
  'ideas_flow': Ideas_flowIcon,
  'innovative': InnovativeIcon,
  'interior_design': Interior_designIcon,
  'interview': InterviewIcon,
  'investment_data': Investment_dataIcon,
  'investor_update': Investor_updateIcon,
  'in_sync': In_syncIcon,
  'in_the_office': In_the_officeIcon,
  'job_offers': Job_offersIcon,
  'join': JoinIcon,
  'journey': JourneyIcon,
  'key_points': Key_pointsIcon,
  'late_at_night': Late_at_nightIcon,
  'launching': LaunchingIcon,
  'learning': LearningIcon,
  'learning_sketching': Learning_sketchingIcon,
  'lightbulb_moment': Lightbulb_momentIcon,
  'live_collaboration': Live_collaborationIcon,
  'location_review': Location_reviewIcon,
  'love_is_in_the_air': Love_is_in_the_airIcon,
  'love_it': Love_itIcon,
  'mail': MailIcon,
  'mailbox': MailboxIcon,
  'maintenance': MaintenanceIcon,
  'maker_launch': Maker_launchIcon,
  'make_it_rain': Make_it_rainIcon,
  'map': MapIcon,
  'mathematics': MathematicsIcon,
  'medical_care': Medical_careIcon,
  'medical_research': Medical_researchIcon,
  'medicine': MedicineIcon,
  'meditation': MeditationIcon,
  'messenger': MessengerIcon,
  'mobile_analytics': Mobile_analyticsIcon,
  'mobile_application': Mobile_applicationIcon,
  'mobile_encryption': Mobile_encryptionIcon,
  'mobile_interface': Mobile_interfaceIcon,
  'mobile_testing': Mobile_testingIcon,
  'modern_design': Modern_designIcon,
  'modern_woman': Modern_womanIcon,
  'moment_to_relax': Moment_to_relaxIcon,
  'movie_night': Movie_nightIcon,
  'moving_forward': Moving_forwardIcon,
  'multitasking': MultitaskingIcon,
  'my_answer': My_answerIcon,
  'my_code_snippets': My_code_snippetsIcon,
  'my_documents': My_documentsIcon,
  'my_files': My_filesIcon,
  'my_location': My_locationIcon,
  'my_personal_files': My_personal_filesIcon,
  'newspaper': NewspaperIcon,
  'new_ideas': New_ideasIcon,
  'new_message': New_messageIcon,
  'notebook_ask4': Notebook_ask4Icon,
  'not_found': Not_foundIcon,
  'office_snack': Office_snackIcon,
  'off_road': Off_roadIcon,
  'online_art': Online_artIcon,
  'online_banking': Online_bankingIcon,
  'online_media': Online_mediaIcon,
  'online_organizer': Online_organizerIcon,
  'online_test': Online_testIcon,
  'opinion': OpinionIcon,
  'order_delivered': Order_deliveredIcon,
  'organizing_projects': Organizing_projectsIcon,
  'os_upgrade': Os_upgradeIcon,
  'outdoor_party': Outdoor_partyIcon,
  'pancakes': PancakesIcon,
  'partying': PartyingIcon,
  'passing_by': Passing_byIcon,
  'people': PeopleIcon,
  'percentages': PercentagesIcon,
  'performance_overview': Performance_overviewIcon,
  'personalization': PersonalizationIcon,
  'personal_data': Personal_dataIcon,
  'personal_documents': Personal_documentsIcon,
  'personal_file': Personal_fileIcon,
  'personal_finance': Personal_financeIcon,
  'personal_trainer': Personal_trainerIcon,
  'phone_call': Phone_callIcon,
  'photocopy': PhotocopyIcon,
  'photo_session': Photo_sessionIcon,
  'pie_graph': Pie_graphIcon,
  'playlist': PlaylistIcon,
  'positive_attitude': Positive_attitudeIcon,
  'predictive_analytics': Predictive_analyticsIcon,
  'preferences_popup': Preferences_popupIcon,
  'pride': PrideIcon,
  'product_explainer': Product_explainerIcon,
  'programming': ProgrammingIcon,
  'projections': ProjectionsIcon,
  'prototyping_process': Prototyping_processIcon,
  'proud_coder': Proud_coderIcon,
  'pull_request': Pull_requestIcon,
  'pumpkin': PumpkinIcon,
  'qa_engineers': Qa_engineersIcon,
  'quality_time': Quality_timeIcon,
  'questions': QuestionsIcon,
  'quitting_time': Quitting_timeIcon,
  'quiz': QuizIcon,
  'react': ReactIcon,
  'reading_time': Reading_timeIcon,
  'ready_for_waves': Ready_for_wavesIcon,
  'real_time_analytics': Real_time_analyticsIcon,
  'real_time_sync': Real_time_syncIcon,
  'relaxation': RelaxationIcon,
  'reminder': ReminderIcon,
  'reviewed_docs': Reviewed_docsIcon,
  'ride_a_bicycle': Ride_a_bicycleIcon,
  'rising': RisingIcon,
  'romantic_getaway': Romantic_getawayIcon,
  'running_wild': Running_wildIcon,
  'santa_claus': Santa_clausIcon,
  'science': ScienceIcon,
  'scientist': ScientistIcon,
  'sculpting': SculptingIcon,
  'searching': SearchingIcon,
  'search_engines': Search_enginesIcon,
  'secure_files': Secure_filesIcon,
  'secure_server': Secure_serverIcon,
  'security': SecurityIcon,
  'security_on': Security_onIcon,
  'selfie': SelfieIcon,
  'server': ServerIcon,
  'server_cluster': Server_clusterIcon,
  'services': ServicesIcon,
  'share_link': Share_linkIcon,
  'sharing_articles': Sharing_articlesIcon,
  'sharing_knowledge': Sharing_knowledgeIcon,
  'shopping': ShoppingIcon,
  'shopping_bags': Shopping_bagsIcon,
  'showing_support': Showing_supportIcon,
  'signal_searching': Signal_searchingIcon,
  'skateboard': SkateboardIcon,
  'slider': SliderIcon,
  'small_town': Small_townIcon,
  'smartwatch': SmartwatchIcon,
  'smart_resize': Smart_resizeIcon,
  'snow_games': Snow_gamesIcon,
  'social_girl': Social_girlIcon,
  'social_life': Social_lifeIcon,
  'social_sharing': Social_sharingIcon,
  'social_thinking': Social_thinkingIcon,
  'solution_mindset': Solution_mindsetIcon,
  'speed_test': Speed_testIcon,
  'spreadsheets': SpreadsheetsIcon,
  'spread_love': Spread_loveIcon,
  'stability_ball': Stability_ballIcon,
  'startup_life': Startup_lifeIcon,
  'start_building': Start_buildingIcon,
  'statistic_chart': Statistic_chartIcon,
  'studying': StudyingIcon,
  'suburbs': SuburbsIcon,
  'summer': SummerIcon,
  'sunlight': SunlightIcon,
  'target': TargetIcon,
  'teacher': TeacherIcon,
  'team_collaboration': Team_collaborationIcon,
  'team_spirit': Team_spiritIcon,
  'term_sheet': Term_sheetIcon,
  'text_files': Text_filesIcon,
  'things_to_say': Things_to_sayIcon,
  'thoughts': ThoughtsIcon,
  'three_d_modelling': Three_d_modellingIcon,
  'time_management': Time_managementIcon,
  'toy_car': Toy_carIcon,
  'to_do': To_doIcon,
  'transfer_files': Transfer_filesIcon,
  'transfer_money': Transfer_moneyIcon,
  'traveling': TravelingIcon,
  'travel_mode': Travel_modeIcon,
  'travel_plans': Travel_plansIcon,
  'treasure_of': Treasure_ofIcon,
  'tree_swing': Tree_swingIcon,
  'trip': TripIcon,
  'true_friends': True_friendsIcon,
  'typewriter': TypewriterIcon,
  'under_construction': Under_constructionIcon,
  'updated_resume': Updated_resumeIcon,
  'upgrade': UpgradeIcon,
  'upload': UploadIcon,
  'uploading': UploadingIcon,
  'users_per_minute': Users_per_minuteIcon,
  'vault': VaultIcon,
  'video_game_night': Video_game_nightIcon,
  'video_influencer': Video_influencerIcon,
  'visionary_technology': Visionary_technologyIcon,
  'visualization': VisualizationIcon,
  'voting': VotingIcon,
  'wait_in_line': Wait_in_lineIcon,
  'walking_in_rain': Walking_in_rainIcon,
  'walking_together': Walking_togetherIcon,
  'walk_in_the_city': Walk_in_the_cityIcon,
  'wallet': WalletIcon,
  'wandering_mind': Wandering_mindIcon,
  'warning': WarningIcon,
  'weather_notification': Weather_notificationIcon,
  'web_search': Web_searchIcon,
  'wedding': WeddingIcon,
  'well_done': Well_doneIcon,
  'winter_skating': Winter_skatingIcon,
  'with_love': With_loveIcon,
  'woman': WomanIcon,
  'word_of_mouth': Word_of_mouthIcon,
  'working': WorkingIcon,
  'working_out': Working_outIcon,
  'working_remotely': Working_remotelyIcon,
  'work_in_progress': Work_in_progressIcon,
  'writer': WriterIcon,
  'yoga': YogaIcon,
  'young_and_happy': Young_and_happyIcon,
};

export default icons;
