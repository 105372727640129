import React from 'react';
const BookmarkIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="43" height="57" viewBox="0 0 43 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M35.0059 0.227778H11.6794C5.25089 0.227778 0.0206299 5.45691 0.0206299 11.884V50.3593C0.0206299 53.5772 2.63582 56.1918 5.85446 56.1918C7.10518 56.1918 8.34716 55.7808 9.35298 55.0288L14.9505 50.8315C15.7727 50.2106 16.9273 50.2106 17.7494 50.8315L23.3471 55.0288C24.3529 55.7808 25.5949 56.1918 26.8456 56.1918C30.0643 56.1918 32.6793 53.5772 32.6793 50.3593V30.5358H35.0146C38.8718 30.5358 42.0117 27.3965 42.0117 23.5403V7.21452C42.0117 3.35825 38.8718 0.219025 35.0234 0.219025L35.0059 0.227778ZM28.0088 7.22328V50.3593C28.0088 51.2512 26.8456 51.8283 26.1459 51.2949L20.5482 47.0976C19.315 46.1707 17.8282 45.7073 16.35 45.7073C14.8719 45.7073 13.385 46.1707 12.1518 47.0976L6.56285 51.2949C5.84566 51.8371 4.69113 51.2599 4.69113 50.3593V11.884C4.69113 8.02776 7.83106 4.88853 11.6882 4.88853H28.4111C28.1575 5.61431 28.0088 6.40131 28.0088 7.22328ZM37.3324 23.549C37.3324 24.8344 36.2828 25.8838 34.9971 25.8838H32.6618V7.23202C32.6618 5.94659 33.7114 4.89727 34.9971 4.89727C36.2828 4.89727 37.3324 5.94659 37.3324 7.23202V23.5578V23.549Z" fill={secondaryColor}/>
<path d="M9.34424 11.8928C9.34424 13.1782 10.385 14.2275 11.6794 14.2275H23.3383C24.624 14.2275 25.6735 13.1869 25.6735 11.8928C25.6735 10.5986 24.6328 9.55801 23.3383 9.55801H11.6794C10.3937 9.55801 9.34424 10.5986 9.34424 11.8928Z" fill={primaryColor}/>
</svg>

);

export default BookmarkIcon;
