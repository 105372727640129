import {
  Button,
  TextField,
  Box,
  Grid,
  Paper,
  Autocomplete,
  Stack,
  Switch,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Form, Formik } from 'formik';
import useImageSelect from '../../hooks/useImageSelect';
import { useEffect, useState } from 'react';
import IconPicker from '../../components/IconPicker';
import IconPickerToggle from '../../components/IconPickerToggle';

const MapObjectCategoryForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    publishers,
    readonly = false,
  } = props;

  const { ImageSelectComponent, uploadImage, uploading, error, uploadedFile } =
    useImageSelect();

  const handleUploaded = (image: any, setFieldValue: any) => {
    setFieldValue('imageId', image.id);
    setFieldValue('image', image);
    setFieldValue('icon', '');
  };
  const [publisherId, setPublisherId] = useState<number | null>(0);
  const [iconPickerOpen, setIconPickerOpen] = useState(false);

  useEffect(() => {
    setIconPickerOpen(entity.icon ? true : false);
    setPublisherId(entity.image?.publisherId ?? 0);
  }, [entity]);

  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};
        if (!values.name) {
          errors.name = 'Required';
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting, setFieldValue }) => {
        let newValues = { ...values };
        if (publisherId) {
          uploadImage(
            (image: any) =>
              (newValues = {
                ...values,
                image: image,
                imageId: image.id,
                icon: '',
              }),
            'kép',
            publisherId,
            '-1'
          )?.then(() => {
            onSubmit(newValues, setSubmitting);
          });
        }
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        setFieldValue,
        handleChange,
        errors: validationErrors,
      }) => (
        <Form>
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>
                Térképelem kategória{' '}
                {values.id > 0 ? 'szerkesztése' : 'létrehozása'}{' '}
              </h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error: string) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.name}
                  onChange={handleChange}
                  label="Name"
                  name="name"
                  required
                  disabled={readonly}
                  fullWidth
                  autoFocus
                  error={!!touched.name && !!validationErrors.name}
                  helperText={
                    !!touched.name &&
                    !!validationErrors.name &&
                    (validationErrors.name as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <IconPickerToggle
                  iconPickerOpen={iconPickerOpen}
                  setIconPickerOpen={setIconPickerOpen}
                />
              </Grid>
              {!iconPickerOpen && (
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    disablePortal
                    id="publisherId"
                    disabled={readonly}
                    value={publisherId}
                    onChange={(event, value) => {
                      setPublisherId(value);
                    }}
                    getOptionLabel={(option) =>
                      publishers?.find((g: any) => g.id === option)?.name ?? ''
                    }
                    options={publishers?.map((g: any) => g.id)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        fullWidth
                        label="Galéria választás"
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                {!iconPickerOpen && (
                  <ImageSelectComponent
                    image={values.image}
                    onSelect={(image: any) => {
                      setFieldValue('imageId', image.id);
                      setFieldValue('image', image);
                      setFieldValue('icon', '');
                    }}
                    alt="kép"
                    onUploaded={(image: any) =>
                      handleUploaded(image, setFieldValue)
                    }
                    publisherId={publisherId}
                    currentFolderId={'-1'}
                  />
                )}
                {iconPickerOpen && (
                  <IconPicker
                    icon={values.icon}
                    onSelect={(icon: any) => {
                      setFieldValue('imageId', null);
                      setFieldValue('icon', icon);
                    }}
                  />
                )}
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item xs={12} sm={isSmallScreen ? 12 : 'auto'} p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth={isSmallScreen}
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12} sm={isSmallScreen ? 12 : 'auto'} p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth={isSmallScreen}
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default MapObjectCategoryForm;
