import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useCheckPermission from '../../hooks/useCheckPermission';
import RssSettingsForm from './RssSettingsForm';
import publisherService from '../../services/publisherService';
import rssSettingsService from '../../services/rssSettingsService';
import tagService from '../../services/tagService';

const RssSettingsCreate = (props: any) => {
  const { onSave = null, navigateBack = true, handleCancel = null } = props;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const [errors, setErrors] = useState<string[]>([]);
  const [tags, setTags] = useState<any[]>([]);

  const { checkPermission } = useCheckPermission();
  const [publishers, setPublishers] = useState<any[]>([]);
  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    publisherService
      .list()
      .then((response: any) => {
        if (!response.hasError) {
          setPublishers(response.records);
        } else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    rssSettingsService
      .create(entity)
      .then((response) => {
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Success!', {
            variant: 'success',
          });

          if (onSave) onSave(response.result);
          if (navigateBack) navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        if (setSubmitting) setSubmitting(false);
      });
  };

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    tagService
      .list()
      .then((response: any) => {
        if (!response.hasError) setTags(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  return (
    <RssSettingsForm
      entity={{
        publisherId: 0,
        tagId: 0,
        feedUrl: '',
        sendPushNotification: false,
        isPublished: false,
      }}
      readonly={!checkPermission(['CreateRssSettings'])}
      onSubmit={onSubmit}
      errors={errors}
      handleCancel={() =>
        handleCancel != null ? handleCancel() : navigate(-1)
      }
      publishers={publishers}
      tags={tags}
      onTagAdded={(tag: any) => {
        setTags([...tags, tag]);
      }}
    />
  );
};

export default RssSettingsCreate;
