import React from 'react';
const BuoyIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="48" height="57" viewBox="0 0 48 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M41.8704 40.2482C41.608 40.4668 41.2844 40.6155 40.917 40.6767C29.8354 42.7054 18.4477 42.6967 7.40989 40.6942C7.05129 40.633 6.70146 40.4669 6.38659 40.2133L3.47405 43.8597C4.40991 44.6117 5.45947 45.0926 6.59649 45.285C12.3778 46.3343 18.2816 46.859 24.1503 46.859C30.0191 46.859 35.9404 46.3168 41.7567 45.2587C42.9112 45.0489 43.987 44.5505 44.8616 43.8247L41.8791 40.2395L41.8704 40.2482Z" fill={primaryColor}/>
<path d="M41.5642 38.2983L40.7159 33.7162C30.0191 35.6924 18.6402 35.7186 7.54111 33.7337L6.71896 38.3245C12.4828 39.3563 18.3428 39.8722 24.1328 39.8722C29.9229 39.8722 35.8879 39.3388 41.5555 38.2983H41.5642Z" fill={primaryColor}/>
<path d="M34.8646 20.6259L34.3573 15.9913C27.1416 16.7696 20.4944 16.7434 13.9259 15.9913L13.4011 20.6259C16.9433 21.0281 20.4506 21.2205 24.1416 21.2205C27.8325 21.2205 31.1736 21.0281 34.8646 20.6259Z" fill={primaryColor}/>
<path d="M37.401 27.3066L36.7625 22.6895C28.4798 23.835 19.7684 23.835 11.5207 22.6895L10.8822 27.3066C15.2291 27.9099 19.6897 28.216 24.1504 28.216C28.611 28.216 33.0628 27.9099 37.4185 27.3066H37.401Z" fill={primaryColor}/>
<path d="M24.1416 56.1979C18.2728 56.1979 12.369 55.6645 6.58773 54.6152C3.24664 54.0031 0.815186 51.1 0.815186 47.7072C0.815186 46.8765 0.963864 46.0632 1.24375 45.285L15.6664 6.1364C16.9696 2.60367 20.3632 0.233948 24.1328 0.233948C27.9025 0.233948 31.2961 2.60367 32.5993 6.1364L47.0219 45.285C47.3105 46.0632 47.4505 46.8765 47.4505 47.7072C47.4505 51.1 45.0453 53.9944 41.7392 54.6065C35.9491 55.6645 30.0191 56.2067 24.1241 56.2067L24.1416 56.1979ZM24.1416 4.89471C22.3224 4.89471 20.6781 6.04024 20.0571 7.74539L5.63441 46.8939C5.5382 47.1562 5.48569 47.4274 5.48569 47.7072C5.48569 48.844 6.29912 49.8233 7.4274 50.0244C18.4565 52.0356 29.9054 52.0269 40.917 50.0157C42.0103 49.8145 42.8062 48.844 42.8062 47.7072C42.8062 47.4274 42.7625 47.1562 42.6575 46.8939L28.2349 7.74539C27.6051 6.04024 25.9608 4.89471 24.1503 4.89471H24.1416Z" fill={secondaryColor}/>
</svg>

);

export default BuoyIcon;
