import React from 'react';
const PhoneIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M53.5929 35.7474L50.0332 32.1885C47.3043 29.4602 42.8699 29.4602 40.141 32.1885L38.558 33.7712C37.8845 34.4445 36.87 34.6544 36.0391 34.2959C33.1003 33.0192 30.3452 31.1479 28.0799 28.8831C25.8146 26.6183 24.0041 23.9688 22.7097 21.0219C22.3161 20.1212 22.5085 19.0981 23.1907 18.4073L24.7738 16.8246C27.5027 14.0964 27.5027 9.66296 24.7738 6.93471L21.214 3.37574C19.1761 1.33831 16.4735 0.219025 13.596 0.219025C10.7185 0.219025 8.01588 1.33831 5.97798 3.37574L3.57275 5.78045C1.79725 7.55556 0.773865 10.0215 0.773865 12.5399C0.773865 14.0089 1.10621 15.4692 1.74469 16.7546C5.82922 25.0793 11.2258 32.6169 17.7855 39.1752C24.3452 45.7335 31.8846 51.1288 40.2111 55.2124C41.4968 55.842 42.9574 56.1743 44.4267 56.1743C46.9457 56.1743 49.4034 55.1512 51.1876 53.3761L53.5929 50.9714C55.6308 48.9339 56.7503 46.2319 56.7503 43.355C56.7503 40.4781 55.6308 37.7674 53.5929 35.7299V35.7474ZM50.2955 47.6922L47.8902 50.0969C46.9806 51.0064 45.7212 51.531 44.4267 51.531C43.6658 51.531 42.9137 51.3649 42.2664 51.0413C34.4035 47.1763 27.2753 42.0784 21.0829 35.8873C14.8905 29.6963 9.79133 22.5697 5.92546 14.7085C5.60184 14.0614 5.43572 13.3094 5.43572 12.5486C5.43572 11.2632 5.96051 9.99523 6.87013 9.08582L9.27536 6.68112C10.4299 5.52686 11.9692 4.88853 13.596 4.88853C15.2316 4.88853 16.7622 5.52686 17.9167 6.68112L21.4764 10.2401C22.386 11.1495 22.386 12.6273 21.4764 13.5367L19.8933 15.1194C17.8379 17.1744 17.2695 20.2262 18.4415 22.9019C19.9721 26.3822 22.1062 29.5127 24.7826 32.1885C27.4589 34.8642 30.7038 37.0678 34.1848 38.5806C36.7563 39.6999 39.8437 39.0878 41.8554 37.0766L43.4384 35.4938C44.348 34.5844 45.8262 34.5844 46.7358 35.4938L50.2955 39.0528C51.45 40.2071 52.0885 41.7461 52.0885 43.3725C52.0885 45.0077 51.45 46.538 50.2955 47.6922Z" fill={secondaryColor}/>
<path d="M46.7357 38.7905C45.8261 37.881 44.348 37.881 43.4383 38.7905C42.5287 39.6999 42.5287 41.1777 43.4383 42.0871L45.3451 43.9934C46.2547 44.9028 47.7329 44.9028 48.6425 43.9934C49.5521 43.0839 49.5521 41.6062 48.6425 40.6967L46.7357 38.7905Z" fill={primaryColor}/>
</svg>

);

export default PhoneIcon;
