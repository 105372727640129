import React from 'react';
const WarningIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="56" viewBox="0 0 57 56" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M55.4126 39.6654C53.5059 34.5937 51.3018 29.5482 48.8878 24.6513C45.5467 17.8832 41.7071 11.2724 37.4739 4.98521C35.3835 1.88096 31.8938 0.0272217 28.1416 0.0272217C24.3894 0.0272217 20.8997 1.88096 18.8093 4.98521C14.5761 11.2637 10.7277 17.8832 7.39535 24.6513C4.98137 29.5394 2.78603 34.5937 0.870585 39.6567C0.398284 40.9159 0.153381 42.2538 0.153381 43.6354C0.153381 49.328 4.43033 54.1461 10.1067 54.8632C16.0717 55.6152 22.1329 55.9912 28.1416 55.9912C34.1503 55.9912 40.3077 55.6064 46.3077 54.8457C51.9053 54.1374 56.1298 49.3193 56.1298 43.6354C56.1298 42.2538 55.8849 40.9159 55.4126 39.6567V39.6654ZM45.7129 50.2286C34.1416 51.6977 22.2728 51.7064 10.6839 50.2461C7.33408 49.8264 4.81518 46.9933 4.81518 43.6529C4.81518 42.8397 4.9551 42.0527 5.23499 41.3181C7.08921 36.3951 9.23205 31.4807 11.5761 26.7237C14.8209 20.1479 18.5556 13.7121 22.6839 7.60853C23.9084 5.7897 25.955 4.70544 28.1503 4.70544C30.3457 4.70544 32.3923 5.7897 33.6168 7.60853C37.7363 13.7121 41.471 20.1479 44.7246 26.7237C47.0686 31.4807 49.2027 36.3863 51.0657 41.3181C51.3456 42.0527 51.4855 42.8309 51.4855 43.6529C51.4855 46.9933 49.019 49.8176 45.7392 50.2374L45.7129 50.2286Z" fill={secondaryColor}/>
<path d="M28.1416 16.3529C26.8559 16.3529 25.8063 17.3936 25.8063 18.6877V32.6787C25.8063 33.9641 26.8471 35.0135 28.1416 35.0135C29.436 35.0135 30.4768 33.9729 30.4768 32.6787V18.6877C30.4768 17.4023 29.436 16.3529 28.1416 16.3529Z" fill={primaryColor}/>
<path d="M28.1416 37.3394C26.8559 37.3394 25.8063 38.38 25.8063 39.6742V42.009C25.8063 43.2944 26.8471 44.3437 28.1416 44.3437C29.436 44.3437 30.4768 43.3032 30.4768 42.009V39.6742C30.4768 38.3888 29.436 37.3394 28.1416 37.3394Z" fill={primaryColor}/>
</svg>

);

export default WarningIcon;
