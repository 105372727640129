import React from 'react';
const EssayIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="62" height="62" viewBox="0 0 62 62" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M31.236 61.8843H4.23613C2.24197 61.8843 0.623901 60.2666 0.623901 58.2728V4.28508C0.623901 2.29136 2.24197 0.673645 4.23613 0.673645H41.4779C43.4721 0.673645 45.0902 2.29136 45.0902 4.28508V48.0332C45.0902 48.4967 44.9065 48.9426 44.5741 49.2661L32.4692 61.3684C32.1369 61.7007 31.6996 61.8843 31.236 61.8843ZM4.23613 4.17139C4.1749 4.17139 4.12243 4.22387 4.12243 4.28508V58.2728C4.12243 58.334 4.1749 58.3865 4.23613 58.3865H30.5101L41.5916 47.3074V4.28508C41.5916 4.22387 41.5392 4.17139 41.4779 4.17139H4.23613Z" fill={secondaryColor}/>
<path d="M31.236 53.5072C30.2739 53.5072 29.4868 52.7202 29.4868 51.7583V48.9689C29.4868 47.4911 30.685 46.293 32.1631 46.293H43.3322C44.2943 46.293 45.0814 47.08 45.0814 48.0419C45.0814 49.0038 44.2943 49.7908 43.3322 49.7908H32.9765V51.767C32.9765 52.7289 32.1894 53.5159 31.2273 53.5159L31.236 53.5072Z" fill={secondaryColor}/>
<path d="M18.9911 26.0323H9.67633C8.71423 26.0323 7.92706 25.2454 7.92706 24.2835V14.9707C7.92706 14.0089 8.71423 13.2219 9.67633 13.2219H11.5393C12.5014 13.2219 13.2886 14.0089 13.2886 14.9707C13.2886 15.9326 12.5014 16.7196 11.5393 16.7196H11.4256V22.5346H17.2419V22.4209C17.2419 21.459 18.0291 20.672 18.9911 20.672C19.9532 20.672 20.7404 21.459 20.7404 22.4209V24.2835C20.7404 25.2454 19.9532 26.0323 18.9911 26.0323Z" fill={secondaryColor}/>
<path d="M16.4285 19.0368C16.3673 19.0368 16.3061 19.0368 16.2448 19.0281C15.6501 18.9669 15.1341 18.6084 14.8629 18.075L13.9358 16.2124C13.5072 15.3467 13.8571 14.2974 14.7142 13.8689C15.3877 13.5279 16.1749 13.6678 16.6997 14.1575L18.79 11.5429C19.3935 10.7909 20.4956 10.6685 21.2477 11.2718C21.9999 11.8752 22.1224 12.977 21.5189 13.729L17.7929 18.381C17.4606 18.8007 16.9533 19.0368 16.4285 19.0368Z" fill={secondaryColor}/>
<path d="M35.889 23.7151H25.6471C24.685 23.7151 23.8978 22.9281 23.8978 21.9662C23.8978 21.0044 24.685 20.2174 25.6471 20.2174H35.889C36.8511 20.2174 37.6383 21.0044 37.6383 21.9662C37.6383 22.9281 36.8511 23.7151 35.889 23.7151Z" fill={secondaryColor}/>
<path d="M18.9911 43.5211H9.67633C8.71423 43.5211 7.92706 42.7341 7.92706 41.7722V32.4595C7.92706 31.4976 8.71423 30.7106 9.67633 30.7106H11.5393C12.5014 30.7106 13.2886 31.4976 13.2886 32.4595C13.2886 33.4214 12.5014 34.2084 11.5393 34.2084H11.4256V40.0233H17.2419V39.9097C17.2419 38.9478 18.0291 38.1608 18.9911 38.1608C19.9532 38.1608 20.7404 38.9478 20.7404 39.9097V41.7722C20.7404 42.7341 19.9532 43.5211 18.9911 43.5211Z" fill={secondaryColor}/>
<path d="M17.0145 36.5256C16.9533 36.5256 16.8921 36.5256 16.8308 36.5168C16.2361 36.4556 15.7201 36.0971 15.4489 35.5637L14.5218 33.7012C14.0932 32.8355 14.4431 31.7862 15.3002 31.3577C15.9737 31.0166 16.7609 31.1565 17.2857 31.6462L19.376 29.0316C19.9795 28.2796 21.0816 28.1572 21.8337 28.7606C22.5859 29.3639 22.7084 30.4657 22.1049 31.2177L18.3789 35.8698C18.0466 36.2895 17.5393 36.5256 17.0145 36.5256Z" fill={secondaryColor}/>
<path d="M36.2564 41.2388H26.0145C25.0524 41.2388 24.2652 40.4518 24.2652 39.4899C24.2652 38.5281 25.0524 37.7411 26.0145 37.7411H36.2564C37.2185 37.7411 38.0057 38.5281 38.0057 39.4899C38.0057 40.4518 37.2185 41.2388 36.2564 41.2388Z" fill={secondaryColor}/>
<path d="M54.5099 59.0948C53.8977 59.0948 53.3292 58.7713 53.0143 58.2466L47.4254 48.9339C47.2592 48.6628 47.1718 48.348 47.1718 48.0332V16.3873C47.1718 12.8546 50.0493 9.98644 53.5741 9.98644H55.437C58.9705 9.98644 61.8393 12.8633 61.8393 16.3873V48.0332C61.8393 48.348 61.7519 48.6628 61.5857 48.9339L55.9968 58.2466C55.6819 58.7713 55.1134 59.0948 54.5012 59.0948H54.5099ZM50.679 47.5522L54.5187 53.9444L58.3583 47.5522V16.3873C58.3583 14.7871 57.0551 13.4842 55.4545 13.4842H53.5915C51.991 13.4842 50.6878 14.7871 50.6878 16.3873V47.5522H50.679Z" fill={primaryColor}/>
<path d="M55.4458 57.2323H53.5828C52.6207 57.2323 51.8336 56.4453 51.8336 55.4834C51.8336 54.5215 52.6207 53.7345 53.5828 53.7345H55.4458C56.4079 53.7345 57.195 54.5215 57.195 55.4834C57.195 56.4453 56.4079 57.2323 55.4458 57.2323Z" fill={primaryColor}/>
<path d="M54.5099 49.7821C53.5478 49.7821 52.7606 48.9951 52.7606 48.0332V24.7644C52.7606 23.8025 53.5478 23.0155 54.5099 23.0155C55.472 23.0155 56.2591 23.8025 56.2591 24.7644V48.0332C56.2591 48.9951 55.472 49.7821 54.5099 49.7821Z" fill={primaryColor}/>
<path d="M60.0988 21.8613H54.5099C53.5478 21.8613 52.7606 21.0743 52.7606 20.1124C52.7606 19.1506 53.5478 18.3636 54.5099 18.3636H60.0988C61.0609 18.3636 61.848 19.1506 61.848 20.1124C61.848 21.0743 61.0609 21.8613 60.0988 21.8613Z" fill={primaryColor}/>
<path d="M60.0988 26.5133H52.647C51.6849 26.5133 50.8977 25.7263 50.8977 24.7644C50.8977 23.8025 51.6849 23.0155 52.647 23.0155H60.0988C61.0609 23.0155 61.8481 23.8025 61.8481 24.7644C61.8481 25.7263 61.0609 26.5133 60.0988 26.5133Z" fill={primaryColor}/>
<path d="M60.0988 49.7821H52.647C51.6849 49.7821 50.8977 48.9951 50.8977 48.0332C50.8977 47.0713 51.6849 46.2843 52.647 46.2843H60.0988C61.0609 46.2843 61.8481 47.0713 61.8481 48.0332C61.8481 48.9951 61.0609 49.7821 60.0988 49.7821Z" fill={primaryColor}/>
</svg>

);

export default EssayIcon;
