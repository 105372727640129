import { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Box,
  Grid,
  Paper,
  Autocomplete,
  useMediaQuery,
  Checkbox,
  FormControlLabel,
  Divider,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import CreateEntityDecorator from '../../components/CreateEntityDecorator';
import LocationCreate from '../locations/LocationCreate';
import useCheckPermission from '../../hooks/useCheckPermission';
import useImageSelect from '../../hooks/useImageSelect';
import MapObjectCategoryCreate from '../mapObjectCategories/MapObjectCategoryCreate';
import PageCreate from '../pages/PageCreate';
import IconPicker from '../../components/IconPicker';
import { set } from 'lodash';

const MapObjectForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    readonly = false,
    groups,
    mapObjectCategories,
    onMapObjectCategoryAdded,
    locations,
    onLocationAdded,
    publishers,
    pages,
    onPageAdded,
  } = props;

  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );

  const { checkPermission } = useCheckPermission();
  const [publisherId, setPublisherId] = useState<number | null>(0);

  const { ImageSelectComponent, uploadImage, uploading, error, uploadedFile } =
    useImageSelect();

  const handleUploaded = (image: any, setFieldValue: any) => {
    setFieldValue('imageId', image.id);
    setFieldValue('image', image);
  };

  useEffect(() => {
    setPublisherId(entity.image?.publisherId ?? 0);
  }, [entity]);

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};
        if (!values.name) {
          errors.name = 'Required';
        }
        if (!values.mapObjectCategoryId) {
          errors.mapObjectCategoryId = 'Required';
        }
        if (!values.locationId) {
          errors.locationId = 'Required';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting, setFieldValue }) => {
        let newValues = { ...values };
        if (publisherId) {
          uploadImage(
            (image: any) =>
              (newValues = {
                ...values,
                image: image,
                imageId: image.id,
              }),
            values.shortTitle,
            publisherId,
            '-1'
          )?.then(() => {
            onSubmit(newValues, setSubmitting);
          });
        }
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        setFieldValue,
        handleChange,
        errors: validationErrors,
      }) => (
        <Form>
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>Térképelem {values.id > 0 ? 'szerkesztés' : 'létrehozás'}</h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error: string) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.name}
                  onChange={handleChange}
                  label="Név"
                  name="name"
                  required
                  disabled={readonly}
                  fullWidth
                  autoFocus
                  error={!!touched.name && !!validationErrors.name}
                  helperText={
                    !!touched.name &&
                    !!validationErrors.name &&
                    (validationErrors.name as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.description}
                  onChange={handleChange}
                  label="Leírás"
                  name="description"
                  disabled={readonly}
                  fullWidth
                  error={
                    !!touched.description && !!validationErrors.description
                  }
                  helperText={
                    !!touched.description &&
                    !!validationErrors.description &&
                    (validationErrors.description as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <CreateEntityDecorator
                  hideAdd={!checkPermission(['CreateMapObjectCategory'])}
                  AutocompleteComponent={
                    <Autocomplete
                      disablePortal
                      id="mapObjectCategoryId"
                      disabled={readonly}
                      value={values.mapObjectCategoryId}
                      onChange={(event, value) => {
                        setFieldValue('mapObjectCategoryId', value);
                      }}
                      getOptionLabel={(option) =>
                        mapObjectCategories.find((g: any) => g.id === option)
                          ?.name ?? ''
                      }
                      options={mapObjectCategories.map((g: any) => g.id)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Térképelem kategória"
                          required
                          fullWidth
                          error={
                            !!touched.mapObjectCategoryId &&
                            !!validationErrors.mapObjectCategoryId
                          }
                          helperText={
                            !!touched.mapObjectCategoryId &&
                            !!validationErrors.mapObjectCategoryId &&
                            (validationErrors.mapObjectCategoryId as string)
                          }
                        />
                      )}
                    />
                  }
                  onSave={(mapObjectCategory: any) => {
                    setFieldValue('mapObjectCategoryId', mapObjectCategory.id);
                    onMapObjectCategoryAdded(mapObjectCategory);
                  }}
                  CreateEntityComponent={MapObjectCategoryCreate}
                />
              </Grid>
              <Grid item xs={12}>
                <CreateEntityDecorator
                  hideAdd={!checkPermission(['CreateLocation'])}
                  AutocompleteComponent={
                    <Autocomplete
                      disablePortal
                      id="locationId"
                      disabled={readonly}
                      value={values.locationId}
                      onChange={(event, value) => {
                        setFieldValue('locationId', value);
                      }}
                      getOptionLabel={(option) =>
                        locations.find((g: any) => g.id === option)?.name ?? ''
                      }
                      options={locations.map((g: any) => g.id)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Helyszín"
                          fullWidth
                          required
                          error={
                            !!touched.locationId &&
                            !!validationErrors.locationId
                          }
                          helperText={
                            !!touched.locationId &&
                            !!validationErrors.locationId &&
                            (validationErrors.locationId as string)
                          }
                        />
                      )}
                    />
                  }
                  onSave={(location: any) => {
                    setFieldValue('locationId', location.id);
                    onLocationAdded(location);
                  }}
                  CreateEntityComponent={LocationCreate}
                />
              </Grid>
              <Grid item xs={12}>
                <CreateEntityDecorator
                  hideAdd={!checkPermission(['CreatePage'])}
                  AutocompleteComponent={
                    <Autocomplete
                      disablePortal
                      id="pageId"
                      value={values.pageId ?? ''}
                      onChange={(event, value) => {
                        setFieldValue('pageId', value);
                      }}
                      getOptionLabel={(option: any) =>
                        pages.find((x: any) => x.id === option)?.name ?? ''
                      }
                      options={pages.map((x: any) => x.id)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Aloldal kiválasztása"
                          fullWidth
                        />
                      )}
                    />
                  }
                  onSave={(value: any) => {
                    onPageAdded(value);
                    setFieldValue('pageId', value.id);
                  }}
                  CreateEntityComponent={PageCreate}
                />
              </Grid>
              <Grid item xs={12} sm={6} marginTop={5}>
                <Autocomplete
                  disablePortal
                  id="publisherId"
                  disabled={readonly}
                  value={publisherId}
                  onChange={(event, value) => {
                    setPublisherId(value);
                  }}
                  getOptionLabel={(option) =>
                    publishers?.find((g: any) => g.id === option)?.name ?? ''
                  }
                  options={publishers?.map((g: any) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Galléria választás"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <ImageSelectComponent
                  image={values.image}
                  onSelect={(image: any) => {
                    setFieldValue('imageId', image.id);
                    setFieldValue('image', image);
                  }}
                  alt="kép"
                  onUploaded={(image: any) =>
                    handleUploaded(image, setFieldValue)
                  }
                  publisherId={publisherId}
                  currentFolderId={'-1'}
                />{' '}
                <Grid item xs={12}>
                  <Typography>Ikon használata a térképen</Typography>
                </Grid>
                <IconPicker
                  icon={values.icon}
                  onSelect={(icon: any) => {
                    setFieldValue('icon', icon);
                  }}
                />
                <Grid item xs={12} pt={2}>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => setFieldValue('icon', null)}
                  >
                    Ikon eltávolítása
                  </Button>
                </Grid>
              </Grid>

              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item xs={12} sm={isSmallScreen ? 12 : 'auto'} p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth={isSmallScreen}
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12} sm={isSmallScreen ? 12 : 'auto'} p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth={isSmallScreen}
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default MapObjectForm;
