import React from 'react';
const RejectedIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M28.1416 0.630005C12.7043 0.630005 0.153381 13.1869 0.153381 28.612C0.153381 44.0371 12.7043 56.594 28.1416 56.594C43.5788 56.594 56.1298 44.0371 56.1298 28.612C56.1298 13.1869 43.5788 0.630005 28.1416 0.630005ZM28.1416 51.9333C15.2845 51.9333 4.81518 41.475 4.81518 28.612C4.81518 15.749 15.2758 5.29076 28.1416 5.29076C41.0074 5.29076 51.468 15.749 51.468 28.612C51.468 41.475 41.0074 51.9333 28.1416 51.9333Z" fill={secondaryColor}/>
<path d="M36.7829 19.9638C35.8733 19.0544 34.3952 19.0544 33.4856 19.9638L28.1416 25.3066L22.7976 19.9638C21.888 19.0544 20.4098 19.0544 19.5002 19.9638C18.5906 20.8732 18.5906 22.351 19.5002 23.2605L24.8442 28.6033L19.5002 33.9461C18.5906 34.8555 18.5906 36.3333 19.5002 37.2427C19.955 37.6974 20.5498 37.9248 21.1533 37.9248C21.7568 37.9248 22.3428 37.6974 22.8063 37.2427L28.1503 31.8999L33.4943 37.2427C33.9491 37.6974 34.5439 37.9248 35.1474 37.9248C35.7509 37.9248 36.3369 37.6974 36.8004 37.2427C37.7101 36.3333 37.7101 34.8555 36.8004 33.9461L31.4564 28.6033L36.8004 23.2605C37.7101 22.351 37.7101 20.8732 36.8004 19.9638H36.7829Z" fill={primaryColor}/>
</svg>

);

export default RejectedIcon;
