import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import MapObjectCategoryForm from './MapObjectCategoryForm';
import { useNavigate, useParams } from 'react-router-dom';
import useCheckPermission from '../../hooks/useCheckPermission';
import mapObjectCategoryService from './mapObjectCategoryService';
import publisherService from '../../services/publisherService';

const MapObjectCategoryEdit = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const params = useParams();
  const [errors, setErrors] = useState<string[]>([]);
  const [entity, setEntity] = useState<any>({ name: '', imageId: '' });

  const { checkPermission } = useCheckPermission();
  const [publishers, setPublishers] = useState<any[]>([]);
  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    publisherService
      .list()
      .then((response: any) => {
        if (!response.hasError) {
          setPublishers(response.records);
        } else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  useEffect(() => {
    mapObjectCategoryService.get(params.id).then((response: any) => {
      if (response.hasError) {
        setErrors(response.errorMessages);
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setEntity(response.result);
      }
    });
  }, [params.id]);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    mapObjectCategoryService
      .update(entity)
      .then((response) => {
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Success!', {
            variant: 'success',
          });

          navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        if (setSubmitting) setSubmitting(false);
      });
  };

  return (
    <MapObjectCategoryForm
      entity={entity}
      readonly={!checkPermission(['EditMapObjectCategory'])}
      onSubmit={onSubmit}
      handleCancel={() => navigate(-1)}
      errors={errors}
      publishers={publishers}
    />
  );
};

export default MapObjectCategoryEdit;
