import React from 'react';
const Voice_messageIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="33" viewBox="0 0 57 33" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M23.5095 12.0418L13.8273 8.41289C12.4016 7.87949 10.7923 8.08062 9.53288 8.94631C8.27341 9.82075 7.52994 11.2461 7.52994 12.7764V20.0342C7.52994 21.5644 8.28216 22.9985 9.53288 23.8642C10.32 24.4151 11.2559 24.6949 12.1917 24.6949C12.7428 24.6949 13.3025 24.5987 13.8273 24.3976L23.5095 20.7687C25.3462 20.0779 26.5357 18.364 26.5357 16.4053C26.5357 14.4465 25.3462 12.7239 23.5095 12.0418ZM12.1917 20.0429V12.7851L21.8739 16.414L12.1917 20.0429Z" fill={primaryColor}/>
<path d="M44.8417 0.0882568H12.1917C5.76321 0.0882568 0.532898 5.31738 0.532898 11.7445V21.0748C0.532898 27.5019 5.76321 32.731 12.1917 32.731H44.8417C51.2703 32.731 56.5005 27.5019 56.5005 21.0748V11.7445C56.5005 5.31738 51.2703 0.0882568 44.8417 0.0882568ZM51.8388 21.0748C51.8388 24.931 48.6989 28.0703 44.8417 28.0703H12.1917C8.33462 28.0703 5.1947 24.931 5.1947 21.0748V11.7445C5.1947 7.88823 8.33462 4.749 12.1917 4.749H44.8417C48.6989 4.749 51.8388 7.88823 51.8388 11.7445V21.0748Z" fill={secondaryColor}/>
<path d="M30.8476 11.7533C29.5619 11.7533 28.5123 12.7938 28.5123 14.088V18.7487C28.5123 20.0342 29.5532 21.0835 30.8476 21.0835C32.1421 21.0835 33.1829 20.0429 33.1829 18.7487V14.088C33.1829 12.8026 32.1421 11.7533 30.8476 11.7533Z" fill={secondaryColor}/>
<path d="M44.8417 11.7533C43.556 11.7533 42.5064 12.7938 42.5064 14.088V18.7487C42.5064 20.0342 43.5473 21.0835 44.8417 21.0835C46.1362 21.0835 47.177 20.0429 47.177 18.7487V14.088C47.177 12.8026 46.1362 11.7533 44.8417 11.7533Z" fill={secondaryColor}/>
<path d="M37.8447 7.08376C36.559 7.08376 35.5094 8.12432 35.5094 9.41849V23.4095C35.5094 24.6949 36.5502 25.7442 37.8447 25.7442C39.1391 25.7442 40.1799 24.7037 40.1799 23.4095V9.41849C40.1799 8.13307 39.1391 7.08376 37.8447 7.08376Z" fill={secondaryColor}/>
</svg>

);

export default Voice_messageIcon;
