import React from 'react';
const HeartIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="56" height="47" viewBox="0 0 56 47" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M51.473 4.681C48.6129 1.82158 44.8083 0.238892 40.75 0.238892C36.6917 0.238892 32.8958 1.81284 30.027 4.681C29.406 5.30185 28.8461 5.96645 28.4088 6.68349C27.9103 5.96645 27.3418 5.30185 26.7296 4.681C23.8696 1.81284 20.0562 0.238892 16.0066 0.238892C11.9571 0.238892 8.15241 1.81284 5.28362 4.681C-0.628884 10.5922 -0.628884 20.2023 5.28362 26.1135L23.9133 44.739C25.1028 45.9282 26.6946 46.5841 28.3739 46.5841C30.0532 46.5841 31.645 45.9282 32.8345 44.739L51.4554 26.1222C57.368 20.211 57.368 10.5922 51.4554 4.68975L51.473 4.681ZM48.1756 22.8169L29.5546 41.4336C28.9337 42.0545 27.8491 42.0545 27.2193 41.4336L8.59851 22.8169C4.50524 18.7245 4.50524 12.07 8.59851 7.97764C10.5839 5.99267 13.2165 4.8996 16.0154 4.8996C18.8142 4.8996 21.4556 5.99267 23.441 7.97764C23.8695 8.40612 24.2543 8.86958 24.6042 9.35927C25.4701 10.5922 26.8871 11.3268 28.3914 11.3268C29.8958 11.3268 31.3127 10.5922 32.1785 9.35927C32.5284 8.86958 32.9132 8.40612 33.3418 7.97764C35.3272 5.99267 37.9598 4.8996 40.7674 4.8996C43.5749 4.8996 46.2077 5.99267 48.1843 7.97764C52.2776 12.07 52.2776 18.7245 48.1843 22.8169H48.1756Z" fill={secondaryColor}/>
<path d="M43.0327 7.55791C41.7907 7.19065 40.5049 7.90769 40.1463 9.14939C39.7877 10.3911 40.4963 11.6765 41.7382 12.035C42.3067 12.2012 42.814 12.4984 43.2338 12.9269C43.6974 13.3904 44.0209 13.9763 44.1696 14.6059C44.4145 15.6814 45.3767 16.416 46.4437 16.416C46.6187 16.416 46.7936 16.3985 46.9685 16.3547C48.2192 16.0662 49.0064 14.8157 48.7178 13.5565C48.3767 12.0613 47.6157 10.7059 46.5399 9.62157C45.5691 8.65094 44.3621 7.93391 43.0414 7.54916L43.0327 7.55791Z" fill={primaryColor}/>
</svg>

);

export default HeartIcon;
