import React from 'react';
const Question_markIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="56" height="58" viewBox="0 0 56 58" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M30.4139 35.0454H25.6209C25.6121 34.3546 25.6034 33.9348 25.6034 33.7862C25.6034 32.2297 25.857 30.953 26.3731 29.9561C26.8891 28.9505 27.9124 27.8313 29.4518 26.5721C30.9911 25.3216 31.9095 24.4997 32.2156 24.1149C32.6791 23.5028 32.9065 22.8295 32.9065 22.0862C32.9065 21.0631 32.4955 20.1799 31.6733 19.4454C30.8512 18.7109 29.7491 18.3436 28.3585 18.3436C26.9678 18.3436 25.9008 18.7284 24.9911 19.4891C24.0903 20.2499 23.4693 21.4216 23.1282 22.9869L18.2827 22.3835C18.4227 20.145 19.376 18.2387 21.1515 16.6734C22.927 15.1082 25.2535 14.3212 28.1311 14.3212C31.166 14.3212 33.5713 15.1169 35.3643 16.6997C37.1573 18.2824 38.0494 20.1275 38.0494 22.2348C38.0494 23.3978 37.717 24.4996 37.061 25.5402C36.4051 26.5808 34.9969 27.9974 32.8453 29.7812C31.7345 30.7081 31.0348 31.4514 30.7724 32.0198C30.5013 32.5794 30.3789 33.5938 30.4051 35.0454H30.4139ZM25.6209 42.1458V36.8642H30.9036V42.1458H25.6209Z" fill={primaryColor}/>
<path d="M3.40523 57.0375C2.59182 57.0375 1.80465 56.6527 1.30611 55.9969C0.798828 55.3411 0.632648 54.5016 0.842559 53.6971L4.06995 41.5774C1.8134 37.5113 0.623901 32.9205 0.623901 28.2335C0.623901 24.5171 1.34985 20.9057 2.79299 17.5129C4.18365 14.2337 6.16906 11.2869 8.69675 8.75977C11.2244 6.23265 14.1719 4.24768 17.4518 2.85732C20.8541 1.42324 24.4576 0.688721 28.1748 0.688721C31.892 0.688721 35.5042 1.4145 38.8978 2.85732C42.1776 4.24768 45.1251 6.23265 47.6528 8.75977C50.1805 11.2869 52.1659 14.2337 53.5566 17.5129C54.991 20.9144 55.7257 24.5171 55.7257 28.2335C55.7257 31.9499 54.9997 35.5613 53.5566 38.9541C52.1659 42.2333 50.1805 45.1801 47.6528 47.7072C45.1251 50.2343 42.1776 52.2193 38.8978 53.6097C35.4954 55.0437 31.892 55.7783 28.1748 55.7783C24.4576 55.7783 20.5655 54.9913 17.0582 53.4435L4.09619 56.9413C3.86878 57.0025 3.64138 57.0287 3.40523 57.0287V57.0375ZM28.1748 5.38445C15.5713 5.38445 5.32067 15.6329 5.32067 28.2335C5.32067 32.4046 6.45769 36.4794 8.60054 40.0297L8.76672 40.3095C8.98538 40.6767 9.05535 41.114 8.94164 41.5337L6.29151 51.4935L16.9445 48.6254C17.3119 48.5292 17.6967 48.5642 18.0378 48.7303L18.2915 48.8527C21.3876 50.3393 24.7112 51.1 28.1835 51.1C40.787 51.1 51.0376 40.8516 51.0376 28.251C51.0376 22.1474 48.6586 16.4111 44.3467 12.0914C40.026 7.77167 34.2885 5.40194 28.1835 5.40194L28.1748 5.38445Z" fill={secondaryColor}/>
</svg>

);

export default Question_markIcon;
