import React from 'react';
const Shopping_bagIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="48" height="57" viewBox="0 0 48 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M47.3208 33.0279C46.8047 28.236 45.9214 23.4441 44.7144 18.7921C43.5424 14.28 39.8776 10.9571 35.452 10.1526C34.4374 4.74861 29.7057 0.630005 24.0031 0.630005C18.3005 0.630005 13.5687 4.73986 12.5542 10.1526C8.12854 10.9484 4.46386 14.2712 3.29185 18.7921C2.08486 23.4528 1.21014 28.2447 0.685362 33.0279C0.248047 37.0416 0.0557133 41.1339 0.116938 45.2001C0.204401 51.4873 5.44341 56.6027 11.7845 56.6027H36.2216C42.5627 56.6027 47.7931 51.4873 47.8893 45.2001C47.9418 41.1427 47.7493 37.0503 47.3208 33.0367V33.0279ZM24.0118 5.29076C27.0468 5.29076 29.6095 7.2495 30.5803 9.95151H17.4433C18.4054 7.24075 20.9768 5.29076 24.0118 5.29076ZM36.2304 51.9333H11.7932C7.9886 51.9333 4.84867 48.8815 4.79619 45.1301C4.74372 41.2651 4.92738 37.3564 5.33846 33.5263C5.837 28.9618 6.66787 24.3972 7.82238 19.9551C8.63579 16.8158 11.4346 14.621 14.627 14.621H33.4053C36.5978 14.621 39.3966 16.8158 40.21 19.9551C41.3645 24.3972 42.1954 28.9618 42.6939 33.5263C43.105 37.3564 43.2887 41.2564 43.2362 45.1214C43.1837 48.8727 40.0438 51.9245 36.2391 51.9245L36.2304 51.9333Z" fill={secondaryColor}/>
<path d="M33.3354 20.4535C30.1167 20.4535 27.5016 23.0681 27.5016 26.286C27.5016 29.5039 30.1167 32.1185 33.3354 32.1185C36.554 32.1185 39.1691 29.5039 39.1691 26.286C39.1691 23.0681 36.554 20.4535 33.3354 20.4535ZM33.3354 27.449C32.6969 27.449 32.1721 26.9243 32.1721 26.286C32.1721 25.6476 32.6969 25.123 33.3354 25.123C33.9739 25.123 34.4986 25.6476 34.4986 26.286C34.4986 26.9243 33.9739 27.449 33.3354 27.449Z" fill={primaryColor}/>
<path d="M14.6795 20.4535C11.4608 20.4535 8.84573 23.0681 8.84573 26.286C8.84573 29.5039 11.4608 32.1185 14.6795 32.1185C17.8981 32.1185 20.5133 29.5039 20.5133 26.286C20.5133 23.0681 17.8981 20.4535 14.6795 20.4535ZM14.6795 27.449C14.041 27.449 13.5162 26.9243 13.5162 26.286C13.5162 25.6476 14.041 25.123 14.6795 25.123C15.3179 25.123 15.8428 25.6476 15.8428 26.286C15.8428 26.9243 15.3179 27.449 14.6795 27.449Z" fill={primaryColor}/>
</svg>

);

export default Shopping_bagIcon;
