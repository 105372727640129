import { Suspense, useCallback, useEffect, useState } from 'react';
import Layout from './layout/Layout';
import { HashRouter as Router } from 'react-router-dom';
import WaitLayer from './layout/WaitLayer';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useAccount,
  useMsal,
} from '@azure/msal-react';

import { loginRequest } from './authConfig';
import axios from 'axios';
import accountService from './services/accountService';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

import { Paper, Button } from '@mui/material';
import { Box } from '@mui/system';

const Login = () => {
  const { instance, inProgress } = useMsal();
  const handleMicrosoftLogin = async () => {
    try {
      await instance.loginRedirect(loginRequest);
    } catch (error) {}
  };

  return (
    <Box
      display="flex"
      id="login"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      margin="-8px"
      bgcolor="#f5f5f5"
    >
      <Paper elevation={3} sx={{ padding: '2rem', textAlign: 'center' }}>
        <img
          src="dunaharaszti_only_logo.png"
          alt="Logo"
          width="200"
          height="300"
          style={{ marginBottom: '1rem', objectFit: 'contain' }}
        />
        <p>
          Üdvözöljük Dunaharaszti városi applikációjának tartalomkezelő
          rendszerében!
        </p>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap="1rem"
          marginTop="2rem"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleMicrosoftLogin}
            disabled={inProgress === 'login' || inProgress !== 'none'}
          >
            Bejelentkezés
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

const App = () => {
  const { instance, accounts } = useMsal();
  const [axiosReady, setAxiosReady] = useState(false);
  const [isUserInfoLoaded, setIsUserInfoLoaded] = useState(false);

  const dispatch = useDispatch<any>();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    let acc = instance.getActiveAccount() ?? accounts[0];
    if (acc) {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          forceRefresh: true,
          account: instance.getActiveAccount() ?? accounts[0],
        })
        .then(
          (response) => {
            localStorage.setItem('cmsWebApiAccessToken', response.accessToken);
            dispatch({
              type: 'SET_ACCESS_TOKEN',
              payload: response.accessToken,
            });
            return response.accessToken;
          },
          (error) => {
            instance
              .acquireTokenRedirect({
                ...loginRequest,
              })
              .then((response) => {
                // localStorage.setItem(
                //   'cmsWebApiAccessToken',
                //   response.accessToken
                // );
                // dispatch({
                //   type: 'SET_ACCESS_TOKEN',
                //   payload: response.accessToken,
                // });
                // return response.accessToken;
              });
          }
        )
        .then(() => {
          axios.interceptors.request.use((config) => {
            const t = localStorage.getItem('cmsWebApiAccessToken');

            config.headers.Authorization = `Bearer ${t}`;

            return config;
          });
          setAxiosReady(true);
        });

      let timer = setInterval(async () => {
        var result = await instance.acquireTokenSilent({
          ...loginRequest,
          forceRefresh: true,
          account: instance.getActiveAccount() ?? accounts[0],
        });

        localStorage.setItem('cmsWebApiAccessToken', result.accessToken);
        console.log('Token refreshed' + result.accessToken);
      }, 10 * 1000 * 60);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [dispatch, instance, accounts]);

  useEffect(() => {
    if (axiosReady) {
      accountService.getUserInfo().then((response: any) => {
        if (!response.hasError) {
          dispatch({ type: 'LOAD_USER_INFO', payload: response });
          setIsUserInfoLoaded(true);
        } else {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        }
      });
    }
  }, [axiosReady, dispatch, enqueueSnackbar]);

  return (
    <div>
      <AuthenticatedTemplate>
        {axiosReady && (
          <Router>
            <Suspense fallback="Loading...">
              <WaitLayer />
              {isUserInfoLoaded && <Layout />}{' '}
            </Suspense>
          </Router>
        )}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </div>
  );
};

export default App;
