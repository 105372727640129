import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import parkingZoneService from '../../../services/parking/parkingZoneService';
import publisherService from '../../../services/publisherService';
import ParkingZoneForm from './ParkingZoneForm';

const ParkingZoneCreate = (props: any) => {
  const { onSave = null, navigateBack = true, handleCancel = null } = props;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);

  const { checkPermission } = useCheckPermission();
  const [zones, setZones] = useState<any[]>([]);

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    parkingZoneService
      .list()
      .then((response: any) => {
        if (!response.hasError) {
          setZones(response.records);
        } else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    parkingZoneService
      .create(entity)
      .then((response) => {
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Success!', {
            variant: 'success',
          });

          if (onSave) onSave(response.result);
          if (navigateBack) navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        if (setSubmitting) setSubmitting(false);
      });
  };

  return (
    <ParkingZoneForm
      entity={{ name: '', description: '', area: '' }}
      readonly={!checkPermission(['CreateParkingZone'])}
      onSubmit={onSubmit}
      errors={errors}
      zones={zones}
      handleCancel={() =>
        handleCancel != null ? handleCancel() : navigate(-1)
      }
    />
  );
};

export default ParkingZoneCreate;
