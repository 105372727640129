import React from 'react';
const RefreshIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="53" height="52" viewBox="0 0 53 52" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M26.3826 47.2025C14.8112 47.2025 5.39143 37.7848 5.39143 26.216C5.39143 23.9862 5.77623 21.8001 6.4497 19.7277L6.6596 19.9375C7.54298 20.8207 8.7325 21.3017 9.95698 21.3017C10.2369 21.3017 10.5167 21.2755 10.7878 21.223C12.2922 20.9519 13.5692 19.955 14.1989 18.5647L18.4759 9.14701C19.2893 7.36316 18.9132 5.30819 17.5226 3.91784C16.1319 2.52748 14.0853 2.1602 12.2923 2.97343L2.88124 7.24944C1.49058 7.87903 0.493465 9.15573 0.22233 10.6598C-0.048806 12.1638 0.432224 13.7115 1.51677 14.7871L2.83747 16.1075C1.47304 19.2555 0.720823 22.692 0.720823 26.1985C0.720823 40.3382 12.231 51.8457 26.3738 51.8457C27.6595 51.8457 28.709 50.8052 28.709 49.511C28.709 48.2169 27.6683 47.1763 26.3738 47.1763L26.3826 47.2025ZM14.2427 7.22323L9.96574 16.6409L4.83165 11.4992L14.2514 7.22323H14.2427Z" fill={secondaryColor}/>
<path d="M51.2483 37.6274L49.9276 36.307C51.292 33.159 52.0443 29.7225 52.0443 26.216C52.0443 12.0763 40.5342 0.568695 26.3914 0.568695C25.1057 0.568695 24.0561 1.60931 24.0561 2.90348C24.0561 4.19765 25.0969 5.23821 26.3914 5.23821C37.9627 5.23821 47.3825 14.6559 47.3825 26.2247C47.3825 28.4545 46.9976 30.6406 46.3241 32.713L46.1142 32.5032C45.0297 31.4276 43.4816 30.9379 41.986 31.209C40.4816 31.4801 39.2047 32.4769 38.5749 33.8673L34.298 43.285C33.4846 45.0688 33.8607 47.1238 35.2514 48.5141C36.1523 49.4148 37.3243 49.887 38.5313 49.887C39.1872 49.887 39.8519 49.7471 40.4729 49.4585L49.8839 45.1825C51.2746 44.5529 52.2716 43.2763 52.5428 41.7722C52.8139 40.2682 52.3329 38.7204 51.2483 37.6449V37.6274ZM38.5313 45.2088L42.8169 35.7911L47.9509 40.924L38.5313 45.2V45.2088Z" fill={secondaryColor}/>
<path d="M26.3826 16.8857C21.2398 16.8857 17.0502 21.0743 17.0502 26.216C17.0502 31.3577 21.231 35.5462 26.3826 35.5462C31.5342 35.5462 35.7148 31.3577 35.7148 26.216C35.7148 21.0743 31.5342 16.8857 26.3826 16.8857ZM26.3826 30.8767C23.8112 30.8767 21.7207 28.7868 21.7207 26.216C21.7207 23.6451 23.8112 21.5552 26.3826 21.5552C28.954 21.5552 31.0443 23.6451 31.0443 26.216C31.0443 28.7868 28.954 30.8767 26.3826 30.8767Z" fill={primaryColor}/>
</svg>

);

export default RefreshIcon;
