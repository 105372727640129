import { Stack, Switch, Typography } from '@mui/material';

export default function IconPickerToggle({
  iconPickerOpen,
  setIconPickerOpen,
}: any) {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography>Kép</Typography>
      <Switch
        checked={iconPickerOpen}
        onChange={(e, checked) => setIconPickerOpen(checked)}
      />
      <Typography>Ikon / Illusztráció</Typography>
    </Stack>
  );
}
