import React from 'react';
const RulerIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M53.8592 8.27878L48.4539 2.87477C46.7484 1.16961 44.4831 0.233948 42.0691 0.233948C40.3723 0.233948 38.728 0.697425 37.3199 1.57186C30.1566 6.00526 23.5007 11.2781 17.5357 17.2418C11.5707 23.2054 6.28794 29.8687 1.84481 37.0478C0.978926 38.4382 0.524109 40.0646 0.524109 41.7697C0.524109 44.1832 1.45998 46.448 3.16551 48.1531L8.57073 53.5572C10.2763 55.2623 12.5415 56.1979 14.9555 56.1979C16.6523 56.1979 18.2967 55.7345 19.7048 54.8601C26.868 50.4267 33.524 45.1538 39.489 39.1901C45.454 33.2265 50.7367 26.5633 55.1799 19.3841C56.0457 17.9938 56.5005 16.3673 56.5005 14.6622C56.5005 12.2487 55.5647 9.98394 53.8592 8.27878ZM51.2178 16.927C46.9583 23.8001 41.9117 30.1835 36.2004 35.8935C30.4978 41.5949 24.1217 46.6491 17.2558 50.8901C16.5911 51.3011 15.7777 51.5285 14.9643 51.5285C13.801 51.5285 12.7078 51.0738 11.8769 50.2518L6.47164 44.8478C5.64949 44.0258 5.19466 42.9328 5.19466 41.7523C5.19466 40.939 5.40459 40.1608 5.81567 39.505C10.0751 32.6319 15.1217 26.2485 20.8331 20.5384C26.5357 14.8371 32.9117 9.78281 39.7776 5.54179C40.4423 5.1308 41.2557 4.90347 42.0691 4.90347C43.2324 4.90347 44.3257 5.35814 45.1566 6.18011L50.5618 11.5842C51.3839 12.4062 51.8388 13.4992 51.8388 14.6797C51.8388 15.4929 51.6288 16.2712 51.2178 16.927Z" fill={secondaryColor}/>
<path d="M16.1713 31.2328C15.2617 30.3234 13.7836 30.3234 12.874 31.2328C11.9643 32.1422 11.9643 33.62 12.874 34.5294L17.5357 39.1901C18.4453 40.0995 19.9235 40.0995 20.8331 39.1901C21.7427 38.2807 21.7427 36.8029 20.8331 35.8935L16.1713 31.2328Z" fill={primaryColor}/>
<path d="M20.8331 26.5632C19.9235 25.6538 18.4453 25.6538 17.5357 26.5632C16.6261 27.4727 16.6261 28.9505 17.5357 29.8599L19.871 32.1946C20.7806 33.104 22.2587 33.104 23.1683 32.1946C24.0779 31.2852 24.0779 29.8074 23.1683 28.898L20.8331 26.5632Z" fill={primaryColor}/>
<path d="M25.5036 21.9025C24.594 20.9931 23.1159 20.9931 22.2063 21.9025C21.2966 22.812 21.2966 24.2897 22.2063 25.1991L26.8681 29.8599C27.7777 30.7693 29.2558 30.7693 30.1654 29.8599C31.075 28.9505 31.075 27.4727 30.1654 26.5632L25.5036 21.9025Z" fill={primaryColor}/>
<path d="M30.1654 17.2418C29.2558 16.3324 27.7777 16.3324 26.8681 17.2418C25.9584 18.1512 25.9584 19.629 26.8681 20.5384L29.2033 22.8731C30.1129 23.7826 31.591 23.7826 32.5006 22.8731C33.4102 21.9637 33.4102 20.4859 32.5006 19.5765L30.1654 17.2418Z" fill={primaryColor}/>
<path d="M34.8272 12.5722C33.9176 11.6628 32.4394 11.6628 31.5298 12.5722C30.6202 13.4817 30.6202 14.9595 31.5298 15.8689L36.1916 20.5297C37.1012 21.4391 38.5794 21.4391 39.489 20.5297C40.3986 19.6202 40.3986 18.1424 39.489 17.233L34.8272 12.5722Z" fill={primaryColor}/>
</svg>

);

export default RulerIcon;
