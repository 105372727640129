import React from 'react';
const Spoon_forkIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="52" height="57" viewBox="0 0 52 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M12.2685 0.431975C7.73794 0.431975 3.74086 3.04655 2.08781 7.0777C0.802101 10.2169 0.60968 13.0239 0.60968 14.423C0.60968 16.9064 1.09073 19.3373 2.03533 21.6458C3.00617 24.0068 4.75545 25.8606 6.91579 27.0411C6.14612 31.2122 5.56011 35.5144 5.27148 39.9216C5.07031 43.0695 5.00035 46.2525 5.06158 49.4005C5.14029 53.2567 8.37641 56.396 12.2598 56.396C16.1431 56.396 19.3793 53.2567 19.458 49.4005C19.528 46.2525 19.458 43.0695 19.2481 39.9216C18.9594 35.5319 18.3822 31.2384 17.6038 27.0586C19.7554 25.9043 21.4871 24.0768 22.4405 21.7595C23.7262 18.6203 23.9186 15.8133 23.9186 14.4142C23.9186 11.9308 23.4376 9.4999 22.493 7.19139C20.8137 3.08153 16.7904 0.423248 12.251 0.423248L12.2685 0.431975ZM14.6125 40.2276C14.8137 43.2357 14.875 46.2875 14.8137 49.3043C14.7875 50.6422 13.6505 51.7352 12.2773 51.7352C10.9041 51.7352 9.76707 50.6422 9.74083 49.3043C9.67961 46.2962 9.74087 43.2444 9.94203 40.2276C10.2044 36.1615 10.7379 32.2003 11.4376 28.3528C11.7175 28.3703 11.9974 28.414 12.286 28.414C12.5747 28.414 12.8458 28.3703 13.117 28.3528C13.8167 32.209 14.3501 36.1702 14.6125 40.2189V40.2276ZM18.1461 20.0019C17.2277 22.2492 14.8749 23.7532 12.286 23.7532C9.69713 23.7532 7.32686 22.2317 6.36477 19.8795C5.64757 18.1306 5.28899 16.2943 5.28899 14.423C5.28899 13.3736 5.43766 11.2488 6.41724 8.84408C7.33561 6.59678 9.68838 5.09274 12.2773 5.09274C14.8662 5.09274 17.2365 6.61426 18.1986 8.96649C18.9158 10.7154 19.2743 12.5517 19.2743 14.423C19.2743 15.4723 19.1257 17.5972 18.1461 20.0019Z" fill={secondaryColor}/>
<path d="M49.5978 0.43199C48.3121 0.43199 47.2625 1.47258 47.2625 2.76675V14.423C47.2625 15.4723 47.1139 17.5972 46.1343 20.0019C45.2159 22.2492 42.8631 23.7532 40.2742 23.7532C37.6853 23.7532 35.3151 22.2317 34.353 19.8795C33.6358 18.1306 33.2772 16.2943 33.2772 14.423V2.76675C33.2772 1.48133 32.2364 0.43199 30.9419 0.43199C29.6475 0.43199 28.6066 1.47258 28.6066 2.76675V14.423C28.6066 16.9064 29.0877 19.3373 30.0323 21.6458C31.0031 24.0068 32.7524 25.8606 34.9127 27.0411C34.1431 31.2122 33.5571 35.5144 33.2684 39.9216C33.0673 43.0696 32.9973 46.2525 33.0585 49.4005C33.1372 53.2568 36.3734 56.396 40.2567 56.396C44.1401 56.396 47.3762 53.2568 47.4549 49.4005C47.5249 46.2525 47.4549 43.0696 47.245 39.9216C46.9564 35.5319 46.3791 31.2384 45.6007 27.0586C47.7523 25.9044 49.4841 24.0768 50.4374 21.7595C51.7232 18.6203 51.9156 15.8134 51.9156 14.4143V2.758C51.9156 1.47257 50.8748 0.423264 49.5803 0.423264L49.5978 0.43199ZM42.6007 40.2276C42.8019 43.2357 42.8632 46.2875 42.8019 49.3043C42.7757 50.6422 41.6386 51.7352 40.2655 51.7352C38.8923 51.7352 37.7553 50.6422 37.729 49.3043C37.6678 46.2962 37.7291 43.2445 37.9302 40.2276C38.1926 36.1615 38.7261 32.2003 39.4258 28.3528C39.7057 28.3703 39.9856 28.414 40.2742 28.414C40.5629 28.414 40.834 28.3703 41.1051 28.3528C41.8048 32.2091 42.3383 36.1702 42.6007 40.2189V40.2276Z" fill={secondaryColor}/>
<path d="M40.2655 19.0838C41.5512 19.0838 42.6007 18.0432 42.6007 16.749V5.09276C42.6007 3.80733 41.5599 2.758 40.2655 2.758C38.971 2.758 37.9302 3.79859 37.9302 5.09276V16.749C37.9302 18.0344 38.971 19.0838 40.2655 19.0838Z" fill={primaryColor}/>
</svg>

);

export default Spoon_forkIcon;
