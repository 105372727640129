import React from 'react';
const Shopping_cartIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="55" viewBox="0 0 57 55" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M42.3826 29.3203C42.68 29.4515 42.9948 29.5127 43.3009 29.5127C44.2018 29.5127 45.0677 28.9792 45.4438 28.0961L47.8403 22.4997C48.3476 21.3192 47.7966 19.9463 46.6158 19.4391C45.4351 18.932 44.0619 19.4829 43.5546 20.6633L41.1581 26.2598C40.6508 27.4403 41.2018 28.8131 42.3826 29.3203Z" fill={primaryColor}/>
<path d="M22.6947 29.0667C22.9308 30.1597 23.8929 30.9117 24.9687 30.9117C25.1261 30.9117 25.2923 30.8942 25.4585 30.8593C26.718 30.5882 27.5226 29.3465 27.2515 28.0873L25.7471 21.0918C25.476 19.8326 24.2428 19.0369 22.9745 19.2992C21.7151 19.5703 20.9104 20.812 21.1816 22.0712L22.6859 29.0667H22.6947Z" fill={primaryColor}/>
<path d="M54.3038 42.568H16.9833C15.6976 42.568 14.6481 41.5187 14.6481 40.2332C14.6481 38.9478 15.6976 37.8985 16.9833 37.8985H41.1494C45.8287 37.8985 50.0356 35.1265 51.8723 30.8418L56.1668 20.8295C56.4729 20.06 56.6391 19.238 56.6391 18.451C56.6391 15.0319 53.8578 12.2512 50.438 12.2512H16.7122L16.1874 9.81158C15.0417 4.46877 10.2487 0.595001 4.78223 0.595001H2.99797C1.71226 0.595001 0.66272 1.63557 0.66272 2.92973C0.66272 4.2239 1.70352 5.26449 2.99797 5.26449H4.78223C8.06209 5.26449 10.9396 7.5905 11.6218 10.7909L16.441 33.299C12.8376 33.5788 9.98627 36.5694 9.98627 40.242C9.98627 44.0983 13.1262 47.2375 16.9833 47.2375H19.3186C19.3186 51.0938 22.4585 54.233 26.3156 54.233C30.1728 54.233 33.3127 51.0938 33.3127 47.2375H37.9745C37.9745 51.0938 41.1144 54.233 44.9715 54.233C48.8287 54.233 51.9686 51.0938 51.9686 47.2375H54.3038C55.5896 47.2375 56.6391 46.1969 56.6391 44.9027C56.6391 43.6086 55.5983 42.568 54.3038 42.568ZM50.4292 16.9207C51.2951 16.9207 51.9686 17.5941 51.9686 18.4598C51.9686 18.6521 51.9161 18.897 51.8549 19.0544L47.5867 29.0142C46.4846 31.5851 43.957 33.2465 41.1494 33.2465H21.2078L17.7093 16.9207H50.438H50.4292ZM26.3156 49.5635C25.0299 49.5635 23.9804 48.5142 23.9804 47.2287H28.6422C28.6422 48.5142 27.5926 49.5635 26.3069 49.5635H26.3156ZM44.9715 49.5635C43.6858 49.5635 42.6362 48.5142 42.6362 47.2287H47.298C47.298 48.5142 46.2485 49.5635 44.9628 49.5635H44.9715Z" fill={secondaryColor}/>
<path d="M33.3127 30.9117C34.5984 30.9117 35.648 29.8712 35.648 28.577V21.5815C35.648 20.2961 34.6071 19.2467 33.3127 19.2467C32.0182 19.2467 30.9774 20.2873 30.9774 21.5815V28.577C30.9774 29.8624 32.0182 30.9117 33.3127 30.9117Z" fill={primaryColor}/>
</svg>

);

export default Shopping_cartIcon;
