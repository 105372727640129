import React from 'react';
const Trash_canIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="53" height="57" viewBox="0 0 53 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M14.7423 12.0945C16.028 12.0945 17.0776 11.0539 17.0776 9.75974C17.0776 7.18889 19.168 5.09897 21.7394 5.09897H31.0717C33.6431 5.09897 35.7335 7.18889 35.7335 9.75974C35.7335 11.0452 36.7743 12.0945 38.0688 12.0945C39.3632 12.0945 40.404 11.0539 40.404 9.75974C40.404 4.61804 36.2145 0.429504 31.0717 0.429504H21.7394C16.5966 0.429504 12.4071 4.6093 12.4071 9.75974C12.4071 11.0452 13.4479 12.0945 14.7423 12.0945Z" fill={secondaryColor}/>
<path d="M49.7276 14.4205H3.08349C1.79779 14.4205 0.74823 15.4611 0.74823 16.7552C0.74823 18.0494 1.78904 19.09 3.08349 19.09H3.88815C3.55579 20.0344 3.40711 21.0487 3.51207 22.0718C3.95813 26.6714 4.6141 31.3059 5.46249 35.8442C6.20593 39.8316 7.1068 43.8365 8.14762 47.7365C9.50329 52.8433 14.1476 56.4022 19.4216 56.4022H33.3982C38.681 56.4022 43.3165 52.8433 44.6722 47.7365C45.713 43.8278 46.6139 39.8316 47.3573 35.8355C48.2057 31.2971 48.8617 26.6626 49.3078 22.0631C49.404 21.0487 49.264 20.0343 48.9317 19.0812H49.7363C51.022 19.0812 52.0716 18.0406 52.0716 16.7465C52.0716 15.4523 51.0308 14.4117 49.7363 14.4117L49.7276 14.4205ZM44.0775 19.8595C44.5148 20.3404 44.7247 20.97 44.6635 21.6171C44.2262 26.0767 43.5877 30.5801 42.7743 34.9872C42.0571 38.861 41.1737 42.7435 40.1679 46.5386C39.3544 49.5991 36.5644 51.7415 33.3982 51.7415H19.4216C16.2554 51.7415 13.4654 49.5991 12.652 46.5386C11.6461 42.7523 10.7628 38.861 10.0456 34.9872C9.22341 30.5801 8.59367 26.0855 8.15636 21.6171C8.09513 20.97 8.30504 20.3492 8.74236 19.8595C9.19717 19.361 9.80941 19.09 10.4829 19.09H42.3369C43.0104 19.09 43.6314 19.361 44.0775 19.8595Z" fill={secondaryColor}/>
<path d="M17.0688 47.0719C18.3545 47.0719 19.4041 46.0314 19.4041 44.7372V23.7507C19.4041 22.4653 18.3633 21.416 17.0688 21.416C15.7744 21.416 14.7336 22.4566 14.7336 23.7507V44.7372C14.7336 46.0226 15.7744 47.0719 17.0688 47.0719Z" fill={primaryColor}/>
<path d="M26.4012 47.0719C27.6869 47.0719 28.7364 46.0314 28.7364 44.7372V23.7507C28.7364 22.4653 27.6956 21.416 26.4012 21.416C25.1067 21.416 24.0659 22.4566 24.0659 23.7507V44.7372C24.0659 46.0226 25.1067 47.0719 26.4012 47.0719Z" fill={primaryColor}/>
<path d="M35.7335 47.0719C37.0192 47.0719 38.0688 46.0314 38.0688 44.7372V23.7507C38.0688 22.4653 37.028 21.416 35.7335 21.416C34.439 21.416 33.3982 22.4566 33.3982 23.7507V44.7372C33.3982 46.0226 34.439 47.0719 35.7335 47.0719Z" fill={primaryColor}/>
</svg>

);

export default Trash_canIcon;
