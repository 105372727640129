import React from 'react';
const PaletteIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="64" height="65" viewBox="0 0 64 65" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M31.5533 64.6282C21.2064 64.6282 11.533 59.2417 6.91494 50.917C3.15402 44.1314 3.42517 36.6462 7.68462 29.8344C8.20065 29.0124 9.27644 28.7675 10.0986 29.2747C10.9208 29.7906 11.1656 30.8662 10.6584 31.6882C7.10736 37.372 6.86246 43.598 9.9849 49.2206C13.9382 56.3473 22.6058 61.1304 31.562 61.1304C47.2354 61.1304 59.9876 48.3812 59.9876 32.7112C59.9876 18.0382 47.3054 8.18328 35.4542 8.18328C33.0577 8.18328 29.6291 9.17138 27.7487 11.34C26.6379 12.6254 26.2181 14.1207 26.4804 15.922C26.6204 16.8752 25.9557 17.7671 24.9936 17.8983C24.0402 18.0382 23.1481 17.3736 23.0169 16.4117C22.6233 13.631 23.3405 11.0776 25.1073 9.04021C27.7224 6.0234 32.183 4.67677 35.4542 4.67677C48.9935 4.67677 63.4861 15.9395 63.4861 32.7025C63.4861 50.3049 49.1684 64.6194 31.562 64.6194L31.5533 64.6282Z" fill={secondaryColor}/>
<path d="M31.5533 64.8468C21.1277 64.8468 11.3756 59.4166 6.72252 51.022C2.91788 44.1664 3.19776 36.6025 7.50095 29.7207C7.78083 29.2747 8.21814 28.9687 8.72543 28.8462C9.24146 28.7238 9.76624 28.82 10.2123 29.0911C11.1307 29.6682 11.4105 30.8837 10.8333 31.8018C7.32602 37.4157 7.08987 43.563 10.1598 49.1069C14.0694 56.1637 22.6671 60.9031 31.5533 60.9031C47.1043 60.9031 59.7602 48.25 59.7602 32.7025C59.7602 18.1606 47.1917 8.39313 35.4454 8.39313C33.1014 8.39313 29.7428 9.35503 27.9061 11.4712C26.839 12.7041 26.4367 14.1469 26.6816 15.8783C26.7516 16.3942 26.6204 16.9189 26.3055 17.3386C25.9907 17.7583 25.5271 18.0294 25.0111 18.1081C23.9353 18.2568 22.9382 17.5135 22.7808 16.438C22.3697 13.5873 23.1131 10.9815 24.9236 8.90032C27.5825 5.83979 32.1218 4.46692 35.4367 4.46692C49.0809 4.46692 63.6873 15.8171 63.6873 32.7112C63.6873 50.4273 49.2646 64.8468 31.5446 64.8468H31.5533ZM9.16274 29.231C9.04904 29.231 8.9266 29.2485 8.8129 29.2747C8.41057 29.3621 8.0782 29.607 7.85954 29.9568C3.64382 36.6987 3.37268 44.1052 7.09861 50.8121C11.6817 59.0755 21.2764 64.4096 31.5446 64.4096C49.0284 64.4096 63.2499 50.1912 63.2499 32.7112C63.2499 15.2312 48.871 4.90414 35.4367 4.90414C32.218 4.90414 27.8186 6.2333 25.256 9.1889C23.5329 11.1739 22.8245 13.666 23.2181 16.3855C23.3405 17.225 24.1102 17.8021 24.9498 17.6797C25.3522 17.6184 25.7108 17.4086 25.9557 17.085C26.2006 16.7615 26.3055 16.3593 26.2443 15.9483C25.9732 14.0857 26.4105 12.5292 27.565 11.1913C29.4892 8.97027 32.9965 7.96467 35.4367 7.96467C47.3929 7.96467 60.1887 17.907 60.1887 32.7112C60.1887 48.5036 47.3404 61.3491 31.5446 61.3491C22.5096 61.3491 13.7633 56.5134 9.77499 49.3256C6.61757 43.633 6.86247 37.3283 10.4572 31.5745C10.6759 31.2247 10.7458 30.8137 10.6496 30.4202C10.5622 30.018 10.3173 29.6857 9.96741 29.4671C9.72251 29.3097 9.44263 29.231 9.16274 29.231Z" fill={secondaryColor}/>
<path d="M37.3958 23.757C34.2821 23.757 31.7545 21.2298 31.7545 18.1168C31.7545 15.0038 34.2821 12.4767 37.3958 12.4767C40.5095 12.4767 43.0372 15.0038 43.0372 18.1168C43.0372 21.2298 40.5095 23.757 37.3958 23.757ZM37.3958 15.9658C36.2151 15.9658 35.253 16.9276 35.253 18.1081C35.253 19.2886 36.2151 20.2505 37.3958 20.2505C38.5766 20.2505 39.5387 19.2886 39.5387 18.1081C39.5387 16.9276 38.5766 15.9658 37.3958 15.9658Z" fill={secondaryColor}/>
<path d="M37.3959 23.9756C34.1597 23.9756 31.5358 21.3435 31.5358 18.1169C31.5358 14.8902 34.1685 12.2581 37.3959 12.2581C40.6232 12.2581 43.2559 14.8902 43.2559 18.1169C43.2559 21.3435 40.6232 23.9756 37.3959 23.9756ZM37.3959 12.6866C34.4046 12.6866 31.9731 15.1175 31.9731 18.1081C31.9731 21.0987 34.4046 23.5296 37.3959 23.5296C40.3871 23.5296 42.8186 21.0987 42.8186 18.1081C42.8186 15.1175 40.3871 12.6866 37.3959 12.6866ZM37.3959 20.4779C36.0927 20.4779 35.0344 19.4198 35.0344 18.1169C35.0344 16.8139 36.0927 15.7559 37.3959 15.7559C38.6991 15.7559 39.7574 16.8139 39.7574 18.1169C39.7574 19.4198 38.6991 20.4779 37.3959 20.4779ZM37.3959 16.1844C36.3376 16.1844 35.4717 17.05 35.4717 18.1081C35.4717 19.1662 36.3376 20.0319 37.3959 20.0319C38.4542 20.0319 39.32 19.1662 39.32 18.1081C39.32 17.05 38.4542 16.1844 37.3959 16.1844Z" fill={secondaryColor}/>
<path d="M50.043 31.5395C46.9293 31.5395 44.4016 29.0124 44.4016 25.8994C44.4016 22.7864 46.9293 20.2592 50.043 20.2592C53.1567 20.2592 55.6843 22.7864 55.6843 25.8994C55.6843 29.0124 53.1567 31.5395 50.043 31.5395ZM50.043 23.757C48.8622 23.757 47.9001 24.7189 47.9001 25.8994C47.9001 27.0799 48.8622 28.0418 50.043 28.0418C51.2237 28.0418 52.1858 27.0799 52.1858 25.8994C52.1858 24.7189 51.2237 23.757 50.043 23.757Z" fill={secondaryColor}/>
<path d="M50.043 31.7581C46.8069 31.7581 44.183 29.1261 44.183 25.8994C44.183 22.6727 46.8156 20.0406 50.043 20.0406C53.2704 20.0406 55.903 22.6727 55.903 25.8994C55.903 29.1261 53.2704 31.7581 50.043 31.7581ZM50.043 20.4779C47.0518 20.4779 44.6203 22.9088 44.6203 25.8994C44.6203 28.89 47.0518 31.3209 50.043 31.3209C53.0342 31.3209 55.4657 28.89 55.4657 25.8994C55.4657 22.9088 53.0342 20.4779 50.043 20.4779ZM50.043 28.2604C48.7398 28.2604 47.6815 27.2023 47.6815 25.8994C47.6815 24.5965 48.7398 23.5384 50.043 23.5384C51.3462 23.5384 52.4045 24.5965 52.4045 25.8994C52.4045 27.2023 51.3462 28.2604 50.043 28.2604ZM50.043 23.9756C48.9847 23.9756 48.1188 24.8413 48.1188 25.8994C48.1188 26.9575 48.9847 27.8232 50.043 27.8232C51.1013 27.8232 51.9672 26.9575 51.9672 25.8994C51.9672 24.8413 51.1013 23.9756 50.043 23.9756Z" fill={secondaryColor}/>
<path d="M51.0226 46.1339C47.9089 46.1339 45.3812 43.6067 45.3812 40.4937C45.3812 37.3807 47.9089 34.8536 51.0226 34.8536C54.1363 34.8536 56.664 37.3807 56.664 40.4937C56.664 43.6067 54.1363 46.1339 51.0226 46.1339ZM51.0226 38.3514C49.8418 38.3514 48.8798 39.3132 48.8798 40.4937C48.8798 41.6742 49.8418 42.6361 51.0226 42.6361C52.2033 42.6361 53.1654 41.6742 53.1654 40.4937C53.1654 39.3132 52.2033 38.3514 51.0226 38.3514Z" fill={secondaryColor}/>
<path d="M51.0226 46.3525C47.7865 46.3525 45.1626 43.7204 45.1626 40.4938C45.1626 37.2671 47.7952 34.635 51.0226 34.635C54.25 34.635 56.8827 37.2671 56.8827 40.4938C56.8827 43.7204 54.25 46.3525 51.0226 46.3525ZM51.0226 35.0722C48.0314 35.0722 45.5999 37.5032 45.5999 40.4938C45.5999 43.4843 48.0314 45.9153 51.0226 45.9153C54.0139 45.9153 56.4453 43.4843 56.4453 40.4938C56.4453 37.5032 54.0139 35.0722 51.0226 35.0722ZM51.0226 42.8547C49.7194 42.8547 48.6611 41.7967 48.6611 40.4938C48.6611 39.1908 49.7194 38.1328 51.0226 38.1328C52.3258 38.1328 53.3841 39.1908 53.3841 40.4938C53.3841 41.7967 52.3258 42.8547 51.0226 42.8547ZM51.0226 38.57C49.9643 38.57 49.0984 39.4357 49.0984 40.4938C49.0984 41.5518 49.9643 42.4175 51.0226 42.4175C52.0809 42.4175 52.9468 41.5518 52.9468 40.4938C52.9468 39.4357 52.0809 38.57 51.0226 38.57Z" fill={secondaryColor}/>
<path d="M44.2092 53.9251H33.5038C28.8944 53.9251 23.9703 50.4886 23.9703 45.3644C23.9703 40.6424 27.8099 36.8036 32.5329 36.8036C37.2559 36.8036 41.0956 40.6424 41.0956 45.3644C41.0956 47.7253 43.9993 49.9988 45.1189 50.6809C45.7923 51.0919 46.1072 51.8964 45.8973 52.6484C45.6874 53.4004 44.9964 53.9251 44.218 53.9251H44.2092ZM32.5242 40.3013C29.7341 40.3013 27.46 42.5749 27.46 45.3644C27.46 48.3462 30.6437 50.4273 33.495 50.4273H39.6087C38.5066 49.0632 37.5883 47.3406 37.5883 45.3644C37.5883 42.5749 35.3142 40.3013 32.5242 40.3013Z" fill={primaryColor}/>
<path d="M44.2092 54.1437H33.5037C28.7895 54.1437 23.7516 50.611 23.7516 45.3644C23.7516 40.52 27.6874 36.585 32.5329 36.585C37.3783 36.585 41.3142 40.52 41.3142 45.3644C41.3142 47.6204 44.1392 49.8327 45.2325 50.4886C45.9847 50.9433 46.3433 51.8527 46.1072 52.7009C45.871 53.5491 45.0926 54.1437 44.2092 54.1437ZM32.5241 37.0222C27.9236 37.0222 24.1801 40.7648 24.1801 45.3644C24.1801 50.3574 28.9994 53.7065 33.495 53.7065H44.2005C44.8827 53.7065 45.4862 53.2431 45.6698 52.5872C45.8535 51.9314 45.5736 51.2231 44.9876 50.8646C43.8419 50.165 40.8594 47.8215 40.8594 45.3644C40.8594 40.7648 37.1159 37.0222 32.5154 37.0222H32.5241ZM40.0635 50.646H33.495C30.5387 50.646 27.2414 48.4774 27.2414 45.3644C27.2414 42.4525 29.6116 40.0827 32.5241 40.0827C35.4367 40.0827 37.8069 42.4525 37.8069 45.3644C37.8069 47.0171 38.4716 48.6785 39.7748 50.2875L40.0635 50.646ZM32.5241 40.52C29.8478 40.52 27.6787 42.6973 27.6787 45.3644C27.6787 48.2238 30.7486 50.2087 33.495 50.2087H39.1626C37.9731 48.626 37.3696 46.9996 37.3696 45.3644C37.3696 42.6886 35.1918 40.52 32.5241 40.52Z" fill={primaryColor}/>
<path d="M22.7807 34.4864C22.3347 34.4864 21.8799 34.3115 21.5388 33.9704C20.8566 33.2884 20.874 32.1604 21.5563 31.4783C21.7662 31.2685 22.9819 30.1579 26.1306 29.1086C27.0489 28.8025 28.0373 29.301 28.3434 30.2191C28.6495 31.1373 28.151 32.1254 27.2326 32.4314C24.8536 33.2272 23.9965 33.9792 23.9877 33.9879C23.6466 34.329 23.2093 34.4951 22.772 34.4951L22.7807 34.4864Z" fill={primaryColor}/>
<path d="M22.7807 34.705C22.256 34.705 21.7574 34.4951 21.3813 34.1191C21.014 33.7518 20.8041 33.2534 20.8128 32.72C20.8128 32.1866 21.0227 31.6969 21.3988 31.3209C21.6175 31.1023 22.8595 29.9655 26.0606 28.8987C27.0927 28.5577 28.2035 29.1173 28.5533 30.1404C28.7195 30.6388 28.6845 31.1723 28.4484 31.6445C28.2122 32.1167 27.8099 32.4664 27.3113 32.6326C25.046 33.3846 24.1802 34.1191 24.1452 34.1453C23.7866 34.5039 23.3055 34.705 22.7895 34.705H22.7807ZM26.6816 29.231C26.5242 29.231 26.358 29.2572 26.2005 29.3097C23.0956 30.3415 21.9148 31.4171 21.7049 31.627C21.4163 31.9155 21.2501 32.309 21.2501 32.72C21.2501 33.131 21.3988 33.5245 21.6875 33.8043C22.2735 34.3814 23.2618 34.3902 23.839 33.8218C23.9527 33.7256 24.8624 32.9823 27.1714 32.2128C27.5562 32.0816 27.8711 31.8106 28.0548 31.4433C28.2384 31.076 28.2647 30.6651 28.1422 30.2716C27.9323 29.6332 27.3288 29.2223 26.6903 29.2223L26.6816 29.231Z" fill={primaryColor}/>
<path d="M22.7895 41.2633C22.186 41.2633 21.6175 40.9572 21.2939 40.4325L1.04617 7.40501C-0.0821011 5.46376 0.416439 3.02411 2.17445 1.69496C4.05491 0.287118 6.76627 0.645614 8.21815 2.49068L31.011 33.6207C31.3609 34.1016 31.4484 34.7312 31.2297 35.2908C31.011 35.8505 30.53 36.2527 29.944 36.3751C26.0257 37.1621 24.4163 40.1877 24.3464 40.3188C24.0577 40.8785 23.4805 41.2458 22.8507 41.2633C22.8332 41.2633 22.807 41.2633 22.7895 41.2633ZM4.80709 4.31827C4.62342 4.31827 4.43975 4.37075 4.28231 4.49317C3.93246 4.7555 3.83625 5.23642 4.05491 5.61242L22.9469 36.4188C23.8303 35.4919 25.0898 34.4689 26.7691 33.7256L5.44557 4.5981C5.30563 4.41447 5.06073 4.31827 4.81583 4.31827H4.80709Z" fill={primaryColor}/>
<path d="M22.7895 41.4819C22.0985 41.4819 21.4688 41.1321 21.1102 40.5462L0.862472 7.51872C-0.31828 5.49002 0.197753 2.91045 2.05197 1.52009C4.02864 0.0422912 6.87119 0.41831 8.39305 2.35082L31.1947 33.4895C31.597 34.0317 31.6845 34.7312 31.4396 35.3608C31.1947 35.9904 30.6524 36.4451 29.9877 36.5763C26.1743 37.337 24.6087 40.2839 24.5387 40.4063C24.2151 41.0359 23.5679 41.4469 22.8595 41.4731H22.7895V41.4819ZM4.78082 1.05661C3.91494 1.05661 3.04031 1.31895 2.30562 1.87859C0.626323 3.13778 0.162766 5.46379 1.22982 7.30011L21.4775 40.3276C21.7574 40.7823 22.2472 41.0534 22.7807 41.0534H22.8332C23.3842 41.0359 23.8828 40.7124 24.1452 40.2227C24.2151 40.0915 25.8769 36.9698 29.8915 36.1653C30.4075 36.0604 30.8273 35.7106 31.0198 35.2209C31.2122 34.7312 31.1422 34.1891 30.8273 33.7606L8.03445 2.63064C7.22979 1.60755 6.00531 1.06537 4.77208 1.06537L4.78082 1.05661ZM22.9032 36.7774L22.7545 36.5326L3.86246 5.72613C3.58258 5.25394 3.70502 4.65059 4.14234 4.3183C4.57966 3.99476 5.27936 4.0647 5.60297 4.46694L27.0927 33.8218L26.8478 33.9267C25.4396 34.5476 24.1801 35.4395 23.0956 36.5676L22.9032 36.7687V36.7774ZM4.80706 4.53691C4.66712 4.53691 4.51843 4.5806 4.41347 4.66804C4.15108 4.86042 4.08112 5.21895 4.2473 5.49877L22.9906 36.0604C24.0052 35.046 25.1597 34.2328 26.4367 33.6382L5.27062 4.72926C5.17441 4.61558 5.00822 4.53691 4.82455 4.53691H4.80706Z" fill={primaryColor}/>
<path d="M34.4745 50.0338C30.3375 50.0338 28.8331 47.2444 28.8331 45.3644C28.8331 44.4025 29.6203 43.6155 30.5824 43.6155C31.5445 43.6155 32.3054 44.3762 32.3317 45.3206C32.3666 45.7753 32.6903 46.5361 34.4745 46.5361C35.4366 46.5361 36.2238 47.3231 36.2238 48.2849C36.2238 49.2468 35.4366 50.0338 34.4745 50.0338Z" fill={primaryColor}/>
<path d="M34.4745 50.2524C30.1713 50.2524 28.6145 47.3318 28.6145 45.3644C28.6145 44.2801 29.4979 43.3969 30.5824 43.3969C31.667 43.3969 32.5241 44.2625 32.5503 45.3206C32.5941 45.9589 33.2763 46.3175 34.4745 46.3175C35.5591 46.3175 36.4424 47.2007 36.4424 48.285C36.4424 49.3693 35.5591 50.2524 34.4745 50.2524ZM30.5737 43.8341C29.734 43.8341 29.0431 44.5249 29.0431 45.3644C29.0431 47.157 30.4862 49.8152 34.4658 49.8152C35.3054 49.8152 35.9964 49.1244 35.9964 48.285C35.9964 47.4455 35.3054 46.7547 34.4658 46.7547C32.4979 46.7547 32.1393 45.8715 32.1043 45.3381C32.0868 44.5074 31.3958 43.8341 30.5737 43.8341Z" fill={primaryColor}/>
</svg>

);

export default PaletteIcon;
