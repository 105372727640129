import React from 'react';
const FilterIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M50.7179 1.91542C36.1815 -0.331882 21.1816 -0.331878 6.64519 1.90668C3.18165 2.44009 0.66272 5.35196 0.66272 8.83222V15.2856C0.66272 16.5448 0.995068 17.769 1.60731 18.827C3.47027 21.9925 5.73558 24.9131 8.34198 27.5189C10.1 29.2766 12.0154 30.8855 14.0358 32.3109C15.8813 33.6138 16.9921 35.7474 16.9921 38.0035V45.6023C16.9921 51.4348 21.7413 56.1743 27.5751 56.1743C30.4002 56.1743 33.059 55.0725 35.0532 53.0788L37.2223 50.9102C39.2252 48.9164 40.3185 46.2581 40.3185 43.4337V38.0035C40.3185 35.7387 41.4205 33.6138 43.266 32.3109C45.2951 30.8855 47.2106 29.2678 48.9599 27.5189C51.5575 24.9219 53.8228 21.9925 55.6945 18.827C56.3155 17.769 56.6391 16.5448 56.6391 15.2856V8.83222C56.6391 5.35196 54.1464 2.44008 50.7179 1.91542ZM51.9686 15.2943C51.9686 15.7228 51.8636 16.1338 51.6624 16.4661C50.0006 19.2905 47.9715 21.9051 45.6537 24.2223C44.0881 25.7876 42.3739 27.2304 40.5634 28.5071C37.4759 30.6844 35.6392 34.2346 35.6392 38.0122V43.4425C35.6392 45.0252 35.027 46.503 33.9074 47.6223L31.7384 49.7909C30.6188 50.9102 29.132 51.5223 27.5576 51.5223C24.2953 51.5223 21.6451 48.8727 21.6451 45.6111V38.0122C21.6451 34.2346 19.7996 30.6844 16.7209 28.5071C14.9105 27.2304 13.2049 25.7963 11.6393 24.2311C9.32156 21.9138 7.29242 19.2992 5.63062 16.4748C5.42945 16.1425 5.32449 15.7228 5.32449 15.2943V8.84096C5.32449 7.67796 6.17289 6.70734 7.35364 6.52371C14.3769 5.43941 21.5402 4.89726 28.6509 4.89726C35.7617 4.89726 42.9686 5.44815 50.0094 6.53245C51.1465 6.70734 51.9773 7.67796 51.9773 8.84096V15.2943H51.9686Z" fill={secondaryColor}/>
<path d="M47.3068 9.55801H9.98625C8.70054 9.55801 7.651 10.5986 7.651 11.8928C7.651 13.1869 8.6918 14.2275 9.98625 14.2275H47.3068C48.5925 14.2275 49.642 13.1869 49.642 11.8928C49.642 10.5986 48.6012 9.55801 47.3068 9.55801Z" fill={primaryColor}/>
<path d="M30.9774 30.5445H26.3156C25.0299 30.5445 23.9803 31.5851 23.9803 32.8792C23.9803 34.1734 25.0212 35.214 26.3156 35.214H30.9774C32.2631 35.214 33.3127 34.1734 33.3127 32.8792C33.3127 31.5851 32.2719 30.5445 30.9774 30.5445Z" fill={primaryColor}/>
</svg>

);

export default FilterIcon;
