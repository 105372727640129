import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useCheckPermission from '../../hooks/useCheckPermission';
import MapObjectForm from './MapObjectForm';
import userGroupService from '../../services/authority/userGroupService';
import mapobjectService from './mapObjectService';
import mapObjectCategoryService from '../../services/mapObjectCategoryService';
import locationService from '../../services/locationService';
import publisherService from '../../services/publisherService';
import pageService from '../../services/pageService';
import { ApprovementStatus } from '../contents/ContentPage';

const MapObjectCreate = (props: any) => {
  const { onSave = null, navigateBack = true, handleCancel = null } = props;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const [mapObjectCategories, setMapObjectCategories] = useState<any[]>([]);

  const { checkPermission } = useCheckPermission();

  const [pages, setPages] = useState<any[]>([]);
  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    pageService
      .listLastApprovedPages()
      .then((response: any) => {
        if (!response.hasError) setPages(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  const [locations, setLocations] = useState<any[]>([]);
  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    locationService
      .list()
      .then((response: any) => {
        if (!response.hasError) setLocations(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  const [publishers, setPublishers] = useState<any[]>([]);
  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    publisherService
      .list()
      .then((response: any) => {
        if (!response.hasError) {
          setPublishers(response.records);
        } else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  useEffect(() => {
    mapObjectCategoryService.list().then((response: any) => {
      if (!response.hasError) setMapObjectCategories(response.records);
      else
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
    });
  }, []);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    mapobjectService
      .create(entity)
      .then((response) => {
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Success!', {
            variant: 'success',
          });

          if (onSave) onSave(response.result);
          if (navigateBack) navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        if (setSubmitting) setSubmitting(false);
      });
  };

  return (
    <MapObjectForm
      entity={{
        name: '',
        imageId: '',
        mapObjectCategoryId: '',
        description: '',
        pageId: '',
      }}
      pages={pages}
      onPageAdded={(page: any) => {
        setPages([...pages, page]);
      }}
      readonly={!checkPermission(['CreateMapObject'])}
      onSubmit={onSubmit}
      errors={errors}
      mapObjectCategories={mapObjectCategories}
      onMapObjectCategoryAdded={(category: any) => {
        setMapObjectCategories([...mapObjectCategories, category]);
      }}
      handleCancel={() =>
        handleCancel != null ? handleCancel() : navigate(-1)
      }
      locations={locations}
      onLocationAdded={(location: any) => {
        setLocations([...locations, location]);
      }}
      publishers={publishers}
    />
  );
};

export default MapObjectCreate;
