import React from 'react';
const Bell_ringIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="51" height="57" viewBox="0 0 51 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M4.97462 22.3279L5.08833 21.8207C6.20786 16.9589 9.00667 12.6479 12.9775 9.69231C14.0096 8.9228 14.2195 7.46248 13.4498 6.43065C12.6801 5.39881 11.2195 5.18021 10.1874 5.95846C5.33321 9.57863 1.90468 14.8427 0.531507 20.7889L0.4178 21.3048C0.137918 22.564 0.925068 23.8057 2.17579 24.0943C2.35072 24.1293 2.52567 24.1555 2.69185 24.1555C3.7589 24.1555 4.72097 23.421 4.96587 22.3367L4.97462 22.3279Z" fill={secondaryColor}/>
<path d="M50.8753 21.3048L50.7528 20.7802C49.3884 14.8427 45.9599 9.57862 41.1057 5.95845C40.0736 5.18895 38.6129 5.39881 37.8432 6.43064C37.0736 7.46248 37.2835 8.9228 38.3156 9.6923C42.2864 12.6566 45.0852 16.9589 46.2047 21.812L46.3184 22.3279C46.5633 23.4122 47.5254 24.1468 48.5925 24.1468C48.7674 24.1468 48.9336 24.1293 49.1085 24.0855C50.368 23.797 51.1551 22.5553 50.8665 21.2961L50.8753 21.3048Z" fill={secondaryColor}/>
<path d="M44.2368 23.5871L43.9395 22.3629C42.5138 16.2068 38.2106 11.3975 32.6392 9.17638V7.821C32.6392 3.96473 29.4993 0.8255 25.6422 0.8255C21.785 0.8255 18.6451 3.96473 18.6451 7.821V9.17638C13.0737 11.3975 8.77051 16.2068 7.34486 22.3629L7.04749 23.5871C6.00668 27.8106 4.64225 31.9992 2.99795 36.0216C2.54314 37.1409 2.31573 38.3039 2.31573 39.4844C2.31573 44.014 5.58687 47.8265 10.0912 48.5523C12.2166 48.8933 14.3594 49.1469 16.5023 49.348C17.3769 53.5891 21.1378 56.7895 25.6422 56.7895C30.1465 56.7895 33.9074 53.5891 34.782 49.348C36.9599 49.1469 39.1377 48.8846 41.2893 48.5348C45.7412 47.8177 48.9686 44.0052 48.9686 39.4844C48.9686 38.3039 48.7412 37.1409 48.2864 36.0216C46.642 31.9992 45.2864 27.8106 44.2368 23.5871ZM23.3156 7.82974C23.3156 6.54432 24.3652 5.49499 25.6509 5.49499C26.9366 5.49499 27.9862 6.54432 27.9862 7.82974V7.98713C27.2165 7.89094 26.4468 7.82974 25.6509 7.82974C24.855 7.82974 24.0853 7.89094 23.3156 7.98713V7.82974ZM25.6509 52.1287C23.8929 52.1287 22.3798 51.1406 21.5839 49.7065C22.9395 49.759 24.2952 49.794 25.6509 49.794C27.0066 49.794 28.3623 49.759 29.7179 49.7065C28.922 51.1406 27.4089 52.1287 25.6509 52.1287ZM40.5459 43.9353C30.75 45.518 20.6393 45.5267 10.8347 43.9527C8.60435 43.5942 6.98626 41.7142 6.98626 39.4931C6.98626 38.916 7.09998 38.3389 7.32738 37.788C9.05915 33.5557 10.4848 29.1485 11.5868 24.7064L11.893 23.4122C13.3886 16.9764 19.0387 12.4818 25.6422 12.4818C32.2456 12.4818 37.9045 16.9764 39.3914 23.4122L39.6975 24.7064C40.7908 29.1485 42.2251 33.547 43.9569 37.788C44.1843 38.3389 44.298 38.916 44.298 39.4931C44.298 41.7229 42.715 43.5942 40.5371 43.944L40.5459 43.9353Z" fill={secondaryColor}/>
<path d="M36.4088 33.6519C29.5517 36.5113 21.7325 36.5113 14.8842 33.6519C13.6947 33.1535 12.3303 33.7218 11.8317 34.9023C11.3332 36.0916 11.9017 37.4557 13.0824 37.9541C17.0795 39.6243 21.304 40.4638 25.6422 40.4638C29.9803 40.4638 34.2048 39.6156 38.2019 37.9541C39.3914 37.4557 39.9511 36.0916 39.4526 34.9023C38.9628 33.7131 37.5984 33.1447 36.4001 33.6519H36.4088Z" fill={primaryColor}/>
</svg>

);

export default Bell_ringIcon;
