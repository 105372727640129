import React, { useState } from 'react';
import {
  Button,
  TextField,
  Box,
  Grid,
  Paper,
  Autocomplete,
  useMediaQuery,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { Form, Formik } from 'formik';
import CreateEntityDecorator from '../../components/CreateEntityDecorator';
import LocationCreate from '../locations/LocationCreate';
import useCheckPermission from '../../hooks/useCheckPermission';
import PublisherTypeCreate from '../publisherTypes/PublisherTypeCreate';
import useImageSelect from '../../hooks/useImageSelect';

const PublisherForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    readonly = false,
    groups,
    publisherTypes,
    onPublisherTypeAdded,
  } = props;

  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );

  const { checkPermission } = useCheckPermission();

  const { ImageSelectComponent, uploadImage, uploading, error, uploadedFile } =
    useImageSelect();

  const handleUploaded = (image: any, setFieldValue: any) => {
    setFieldValue('imageId', image.id);
    setFieldValue('image', image);
  };

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};
        if (!values.name) {
          errors.name = 'Required';
        }
        if (!values.email) {
          errors.email = 'Required';
        }
        if (!values.publisherTypeId) {
          errors.publisherTypeId = 'Required';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting, setFieldValue }) => {
        let newValues = { ...values };
        if (values.id) {
          uploadImage(
            (image: any) =>
              (newValues = { ...values, image: image, imageId: image.id }),
            'kép',
            values.id,
            '-1'
          )?.then(() => {
            onSubmit(newValues, setSubmitting);
          });
        } else {
          onSubmit(newValues, setSubmitting);
        }
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        setFieldValue,
        handleChange,
        errors: validationErrors,
      }) => (
        <Form>
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>Publikáló {values.id > 0 ? 'szerkesztés' : 'létrehozás'}</h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error: string) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.name}
                  onChange={handleChange}
                  label="Név"
                  name="name"
                  required
                  disabled={readonly}
                  fullWidth
                  autoFocus
                  error={!!touched.name && !!validationErrors.name}
                  helperText={
                    !!touched.name &&
                    !!validationErrors.name &&
                    (validationErrors.name as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.email}
                  onChange={handleChange}
                  label="Email elérhetőség"
                  name="email"
                  required
                  type="email"
                  disabled={readonly}
                  fullWidth
                  autoFocus
                  error={!!touched.email && !!validationErrors.email}
                  helperText={
                    !!touched.email &&
                    !!validationErrors.email &&
                    (validationErrors.email as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.website}
                  onChange={handleChange}
                  label="Weboldal"
                  name="website"
                  disabled={readonly}
                  fullWidth
                  autoFocus
                  error={!!touched.website && !!validationErrors.website}
                  helperText={
                    !!touched.website &&
                    !!validationErrors.website &&
                    (validationErrors.website as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <CreateEntityDecorator
                  hideAdd={!checkPermission(['CreatePublisherType'])}
                  AutocompleteComponent={
                    <Autocomplete
                      disablePortal
                      id="publisherTypeId"
                      disabled={readonly}
                      value={values.publisherTypeId}
                      onChange={(event, value) => {
                        setFieldValue('publisherTypeId', value);
                      }}
                      getOptionLabel={(option) =>
                        publisherTypes.find((g: any) => g.id === option)
                          ?.name ?? ''
                      }
                      options={publisherTypes.map((g: any) => g.id)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Típus"
                          required
                          fullWidth
                          error={
                            !!touched.publisherTypeId &&
                            !!validationErrors.publisherTypeId
                          }
                          helperText={
                            !!touched.publisherTypeId &&
                            !!validationErrors.publisherTypeId &&
                            (validationErrors.publisherTypeId as string)
                          }
                        />
                      )}
                    />
                  }
                  onSave={(publisherType: any) => {
                    setFieldValue('publisherTypeId', publisherType.id);
                    onPublisherTypeAdded(publisherType);
                  }}
                  CreateEntityComponent={PublisherTypeCreate}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="groupId"
                  value={values.groupIds}
                  multiple
                  disabled={readonly}
                  onChange={(event, value) => {
                    setFieldValue('groupIds', value);
                  }}
                  getOptionLabel={(option) =>
                    groups?.find((g: any) => g.id === option)?.name ?? ''
                  }
                  options={groups?.map((g: any) => g.id)}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label="Csoportok" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  label="Intézmény?"
                  labelPlacement="start"
                  control={
                    <Checkbox
                      value={values.isInstitution}
                      checked={values.isInstitution}
                      onChange={(e) => {
                        setFieldValue('isInstitution', e.target.checked);
                      }}
                    ></Checkbox>
                  }
                />
              </Grid>
              {values.id > 0 && (
                <Grid item xs={12}>
                  <ImageSelectComponent
                    image={values.image}
                    onSelect={(image: any) => {
                      setFieldValue('imageId', image.id);
                      setFieldValue('image', image);
                    }}
                    alt="kép"
                    onUploaded={(image: any) =>
                      handleUploaded(image, setFieldValue)
                    }
                    publisherId={values.id}
                    currentFolderId={'-1'}
                  />
                </Grid>
              )}

              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item xs={12} sm={isSmallScreen ? 12 : 'auto'} p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth={isSmallScreen}
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12} sm={isSmallScreen ? 12 : 'auto'} p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth={isSmallScreen}
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default PublisherForm;
