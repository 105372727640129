import React from 'react';
const Alarm_clockIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="53" viewBox="0 0 57 53" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M49.0128 28.7488C49.0128 17.9669 40.835 9.06517 30.3569 7.90217V5.43625H35.0187C36.3044 5.43625 37.354 4.39568 37.354 3.10151C37.354 1.80735 36.3132 0.766754 35.0187 0.766754H21.0246C19.7389 0.766754 18.6894 1.80735 18.6894 3.10151C18.6894 4.39568 19.7302 5.43625 21.0246 5.43625H25.6864V7.90217C15.2083 9.06517 7.03052 17.9669 7.03052 28.7488C7.03052 35.1759 9.94306 40.9297 14.5086 44.7859L12.0772 48.4411C11.36 49.5166 11.6573 50.9595 12.7331 51.6765C13.8089 52.3935 15.2521 52.0962 15.9692 51.0207L18.392 47.3743C21.2783 48.8695 24.5494 49.7353 28.0217 49.7353C31.494 49.7353 34.7651 48.8783 37.6514 47.3743L40.0741 51.0207C40.7825 52.0962 42.2344 52.3848 43.3102 51.6765C44.386 50.9682 44.6747 49.5166 43.9662 48.4411L41.5347 44.7859C46.1003 40.9297 49.0128 35.1759 49.0128 28.7488ZM18.9693 42.32C18.9693 42.32 18.9692 42.32 18.9605 42.3113C18.9518 42.3113 18.943 42.3113 18.9255 42.2938C14.5698 39.3557 11.6923 34.3801 11.6923 28.7488C11.6923 19.7508 19.013 12.423 28.0217 12.423C37.0304 12.423 44.351 19.742 44.351 28.7488C44.351 34.3889 41.4735 39.3732 37.1091 42.3025C37.1091 42.3025 37.1004 42.3025 37.0916 42.3025C34.494 44.0427 31.3803 45.057 28.0304 45.057C24.6806 45.057 21.5669 44.0427 18.978 42.3025L18.9693 42.32Z" fill={secondaryColor}/>
<path d="M48.3831 9.53736C47.4997 8.60171 46.0216 8.558 45.0857 9.44118C44.1499 10.3244 44.1061 11.8022 44.9895 12.7378C49.0915 17.0838 51.3481 22.7676 51.3481 28.74C51.3481 30.0254 52.3889 31.0748 53.6834 31.0748C54.9778 31.0748 56.0186 30.0342 56.0186 28.74C56.0186 21.5696 53.3072 14.749 48.3831 9.53736Z" fill={secondaryColor}/>
<path d="M10.9663 9.44118C10.0305 8.558 8.55237 8.60171 7.66899 9.53736C2.74481 14.749 0.0334473 21.5696 0.0334473 28.74C0.0334473 30.0254 1.07427 31.0748 2.36872 31.0748C3.66318 31.0748 4.704 30.0342 4.704 28.74C4.704 22.7676 6.96054 17.0838 11.0626 12.7378C11.9459 11.8022 11.9022 10.3244 10.9663 9.44118Z" fill={secondaryColor}/>
<path d="M36.6718 34.0915L30.3569 27.7781V19.4185C30.3569 18.1331 29.3161 17.0838 28.0217 17.0838C26.7272 17.0838 25.6864 18.1243 25.6864 19.4185V28.7487C25.6864 29.3696 25.9313 29.9642 26.3686 30.4014L33.3657 37.3969C33.8205 37.8516 34.4152 38.079 35.0187 38.079C35.6222 38.079 36.2082 37.8516 36.6718 37.3969C37.5814 36.4875 37.5814 35.0097 36.6718 34.1003V34.0915Z" fill={primaryColor}/>
</svg>

);

export default Alarm_clockIcon;
