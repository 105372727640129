import React from 'react';
const TempleIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="73" viewBox="0 0 57 73" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M30.0436 0H27.0698V16.9116H30.0436V0Z" fill={primaryColor}/>
<path d="M34.5916 4.35471H22.5567V7.32779H34.5916V4.35471Z" fill={primaryColor}/>
<path d="M55.8714 69.7888H1.27692V72.7619H55.8714V69.7888Z" fill={secondaryColor}/>
<path d="M2.43143 53.8304L0.428528 51.6355L14.2652 39.0524V27.9383H17.2389V39.7082C17.2389 40.1279 17.064 40.5214 16.7491 40.81L2.43143 53.8391V53.8304Z" fill={secondaryColor}/>
<path d="M35.3088 70.7158H32.5887V52.4051C32.5887 50.149 30.752 48.3214 28.5042 48.3214C26.2564 48.3214 24.4197 50.1577 24.4197 52.4051V70.7158H21.6996V52.4051C21.6996 48.6537 24.7521 45.6019 28.5042 45.6019C32.2564 45.6019 35.3088 48.6537 35.3088 52.4051V70.7158Z" fill={primaryColor}/>
<path d="M47.5887 66.4398H38.5187V57.0221C38.5187 54.5212 40.5566 52.4837 43.0581 52.4837C45.5595 52.4837 47.5974 54.5212 47.5974 57.0221V66.4398H47.5887ZM41.4925 63.4667H44.6237V57.0221C44.6237 56.1564 43.924 55.4568 43.0581 55.4568C42.1922 55.4568 41.4925 56.1564 41.4925 57.0221V63.4667Z" fill={secondaryColor}/>
<path d="M33.1135 41.4658H24.0436V32.0481C24.0436 29.5472 26.0815 27.5098 28.5829 27.5098C31.0844 27.5098 33.1223 29.5472 33.1223 32.0481V41.4658H33.1135ZM27.0086 38.4927H30.1398V32.0481C30.1398 31.1824 29.4401 30.4829 28.5742 30.4829C27.7083 30.4829 27.0086 31.1824 27.0086 32.0481V38.4927Z" fill={secondaryColor}/>
<path d="M18.5596 66.4398H9.48969V57.0221C9.48969 54.5212 11.5276 52.4837 14.029 52.4837C16.5305 52.4837 18.5684 54.5212 18.5684 57.0221V66.4398H18.5596ZM12.4547 63.4667H15.5859V57.0221C15.5859 56.1564 14.8862 55.4568 14.0203 55.4568C13.1544 55.4568 12.4547 56.1564 12.4547 57.0221V63.4667Z" fill={secondaryColor}/>
<path d="M43.2417 32.3192L28.4517 17.5325L13.6617 32.3192L11.5626 30.2206L27.4109 14.3758C27.6908 14.0959 28.0669 13.9385 28.4604 13.9385C28.854 13.9385 29.2301 14.0959 29.51 14.3758L45.3583 30.2206L43.2592 32.3192H43.2417Z" fill={secondaryColor}/>
<path d="M6.50719 48.9685H3.53345V70.7682H6.50719V48.9685Z" fill={secondaryColor}/>
<path d="M54.5682 53.8304L40.2505 40.8013C39.9444 40.5214 39.7607 40.1192 39.7607 39.6995V27.9295H42.7344V39.0436L56.5711 51.6268L54.5682 53.8216V53.8304Z" fill={secondaryColor}/>
<path d="M53.4749 48.9685H50.5012V70.7682H53.4749V48.9685Z" fill={secondaryColor}/>
</svg>

);

export default TempleIcon;
