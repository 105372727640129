import React from 'react';
const PushpinIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M8.09223 45.2176L1.09518 52.2131C0.18556 53.1225 0.18556 54.6003 1.09518 55.5097C2.00479 56.4191 3.48292 56.4191 4.39254 55.5097L11.3896 48.5142C12.2992 47.6048 12.2992 46.127 11.3896 45.2176C10.48 44.3082 9.00184 44.3082 8.09223 45.2176Z" fill={secondaryColor}/>
<path d="M54.0541 10.9221L45.6926 2.56253C44.1883 1.0585 42.1853 0.227783 40.06 0.227783C37.9346 0.227783 35.9318 1.0585 34.4274 2.56253L31.4099 5.57934C29.2146 7.77417 29.2146 11.3506 31.4099 13.5367C31.786 13.9127 31.786 14.5248 31.4099 14.9008L30.3603 15.9502C28.5761 17.734 25.926 18.3286 23.2671 17.5416C23.2496 17.5416 23.2233 17.5329 23.2059 17.5242C19.9435 16.6497 13.4625 15.6529 6.69281 18.8183C4.42752 19.8851 2.71325 21.9051 2.01354 24.3448C1.2876 26.8631 1.67244 29.5739 3.08059 31.7862C5.85317 36.1409 9.1068 40.2071 12.754 43.8535C16.375 47.4736 20.3983 50.709 24.7277 53.4635C26.958 54.8801 29.6956 55.2649 32.2408 54.5129C34.786 53.7608 36.8763 51.942 37.9696 49.5373C40.2699 44.4743 40.6635 38.7205 39.0717 33.334C38.2845 30.6757 38.8792 28.0261 40.6635 26.2423L41.7131 25.193C42.0891 24.817 42.7014 24.817 43.0775 25.193C45.2728 27.3878 48.8413 27.3878 51.0366 25.193L54.0541 22.1762C55.5585 20.6721 56.3894 18.6697 56.3894 16.5448C56.3894 14.4199 55.5585 12.4174 54.0541 10.9134V10.9221ZM50.7567 18.8883L47.7393 21.9051C47.3632 22.2811 46.7509 22.2811 46.3748 21.9051C44.1795 19.7102 40.6023 19.7102 38.4157 21.9051L37.3661 22.9544C34.4011 25.9187 33.3428 30.4046 34.6023 34.6631C35.8705 38.9741 35.5644 43.5649 33.7277 47.6136C33.1941 48.794 32.1708 49.6772 30.9201 50.0532C29.6694 50.4205 28.3312 50.2369 27.2466 49.5461C19.1301 44.3694 12.1855 37.4089 7.02517 29.3028C6.33422 28.2185 6.15055 26.8981 6.50915 25.6564C6.85025 24.4759 7.62867 23.5578 8.77444 23.0156C14.1009 20.5322 19.3312 21.3367 21.9989 22.0363C26.2496 23.278 30.7277 22.2199 33.6839 19.2643L34.7335 18.215C36.9288 16.0201 36.9288 12.4524 34.7335 10.2576C34.3574 9.88157 34.3574 9.26946 34.7335 8.89345L37.751 5.87664C38.372 5.25579 39.2029 4.91477 40.0862 4.91477C40.9696 4.91477 41.8005 5.26454 42.4215 5.87664L50.783 14.2363C51.3952 14.8484 51.7451 15.6966 51.7451 16.571C51.7451 17.4455 51.404 18.2762 50.783 18.9058L50.7567 18.8883Z" fill={secondaryColor}/>
<path d="M22.6636 33.9723C22.6636 33.9723 22.6461 33.9548 22.6373 33.9461C20.512 31.8212 18.5441 29.5127 16.7773 27.0555C16.0164 25.9974 14.5645 25.7788 13.5237 26.5309C12.4829 27.2829 12.2467 28.7432 12.9989 29.7838C14.9144 32.4333 17.0484 34.9429 19.3487 37.234C19.3575 37.234 19.3662 37.2515 19.375 37.2602C21.684 39.5687 24.2029 41.7111 26.8793 43.6349C27.9289 44.3869 29.3808 44.1508 30.1329 43.1102C30.8851 42.0696 30.6577 40.6093 29.6082 39.8573C27.1417 38.0734 24.8064 36.0972 22.6723 33.9636L22.6636 33.9723Z" fill={primaryColor}/>
</svg>

);

export default PushpinIcon;
