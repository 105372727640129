import React from 'react';
const Mailbox_1Icon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="53" viewBox="0 0 57 53" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M40.4209 0.766754H17.0945C8.09452 0.766754 0.765076 8.08581 0.765076 17.0925V24.088C0.765076 30.5151 5.99544 35.7443 12.424 35.7443H17.4181C18.4589 39.7579 22.0799 42.7398 26.4181 42.7398V49.7353C26.4181 51.0207 27.4588 52.07 28.7533 52.07C30.0477 52.07 31.0886 51.0294 31.0886 49.7353V42.7398C35.4268 42.7398 39.0478 39.7579 40.0886 35.7443H45.0827C51.5112 35.7443 56.7415 30.5151 56.7415 24.088V17.0925C56.7415 8.09455 49.4121 0.766754 40.4122 0.766754H40.4209ZM5.43568 24.0793V17.0838C5.43568 10.6566 10.6659 5.42749 17.0945 5.42749C23.523 5.42749 28.7533 10.6566 28.7533 17.0838V31.0748H12.424C8.56686 31.0748 5.42693 27.9355 5.42693 24.0793H5.43568ZM31.0973 38.0703H26.4356C24.7213 38.0703 23.2256 37.1259 22.421 35.7355H35.1206C34.3072 37.1259 32.8204 38.0703 31.1061 38.0703H31.0973ZM52.0885 24.0793C52.0885 27.9355 48.9486 31.0748 45.0914 31.0748H33.4326V17.0838C33.4326 12.5192 31.5434 8.39184 28.5085 5.42749H40.4297C46.8582 5.42749 52.0885 10.6566 52.0885 17.0838V24.0793Z" fill={secondaryColor}/>
<path d="M41.5929 12.423C38.3743 12.423 35.7591 15.0376 35.7591 18.2555C35.7591 21.4734 38.3743 24.088 41.5929 24.088C44.8116 24.088 47.4268 21.4734 47.4268 18.2555C47.4268 15.0376 44.8116 12.423 41.5929 12.423ZM41.5929 19.4185C40.9544 19.4185 40.4297 18.8938 40.4297 18.2555C40.4297 17.6172 40.9544 17.0925 41.5929 17.0925C42.2314 17.0925 42.7561 17.6172 42.7561 18.2555C42.7561 18.8938 42.2314 19.4185 41.5929 19.4185Z" fill={primaryColor}/>
<path d="M26.4268 26.414C26.4268 25.1286 25.386 24.0793 24.0915 24.0793H10.0974C8.81173 24.0793 7.76213 25.1198 7.76213 26.414C7.76213 27.7082 8.80298 28.7487 10.0974 28.7487H24.0915C25.3772 28.7487 26.4268 27.7082 26.4268 26.414Z" fill={primaryColor}/>
</svg>

);

export default Mailbox_1Icon;
