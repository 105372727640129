import React from 'react';
const FireIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="43" height="56" viewBox="0 0 43 56" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M36.1086 19.8183C35.3214 19.0663 34.8403 18.4716 34.5605 17.8683C33.9307 16.5304 33.5021 15.1225 33.2835 13.6797C32.9599 11.4849 33.1348 9.25508 33.8083 7.03401C34.3156 5.36383 34.0094 3.6062 32.9774 2.2071C31.9541 0.825484 30.371 0.0297546 28.6392 0.0297546C28.473 0.0297546 28.3068 0.0472413 28.1407 0.0822188C23.5663 1.07908 14.89 4.02594 8.1466 12.5779C1.11457 21.4797 0.563543 31.2297 0.651006 35.0335C0.651006 40.9884 3.21368 46.681 7.65681 50.651C11.5139 54.105 16.5256 55.985 21.6946 55.985C22.4818 55.985 23.269 55.9413 24.0649 55.8538C33.5721 54.787 41.3213 47.127 42.4759 37.6393C43.298 30.9324 40.9715 24.4353 36.1086 19.8095V19.8183ZM37.8491 37.0797C36.9482 44.4512 30.9307 50.4061 23.5488 51.2281C18.8258 51.7527 14.2865 50.3186 10.7705 47.1882C7.30696 44.0927 5.32153 39.6593 5.32153 35.016C5.32153 34.9985 5.32153 34.981 5.32153 34.9548C5.24282 31.6756 5.71511 23.2024 11.82 15.4723C17.6888 8.03961 25.438 5.50372 28.8229 4.71673C29.024 4.76045 29.1552 4.88287 29.2339 4.9878C29.3214 5.10148 29.4526 5.34633 29.3477 5.67861C28.473 8.56426 28.2456 11.4849 28.6742 14.3618C28.9541 16.2593 29.5226 18.1044 30.3447 19.862C31.0357 21.3223 32.0765 22.4153 32.8987 23.1936C36.6858 26.7875 38.4876 31.8505 37.8491 37.0709V37.0797Z" fill={secondaryColor}/>
<path d="M24.0211 25.1873C23.4526 23.9893 22.9803 22.7476 22.5955 21.4972C22.3593 20.7014 21.7121 20.0981 20.8987 19.9057C20.094 19.7133 19.2457 19.9669 18.6684 20.5703C18.2049 21.06 17.75 21.5846 17.304 22.1443C12.6072 28.0904 12.2486 34.605 12.3098 37.0972V37.3507C12.3098 42.4924 16.4993 46.681 21.6421 46.681C22.0095 46.681 22.3768 46.6635 22.7617 46.6197C26.8987 46.1563 30.3972 42.6936 30.8957 38.575C31.3943 34.4564 29.3389 31.8243 28.0707 30.6175C26.8987 29.507 25.2106 27.7407 24.0124 25.2048L24.0211 25.1873ZM22.2544 41.9678C19.4031 42.2738 16.9891 40.114 16.9891 37.307V36.9922C16.9454 35.2346 17.1553 31.0722 19.6742 26.8924C19.718 26.9886 19.7617 27.0761 19.8054 27.1723C21.336 30.4164 23.4264 32.6025 24.8695 33.9754C25.648 34.7099 26.5138 36.0041 26.2777 37.989C26.0328 39.9565 24.231 41.7404 22.2544 41.9678Z" fill={primaryColor}/>
</svg>

);

export default FireIcon;
