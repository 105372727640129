import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { FileCopy } from '@mui/icons-material';

const ReportForm = (props: any) => {
  const { entity, onSubmit, errors, handleCancel, readonly = false } = props;

  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );

  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [selectedImageUrl, setSelectedImageUrl] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};
        if (!values.name) {
          errors.name = 'Required';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        setFieldValue,
        handleChange,
        errors: validationErrors,
      }) => {
        const handleCopyToClipboard = () => {
          const googleMapsLink = `https://www.google.com/maps?q=${values.latitude},${values.longitude}`;

          // Create a new clipboard item
          navigator.clipboard
            .writeText(googleMapsLink)
            .then(() => {
              enqueueSnackbar('Vágolapra másolva!', {
                variant: 'success',
              });
            })
            .catch((error) => {
              enqueueSnackbar('Hiba történt a vágólapra helyezés közben!', {
                variant: 'error',
              });
            });
        };

        return (
          <Form>
            <Paper>
              <Grid container justifyContent="left" spacing={2} p={5}>
                <h2>
                  Jelentés {values.id !== 0 ? 'megtekintése' : 'létrehozás'}
                </h2>
                <Grid xs={12} item pt={5}>
                  <Box color="red">
                    {errors.map((error: string) => (
                      <li>{error}</li>
                    ))}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography>Beküldés ideje: {values.created}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={values.email}
                    label="Bejelentő email címe"
                    name="email"
                    disabled={true}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={values.reportCategoryName}
                    label="Kategória"
                    name="reportCategoryName"
                    disabled={true}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={values.subject}
                    onChange={handleChange}
                    label="Tárgy"
                    name="subject"
                    required
                    disabled={readonly}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    error={!!touched.subject && !!validationErrors.subject}
                    helperText={
                      !!touched.subject &&
                      !!validationErrors.subject &&
                      (validationErrors.subject as string)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={values.message}
                    onChange={handleChange}
                    label="Üzenet"
                    name="message"
                    required
                    multiline
                    rows={10}
                    InputLabelProps={{ shrink: true }}
                    disabled={readonly}
                    fullWidth
                    error={!!touched.message && !!validationErrors.message}
                    helperText={
                      !!touched.message &&
                      !!validationErrors.message &&
                      (validationErrors.message as string)
                    }
                  />
                </Grid>
                {values.latitude && values.longitude && (
                  <div>
                    <a
                      href={`https://www.google.com/maps?q=${values.latitude},${values.longitude}`}
                      target="_blank"
                    >
                      Megtekintés Google Maps-en
                    </a>

                    <IconButton onClick={handleCopyToClipboard}>
                      <FileCopy />
                    </IconButton>
                  </div>
                )}
                <Grid item xs={12}>
                  <h3>Képek</h3>
                  <Grid container spacing={2}>
                    {values.imageUrls &&
                      values.imageUrls?.map(
                        (imageUrl: string, index: number) => (
                          <Grid item key={index}>
                            <img
                              src={imageUrl}
                              alt={`Image ${index}`}
                              style={{
                                width: '100px',
                                height: '100px',
                                cursor: 'pointer',
                                border:
                                  index === selectedImageIndex
                                    ? '2px solid blue'
                                    : 'none',
                              }}
                              onClick={() => {
                                setSelectedImageIndex(index);
                                setSelectedImageUrl(imageUrl);
                              }}
                            />
                          </Grid>
                        )
                      )}
                  </Grid>
                  {selectedImageUrl && (
                    <img
                      src={selectedImageUrl}
                      alt="Selected Image"
                      style={{ width: '100%', marginTop: '10px' }}
                    />
                  )}
                </Grid>
                <Grid container item pt={3} justifyContent="left">
                  {!readonly && (
                    <Grid item xs={12} sm={isSmallScreen ? 12 : 'auto'} p={1}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth={isSmallScreen}
                        disabled={isSubmitting}
                      >
                        Mentés
                      </Button>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={isSmallScreen ? 12 : 'auto'} p={1}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      fullWidth={isSmallScreen}
                      onClick={handleCancel}
                    >
                      Mégse
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ReportForm;
