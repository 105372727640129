import React from 'react';
const Teacher_class_1Icon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="59" height="59" viewBox="0 0 59 59" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M19.5388 58.3285C19.2239 58.3285 18.909 58.241 18.6204 58.0661C17.7982 57.5589 17.5446 56.4746 18.0606 55.6614C18.1219 55.5652 18.2181 55.3291 18.2968 55.128C18.6379 54.2885 19.1627 53.0206 20.5533 52.3736C20.5796 52.3561 20.6146 52.3473 20.6408 52.3385L25.014 50.5897C25.4775 50.406 26.0023 50.4235 26.4483 50.6509C26.4483 50.6509 27.909 51.3417 30.0431 51.3417C32.1772 51.3417 33.6291 50.6509 33.6466 50.6509C34.0926 50.441 34.6087 50.4148 35.0635 50.5984L39.4366 52.3473C39.4366 52.3473 39.4978 52.3735 39.5241 52.3823C40.9235 53.0293 41.4395 54.2973 41.7806 55.1368C41.8681 55.3379 41.9556 55.574 42.0168 55.6701C42.5241 56.4921 42.2704 57.5677 41.457 58.0749C40.6349 58.582 39.5591 58.3284 39.0518 57.5152C38.8331 57.1654 38.6844 56.8069 38.5445 56.4659C38.4133 56.1424 38.2209 55.6702 38.0634 55.5652L34.4162 54.1049C33.5416 54.4197 32.0023 54.8394 30.0343 54.8394C28.0664 54.8394 26.5271 54.4197 25.6524 54.1049L22.0052 55.5652C21.8565 55.6614 21.6554 56.1424 21.5242 56.4659C21.3842 56.8157 21.2355 57.1742 21.0169 57.5152C20.6845 58.0486 20.116 58.3372 19.53 58.3459L19.5388 58.3285Z" fill={secondaryColor}/>
<path d="M32.667 51.333C32.0985 51.333 31.5474 51.0619 31.2063 50.5547C30.6728 49.7502 30.8915 48.6659 31.6874 48.1325C33.1305 47.1706 33.1568 46.8733 33.3229 45.2206C33.3492 44.9495 33.3842 44.6522 33.4192 44.3462C33.5329 43.393 33.6028 42.3262 33.1918 41.5567C32.8769 40.9621 32.2471 40.4724 31.4512 40.2013C30.5591 39.904 29.5008 39.8953 28.5999 40.2013C27.804 40.4724 27.1743 40.9621 26.8594 41.5567C26.4571 42.3262 26.5183 43.4018 26.6408 44.3462C26.6757 44.661 26.7107 44.9495 26.737 45.2206C26.9031 46.8733 26.9381 47.1706 28.3725 48.1325C29.1772 48.6659 29.3958 49.7502 28.8536 50.5547C28.32 51.3592 27.2355 51.5778 26.4308 51.0356C23.6233 49.1643 23.4484 47.4942 23.2559 45.5704C23.2297 45.3168 23.2035 45.0545 23.1685 44.7659C22.9935 43.3668 22.9061 41.548 23.772 39.9215C24.5067 38.5224 25.8274 37.4469 27.4892 36.8785C29.0985 36.3363 30.9702 36.3363 32.5795 36.8785C34.2413 37.4381 35.562 38.5224 36.2967 39.9215C37.1538 41.548 37.0664 43.3668 36.9002 44.7659C36.8652 45.0545 36.839 45.3256 36.8127 45.5704C36.6203 47.4942 36.4454 49.1643 33.6378 51.0356C33.3404 51.2368 32.9993 51.333 32.667 51.333Z" fill={secondaryColor}/>
<path d="M56.282 58.3285C55.696 58.3285 55.1275 58.0312 54.7951 57.5065C54.5765 57.1567 54.4278 56.7982 54.2878 56.4572C54.1566 56.1336 53.9642 55.6614 53.8068 55.5565L50.1596 54.0962C49.2849 54.411 47.7456 54.8307 45.7777 54.8307C42.8302 54.8307 40.836 53.8776 40.6261 53.7726C39.7602 53.3442 39.4104 52.2861 39.8477 51.4292C40.2762 50.5635 41.3345 50.2137 42.1917 50.6509C42.1917 50.6509 43.6523 51.3417 45.7864 51.3417C47.9205 51.3417 49.3724 50.6509 49.3899 50.6509C49.8359 50.441 50.352 50.4148 50.8068 50.5984L55.18 52.3473C55.18 52.3473 55.2412 52.3735 55.2674 52.3823C56.6668 53.0293 57.1829 54.2973 57.524 55.1368C57.6114 55.3379 57.6989 55.574 57.7601 55.6702C58.2674 56.4921 58.0138 57.5677 57.2003 58.0749C56.9117 58.2497 56.5969 58.3372 56.282 58.3372V58.3285Z" fill={secondaryColor}/>
<path d="M48.4103 51.333C47.8418 51.333 47.2908 51.0619 46.9497 50.5547C46.4162 49.7502 46.6348 48.6659 47.4307 48.1325C48.8739 47.1706 48.9001 46.8733 49.0663 45.2206C49.0925 44.9495 49.1275 44.6522 49.1625 44.3462C49.2762 43.393 49.3462 42.3262 48.9351 41.5567C48.6202 40.9621 47.9905 40.4724 47.1946 40.2013C46.3025 39.904 45.2442 39.8953 44.3433 40.2013C43.5474 40.4724 42.9176 40.9621 42.6028 41.5567C42.2004 42.3262 42.2617 43.4018 42.3841 44.3462C42.4191 44.661 42.4541 44.9495 42.4803 45.2206C42.6465 46.8733 42.6815 47.1706 44.1159 48.1325C44.9205 48.6659 45.1392 49.7502 44.5969 50.5547C44.0634 51.3592 42.9788 51.5778 42.1742 51.0356C39.3666 49.1643 39.1917 47.4942 38.9993 45.5704C38.973 45.3168 38.9468 45.0545 38.9118 44.7659C38.7369 43.3668 38.6494 41.548 39.5153 39.9215C40.25 38.5224 41.5707 37.4469 43.2325 36.8785C44.8418 36.3363 46.7135 36.3363 48.3228 36.8785C49.9846 37.4381 51.3053 38.5224 52.04 39.9215C52.8972 41.548 52.8097 43.3668 52.6435 44.7659C52.6085 45.0545 52.5823 45.3256 52.5561 45.5704C52.3637 47.4942 52.1887 49.1643 49.3812 51.0356C49.0838 51.2368 48.7427 51.333 48.4103 51.333Z" fill={secondaryColor}/>
<path d="M56.282 34.7187H24.7953C23.8332 34.7187 23.046 33.9317 23.046 32.9698C23.046 32.0079 23.8332 31.2209 24.7953 31.2209H54.5327V4.11337H20.4221C19.46 4.11337 18.6729 3.32638 18.6729 2.3645C18.6729 1.40262 19.46 0.615623 20.4221 0.615623H56.282C57.2441 0.615623 58.0313 1.40262 58.0313 2.3645V32.9698C58.0313 33.9317 57.2441 34.7187 56.282 34.7187Z" fill={secondaryColor}/>
<path d="M14.772 15.4811C14.2035 15.4811 13.6525 15.21 13.3114 14.7028C12.7778 13.8983 12.9965 12.814 13.7924 12.2806C14.9032 11.5373 14.9207 11.3975 15.0606 10.0159C15.0869 9.77975 15.1131 9.53487 15.1393 9.26379C15.2355 8.50303 15.288 7.64612 14.9819 7.06025C14.7545 6.62303 14.2734 6.25573 13.6612 6.04587C12.9615 5.80977 12.1306 5.80977 11.4222 6.04587C10.8187 6.24699 10.3289 6.62303 10.1015 7.06025C9.79534 7.64612 9.84781 8.50303 9.94402 9.26379C9.97901 9.53487 10.0052 9.77975 10.0227 10.0159C10.1627 11.3975 10.1802 11.5373 11.291 12.2806C12.0956 12.814 12.3143 13.8983 11.772 14.7028C11.2385 15.5073 10.1539 15.7259 9.34927 15.1838C7.04025 13.6447 6.73413 12.2719 6.54171 10.3656C6.52421 10.1557 6.49798 9.9284 6.47174 9.68356C6.32305 8.45935 6.24433 6.87662 7.00526 5.42505C7.66123 4.18335 8.83324 3.22145 10.3026 2.72302C11.7283 2.24208 13.3638 2.24208 14.7807 2.72302C16.2501 3.22145 17.4221 4.18335 18.0781 5.42505C18.839 6.87662 18.7603 8.45935 18.6116 9.68356C18.5854 9.9284 18.5591 10.147 18.5417 10.3656C18.3492 12.2806 18.0431 13.6447 15.7341 15.1838C15.4367 15.3849 15.0956 15.4811 14.7632 15.4811H14.772Z" fill={primaryColor}/>
<path d="M19.5475 51.333C18.5854 51.333 17.7982 50.546 17.7982 49.5841V32.9698C17.7982 32.9173 17.7982 32.8736 17.7982 32.8211L18.6729 22.3279C18.7341 21.6021 19.2414 20.9812 19.9498 20.7888L34.0489 16.8714L33.9702 16.5566L17.4134 18.9263C16.7312 19.2149 14.9644 19.8532 12.5504 19.8532C10.5825 19.8532 9.04317 19.4335 8.16854 19.1187L4.49509 20.5877C3.86535 20.8763 3.80413 20.9463 3.80413 22.4765V37.342C3.80413 37.8142 4.20646 38.2164 4.67876 38.2164C5.64085 38.2164 6.42802 39.0034 6.42802 39.9653C6.42802 40.9272 5.64085 41.7142 4.67876 41.7142C2.26478 41.7142 0.305603 39.7554 0.305603 37.342V22.4765C0.305603 19.2848 1.27644 18.2268 3.06943 17.3961C3.09567 17.3786 3.13066 17.3698 3.1569 17.3611L7.53006 15.6122C7.99361 15.4285 8.51839 15.446 8.96445 15.6734C8.96445 15.6734 10.4251 16.3642 12.5592 16.3642C14.6933 16.3642 16.1452 15.6734 16.1627 15.6734C16.3289 15.5947 16.5125 15.5335 16.6874 15.5073L33.4891 13.1025C35.2734 12.849 36.9439 13.977 37.3812 15.7171L37.4599 16.0319C37.9235 17.8857 36.8477 19.7395 35.0022 20.2467L22.0752 23.8407L21.3055 33.0485V49.5928C21.3055 50.5547 20.5183 51.3417 19.5562 51.3417L19.5475 51.333Z" fill={primaryColor}/>
<path d="M5.5534 51.333C4.59131 51.333 3.80414 50.546 3.80414 49.5841V38.2164C3.80414 37.2546 4.59131 36.4676 5.5534 36.4676C6.5155 36.4676 7.30266 37.2546 7.30266 38.2164V49.5841C7.30266 50.546 6.5155 51.333 5.5534 51.333Z" fill={primaryColor}/>
<path d="M38.7806 17.2299C38.0109 17.2299 37.3025 16.7227 37.0926 15.9445C36.8389 15.0088 37.39 14.0469 38.3171 13.7933L47.938 11.17C48.8739 10.9164 49.836 11.4674 50.0896 12.3943C50.3432 13.3299 49.7922 14.2918 48.8651 14.5454L39.2442 17.1687C39.0867 17.2124 38.9381 17.2299 38.7806 17.2299Z" fill={primaryColor}/>
<path d="M12.5504 51.333C11.5883 51.333 10.8011 50.546 10.8011 49.5841V42.5886C10.8011 41.6267 11.5883 40.8398 12.5504 40.8398C13.5125 40.8398 14.2997 41.6267 14.2997 42.5886V49.5841C14.2997 50.546 13.5125 51.333 12.5504 51.333Z" fill={primaryColor}/>
</svg>

);

export default Teacher_class_1Icon;
