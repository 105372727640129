import { FileCopy } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';

const CopyButton = ({ text = '' }) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleCopy = () => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        enqueueSnackbar('Sikeres másolás!', { variant: 'success' });
      })
      .catch((err) => {
        enqueueSnackbar('Sikertelen másolás!', { variant: 'error' });
      });
  };

  return (
    <Tooltip title="Másolás" arrow>
      <IconButton onClick={handleCopy} size="small">
        <FileCopy color="primary" />
      </IconButton>
    </Tooltip>
  );
};

export default CopyButton;
