import React from 'react';
const Magnifying_glassIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M50.7844 6.10146C47.2596 2.57748 42.5716 0.63623 37.5949 0.63623C32.6095 0.63623 27.9303 2.57748 24.4055 6.10146C20.8808 9.62545 18.9391 14.3125 18.9391 19.2967C18.9391 23.599 20.4172 27.5602 22.8749 30.7169L20.9507 32.6407C18.8866 31.7487 16.4201 31.9849 14.5484 33.4189C12.6942 34.8443 10.8663 36.3483 9.10825 37.896C6.738 39.9772 4.4377 42.1808 2.25987 44.4543C-0.407757 47.2438 -0.346556 51.7471 2.39979 54.4929C5.14613 57.2386 9.65052 57.3085 12.4406 54.6327C14.7146 52.4554 16.9187 50.1557 19.0003 47.7859C20.5397 46.0283 22.044 44.2007 23.4784 42.3469C24.9216 40.4756 25.1577 38.0097 24.2568 35.946L26.181 34.0223C29.3384 36.4795 33.3005 37.9573 37.6037 37.9573C42.5891 37.9573 47.2771 36.016 50.7931 32.492C54.3179 28.9681 56.2596 24.2811 56.2596 19.3055C56.2596 14.3212 54.3179 9.6342 50.7931 6.11022L50.7844 6.10146ZM19.77 39.4875C18.4056 41.2626 16.9624 43.0202 15.4843 44.6991C13.4901 46.9639 11.3823 49.1763 9.20444 51.2574C8.2336 52.1843 6.65925 52.1494 5.68841 51.1875C4.71757 50.2169 4.68257 48.6429 5.61842 47.6723C7.70004 45.4949 9.91286 43.3875 12.1782 41.3938C13.8574 39.916 15.6155 38.4819 17.391 37.109C18.0557 36.5931 19.0178 36.6631 19.6213 37.2577C20.2248 37.861 20.286 38.8142 19.77 39.4875ZM47.487 29.1866C44.8456 31.8274 41.3296 33.2878 37.5949 33.2878C29.8807 33.2878 23.6008 27.0093 23.6008 19.2967C23.6008 15.5629 25.0527 12.0477 27.7029 9.40686C30.353 6.76606 33.8603 5.30575 37.5949 5.30575C41.3296 5.30575 44.8456 6.75732 47.487 9.40686C50.1284 12.0477 51.589 15.5629 51.589 19.2967C51.589 23.0306 50.1371 26.5458 47.487 29.1866Z" fill={secondaryColor}/>
<path d="M29.3471 11.0508C28.4375 11.9602 28.4375 13.438 29.3471 14.3474C30.2568 15.2568 31.7349 15.2568 32.6445 14.3474C35.3734 11.6192 39.8077 11.6192 42.5366 14.3474C43.4462 15.2568 44.9244 15.2568 45.834 14.3474C46.7436 13.438 46.7436 11.9602 45.834 11.0508C41.2859 6.50369 33.8865 6.50369 29.3384 11.0508H29.3471Z" fill={primaryColor}/>
</svg>

);

export default Magnifying_glassIcon;
