import { useEffect, useState } from 'react';

export const useBarcodeScanner = (
  onBarcodeRead: (barcode: any) => void,
  minBarcodeLength: number = 5
) => {
  const [barcodeRead, setBarcodeRead] = useState<any>({
    data: '',
    timeStamp: 0,
    item: null,
  });

  const barcode = {
    timing: 1000,
    data: '',
  };
  const barcodeReaded = () => {
    if (barcode.data.length >= minBarcodeLength && barcode.data !== '0') {
      setBarcodeRead((state: any) => {
        onBarcodeRead({ data: barcode.data, timeStamp: barcode.timing });
        return { data: barcode.data, timeStamp: barcode.timing };
      });
    } else {
      barcode.data = '';
    }
  };

  let timeout = setTimeout(barcodeReaded, 500);

  const scanner = (e: any) => {
    if (barcode.data.length === 0 || e.timeStamp - barcode.timing < 200) {
      barcode.data += e.key;
      barcode.timing = e.timeStamp;
      clearTimeout(timeout);
      timeout = setTimeout(barcodeReaded, 500);
    } else {
      barcode.data = '';
    }
  };

  useEffect(() => {
    window.addEventListener('keypress', scanner);

    return () => {
      window.removeEventListener('keypress', scanner);
    };
  }, [onBarcodeRead]);

  return { lastBarcode: barcodeRead };
};
