import React from 'react';
const StoreIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M56.3894 19.486C56.3894 19.2674 56.3544 19.0575 56.3019 18.8477L53.5731 9.29008C52.1474 4.31453 47.5381 0.834259 42.3603 0.834259H14.4508C9.27303 0.834259 4.66372 4.31453 3.23807 9.29008L0.509221 18.8477C0.447997 19.0575 0.421753 19.2761 0.421753 19.486C0.421753 22.9225 2.31096 25.8956 5.08354 27.5134V45.1333C5.08354 51.5604 10.3138 56.7895 16.7424 56.7895H19.0776C21.6491 56.7895 23.7394 54.6996 23.7394 52.1288V45.1333C23.7394 42.5624 25.8298 40.4725 28.4012 40.4725C30.9726 40.4725 33.063 42.5624 33.063 45.1333V52.1288C33.063 54.6996 35.1534 56.7895 37.7248 56.7895H40.06C46.4886 56.7895 51.7189 51.5604 51.7189 45.1333V27.5134C54.4915 25.8956 56.3807 22.9225 56.3807 19.486H56.3894ZM7.71619 10.5668C8.57333 7.57618 11.3372 5.49501 14.4421 5.49501H42.3516C45.4565 5.49501 48.2291 7.58492 49.0775 10.5668L51.7101 19.7833C51.5614 22.2143 49.5323 24.1555 47.0571 24.1555C45.7189 24.1555 44.442 23.5784 43.5498 22.5728C42.6665 21.5672 41.3895 20.99 40.06 20.99C38.7306 20.99 37.4536 21.5672 36.5703 22.5728C34.926 24.4353 31.8035 24.6277 29.9668 22.9663C29.0747 22.1618 27.719 22.1618 26.8356 22.9663C24.9901 24.6364 21.8677 24.4441 20.2322 22.5728C19.3488 21.5672 18.0718 20.99 16.7424 20.99C15.4129 20.99 14.136 21.5672 13.2526 22.5728C12.3692 23.5784 11.0923 24.1555 9.74533 24.1555C7.27012 24.1555 5.24098 22.223 5.09229 19.7833L7.72493 10.5668H7.71619ZM47.0571 45.1333C47.0571 48.9895 43.9172 52.1288 40.06 52.1288H37.7248V45.1333C37.7248 39.9916 33.5353 35.803 28.3925 35.803C23.2496 35.803 19.0602 39.9916 19.0602 45.1333V52.1288H16.7249C12.8678 52.1288 9.72783 48.9895 9.72783 45.1333V28.8075C12.4129 28.8075 14.9581 27.6532 16.7074 25.6333C19.5674 28.8949 24.6928 29.6907 28.3837 27.5483C32.0659 29.6907 37.2175 28.8862 40.0338 25.6333C41.8093 27.6445 44.3632 28.7988 47.0396 28.7988V45.1245L47.0571 45.1333Z" fill={secondaryColor}/>
<path d="M21.4042 12.4905H35.3983C36.684 12.4905 37.7335 11.4499 37.7335 10.1558C37.7335 8.86159 36.6927 7.82101 35.3983 7.82101H21.4042C20.1184 7.82101 19.0689 8.86159 19.0689 10.1558C19.0689 11.4499 20.1097 12.4905 21.4042 12.4905Z" fill={primaryColor}/>
</svg>

);

export default StoreIcon;
