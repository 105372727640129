import {
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Paper,
  Dialog,
  Box,
  Grid,
  Typography,
  Button,
  Stack,
  Switch,
  IconButton,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import Autocomplete, {
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import useImageSelect from '../../hooks/useImageSelect';
import CreateEntityDecorator from '../../components/CreateEntityDecorator';
import LocationCreate from '../locations/LocationCreate';
import PageCreate from '../pages/PageCreate';
import useCheckPermission from '../../hooks/useCheckPermission';
import IconPicker from '../../components/IconPicker';
import IconPickerToggle from '../../components/IconPickerToggle';
import { StaticMenuItems } from '../../types/StaticMenuItems';
import HelperDialog from '../../components/HelperDialog';
import '../../css/dataGrid.css';
import HelpIcon from '@mui/icons-material/Help';
import DataList from '../../components/DataList';
import {
  GridColDef,
  GridActionsCellItem,
  GridActionsColDef,
} from '@mui/x-data-grid';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility,
  HideImage,
  Image,
  Edit,
} from '@mui/icons-material';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';

export const MenuItemEditorDialog = (props: any) => {
  const {
    pages,
    handleSubmitModal,
    open,
    setOpen,
    form,
    menuItems,
    onPageAdded,
  } = props;
  const [selectedOption, setSelectedOption] = useState<
    | 'subMenu'
    | 'pageId'
    | 'link'
    | 'static'
    | 'phone'
    | 'mail'
    | 'sms'
    | 'facebook'
    | 'proxy'
  >('subMenu');
  const initialState = {
    isUpdate: false,
    name: '',
    link: null,
    id: '',
    pageId: null,
    parentId: null,
    isWebView: false,
    menu: 1,
    imageId: null,
    image: {},
    icon: '',
    staticType: null,
    proxyId: null,
    keywords: '',
  };
  const [state, setState] = useState<any>(initialState);
  const [proxyOpen, setProxyOpen] = useState(false);
  const [proxyForm, setProxyForm] = useState<any>(null);
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );

  useEffect(() => {
    if (!form) return;
    setState({ ...state, ...form });
    if (form.staticType != null) {
      setSelectedOption('static');
    } else if (form.proxyId > 0) {
      setSelectedOption('proxy');
    } else if (form.link != null && form.link !== '') {
      if (form.link.includes('fb://page/')) {
        setSelectedOption('facebook');
        let pageInfo = extractPageInfoFromUrl(form.link);
        if (pageInfo) {
          setTextFieldValue(pageInfo.pageId);
          setFallBackValue(pageInfo.fallbackUrl);
        }
      } else if (form.link.includes('tel:')) {
        setSelectedOption('phone');
        setTextFieldValue(form.link.split(':')[1]);
      } else if (form.link.includes('sms:')) {
        setSelectedOption('sms');
        setTextFieldValue(form.link.split(':')[1]);
      } else if (form.link.includes('mailto:')) {
        setSelectedOption('mail');
        setTextFieldValue(form.link.split(':')[1]);
      } else {
        setSelectedOption('link');
      }
    } else if (form.pageId != null && form.pageId !== '') {
      setSelectedOption('pageId');
    } else setSelectedOption('subMenu');
  }, [form]);
  const { checkPermission } = useCheckPermission();

  const { ImageSelectComponent, uploadImage, uploading, error, uploadedFile } =
    useImageSelect();

  const handleUploaded = (image: any) => {
    setState({
      ...state,
      imageId: image.id,
      image: image,
      icon: '',
    });
  };

  function extractPageInfoFromUrl(url: string) {
    const pageIdRegex = /fb:\/\/page\/(\d+)\?fallback=/;
    const fallbackRegex = /\?fallback=(.+)/;

    const pageIdMatch = url?.match(pageIdRegex);
    const fallbackMatch = url?.match(fallbackRegex);

    if (pageIdMatch && fallbackMatch) {
      const pageId = pageIdMatch[1];
      const fallbackUrl = fallbackMatch[1];

      return { pageId, fallbackUrl };
    }

    return null; // Return null if the URL format doesn't match expected pattern
  }

  const isValid = useCallback(() => {
    switch (selectedOption) {
      case 'subMenu':
        return state.name !== '' && state.parentId !== '';
      case 'pageId':
        return state.name !== '' && state.pageId !== '';
      case 'link':
        return state.name !== '' && state.link !== '';
      case 'static':
        return state.name !== '' && state.staticType !== null;
      case 'facebook':
        let facebookLinkInfo = extractPageInfoFromUrl(state.link);
        return state.name !== '' && facebookLinkInfo !== null;
      case 'phone':
        return state.name !== '' && state.link !== '';
      case 'mail':
        return state.name !== '' && state.link !== '';
      case 'sms':
        return state.name !== '' && state.link !== '';
      case 'proxy':
        return state.proxyId > 0;
      default:
        return false;
    }
  }, [state, selectedOption]);

  const hasChildren = useCallback(() => {
    return menuItems.some((x: any) => x.parentId === state.id);
  }, [state, menuItems]);

  const [iconPickerOpen, setIconPickerOpen] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState('');
  const [fallBackValue, setFallBackValue] = useState('');
  const [helperDialog, setHelperDialog] = useState<any>({
    open: false,
    helperText: '',
    helperPicture: '',
  });

  useEffect(() => {
    setIconPickerOpen(state.icon ? true : false);
  }, [state]);

  const getName = (option: any) => {
    switch (option) {
      case StaticMenuItems.CityLeading:
        return 'Város vezetés';
      case StaticMenuItems.FAQ:
        return 'Gyakran ismételt kérdések';
      case StaticMenuItems.Report:
        return 'Bejelentés';
      case StaticMenuItems.InterestsSettings:
        return 'Érdeklődési körök beállítások';
    }
    return '';
  };

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    actions.push(
      <Button
        variant="contained"
        color={color ? color : 'primary'}
        onClick={() => {
          setState({ ...state, proxyId: params.row.id });
        }}
        sx={{ padding: '0px 10px' }}
      >
        Kiválasztás
      </Button>
    );
    actions.push(
      <Tooltip title="Szerkesztés">
        <IconButton
          color="primary"
          onClick={(event) => {
            event.stopPropagation();

            setProxyOpen(true);
            setProxyForm({ ...params.row, isUpdate: true, isProxy: true });
          }}
        >
          <Edit />
        </IconButton>
      </Tooltip>
    );

    return actions;
  };

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Megnevezés', flex: 150 },
    {
      field: 'actions',
      type: 'actions',
      flex: 100,
      align: 'right',
      getActions: getActions,
    } as GridActionsColDef,
  ];

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
        setState(initialState);
        setProxyForm(null);
        setProxyOpen(false);
      }}
      maxWidth={'xl'}
      fullWidth
    >
      <Paper>
        <Box p={5}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h2>Menüelem hozzáadása</h2>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Menüpont megnevezése"
                value={state.name}
                onChange={(event) =>
                  setState({ ...state, name: event.target.value })
                }
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                value={
                  state.keywords?.length > 0
                    ? state.keywords?.split(';') ?? []
                    : []
                }
                onChange={(event: any, newValue: any) => {
                  setState({
                    ...state,
                    keywords: newValue.join(';'),
                  });
                }}
                autoSelect
                freeSolo
                options={[]}
                id="keywords"
                multiple
                renderInput={(params) => (
                  <TextField {...params} label="Kulcsszavak" />
                )}
              />
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={6}>
                <Typography variant="subtitle1">
                  <b>Menűpont típusa</b>
                </Typography>
                <RadioGroup
                  aria-label="menu-option"
                  name="menu-option"
                  value={selectedOption}
                  onChange={(event: any) => {
                    setSelectedOption(event.target.value);
                    setTextFieldValue('');
                    setFallBackValue('');
                    if (event.target.value === 'proxy') {
                      setState({
                        ...state,
                        isUpdate: false,
                        name: '',
                        link: null,
                        pageId: null,
                        isWebView: false,
                        staticType: null,
                        keywords: '',
                        imageId: null,
                        image: {},
                        icon: '',
                      });
                    }
                    if (event.target.value === 'static') {
                      setState({
                        ...state,
                        isUpdate: false,
                        name: '',
                        link: null,
                        pageId: null,
                        isWebView: false,
                        keywords: '',
                        imageId: null,
                        image: {},
                        icon: '',
                      });
                    }

                    if (event.target.value === 'subMenu') {
                      setState({
                        ...state,
                        pageId: null,
                        link: null,
                        isWebView: false,
                        proxyId: null,
                        staticType: null,
                      });
                    }
                    if (
                      event.target.value === 'link' ||
                      event.target.value === 'phone' ||
                      event.target.value === 'facebook' ||
                      event.target.value === 'mail' ||
                      event.target.value === 'sms'
                    ) {
                      setState({
                        ...state,
                        pageId: null,
                        proxyId: null,
                        staticType: null,
                      });
                    }
                    if (event.target.value === 'pageId') {
                      setState({
                        ...state,
                        link: null,
                        isWebView: false,
                        proxyId: null,
                        staticType: null,
                      });
                    }
                  }}
                  row
                >
                  <FormControlLabel
                    value="subMenu"
                    control={<Radio disabled={hasChildren()} />}
                    label="Almenü"
                  />
                  <FormControlLabel
                    value="pageId"
                    control={<Radio disabled={hasChildren()} />}
                    label="Aloldal"
                  />
                  <FormControlLabel
                    value="link"
                    control={<Radio disabled={hasChildren()} />}
                    label="Hivatkozás"
                  />
                  <FormControlLabel
                    value="phone"
                    control={<Radio disabled={hasChildren()} />}
                    label="Telefon"
                  />
                  <FormControlLabel
                    value="mail"
                    control={<Radio disabled={hasChildren()} />}
                    label="Email"
                  />
                  <FormControlLabel
                    value="sms"
                    control={<Radio disabled={hasChildren()} />}
                    label="SMS"
                  />
                  <FormControlLabel
                    value="facebook"
                    control={<Radio disabled={hasChildren()} />}
                    label="Facebook oldal"
                  />
                  <FormControlLabel
                    value="proxy"
                    control={<Radio disabled={hasChildren()} />}
                    label="Helyettesítő menüpont"
                  />
                  <FormControlLabel
                    value="static"
                    control={<Radio disabled={hasChildren()} />}
                    label="Statikus"
                  />
                </RadioGroup>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="subtitle1">
                  <b>Megjelenítés helye</b>
                </Typography>
                <RadioGroup
                  aria-label="menu"
                  name="menu"
                  row
                  value={state.menu}
                  onChange={(event) =>
                    setState({ ...state, menu: event.target.value })
                  }
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio disabled />}
                    label="Főmenű"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio disabled />}
                    label="Hamburger menű"
                  />
                </RadioGroup>
              </Grid>
            </Grid>

            {selectedOption === 'pageId' && (
              <Grid item xs={12}>
                <CreateEntityDecorator
                  hideAdd={!checkPermission(['CreatePage'])}
                  AutocompleteComponent={
                    <Autocomplete
                      disablePortal
                      id="pageId"
                      value={
                        pages.find((x: any) => x.id === state.pageId) ?? ''
                      }
                      onChange={(event, value) => {
                        setState({
                          ...state,
                          pageId: value.id,
                          link: null,
                          isWebView: false,
                        });
                      }}
                      getOptionLabel={(option: any) => option?.name ?? ''}
                      options={pages}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Aloldal kiválasztása"
                          fullWidth
                        />
                      )}
                    />
                  }
                  onSave={(value: any) => {
                    console.log(value);
                    setState({
                      ...state,
                      pageId: value.id,
                      link: null,
                      isWebView: false,
                    });
                    onPageAdded(value);
                  }}
                  CreateEntityComponent={PageCreate}
                />
              </Grid>
            )}
            {(selectedOption === 'phone' || selectedOption === 'sms') && (
              <Grid item xs={12}>
                <TextField
                  label="Telefonszám"
                  value={textFieldValue}
                  onChange={(event) => {
                    var value =
                      selectedOption === 'phone'
                        ? `tel:${event.target.value}`
                        : `sms:${event.target.value}`;
                    setTextFieldValue(event.target.value);
                    console.log(value);

                    setState({
                      ...state,
                      link: value,
                      pageId: null,
                    });
                  }}
                  fullWidth
                />
              </Grid>
            )}
            {selectedOption === 'mail' && (
              <Grid item xs={12}>
                <TextField
                  label="Email cím"
                  value={textFieldValue}
                  type="email"
                  onChange={(event) => {
                    var value = `mailto:${event.target.value}`;
                    setTextFieldValue(event.target.value);
                    console.log(value);

                    setState({
                      ...state,
                      link: value,
                      pageId: null,
                    });
                  }}
                  fullWidth
                />
              </Grid>
            )}
            {selectedOption === 'facebook' && (
              <>
                <Grid item xs={5}>
                  <TextField
                    label="Oldalazonosító"
                    value={textFieldValue}
                    type="number"
                    onChange={(event) => {
                      var link = state.link || '';
                      var parts = link.split('?');
                      var value = `fb://page/${event.target.value}${
                        parts[1] ? '?' + parts[1] : ''
                      }`;
                      setTextFieldValue(event.target.value);
                      console.log(value);

                      setState({
                        ...state,
                        link: value,
                        pageId: null,
                      });
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    onClick={() => {
                      setHelperDialog({
                        open: true,
                        helperText:
                          'Az oldalazonosító a Facebook oldal névjegyei között, "Az oldal átláthatósága" alatt található.',
                        helperPicture: `${process.env.PUBLIC_URL}/PageIdHelperPicture.png`,
                      });
                    }}
                  >
                    <HelpIcon color="primary" />
                  </IconButton>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label="Oldal Url"
                    value={fallBackValue}
                    onChange={(event) => {
                      var link = state.link || '';
                      var parts = link.split('?');
                      var value = `${parts[0]}?fallback=${event.target.value}`;
                      setFallBackValue(event.target.value);
                      console.log(value);
                      setState({
                        ...state,
                        link: value,
                        pageId: null,
                      });
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    onClick={() => {
                      setHelperDialog({
                        open: true,
                        helperText:
                          'Az oldal Url-t a böngésző címsorából lehet kimásolni.',
                        helperPicture: `${process.env.PUBLIC_URL}/PageUrlHelperPicture.png`,
                      });
                    }}
                  >
                    <HelpIcon color="primary" />
                  </IconButton>
                </Grid>
              </>
            )}
            {selectedOption === 'link' && (
              <>
                <Grid item xs={12}>
                  <TextField
                    label="Hivatkozás"
                    value={state.link}
                    onChange={(event) =>
                      setState({
                        ...state,
                        link: event.target.value,
                        pageId: null,
                      })
                    }
                    fullWidth
                  />
                  <Grid item xs={12}>
                    <Box pt={2}>
                      <Typography variant="subtitle1">
                        <b> Megnyitás az alkalmazáson belül?</b>
                      </Typography>
                      <RadioGroup
                        aria-label="isWebView"
                        name="isWebView"
                        row
                        value={state.isWebView ? 'true' : 'false'}
                        onChange={(event) =>
                          setState({
                            ...state,
                            isWebView: event.target.value === 'true',
                          })
                        }
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Igen"
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="Nem"
                        />
                      </RadioGroup>
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}
            {selectedOption === 'subMenu' && <Grid item xs={12}></Grid>}
            {selectedOption === 'static' && (
              <Grid item xs={12}>
                {' '}
                <Autocomplete
                  value={state.staticType}
                  onChange={(event, newValue) => {
                    setState({
                      ...state,
                      staticType: newValue,
                      name: getName(newValue),
                    });
                  }}
                  id="type"
                  fullWidth
                  options={Object.values(StaticMenuItems).filter((x) =>
                    Number.isFinite(x)
                  )}
                  getOptionLabel={(option) => {
                    return getName(option);
                  }}
                  renderInput={(params) => (
                    <TextField required {...params} label="Típus" />
                  )}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <b>Menüpont képének kiválasztása</b>
              </Typography>
              <Grid item xs={12}>
                <IconPickerToggle
                  iconPickerOpen={iconPickerOpen}
                  setIconPickerOpen={setIconPickerOpen}
                />
              </Grid>
              {!iconPickerOpen && (
                <ImageSelectComponent
                  image={state.image}
                  onSelect={(image: any) => {
                    setState({
                      ...state,
                      imageId: image.id,
                      imageUrl: image.thumbnailUrl,
                      icon: '',
                    });
                  }}
                  alt="kép"
                  onUploaded={(image: any) => handleUploaded(image)}
                  publisherId={1}
                  currentFolderId={'-1'}
                />
              )}
              {iconPickerOpen && (
                <IconPicker
                  icon={state.icon}
                  onSelect={(icon: any) => {
                    setState({
                      ...state,
                      imageId: null,
                      imageUrl: null,
                      icon: icon,
                    });
                  }}
                />
              )}
            </Grid>

            {selectedOption === 'proxy' && (
              <Grid item xs={12}>
                <DataList
                  rows={menuItems.filter((x: any) => !(x.proxyId > 0))}
                  columns={columns}
                  getRowClassName={(params: any) => {
                    return params.row.id === state.proxyId ? 'verified' : '';
                  }}
                />
              </Grid>
            )}

            <Grid item xs={12} sm={isSmallScreen ? 12 : 'auto'} p={1}>
              <Button
                variant="contained"
                disabled={!isValid()}
                fullWidth={isSmallScreen}
                onClick={() => {
                  var newValues = { ...state };

                  if (selectedOption === 'proxy') {
                    handleSubmitModal(newValues);
                    setState(initialState);
                  } else {
                    uploadImage(
                      (image: any) =>
                        (newValues = {
                          ...state,
                          image: image,
                          imageId: image.id,
                          icon: '',
                        }),
                      'kép',
                      1,
                      '-1'
                    )?.then(() => {
                      handleSubmitModal(newValues);
                      setState(initialState);
                    });
                  }
                }}
              >
                Mentés
              </Button>
            </Grid>
            <HelperDialog
              open={helperDialog.open}
              handleClose={() =>
                setHelperDialog({
                  open: false,
                  helperText: '',
                  helperPicture: '',
                })
              }
              helperText={helperDialog.helperText}
              helperPicture={helperDialog.helperPicture}
            />
            <MenuItemEditorDialog
              pages={pages}
              form={proxyForm}
              onPageAdded={onPageAdded}
              menuItems={menuItems}
              handleSubmitModal={(newValues: any) => {
                setProxyOpen(false);
                setProxyForm(null);
                handleSubmitModal(newValues);
              }}
              open={proxyOpen}
              setOpen={setProxyOpen}
            />
          </Grid>
        </Box>
      </Paper>
    </Dialog>
  );
};
