import axios from 'axios';
import { postData } from '../../services/axiosUtils';

const mapobjectService = {
  list: () => {
    return axios.get(`/api/mapobject/list`).then((response) => {
      return response.data;
    });
  },
  get: (id: any) => {
    return axios.get(`/api/mapobject/details/${id}`).then((response) => {
      return response.data;
    });
  },
  create: (entity: any) => {
    return postData({
      url: `/api/mapobject/create`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  update: (entity: any) => {
    return postData({
      url: `/api/mapobject/update`,
      data: entity,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  delete: (id: any) => {
    return postData({
      url: `/api/mapobject/delete/${id}`,
      method: 'DELETE',
    }).then((response) => {
      return response;
    });
  },
};

export default mapobjectService;
