import React from 'react';
const CrownIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="48" viewBox="0 0 57 48" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M53.0161 9.1844C51.0919 8.30122 48.9053 8.60725 47.3047 9.98012L41.0161 15.3754C40.4301 15.8826 39.6692 16.0574 38.917 15.8651C38.1736 15.6727 37.5876 15.1481 37.3165 14.4223L33.4768 4.19135C32.6459 1.97902 30.5031 0.492493 28.1416 0.492493C25.7801 0.492493 23.6373 1.97902 22.8064 4.19135L18.9667 14.4223C18.6956 15.1481 18.1096 15.6727 17.3661 15.8651C16.6139 16.0574 15.853 15.8826 15.267 15.3754L8.96968 9.98012C7.3691 8.60725 5.1825 8.30122 3.25831 9.1844C1.34287 10.0676 0.153381 11.9301 0.153381 14.0375C0.153381 14.5622 0.232115 15.0868 0.389549 15.6115L7.56148 38.9065C9.07459 43.8296 13.5528 47.135 18.7043 47.135H37.5788C42.7304 47.135 47.2086 43.8296 48.7217 38.9065L55.8936 15.594C56.051 15.0868 56.1298 14.5622 56.1298 14.0375C56.1298 11.9301 54.9403 10.0676 53.0249 9.1844H53.0161ZM44.261 37.5336C43.3514 40.4893 40.6663 42.4742 37.5701 42.4742H18.6956C15.6081 42.4742 12.9142 40.4893 12.0046 37.5336L4.83264 14.2474C4.81514 14.1774 4.79767 14.1075 4.79767 14.0375C4.79767 13.679 5.00759 13.5041 5.19127 13.4254C5.26998 13.3904 5.37491 13.3554 5.48861 13.3554C5.61981 13.3554 5.76854 13.3992 5.91722 13.5303L12.2145 18.9256C13.9463 20.4121 16.2991 20.9543 18.5119 20.3859C20.7247 19.8176 22.5177 18.1998 23.3224 16.0662L27.1532 5.83529C27.4594 5.03081 28.7888 5.03081 29.095 5.83529L32.9345 16.0662C33.7392 18.1998 35.5322 19.8176 37.745 20.3859C39.9491 20.9456 42.3106 20.4034 44.0424 18.9256L50.331 13.5303C50.6021 13.2942 50.8733 13.3467 51.0569 13.4254C51.2406 13.5041 51.4505 13.679 51.4243 14.2386L44.2523 37.5336H44.261Z" fill={secondaryColor}/>
<path d="M28.1416 21.479C24.2845 21.479 21.1445 24.6182 21.1445 28.4745C21.1445 32.3308 24.2845 35.47 28.1416 35.47C31.9987 35.47 35.1386 32.3308 35.1386 28.4745C35.1386 24.6182 31.9987 21.479 28.1416 21.479ZM28.1416 30.8092C26.8559 30.8092 25.8063 29.7599 25.8063 28.4745C25.8063 27.1891 26.8559 26.1397 28.1416 26.1397C29.4273 26.1397 30.4768 27.1891 30.4768 28.4745C30.4768 29.7599 29.4273 30.8092 28.1416 30.8092Z" fill={primaryColor}/>
</svg>

);

export default CrownIcon;
