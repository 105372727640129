import React from 'react';
const Faulty_fileIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="43" height="57" viewBox="0 0 43 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M41.7654 18.3873C41.5468 15.2656 40.1386 12.4236 37.8121 10.3862L29.9841 3.52189C27.8588 1.65934 25.1299 0.627502 22.2961 0.627502H12.8238C6.93758 0.627502 1.96093 5.04337 1.23499 10.9108C0.509042 16.7696 0.150452 22.7245 0.150452 28.6095C0.150452 34.4945 0.517805 40.5543 1.2525 46.448C1.96969 52.228 6.94633 56.5915 12.8238 56.5915H29.4594C35.3456 56.5915 40.3223 52.1756 41.0482 46.3081C41.7742 40.4494 42.1328 34.4945 42.1328 28.6095C42.1328 25.2167 42.0103 21.7714 41.7742 18.3873H41.7654ZM32.9054 12.2925H30.4651C29.1794 12.2925 28.1299 11.2432 28.1299 9.95774V8.10393L32.9054 12.2837V12.2925ZM36.4127 45.7397C35.9754 49.2724 32.9841 51.9307 29.4506 51.9307H12.8151C9.29033 51.9307 6.30784 49.3249 5.87928 45.8709C5.17082 40.1696 4.81225 34.3633 4.81225 28.6095C4.81225 22.8557 5.17086 17.1456 5.86182 11.4792C6.29913 7.94652 9.29034 5.28822 12.8238 5.28822H22.2961C22.6897 5.28822 23.0833 5.33193 23.4769 5.39314V9.94898C23.4769 13.8053 26.6168 16.9445 30.4739 16.9445H36.745C36.9375 17.5129 37.0774 18.0988 37.1211 18.7109C37.3485 21.99 37.471 25.3129 37.471 28.6007C37.471 34.3021 37.1123 40.0646 36.4214 45.7309L36.4127 45.7397Z" fill={secondaryColor}/>
<path d="M21.1416 16.9532C13.4273 16.9532 7.14749 23.2317 7.14749 30.9442C7.14749 38.6568 13.4273 44.9352 21.1416 44.9352C28.8558 44.9352 35.1357 38.6568 35.1357 30.9442C35.1357 23.2317 28.8558 16.9532 21.1416 16.9532ZM21.1416 40.2745C15.9988 40.2745 11.8093 36.0859 11.8093 30.9442C11.8093 25.8025 15.99 21.614 21.1416 21.614C26.2932 21.614 30.4739 25.8025 30.4739 30.9442C30.4739 36.0859 26.2932 40.2745 21.1416 40.2745Z" fill={secondaryColor}/>
<path d="M25.1211 26.9655C24.2115 26.0561 22.7334 26.0561 21.8238 26.9655L21.1416 27.6476L20.4593 26.9655C19.5497 26.0561 18.0716 26.0561 17.162 26.9655C16.2524 27.875 16.2524 29.3527 17.162 30.2621L17.8442 30.9442L17.162 31.6263C16.2524 32.5357 16.2524 34.0135 17.162 34.9229C18.0716 35.8323 19.5497 35.8323 20.4593 34.9229L21.1416 34.2409L21.8238 34.9229C22.7334 35.8323 24.2115 35.8323 25.1211 34.9229C26.0307 34.0135 26.0307 32.5357 25.1211 31.6263L24.4389 30.9442L25.1211 30.2621C26.0307 29.3527 26.0307 27.875 25.1211 26.9655Z" fill={primaryColor}/>
</svg>

);

export default Faulty_fileIcon;
