import React, { useEffect, useState } from 'react';
import {
  GridColDef,
  GridActionsCellItem,
  GridActionsColDef,
} from '@mui/x-data-grid';
import {
  Paper,
  IconButton,
  Grid,
  Tooltip,
  Select,
  MenuItem,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Visibility,
} from '@mui/icons-material';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useConfirmDeleteDialog from '../../hooks/useConfirmDeleteDialog';
import useCheckPermission from '../../hooks/useCheckPermission';
import DataList from '../../components/DataList';
import reportService from '../../services/reportService';
import { formatTime } from '../../utils/formatters';

export enum ReportStatus {
  None = 0,
  New = 1,
  InProccess = 2,
  Forwarded = 3,
  Closed = 4,
}

const ReportsPage = () => {
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [rows, setRows] = useState<any[]>([]);
  const [filterStatus, setFilterStatus] = useState<ReportStatus | null>(null); // Initialize the filterStatus state
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();

  const translateStatus = (status: ReportStatus) => {
    switch (status) {
      case ReportStatus.None:
        return 'Nincs';
      case ReportStatus.New:
        return 'Új';
      case ReportStatus.InProccess:
        return 'Folyamatban';
      case ReportStatus.Forwarded:
        return 'Továbbítva';
      case ReportStatus.Closed:
        return 'Lezárt';
      default:
        return '';
    }
  };

  const { checkPermission } = useCheckPermission();

  const changeStatus = (id: number, status: ReportStatus) => {
    reportService.update({ id, status }).then((response) => {
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres módosítás!', {
          variant: 'success',
        });
        const updatedRows = rows.map((row) =>
          row.id === id ? { ...row, status: status } : row
        );
        setRows(updatedRows);
      }
    });
  };

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    reportService
      .list()
      .then((response) => {
        if (!response.hasError) {
          // Apply the status filter if set
          const filteredRows = filterStatus
            ? response.records.filter((row: any) => row.status === filterStatus)
            : response.records;

          setRows(filteredRows);
        } else {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        }
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, [filterStatus]); // Listen for changes in filterStatus

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          <Tooltip title="Megtekintés">
            <Visibility />
          </Tooltip>
        }
        label={'Megtekintés'}
        onClick={() => navigate(`/reports/view/${params.row.id}`)}
      />
    );

    if (checkPermission(['DeleteReport'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon />
            </Tooltip>
          }
          label="Törlés"
          onClick={() =>
            setParams({
              open: true,
              name: 'Biztosan törölni szeretné ezt az elemet?',
              onConfirm: async () => handleDelete(params.row.id),
            })
          }
        />
      );
    }

    if (checkPermission(['UpdateReport'])) {
      if (
        params.row.status !== ReportStatus.New &&
        params.row.status !== ReportStatus.Closed
      ) {
        actions.push(
          <GridActionsCellItem
            color={color ? color : 'primary'}
            icon={
              <Tooltip title="Státusz módosítása: 'Új'">
                <EditIcon />
              </Tooltip>
            }
            label="Státusz módosítása: 'Új'"
            showInMenu
            onClick={() => {
              changeStatus(params.row.id, ReportStatus.New);
            }}
          />
        );
      }
      if (
        params.row.status !== ReportStatus.InProccess &&
        params.row.status !== ReportStatus.Closed
      ) {
        actions.push(
          <GridActionsCellItem
            color={color ? color : 'primary'}
            icon={
              <Tooltip title="Státusz módosítása: 'Folyamatban'">
                <EditIcon />
              </Tooltip>
            }
            label="Státusz módosítása: 'Folyamatban'"
            showInMenu
            onClick={() => {
              changeStatus(params.row.id, ReportStatus.InProccess);
            }}
          />
        );
      }
      if (
        params.row.status !== ReportStatus.Forwarded &&
        params.row.status !== ReportStatus.Closed
      ) {
        actions.push(
          <GridActionsCellItem
            color={color ? color : 'primary'}
            icon={
              <Tooltip title="Státusz módosítása: 'Továbbított'">
                <EditIcon />
              </Tooltip>
            }
            label="Státusz módosítása: 'Továbbítva'"
            showInMenu
            onClick={() => {
              changeStatus(params.row.id, ReportStatus.Forwarded);
            }}
          />
        );
      }
      if (params.row.status !== ReportStatus.Closed) {
        actions.push(
          <GridActionsCellItem
            color={color ? color : 'primary'}
            icon={
              <Tooltip title="Státusz módosítása: 'Lezárt'">
                <EditIcon />
              </Tooltip>
            }
            label="Státusz módosítása: 'Lezárt'"
            showInMenu
            onClick={() => {
              changeStatus(params.row.id, ReportStatus.Closed);
            }}
          />
        );
      }
    }

    return actions;
  };

  const columns: GridColDef[] = [
    { field: 'reportCategoryName', headerName: 'Kategória', flex: 100 },
    { field: 'email', headerName: 'Bejelentő email címe', flex: 100 },
    {
      field: 'created',
      headerName: 'Bejelentés ideje',
      flex: 100,
      valueFormatter: (params) => formatTime(params.value, ''),
    },
    { field: 'subject', headerName: 'Tárgy', flex: 100 },
    { field: 'message', headerName: 'Üzenet', flex: 100 },
    {
      field: 'status',
      headerName: 'Státusz',
      flex: 100,
      valueFormatter(params) {
        return translateStatus(params.value as ReportStatus);
      },
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 100,
      align: 'right',
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const handleDelete = (id: number) => {
    reportService.delete(id).then((response) => {
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés!', {
          variant: 'success',
        });
        setRows(rows.filter((row) => row.id !== id));
      }
    });
  };

  // Handle the status filter change
  const handleFilterChange = (value: any) => {
    setFilterStatus(value as ReportStatus | null);
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Bejelentések</h2>
        </Grid>
        <Grid item xs={12} pb={2}>
          <Select
            value={filterStatus || ''}
            onChange={(event) => handleFilterChange(event.target.value)}
            displayEmpty
            fullWidth
          >
            <MenuItem value="">Összes státusz</MenuItem>
            {Object.keys(ReportStatus)
              .filter((key) => !isNaN(Number(key)))
              .map((status) => (
                <MenuItem key={status} value={parseInt(status)}>
                  {translateStatus(parseInt(status))}
                </MenuItem>
              ))}
          </Select>
        </Grid>
        <Grid item xs={12}>
          <DataList rows={rows} columns={columns} />
        </Grid>
        <ConfirmDeleteDialog />
      </Grid>
    </Paper>
  );
};

export default ReportsPage;
