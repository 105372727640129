import {
  FileData,
  ChonkyFileActionData,
  ChonkyActions,
  FileHelper,
} from '@aperturerobotics/chonky';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { CustomFileData } from './useGalleryFileMap';

export const useFileActionHandler = (
  setCurrentFolderId: (folderId: string) => void,
  deleteFiles: (files: CustomFileData[]) => void,
  moveFiles: (
    files: FileData[],
    source: FileData,
    destination: FileData
  ) => void,
  createFolder: (folderName: string) => void,
  selectFile: (file: CustomFileData) => void,
  uploadFile: () => void
) => {
  const { enqueueSnackbar } = useSnackbar();
  return useCallback(
    (data: ChonkyFileActionData) => {
      if (data.id === ChonkyActions.OpenFiles.id) {
        const { targetFile, files } = data.payload;
        const fileToOpen = targetFile ?? files[0];
        if (fileToOpen && FileHelper.isDirectory(fileToOpen)) {
          console.log('Opening folder:', fileToOpen);
          setCurrentFolderId(fileToOpen.id);
          return;
        } else {
          selectFile(fileToOpen as CustomFileData);
        }
      } else if (data.id === ChonkyActions.DeleteFiles.id) {
        deleteFiles(data.state.selectedFilesForAction!);
      } else if (data.id === ChonkyActions.MoveFiles.id) {
        moveFiles(
          data.payload.files,
          data.payload.source!,
          data.payload.destination
        );
      } else if (data.id === ChonkyActions.CreateFolder.id) {
        const folderName = prompt('Provide the name for your new folder:');
        if (folderName) createFolder(folderName);
      } else if (data.id === ChonkyActions.UploadFiles.id) {
        uploadFile();
      }
    },
    [createFolder, deleteFiles, moveFiles, setCurrentFolderId]
  );
};
