import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  FormControlLabel,
  Checkbox,
  Button,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";

export default function PermissionDialog(props: any) {
  const { open, onClose, permissions, selectedPermissions } = props;

  const [checkedPermissions, setCheckedPermissions] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (selectedPermissions && selectedPermissions.length)
      setCheckedPermissions(selectedPermissions);
  }, [selectedPermissions]);

  const handlePermissionChange = (event: any) => {
    const { value, checked } = event.target;
    const permissionId = parseInt(value);

    if (value === "all") {
      setSelectAll(checked);
      setCheckedPermissions(
        checked ? permissions.map((permission: any) => permission.id) : []
      );
    } else {
      if (checked) {
        setCheckedPermissions([...checkedPermissions, permissionId]);
      } else {
        setCheckedPermissions([
          ...checkedPermissions.filter(
            (permission) => permission !== permissionId
          ),
        ]);
      }
    }
  };

  const handleSave = () => {
    onClose(checkedPermissions);
  };

  const filteredPermissions = permissions.filter((permission: any) =>
    permission.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Dialog
      open={open}
      onClose={() => onClose(selectedPermissions)}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Jogosultságok</DialogTitle>
      <DialogContent>
        <TextField
          label="Search"
          value={searchQuery}
          autoFocus
          onChange={(e) => setSearchQuery(e.target.value)}
          fullWidth
          margin="normal"
          variant="outlined"
        />

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectAll}
                  onChange={handlePermissionChange}
                  value="all"
                  color="primary"
                />
              }
              label="Mindegyik Kijelölése"
            />
          </Grid>
          {filteredPermissions.map((permission: any, i: any) => (
            <Grid item xs={12} sm={6} md={4} key={i}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedPermissions.includes(permission.id)}
                    onChange={handlePermissionChange}
                    value={permission.id}
                    color="primary"
                  />
                }
                label={permission.description || permission.name}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <Button variant="contained" color="primary" onClick={handleSave}>
        Save
      </Button>
    </Dialog>
  );
}
