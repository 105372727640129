import React from 'react';
const ChatIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M39.1115 0.796176C35.1144 0.560078 31.0649 0.568823 27.0941 0.82241C17.8317 1.40828 10.5373 8.98965 10.0388 18.2762C4.30993 21.5466 0.66272 27.6589 0.66272 34.3134V50.2106C0.66272 52.8252 2.15834 55.0725 4.57232 56.0781C5.38573 56.4191 6.2341 56.5853 7.065 56.5853C8.70931 56.5853 10.3011 55.9382 11.5431 54.7052C13.0037 53.2449 15.0941 52.5279 17.5081 52.729C17.5256 52.729 17.753 52.7465 17.7705 52.7465C21.8463 52.9914 25.7996 53.0001 29.7792 52.7727C37.5022 52.318 43.9745 47.2113 46.336 40.1546C47.473 41.064 48.8199 41.5625 50.2193 41.5625C51.0502 41.5625 51.8811 41.3963 52.6945 41.064C55.1172 40.0584 56.6303 37.8024 56.6303 35.1791V19.2818C56.6303 9.49683 48.9336 1.38205 39.1027 0.796176H39.1115ZM29.5168 48.1207C25.7296 48.3393 22.0387 48.3306 17.9717 48.0945L17.7355 48.077C14.1495 47.7535 10.6947 48.9689 8.25451 51.3999C7.48484 52.1606 6.6102 51.8808 6.36531 51.7759C6.05044 51.6447 5.32449 51.2337 5.32449 50.2106V34.3134C5.32449 30.0898 7.30115 26.1461 10.5111 23.5578C12.374 31.3228 19.1612 37.2602 27.5139 37.7586C31.511 37.9947 35.5605 37.986 39.5313 37.7324L39.9774 37.6974C40.7208 37.6362 41.4467 37.7062 42.1465 37.8461C40.6246 43.5474 35.6042 47.7622 29.5256 48.1207H29.5168ZM51.9686 35.1791C51.9686 36.2109 51.2339 36.6219 50.919 36.753C50.6042 36.8842 49.782 37.1116 49.0648 36.3858C46.887 34.2084 43.8782 33.0017 40.6946 33.0017C40.3185 33.0017 39.9424 33.0192 39.5663 33.0542L39.2427 33.0804C35.4556 33.3253 31.6072 33.334 27.7938 33.1066C20.4294 32.6694 14.6568 26.5921 14.6568 19.2818C14.6568 11.9715 20.2545 5.93787 27.3914 5.48316C31.1786 5.23832 35.027 5.22957 38.8404 5.45693C46.2048 5.89415 51.9773 11.9715 51.9773 19.2818V35.1791H51.9686Z" fill={secondaryColor}/>
<path d="M22.8171 13.4493C19.5985 13.4493 16.9833 16.0638 16.9833 19.2818C16.9833 22.4997 19.5985 25.1143 22.8171 25.1143C26.0358 25.1143 28.6509 22.4997 28.6509 19.2818C28.6509 16.0638 26.0358 13.4493 22.8171 13.4493ZM22.8171 20.4448C22.1699 20.4448 21.6539 19.9201 21.6539 19.2818C21.6539 18.6434 22.1786 18.1188 22.8171 18.1188C23.4556 18.1188 23.9804 18.6434 23.9804 19.2818C23.9804 19.9201 23.4556 20.4448 22.8171 20.4448Z" fill={primaryColor}/>
<path d="M36.8112 13.4493C33.5926 13.4493 30.9774 16.0638 30.9774 19.2818C30.9774 22.4997 33.5926 25.1143 36.8112 25.1143C40.0299 25.1143 42.645 22.4997 42.645 19.2818C42.645 16.0638 40.0299 13.4493 36.8112 13.4493ZM36.8112 20.4448C36.164 20.4448 35.648 19.9201 35.648 19.2818C35.648 18.6434 36.1727 18.1188 36.8112 18.1188C37.4497 18.1188 37.9745 18.6434 37.9745 19.2818C37.9745 19.9201 37.4497 20.4448 36.8112 20.4448Z" fill={primaryColor}/>
</svg>

);

export default ChatIcon;
