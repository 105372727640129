import React from 'react';
const Voice_recorderIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="56" height="43" viewBox="0 0 56 43" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M21.0147 22.379C19.729 22.379 18.6794 23.4195 18.6794 24.7137V31.7092C18.6794 32.9946 19.7203 34.0439 21.0147 34.0439C22.3092 34.0439 23.3501 33.0034 23.3501 31.7092V24.7137C23.3501 23.4283 22.3092 22.379 21.0147 22.379Z" fill={secondaryColor}/>
<path d="M35.0089 22.379C33.7231 22.379 32.6736 23.4195 32.6736 24.7137V31.7092C32.6736 32.9946 33.7144 34.0439 35.0089 34.0439C36.3033 34.0439 37.3442 33.0034 37.3442 31.7092V24.7137C37.3442 23.4283 36.3033 22.379 35.0089 22.379Z" fill={secondaryColor}/>
<path d="M28.0118 23.542C26.7261 23.542 25.6765 24.5826 25.6765 25.8767V30.5375C25.6765 31.8229 26.7173 32.8722 28.0118 32.8722C29.3062 32.8722 30.3471 31.8317 30.3471 30.5375V25.8767C30.3471 24.5913 29.3062 23.542 28.0118 23.542Z" fill={secondaryColor}/>
<path d="M42.0059 23.542C40.7202 23.542 39.6706 24.5826 39.6706 25.8767V30.5375C39.6706 31.8229 40.7114 32.8722 42.0059 32.8722C43.3004 32.8722 44.3412 31.8317 44.3412 30.5375V25.8767C44.3412 24.5913 43.3004 23.542 42.0059 23.542Z" fill={secondaryColor}/>
<path d="M14.0177 23.542C12.732 23.542 11.6824 24.5826 11.6824 25.8767V30.5375C11.6824 31.8229 12.7233 32.8722 14.0177 32.8722C15.3122 32.8722 16.353 31.8317 16.353 30.5375V25.8767C16.353 24.5913 15.3122 23.542 14.0177 23.542Z" fill={secondaryColor}/>
<path d="M45.7143 1.31375C33.9855 -0.137813 21.9418 -0.129052 10.1693 1.33126C4.38795 2.0483 0.0235596 7.02383 0.0235596 12.9V29.5319C0.0235596 35.4168 4.44046 40.3924 10.3092 41.1182C16.1518 41.8439 22.1167 42.2025 28.0118 42.2025C33.9068 42.2025 39.9505 41.8352 45.8543 41.1007C51.6356 40.3836 56 35.4081 56 29.5319V12.9C56 7.01509 51.5831 2.03954 45.7143 1.31375ZM51.3295 29.5319C51.3295 33.0559 48.723 36.0377 45.2682 36.4661C33.8805 37.874 22.2217 37.8828 10.869 36.4837C7.34424 36.0464 4.67655 33.0558 4.67655 29.5231V12.8913C4.67655 9.36737 7.283 6.38549 10.7378 5.95701C16.4491 5.24872 22.2567 4.89023 28.003 4.89023C33.7493 4.89023 39.4781 5.24001 45.137 5.93956C48.6618 6.37678 51.3295 9.36732 51.3295 12.9V29.5319Z" fill={secondaryColor}/>
<path d="M41.4198 9.91824L31.764 16.0568C29.4812 17.5084 26.5336 17.5084 24.2508 16.0568L14.5949 9.91824C13.5017 9.22744 12.0672 9.55099 11.3763 10.6353C10.6853 11.7196 11.009 13.1624 12.0935 13.8532L21.7494 19.9918C23.6473 21.1985 25.8339 21.8106 28.0118 21.8106C30.1896 21.8106 32.3675 21.2072 34.2742 19.9918L43.93 13.8532C45.0146 13.1624 45.3382 11.7196 44.6473 10.6353C43.9563 9.55099 42.5219 9.22744 41.4286 9.91824H41.4198Z" fill={primaryColor}/>
</svg>

);

export default Voice_recorderIcon;
