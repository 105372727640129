import {
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Stack,
  Switch,
  Typography,
  List,
  Pagination,
  useMediaQuery,
  TablePagination,
  Box,
  Tab,
  Tabs,
  Badge,
} from '@mui/material';
import { memo, useEffect, useState } from 'react';
import icons from '../components/icons';
import illustrations from '../components/illustrations';

export default function IconPicker({ onSelect, icon }: any) {
  const [selectedIcon, setSelectedIcon] = useState<any>(null);
  const [iconList, setIconList] = useState<any>([]);
  const [illustrationList, setIllustrationList] = useState<any>([]);
  const [search, setSearch] = useState<string>('');
  const [isIllustration, setIsIllustration] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(12);
  const isSmallScreen = useMediaQuery('(max-width:1800px)');
  const [tabValue, setTabValue] = useState<number>(0);

  const handleSearch = (value: string) => {
    setSearch(value);
    if (isIllustration) {
      if (value === '') {
        fetchIllustrationList();
      } else {
        setIllustrationList(
          Object.keys(illustrations)?.filter((illustration: any) =>
            illustration?.toLowerCase()?.includes(value?.toLowerCase())
          )
        );
        setCurrentPage(1);
      }
    } else {
      if (value === '') {
        fetchIconList();
      } else {
        setIconList(
          Object.keys(icons)?.filter((icon: any) =>
            icon?.toLowerCase()?.includes(value?.toLowerCase())
          )
        );
      }
    }
  };

  useEffect(() => {
    fetchIconList();
    fetchIllustrationList();
  }, []);

  useEffect(() => {
    setSelectedIcon(icon);
    if (Object.keys(illustrations).find((key) => key === icon)) {
      setIsIllustration(true);
      setTabValue(1);
      let index = Object.keys(illustrations).indexOf(icon) + 1;
      setCurrentPage(Math.ceil(index / itemsPerPage));
    }
  }, [icon, illustrations]);

  const fetchIconList = () => {
    setIconList(Object.keys(icons));
  };
  const fetchIllustrationList = () => {
    setIllustrationList(Object.keys(illustrations));
  };

  const handleIconClick = (icon: any) => {
    setSelectedIcon(icon);
    onSelect(icon);
  };

  const getIconComponent = (key: string) => {
    const Icon = Object.values(icons)[Object.keys(icons).indexOf(key)];
    return (
      <Icon
        primaryColor="#008B61"
        secondaryColor="#5B5B5B"
        height={50}
        width={50}
      />
    );
  };
  const getIllustrationComponent = (key: string) => {
    const Illustration = Object.values(illustrations)[
      Object.keys(illustrations).indexOf(key)
    ] as any;
    return (
      <Illustration
        primaryColor="#008B61"
        secondaryColor="#5B5B5B"
        height={50}
        width={50}
      />
    );
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentIllustrationList = illustrationList.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <Paper style={{ backgroundColor: 'white' }}>
      <Grid container p={3}>
        <Grid item xs={12} p={2}>
          <Grid item xs={12}></Grid>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              pb: 2,
              maxWidth: isSmallScreen ? '280px' : '100%',
            }}
          >
            <Tabs
              value={tabValue}
              onChange={(e, newValue) => {
                setTabValue(newValue);
                setSearch('');
                if (newValue === 0) {
                  setIsIllustration(false);
                }
                if (newValue === 1) {
                  setIsIllustration(true);
                }
              }}
              TabIndicatorProps={{ style: { background: '#1976d2' } }}
              sx={{
                maxWidth: isSmallScreen ? '280px' : '100%',
              }}
              variant="fullWidth"
            >
              <Tab value={0} label={'Ikonok'} sx={{ fontWeight: 'bold' }} />
              <Tab
                value={1}
                label={'Illusztrációk'}
                sx={{ fontWeight: 'bold' }}
              />
            </Tabs>
          </Box>
        </Grid>
        <Grid item xs={12} p={2}>
          <TextField
            label="Keresés"
            variant="outlined"
            fullWidth
            style={{
              backgroundColor: 'white',
              borderRadius: '8px',
            }}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Grid>
        {!isIllustration && (
          <Grid
            container
            item
            xs={12}
            spacing={2}
            p={2}
            style={{
              overflowY: 'auto',
              maxHeight: '350px',
              backgroundColor: 'white',
            }}
          >
            <Grid item container xs={12}>
              <>
                {iconList.map((icon: any) => (
                  <Grid item key={icon}>
                    <IconButton
                      onClick={() => handleIconClick(icon)}
                      style={{
                        border:
                          selectedIcon === icon
                            ? '2px solid #e0e0e0'
                            : '2px solid transparent',
                        borderRadius: '8px',
                        backgroundColor:
                          selectedIcon === icon ? '#e0e0e0' : 'white',
                      }}
                    >
                      <Tooltip title={icon}>
                        {<div>{getIconComponent(icon)}</div>}
                      </Tooltip>
                    </IconButton>
                  </Grid>
                ))}
              </>
            </Grid>
          </Grid>
        )}
        <Grid container pb={7}>
          {isIllustration && (
            <>
              {currentIllustrationList.map((illustration: any) => (
                <Grid
                  style={{
                    transform: isSmallScreen ? 'scale(0.12)' : 'scale(0.2)',
                    height: '250px',
                  }}
                  item
                  xs={3}
                  key={illustration}
                >
                  <IconButton
                    onClick={() => handleIconClick(illustration)}
                    style={{
                      border:
                        selectedIcon === illustration
                          ? '2px solid #e0e0e0'
                          : '2px solid transparent',
                      borderRadius: '8px',
                      backgroundColor:
                        selectedIcon === illustration ? '#e0e0e0' : 'white',
                    }}
                  >
                    <Tooltip title={illustration}>
                      <div>{getIllustrationComponent(illustration)}</div>
                    </Tooltip>
                  </IconButton>
                </Grid>
              ))}
            </>
          )}
        </Grid>
        {isIllustration && (
          <Grid item xs={12} textAlign={'center'}>
            <Pagination
              page={currentPage}
              count={Math.ceil(illustrationList.length / itemsPerPage)}
              onChange={(e, page) => paginate(page)}
              boundaryCount={isSmallScreen ? 6 : illustrationList.length}
            />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}
