import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { menuItems } from './listItems';
import {
  Autocomplete,
  Button,
  Grid,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import AppRoutes from './AppRoutes';
import { Copyright } from './Copyright';
import { useAccount, useMsal } from '@azure/msal-react';
import {
  CloudSync,
  Logout,
  Person,
  SettingsApplications,
} from '@mui/icons-material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListSubheader from '@mui/material/ListSubheader';
import tenantService from '../pages/tenants/tenantService';
import useCheckPermission from '../hooks/useCheckPermission';
import { CollapseMenu } from '../components/CollapseMenu';
import { NavLink, useNavigate } from 'react-router-dom';

const item = {
  py: '2px',
  px: 3,
};

const MyNavLink = React.forwardRef<any, any>((props, ref) => (
  <NavLink
    ref={ref}
    to={props.to}
    className={({ isActive }) =>
      `${props.className} ${isActive ? props.activeClassName : ''}`
    }
  >
    {props.children}
  </NavLink>
));

const ActiveLink = styled(ListItem)(({ theme }) => ({
  backgroundColor: theme.palette.info.light,
  '& .MuiSvgIcon-root': {
    color: '#FFFFFF',
    stroke: '#FFFFFF',
    fill: '#19ABC0',
  },
}));

const drawerWidth: number = 300;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'fixed',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(0),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

let mdTheme = createTheme();

mdTheme = {
  ...mdTheme,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          '*::-webkit-scrollbar': {
            width: '0px',
          },
        },
      },
    },
  },
};

const DashboardContent = (): JSX.Element => {
  const [myTenants, setMyTenants] = React.useState<any[]>([]);
  const [activeTenant, setActiveTenant] = React.useState<any>('');
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const navigate = useNavigate();

  const { checkPermission, hasAnyPermission } = useCheckPermission();
  const isSmallScreen = useMediaQuery(mdTheme.breakpoints.down('md'));
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  // set open to false if isSmallScreen is true
  React.useEffect(() => {
    setOpen(!isSmallScreen);
  }, [isSmallScreen]);

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Grid
              container
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Grid item>
                <Typography
                  component="h1"
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ flexGrow: 1 }}
                >
                  {!isSmallScreen &&
                    !open &&
                    'Hermész Content management system'}
                </Typography>
              </Grid>
              {!open && (
                <Grid item maxHeight={'60px'}>
                  <div
                    style={{
                      backgroundColor: 'white',
                      borderRadius: '50%',
                      padding: '10px',
                      width: '80px',
                      height: '80px',
                      alignContent: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                    }}
                  >
                    <img
                      alt="Dunaharaszti logo"
                      src={`${process.env.PUBLIC_URL}/dunaharaszti_only_logo.png`}
                      style={{
                        height: '100%',
                      }}
                    />
                  </div>
                </Grid>
              )}

              <Grid item>
                <IconButton
                  onClick={() => instance.logoutRedirect()}
                  color="inherit"
                >
                  <Tooltip title="Logout">
                    <Logout />
                  </Tooltip>
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          open={open}
          sx={{ overflow: 'auto', maxHeight: '100vh' }}
        >
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          {open && (
            <Box
              pl={2}
              pt={3}
              mt="-80px"
              display="flex"
              alignItems="center"
              pb={1}
            >
              <img
                alt="Dunaharaszti logo"
                src={`${process.env.PUBLIC_URL}/dunaharaszti_only_logo.png`}
                style={{
                  height: '50px',
                  marginRight: '10px',
                }}
              />
              <Button
                onClick={() => navigate('/')}
                style={{ color: 'black', cursor: 'pointer' }}
              >
                <Typography variant="h4">CMS</Typography>
              </Button>
            </Box>
          )}
          <Divider />
          <Divider />
          <Divider />
          <List component="nav">
            {account && (
              <>
                <ListSubheader>
                  <ListItemIcon>
                    <Box pr={4}>
                      <Person color="primary" />
                    </Box>
                    <Typography fontSize="1rem">
                      {account.name}
                      <br /> {account.username}
                    </Typography>
                  </ListItemIcon>
                </ListSubheader>
                {activeTenant && (
                  <Box p={2}>
                    <Autocomplete
                      options={myTenants.map((x) => x.tenantId)}
                      value={activeTenant}
                      getOptionLabel={(option) =>
                        myTenants.find((x) => x.tenantId === option)?.tenantName
                      }
                      onChange={(event, newValue) => {
                        tenantService
                          .changeTenant({ tenantId: newValue })
                          .then((response) => {
                            if (!response.hasError)
                              setActiveTenant(response.result?.tenantId);
                            window.location.reload();
                          });
                      }}
                      disableClearable
                      size={'small'}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ fontSize: '10px' }}
                          variant="standard"
                        />
                      )}
                    />
                  </Box>
                )}
              </>
            )}
            {account && (
              <>
                <Divider />
                <Divider />
              </>
            )}
            {menuItems.map(
              ({ id, children, icon, requiredPermissions, collapsed }) =>
                hasAnyPermission(requiredPermissions) ? (
                  <div key={id}>
                    <CollapseMenu
                      collapsed={collapsed}
                      icon={icon}
                      id={id}
                      key={id}
                      minimal={!open}
                    >
                      {children.map(
                        ({
                          id: childId,
                          icon,
                          to,
                          useHref,
                          requiredPermissions,
                        }) =>
                          checkPermission(requiredPermissions) ? (
                            !useHref ? (
                              <ListItemButton
                                sx={item}
                                key={childId}
                                component={MyNavLink}
                                activeClassName={ActiveLink}
                                to={to}
                                onClick={() => {
                                  debugger;
                                  setOpen(false);
                                }}
                              >
                                <ListItemButton
                                  sx={{ p: 0 }}
                                  onClick={() => {
                                    if (isSmallScreen) setOpen(false);
                                  }}
                                >
                                  <ListItemIcon>{icon}</ListItemIcon>
                                  <ListItemText>{childId}</ListItemText>
                                </ListItemButton>
                              </ListItemButton>
                            ) : (
                              <ListItemButton
                                sx={item}
                                key={childId}
                                component="a"
                                href={to}
                                onClick={() => {
                                  setOpen(false);
                                }}
                              >
                                <ListItemIcon>{icon}</ListItemIcon>
                                <ListItemText>{childId}</ListItemText>
                              </ListItemButton>
                            )
                          ) : null
                      )}
                    </CollapseMenu>
                  </div>
                ) : null
            )}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            overflow: 'auto',
            minHeight: '100vh',
            marginLeft: (theme) => (open ? `${drawerWidth}px` : '0px'), // Adjust marginLeft when the drawer is open
            transition: mdTheme.transitions.create('margin', {
              easing: mdTheme.transitions.easing.sharp,
              duration: mdTheme.transitions.duration.leavingScreen,
            }),
          }}
        >
          <Toolbar />
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Box>
              <AppRoutes />
            </Box>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default function Layout() {
  return <DashboardContent />;
}
