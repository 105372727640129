import React from 'react';
const BackpackIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="64" viewBox="0 0 57 64" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M36.2674 7.80351H32.7688C32.7688 5.5562 30.9409 3.71989 28.6843 3.71989C26.4278 3.71989 24.5998 5.54746 24.5998 7.80351H21.1013C21.1013 3.6237 24.5036 0.222137 28.6843 0.222137C32.865 0.222137 36.2674 3.6237 36.2674 7.80351Z" fill={secondaryColor}/>
<path d="M43.4568 58.1361H13.9118C11.9789 58.1361 10.4133 56.5709 10.4133 54.6384V40.6474C10.4133 38.7149 11.9789 37.1496 13.9118 37.1496H43.4568C45.3898 37.1496 46.9554 38.7149 46.9554 40.6474V54.6384C46.9554 56.5709 45.3898 58.1361 43.4568 58.1361ZM13.9118 40.6474V54.6384H43.4481V40.6474H13.9118Z" fill={primaryColor}/>
<path d="M49.0895 63.9686H8.27043C4.08969 63.9686 0.687378 60.5671 0.687378 56.3872V48.7359C0.687378 46.2787 2.27045 44.1276 4.57073 43.3756V27.3647C4.57073 15.6122 14.1305 6.05463 25.8855 6.05463H31.4569C43.2119 6.05463 52.7717 15.6122 52.7717 27.3647V43.3756C55.0807 44.1276 56.655 46.2787 56.655 48.7359V56.3872C56.655 60.5671 53.2527 63.9686 49.072 63.9686H49.0895ZM25.903 9.55238C16.0809 9.55238 8.08675 17.5447 8.08675 27.3647V44.7223C8.08675 45.5267 7.54448 46.2263 6.75731 46.4187L5.81272 46.6548C4.85937 46.8909 4.19464 47.7478 4.19464 48.7272V56.3785C4.19464 58.6258 6.02262 60.4621 8.27917 60.4621H49.0982C51.346 60.4621 53.1827 58.6345 53.1827 56.3785V48.7272C53.1827 47.7478 52.518 46.8909 51.5647 46.6548L50.6201 46.4187C49.8417 46.2263 49.2906 45.5267 49.2906 44.7223V27.3647C49.2906 17.5447 41.2965 9.55238 31.4744 9.55238H25.903Z" fill={secondaryColor}/>
<path d="M45.2061 29.9618C44.244 29.9618 43.4568 29.1748 43.4568 28.2129C43.4568 21.025 37.9467 15.3849 30.9059 15.3849H26.4628C19.4307 15.3849 13.9118 21.0163 13.9118 28.2129C13.9118 29.1748 13.1246 29.9618 12.1625 29.9618C11.2004 29.9618 10.4133 29.1748 10.4133 28.2129C10.4133 19.0575 17.4628 11.8871 26.4628 11.8871H30.9059C39.9058 11.8871 46.9554 19.0575 46.9554 28.2129C46.9554 29.1748 46.1682 29.9618 45.2061 29.9618Z" fill={secondaryColor}/>
<path d="M33.5473 29.9617H23.8301C22.868 29.9617 22.0809 29.1748 22.0809 28.2129V22.3804C22.0809 21.4185 22.868 20.6315 23.8301 20.6315H33.5473C34.5094 20.6315 35.2966 21.4185 35.2966 22.3804V28.2129C35.2966 29.1748 34.5094 29.9617 33.5473 29.9617ZM25.5707 26.464H31.7893V24.1292H25.5707V26.464Z" fill={secondaryColor}/>
</svg>

);

export default BackpackIcon;
