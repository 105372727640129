import React from 'react';
const Shield_xIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="56" height="57" viewBox="0 0 56 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M55.93 19.486V18.8477C55.93 15.551 53.6298 12.6741 50.4548 12.0183C48.6618 11.6423 46.8951 11.0827 45.2246 10.3481C42.1109 8.97526 39.2945 7.00777 36.863 4.50687C34.5628 2.1284 31.4491 0.8255 28.1167 0.8255H28.003C24.6182 0.8255 21.4433 2.16338 19.0556 4.60306C16.6503 7.06023 13.8603 8.99275 10.7816 10.3481C9.11103 11.0827 7.34419 11.6423 5.55119 12.0183C2.38503 12.6741 0.0760995 15.551 0.0760995 18.8477V19.3373C-0.18629 23.5346 0.0147939 34.3777 7.53662 44.2238C12.3296 50.4935 18.1984 53.9038 22.2829 55.6527C24.0409 56.4135 25.9651 56.7895 28.003 56.7895C30.0409 56.7895 31.9564 56.4047 33.7231 55.6527C37.8077 53.8951 43.6764 50.4935 48.4694 44.2238C55.9912 34.3777 56.1924 23.5434 55.93 19.486ZM44.7785 41.3819C40.5978 46.8559 35.4636 49.829 31.8951 51.368C29.5336 52.3823 26.4812 52.3823 24.1197 51.368C20.5512 49.829 15.417 46.8559 11.2363 41.3819C4.66782 32.7862 4.5017 23.3073 4.73785 19.4773V18.8389C4.73785 17.7371 5.48129 16.7927 6.49586 16.5829C8.61247 16.1456 10.6853 15.4811 12.6532 14.6154C16.2829 13.0239 19.5541 10.7504 22.3792 7.86472C23.8836 6.32571 25.8777 5.48624 28.003 5.48624H28.1167C30.1809 5.48624 32.0963 6.29073 33.522 7.75978C36.3733 10.6979 39.6794 13.0064 43.3528 14.6241C45.3208 15.4898 47.3936 16.1544 49.5102 16.5916C50.5247 16.8015 51.2682 17.7546 51.2682 18.8477V19.6259C51.5043 23.3073 51.3295 32.7862 44.7697 41.3819H44.7785Z" fill={secondaryColor}/>
<path d="M31.3004 28.8162L36.6356 23.4734C37.5453 22.564 37.5453 21.0862 36.6356 20.1768C35.726 19.2674 34.2566 19.2674 33.347 20.1768L28.0118 25.5196L22.6766 20.1768C21.7669 19.2674 20.2975 19.2674 19.3879 20.1768C18.4783 21.0862 18.4783 22.564 19.3879 23.4734L24.7232 28.8162L19.3879 34.159C18.4783 35.0685 18.4783 36.5463 19.3879 37.4557C20.2975 38.3651 21.7669 38.3651 22.6766 37.4557L28.0118 32.1129L33.347 37.4557C34.2566 38.3651 35.726 38.3651 36.6356 37.4557C37.5453 36.5463 37.5453 35.0685 36.6356 34.159L31.3004 28.8162Z" fill={primaryColor}/>
</svg>

);

export default Shield_xIcon;
