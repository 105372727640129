import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReportForm from './ReportForm';
import { useNavigate, useParams } from 'react-router-dom';
import useCheckPermission from '../../hooks/useCheckPermission';
import reportService from '../../services/reportService';

const ReportView = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const params = useParams();
  const [errors, setErrors] = useState<string[]>([]);

  const [entity, setEntity] = useState<any>({
    id: 0,
    Subject: '',
    Message: '',
    UserId: '',
    Status: '',
    Attachments: [],
  });

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    reportService
      .get(params.id)
      .then((response: any) => {
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setEntity(response.result);
        }
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, [params.id]);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    reportService
      .update(entity)
      .then((response) => {
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Success!', {
            variant: 'success',
          });

          navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        if (setSubmitting) setSubmitting(false);
      });
  };

  return (
    <ReportForm
      entity={entity}
      readonly={true}
      onSubmit={onSubmit}
      handleCancel={() => navigate(-1)}
      errors={errors}
    />
  );
};

export default ReportView;
