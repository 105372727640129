import React from 'react';
const ScreenshotIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M38.5074 23.3484C41.726 23.3484 44.3412 20.7339 44.3412 17.516C44.3412 14.2981 41.726 11.6835 38.5074 11.6835C35.2887 11.6835 32.6736 14.2981 32.6736 17.516C32.6736 20.7339 35.2887 23.3484 38.5074 23.3484ZM38.5074 16.3529C39.1459 16.3529 39.6706 16.8777 39.6706 17.516C39.6706 18.1543 39.1459 18.679 38.5074 18.679C37.8689 18.679 37.3442 18.1543 37.3442 17.516C37.3442 16.8777 37.8689 16.3529 38.5074 16.3529Z" fill={primaryColor}/>
<path d="M49.003 0.0360107H46.6677C45.3819 0.0360107 44.3325 1.07652 44.3325 2.37069C44.3325 3.66486 45.3732 4.70548 46.6677 4.70548H49.003C50.2887 4.70548 51.3383 5.75484 51.3383 7.04026V9.37494C51.3383 10.6604 52.379 11.7097 53.6735 11.7097C54.9679 11.7097 56.0088 10.6691 56.0088 9.37494V7.04026C56.0088 3.18399 52.8688 0.0447637 49.0117 0.0447637L49.003 0.0360107Z" fill={secondaryColor}/>
<path d="M2.35035 11.6922C3.63606 11.6922 4.68555 10.6516 4.68555 9.3574V7.02272C4.68555 5.73729 5.73515 4.68793 7.02085 4.68793H9.35616C10.6419 4.68793 11.6914 3.64731 11.6914 2.35314C11.6914 1.05898 10.6506 0.0184631 9.35616 0.0184631H7.02085C3.16373 0.0184631 0.0238037 3.15769 0.0238037 7.01396V9.34864C0.0238037 10.6341 1.06465 11.6834 2.35911 11.6834L2.35035 11.6922Z" fill={secondaryColor}/>
<path d="M9.34721 51.3304H7.01191C5.7262 51.3304 4.6766 50.2812 4.6766 48.9957V46.661C4.6766 45.3755 3.63586 44.3262 2.34141 44.3262C1.04695 44.3262 0.00610352 45.3668 0.00610352 46.661V48.9957C0.00610352 52.852 3.14603 55.9912 7.00315 55.9912H9.33846C10.6242 55.9912 11.6737 54.9506 11.6737 53.6565C11.6737 52.3623 10.6329 51.3217 9.33846 51.3217L9.34721 51.3304Z" fill={secondaryColor}/>
<path d="M53.6647 44.3349C52.379 44.3349 51.3295 45.3755 51.3295 46.6697V49.0045C51.3295 50.2899 50.2799 51.3392 48.9942 51.3392H46.6589C45.3732 51.3392 44.3237 52.3798 44.3237 53.674C44.3237 54.9681 45.3644 56.0088 46.6589 56.0088H48.9942C52.8513 56.0088 55.9913 52.8695 55.9913 49.0133V46.6785C55.9913 45.393 54.9504 44.3437 53.6559 44.3437L53.6647 44.3349Z" fill={secondaryColor}/>
<path d="M50.2974 40.7061C50.9883 36.5175 51.3382 32.2503 51.3382 28.018C51.3382 23.7857 50.9883 19.4834 50.2974 15.3036C49.4927 10.398 45.6356 6.55046 40.7115 5.74598C32.3237 4.36436 23.6561 4.37311 15.2946 5.74598C10.3879 6.55046 6.53962 10.4068 5.73496 15.3299C5.044 19.5184 4.69409 23.7857 4.69409 28.018C4.69409 32.2503 5.044 36.5524 5.73496 40.7322C6.53962 45.6378 10.3967 49.4854 15.3209 50.2899C19.5104 50.9807 23.7786 51.3304 28.0118 51.3304C32.245 51.3304 36.5482 50.9807 40.729 50.2899C45.6356 49.4854 49.484 45.6292 50.2886 40.7061H50.2974ZM10.3268 16.0819C10.8166 13.1263 13.1168 10.8265 16.0468 10.3368C19.9826 9.68969 24.006 9.35741 28.003 9.35741C32.0001 9.35741 36.0059 9.68968 39.9418 10.328C42.8893 10.8177 45.1983 13.1175 45.6881 16.0469C46.3353 19.9818 46.6676 24.0043 46.6676 28.0005C46.6676 30.3002 46.5278 32.6174 46.3091 34.9172L41.8747 28.254C39.828 25.176 36.3907 23.3397 32.6735 23.3397H23.3413C19.6241 23.3397 16.1868 25.1761 14.1489 28.2453L9.70571 34.8998C9.48705 32.6 9.34719 30.3002 9.34719 28.0005C9.34719 24.0218 9.67079 19.9993 10.318 16.0644L10.3268 16.0819ZM39.968 45.6903C32.1051 46.9845 23.9622 46.9932 16.0731 45.6903C13.7028 45.2968 11.7699 43.7316 10.8428 41.6154L18.0322 30.8424C19.213 29.0673 21.1897 28.018 23.3413 28.018C25.4929 28.018 27.4783 29.076 28.659 30.8511L35.4024 40.9683C36.1196 42.0352 37.5628 42.3325 38.6386 41.6154C39.7056 40.8984 40.003 39.4556 39.2858 38.38L32.5511 28.2627C32.4899 28.1753 32.4199 28.0967 32.3587 28.0092H32.6823C34.8339 28.0092 36.8194 29.0673 37.9914 30.8424L45.1721 41.6329C44.2362 43.7403 42.3208 45.2968 39.9768 45.6816L39.968 45.6903Z" fill={secondaryColor}/>
</svg>

);

export default ScreenshotIcon;
