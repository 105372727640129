import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Box,
  Grid,
  InputAdornment,
  Autocomplete,
} from '@mui/material';
import {
  MapContainer,
  TileLayer,
  Marker,
  useMap,
  Tooltip,
} from 'react-leaflet';
import { useDebouncedCallback } from 'use-debounce';
import L, { Polygon, icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Search } from '@mui/icons-material';
import UpdateMapCenter from '../components/UpdateMapCenter';

interface LocationPickerDialogProps {
  zones?: any[];
  defaultLat?: number;
  defaultLong?: number;
  onLocationSelected: (location: {
    lat: number;
    lng: number;
    address: string;
  }) => void;
}

const MapContent = (props: any) => {
  const {
    onLocationSelected,
    handleSearchByLocation,
    handleSelectLocation,
    selectedLocation,
    zones,
  } = props;
  const map = useMap();

  map.on('click', (e) => {
    handleSearchByLocation(e.latlng.lat, e.latlng.lng)?.then(
      (location: any) => {
        if (location.error) {
          return;
        } else {
          handleSelectLocation({
            lat: e.latlng.lat,
            lon: e.latlng.lng,
            address: location?.display_name ?? '',
          });
        }
      }
    );
  });

  useEffect(() => {
    if (selectedLocation) {
      const currentBounds = map.getBounds();
      const isOutsideBounds =
        selectedLocation.lat < currentBounds.getSouthWest().lat ||
        selectedLocation.lat > currentBounds.getNorthEast().lat ||
        selectedLocation.lng < currentBounds.getSouthWest().lng ||
        selectedLocation.lng > currentBounds.getNorthEast().lng;

      if (isOutsideBounds) {
        map.flyTo([selectedLocation.lat, selectedLocation.lng], 18);
      }
    }
  }, [map, selectedLocation]);

  useEffect(() => {
    if (!zones || zones.length === 0) return;
    zones.forEach((zone: any) => {
      var area = zone.area ? JSON.parse(zone.area) : null;
      if (area) {
        const coords = area.geometry.coordinates[0].map(
          (coord: [number, number]) => [coord[1], coord[0]]
        );

        const polygon = L.polygon(coords, {
          color: zone.color ?? 'blue',
        }).addTo(map);
        polygon.bindTooltip(zone.name);
      }
    });
  }, [map, zones]);

  return (
    <>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {selectedLocation && (
        <Marker
          position={[selectedLocation.lat, selectedLocation.lng]}
          icon={L.icon({
            iconUrl: 'https://i.imgur.com/7teZKif.png',
            iconAnchor: [22, 50],
          })}
          eventHandlers={{
            click: () => {
              onLocationSelected(selectedLocation);
              map.flyTo([selectedLocation.lat, selectedLocation.lng], 18);
            },
          }}
        >
          <Tooltip>{selectedLocation.address}</Tooltip>
        </Marker>
      )}
    </>
  );
};

export const useLocationPicker = () => {
  const [open, setOpen] = useState(false);

  const LocationPickerButton = () => (
    <Button variant="outlined" onClick={() => setOpen(true)}>
      Hely meghatározás
    </Button>
  );

  const LocationPickerDialog = (props: LocationPickerDialogProps) => {
    const { onLocationSelected, zones = [], defaultLat, defaultLong } = props;
    const [searchResults, setSearchResults] = useState<any[]>([]);
    const [searchResult, setSearchResult] = useState<any>(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [center, setCenter] = useState<[number, number]>([0, 0]);

    const [selectedLocation, setSelectedLocation] = useState<{
      lat: number;
      lng: number;
      address: string;
    } | null>({ lat: 47.352746328290266, lng: 19.09674477339744, address: '' });

    const handleSelectLocation = useCallback(
      (location: { lat: number; lon: number; address: any }) => {
        setSelectedLocation({
          lat: location.lat,
          lng: location.lon,
          address: location.address,
        });
      },
      []
    );

    const handleSearch = useDebouncedCallback(async (query: string) => {
      if (query.trim() === '') {
        setSearchResults([]);
        return;
      }

      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?q=${query}&format=json&addressdetails=1`
      );
      const data = await response.json();
      setSearchResults(data);
    }, 300);

    const handleSearchByLocation = useDebouncedCallback(
      async (lat: number, lon: number) => {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lon}`
        );
        if (response.status !== 200) {
          return null;
        }
        const data = await response.json();
        return data;
      },
      300
    );

    const handleClose = () => {
      setOpen(false);
      setSelectedLocation(null);
      setSearchResults([]);
    };

    const handleConfirm = (onLocationSelected: any) => {
      console.log(onLocationSelected);
      if (selectedLocation) {
        onLocationSelected(selectedLocation);
        handleClose();
      }
    };

    useEffect(() => {
      if (defaultLat && defaultLong) {
        setCenter([defaultLat, defaultLong]);
      }
    }, [defaultLat, defaultLong]);

    return (
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
        <DialogTitle>Hely meghatározás</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} pt={2}>
            <Grid container item xs={12} lg={5} spacing={2} maxHeight={'340px'}>
              <Grid item xs={12} sm={12}>
                <TextField
                  label="Search"
                  variant="outlined"
                  fullWidth
                  autoFocus
                  value={searchQuery}
                  onKeyDown={(ev) => {
                    if (ev.key === 'Enter') {
                      handleSearch(searchQuery);
                      ev.preventDefault();
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="search"
                          onClick={() => handleSearch(searchQuery)}
                          edge="end"
                        >
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    handleSearch(e.target.value);
                  }}
                />{' '}
              </Grid>
              {searchResults.length > 0 && (
                <Grid item xs={12}>
                  <div style={{ maxHeight: '340px', overflowY: 'auto' }}>
                    <ul>
                      {searchResults.map((result) => (
                        <li key={result.place_id}>
                          <Button
                            variant="text"
                            style={{ color: 'black' }}
                            onClick={() =>
                              handleSelectLocation({
                                lat: result.lat,
                                lon: result.lon,
                                address: result.display_name,
                              })
                            }
                          >
                            {result.display_name}
                          </Button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} lg={7}>
              <MapContainer
                center={center}
                zoom={16}
                style={{ height: '400px', width: '100%' }}
              >
                <UpdateMapCenter center={center} />
                <MapContent
                  onLocationSelected={onLocationSelected}
                  handleSearchByLocation={handleSearchByLocation}
                  handleSelectLocation={handleSelectLocation}
                  selectedLocation={selectedLocation}
                  zones={zones}
                  defaultLat={defaultLat}
                  defaultLong={defaultLong}
                />{' '}
              </MapContainer>{' '}
            </Grid>
          </Grid>
        </DialogContent>{' '}
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => handleConfirm(onLocationSelected)}
            disabled={!selectedLocation}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>{' '}
      </Dialog>
    );
  };

  return { LocationPickerButton, LocationPickerDialog };
};
