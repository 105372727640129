import axios from "axios";
import { postData } from "./axiosUtils";

const tagService = {
  list: () => {
    return axios.get(`/api/tag/list`).then((response) => {
      return response.data;
    });
  },
  get: (id: any) => {
    return axios.get(`/api/tag/details/${id}`).then((response) => {
      return response.data;
    });
  },
  create: (entity: any) => {
    return postData({
      url: `/api/tag/create`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  update: (entity: any) => {
    return postData({
      url: `/api/tag/update`,
      data: entity,
      method: "PUT",
    }).then((response) => {
      return response;
    });
  },
  delete: (id: any) => {
    return postData({
      url: `/api/tag/delete/${id}`,
      method: "DELETE",
    }).then((response) => {
      return response;
    });
  },
};

export default tagService;
