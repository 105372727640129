import React from 'react';
const BookIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="43" height="57" viewBox="0 0 43 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M40.3248 6.75168C39.8 3.32389 36.8875 0.831726 33.4064 0.831726H12.7214C6.83513 0.831726 1.85848 5.24764 1.13254 11.1151C0.406594 16.9651 0.0480042 22.92 0.0480042 28.8137C0.0480042 35.442 0.511565 42.1402 1.42118 48.7072C2.05966 53.3155 6.03048 56.7957 10.6485 56.7957H33.4239C36.8962 56.7957 39.8 54.2861 40.3335 50.8233C41.453 43.5655 42.0215 36.159 42.0215 28.8137C42.0215 21.4685 41.453 14.0183 40.3248 6.75168ZM5.75059 11.6835C6.18791 8.15074 9.17914 5.49249 12.7126 5.49249H33.3977C34.561 5.49249 35.5318 6.32321 35.7067 7.45998C36.7913 14.4992 37.351 21.6783 37.351 28.8137C37.351 31.918 37.2286 35.031 37.0187 38.144H10.6398C8.60188 38.144 6.72145 38.8435 5.1646 39.9978C4.86723 36.2727 4.70105 32.5388 4.70105 28.8225C4.70105 23.1124 5.05089 17.3498 5.75059 11.6922V11.6835ZM33.4152 52.135H10.6398C8.29576 52.135 6.35409 50.4298 6.03048 48.0689C5.978 47.6666 5.93426 47.2556 5.88178 46.8446L6.03048 46.8621C6.35409 44.5099 8.29576 42.796 10.6398 42.796H36.6251C36.3802 45.2444 36.0916 47.6841 35.7242 50.1063C35.5405 51.278 34.5784 52.1262 33.4239 52.1262L33.4152 52.135Z" fill={secondaryColor}/>
<path d="M11.6981 35.8092C12.9838 35.8092 14.0334 34.7687 14.0334 33.4745V10.1532C14.0334 8.86779 12.9926 7.81848 11.6981 7.81848C10.4036 7.81848 9.36282 8.85905 9.36282 10.1532V33.4745C9.36282 34.7599 10.4036 35.8092 11.6981 35.8092Z" fill={primaryColor}/>
<path d="M30.354 45.1395H21.0217C19.7359 45.1395 18.6864 46.18 18.6864 47.4742C18.6864 48.7684 19.7272 49.809 21.0217 49.809H30.354C31.6397 49.809 32.6893 48.7684 32.6893 47.4742C32.6893 46.18 31.6484 45.1395 30.354 45.1395Z" fill={secondaryColor}/>
</svg>

);

export default BookIcon;
