import {
  DownloadForOffline,
  Edit as EditIcon,
  Visibility,
} from '@mui/icons-material';
import { Grid, Paper, Tooltip } from '@mui/material';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
} from '@mui/x-data-grid';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DataList from '../../../components/DataList';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';
import parkingStatisticService from '../../../services/parking/parkingStatisticService';

const ParkingStatisticsPage = () => {
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [rows, setRows] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    parkingStatisticService
      .list()
      .then((response) => {
        if (!response.hasError) setRows(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          <Tooltip title="Letöltés">
            <DownloadForOffline />
          </Tooltip>
        }
        label={'Letöltés'}
        onClick={() => {
          window.open(params.row.url, '_blank');
        }}
      />
    );

    return actions;
  };

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Megnevezés', flex: 100 },
    {
      field: 'date',
      headerName: 'Dátum',
      flex: 100,
      valueFormatter(params) {
        return params.value ? new Date(params.value).toLocaleDateString() : '';
      },
    },
    { field: 'numberOfParkings', headerName: 'Parkolások száma', flex: 100 },
    {
      field: 'numberOfExtensions',
      headerName: 'Hosszabítások száma',
      flex: 100,
    },
    {
      field: 'actions',
      type: 'actions',
      align: 'right',
      flex: 100,
      getActions: getActions,
    } as GridActionsColDef,
  ];

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12} textAlign={{ xs: 'center', sm: 'left' }}>
          <h2>Parkoló statisztikák</h2>
        </Grid>
        <Grid item xs={12}>
          <DataList rows={rows} columns={columns} />
        </Grid>
        <ConfirmDeleteDialog />
      </Grid>
    </Paper>
  );
};

export default ParkingStatisticsPage;
