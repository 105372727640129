import React, { useState } from 'react';
import {
  Button,
  FormControlLabel,
  Radio,
  TextField,
  Paper,
  Dialog,
  Box,
  Grid,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import DoneIcon from '@mui/icons-material/Done';
import SyncIcon from '@mui/icons-material/Sync';
import {
  Add,
  ChevronRight,
  Delete,
  Edit,
  ExpandMore,
  Web,
  Link,
  MenuOpen,
} from '@mui/icons-material';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { TreeView } from '@mui/x-tree-view';
import { MenuItemEditorDialog } from './MenuItemEditorDialog';
import { ApprovementStatus } from '../contents/ContentPage';
import useCheckPermission from '../../hooks/useCheckPermission';
import ClearIcon from '@mui/icons-material/Clear';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DehazeIcon from '@mui/icons-material/Dehaze';
import DensitySmallIcon from '@mui/icons-material/DensitySmall';

interface MenuItemTreeProps {
  menuItems: any[];
  onAddMenuItem: (menuItem: any) => void;
  onMoveMenuItem: (menuItem: any, newParentId: number) => void;
  pages: any[];
  handleDelete: any;
  handleUpdate: any;
  updateOrder: any;
  handleRecover: any;
  handleReject: any;
  onPageAdded: any;
}

export function MenuItemTree(props: MenuItemTreeProps) {
  const {
    menuItems,
    onAddMenuItem,
    onMoveMenuItem,
    pages,
    handleDelete,
    handleUpdate,
    updateOrder,
    handleRecover,
    handleReject,
    onPageAdded,
  } = props;

  const [open, setOpen] = useState(false);
  const [form, setForm] = useState<any>(null);
  const [filteredMenuItems, setFilteredMenuItems] = useState<any[]>([]);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [selectedItem, setSelectedItem] = useState<string>('');
  const [selectedItemsHamburger, setSelectedItemsHamburger] = useState<any[]>(
    []
  );
  const [selectedItemHamburger, setSelectedItemHamburger] =
    useState<string>('');
  const [searchQuery, setSearchQuery] = useState('');

  let { checkPermission } = useCheckPermission();

  const handleAddMenuItem = (parentId: any, menu: any) => {
    setForm({ parentId, menu: menu });
    setOpen(true);
  };

  const filterMenuItems = (query: string) => {
    const filteredItems = menuItems.filter((item) =>
      item.name.toLowerCase().includes(query.toLowerCase())
    );
    if (query === '') {
      setFilteredMenuItems([]);
    } else {
      setFilteredMenuItems(filteredItems);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    filterMenuItems(event.target.value);
  };

  const handleMoveMenuItem = (menuItem: any, newParentId: number) => {
    // Handle drag and drop to reorder and move menu items
  };

  const handleSubmitModal = (entity: any) => {
    // Send server request to add new menu item
    console.log(entity);

    let parentMenuItems = menuItems.filter(
      (x) => x.parentId == entity.parentId
    );

    const newMenuItem = {
      ...entity,
      order: entity.id > 0 ? entity.order : parentMenuItems.length + 1,
    };

    entity.isUpdate ? handleUpdate(newMenuItem) : onAddMenuItem(newMenuItem);
    if (!entity.isProxy) {
      setOpen(false);
      setForm(null);
    }
  };

  const openNodeItem = (item: any) => {
    if (item) {
      if (item.parentId) {
        let parent = menuItems.find((x) => x.id == item.parentId);
        if (parent) {
          openNodeItem(parent);
        }
      }
      if (item.menu === 1) {
        setSelectedItems((prev) => [...prev, `${item.id}`]);
        setSelectedItem(`${item.id}`);
      } else if (item.menu === 2) {
        setSelectedItemsHamburger((prev) => [...prev, `${item.id}`]);
        setSelectedItemHamburger(`${item.id}`);
      }
    }
  };

  function renderTree(
    menuItems: any[],
    handleDelete: any,
    handleRecover: any,
    handleReject: any,
    handleAddNode: any,
    parentId: any,
    menu: number
  ): React.ReactNode {
    let children =
      parentId == null
        ? menuItems.filter((x) => x.parentId == null)
        : menuItems.filter((x) => x.parentId == parentId);

    return (
      <>
        {children.map((menuItem) => {
          let isLeaf = menuItem.pageId != null || !!menuItem.link;
          let isPage = menuItem.pageId != null;
          let isProxy = menuItem.proxyId > 0;
          let isStatic = menuItem.staticType != null

          return (
            <TreeItem
              key={menuItem.id}
              nodeId={menuItem.id.toString()}
              icon={
                isLeaf ? (
                  isPage ? (
                    <Web color="primary" />
                  ) : (
                    <Link color="primary" />
                  )
                ) : isProxy ? (
                          <ContentCopyIcon color="primary" />
                      ) : isStatic ? <MenuOpen color="primary" />
                          : null
              }
              label={
                <Grid container justifyContent="space-between">
                  <Grid item container xs={5} alignItems="center">
                    <Grid item>
                      {menuItem.name}
                      {isLeaf && (
                        <>
                          {menuItem.pageId == null ? (
                            <Typography display="inline">
                              (
                              <Tooltip title={menuItem.link}>
                                <a href={menuItem.link} target="_blank">
                                  {menuItem.link.length > 100
                                    ? `${menuItem.link.slice(0, 130)}...`
                                    : menuItem.link}
                                </a>
                              </Tooltip>
                              )
                            </Typography>
                          ) : (
                            <Typography display="inline">
                              ({menuItem.pageName})
                            </Typography>
                          )}
                        </>
                      )}
                      {menuItem.status === ApprovementStatus.SentToDelete && (
                        <Typography display="inline" color="error">
                          (Törlés jóváhagyására vár)
                        </Typography>
                      )}
                    </Grid>
                    {!isLeaf && (
                      <Grid item>
                        <IconButton
                          color="primary"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleAddNode(menuItem.id, menu);
                          }}
                        >
                          <Add />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item>
                    <Tooltip title="Mozgatás felfelé">
                      <IconButton
                        color="primary"
                        disabled={menuItem.order == 1}
                        onClick={(event) => {
                          event.stopPropagation();

                          updateOrder({
                            ...menuItem,
                            order: menuItem.order - 1,
                          });
                        }}
                      >
                        <NorthIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Mozgatás lefelé">
                      <IconButton
                        color="primary"
                        disabled={
                          menuItem.order ==
                          menuItems.filter(
                            (x) => x.parentId == menuItem.parentId
                          ).length
                        }
                        onClick={(event) => {
                          event.stopPropagation();

                          console.log(menuItem.order);
                          updateOrder({
                            ...menuItem,
                            order: menuItem.order + 1,
                          });
                        }}
                      >
                        <SouthIcon />
                      </IconButton>
                    </Tooltip>

                    {menuItem.status === ApprovementStatus.DeletedSecondLevel &&
                      checkPermission(['RecoverDeletedMenuItem']) && (
                        <>
                          <Tooltip title="Visszaállítás">
                            <IconButton
                              color="primary"
                              onClick={(event) => {
                                event.stopPropagation();
                                handleRecover(menuItem.id);
                              }}
                            >
                              <SyncIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                    {menuItem.status === ApprovementStatus.SentToDelete &&
                      checkPermission(['ApproveDeletedMenuItem']) && (
                        <>
                          <Tooltip title="Jóváhagyás">
                            <IconButton
                              color="success"
                              onClick={(event) => {
                                event.stopPropagation();
                                handleDelete(menuItem.id);
                              }}
                            >
                              <DoneIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Elutasítás">
                            <IconButton
                              color="error"
                              onClick={(event) => {
                                event.stopPropagation();
                                handleReject(menuItem.id);
                              }}
                            >
                              <ClearIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                    <Tooltip title="Szerkesztés">
                      <IconButton
                        color="primary"
                        onClick={(event) => {
                          event.stopPropagation();

                          setOpen(true);
                          setForm({
                            ...menuItem,
                            isUpdate: true,
                            isProxy: false,
                          });
                        }}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                    {(menuItem.status ===
                      ApprovementStatus.ApprovedSecondLevel ||
                      (menuItem.status ===
                        ApprovementStatus.DeletedSecondLevel &&
                        checkPermission(['DeleteMenuItemPermanently']))) && (
                      <Tooltip title="Törlés">
                        <IconButton
                          color="error"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleDelete(menuItem.id);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              }
              data-id={menuItem.id}
              draggable
              onDragStart={(event) => {
                event.dataTransfer.setData('menuItemId', menuItem.id);
              }}
            >
              {renderTree(
                menuItems,
                handleDelete,
                handleRecover,
                handleReject,
                handleAddNode,
                menuItem.id,
                menu
              )}
            </TreeItem>
          );
        })}
      </>
    );
  }

  return (
    <>
      <Autocomplete
        onChange={(event: any, newValue: any) => {
          debugger;
          if (newValue === null) return;
          if (!selectedItems.includes('-1') && newValue.menu === 1) {
            setSelectedItems([...selectedItems, '-1']);
          } else if (
            !selectedItemsHamburger.includes('-2') &&
            newValue.menu === 2
          ) {
            setSelectedItemsHamburger([...selectedItemsHamburger, '-2']);
          }

          openNodeItem(newValue);
        }}
        getOptionLabel={(option) => `(${option.id})${option.name}`}
        options={filteredMenuItems}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={handleSearchChange}
            value={searchQuery}
            fullWidth
            variant="outlined"
            label="Keresés"
          />
        )}
      />
      <Grid container justifyContent="end">
        {/* <Grid item>
          <Tooltip title="Összes kiynitása">
            <IconButton
              onClick={() => {
                let ids = menuItems.map((x) => x.id.toString());
                setSelectedItems(['-1', ...ids]);
              }}
            >
              <DensitySmallIcon color="primary" />
            </IconButton>
          </Tooltip>
        </Grid> */}
        <Grid item>
          <Tooltip title="Összes összecsukás">
            <IconButton
              onClick={() => {
                setSelectedItems([]);
                setSelectedItemsHamburger([]);
              }}
            >
              <DehazeIcon color="primary" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <TreeView
        defaultCollapseIcon={<ExpandMore />}
        defaultExpandIcon={<ChevronRight />}
        selected={selectedItem}
        onSelect={(value: any) => {
          setSelectedItem(value);
        }}
        expanded={selectedItems}
        onNodeToggle={(event: any, value: any) => {
          event.stopPropagation();

          setSelectedItems([...value]);
        }}
        onDragEnter={(event: any) => event.preventDefault()}
      >
        <TreeItem
          nodeId={'-1'}
          label={
            <>
              {'Főmenű'}
              <IconButton
                color="primary"
                onClick={(event) => {
                  event.stopPropagation();
                  handleAddMenuItem(null, 1);
                }}
              >
                <Add />
              </IconButton>
            </>
          }
        >
          {renderTree(
            menuItems.filter((x) => x.menu == 1),
            handleDelete,
            handleRecover,
            handleReject,
            handleAddMenuItem,
            null,
            1
          )}
        </TreeItem>
      </TreeView>
      <TreeView
        defaultCollapseIcon={<ExpandMore />}
        defaultExpandIcon={<ChevronRight />}
        selected={selectedItemHamburger}
        onSelect={(value: any) => {
          setSelectedItemHamburger(value);
        }}
        expanded={selectedItemsHamburger}
        onNodeToggle={(event: any, value: any) => {
          event.stopPropagation();

          setSelectedItemsHamburger([...value]);
        }}
        onDragEnter={(event: any) => event.preventDefault()}
      >
        <TreeItem
          nodeId={'-2'}
          label={
            <>
              {'Hamburger menű'}
              <IconButton
                color="primary"
                onClick={(event) => {
                  event.stopPropagation();
                  handleAddMenuItem(null, 2);
                }}
              >
                <Add />
              </IconButton>
            </>
          }
        >
          {renderTree(
            menuItems.filter((x) => x.menu == 2),
            handleDelete,
            handleRecover,
            handleReject,
            handleAddMenuItem,
            null,
            2
          )}
        </TreeItem>
      </TreeView>
      <MenuItemEditorDialog
        pages={pages}
        form={form}
        onPageAdded={onPageAdded}
        menuItems={menuItems}
        handleSubmitModal={handleSubmitModal}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
