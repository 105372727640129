import React from 'react';
const Credit_cardIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="43" viewBox="0 0 57 43" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M45.9739 1.31375C34.2364 -0.137813 22.1927 -0.129052 10.4289 1.33126C4.64759 2.0483 0.283203 7.02383 0.283203 12.9V29.5319C0.283203 35.4168 4.7001 40.3924 10.5689 41.1182C16.4202 41.8439 22.3764 42.2025 28.2714 42.2025C34.1664 42.2025 40.2101 41.8352 46.1139 41.1007C51.8952 40.3836 56.2596 35.4081 56.2596 29.5319V12.9C56.2596 7.01509 51.8427 2.03954 45.9739 1.31375ZM51.5978 14.2205V18.8812H4.95376V14.2205H51.5978ZM11.0062 5.95701C16.7175 5.24872 22.5251 4.89023 28.2714 4.89023C34.0177 4.89023 39.7466 5.24001 45.4055 5.93956C47.7232 6.22813 49.6474 7.62723 50.7144 9.55099H5.81963C6.86045 7.63597 8.7409 6.23683 11.0149 5.95701H11.0062ZM45.5366 36.4661C34.1489 37.8827 22.4901 37.8828 11.1374 36.4837C7.60384 36.0464 4.945 33.0558 4.945 29.5231V23.542H51.5891V29.5319C51.5891 33.0559 48.9827 36.0377 45.5279 36.4661H45.5366Z" fill={secondaryColor}/>
<path d="M21.2743 28.2115H11.942C10.6563 28.2115 9.60674 29.252 9.60674 30.5462C9.60674 31.8404 10.6476 32.881 11.942 32.881H21.2743C22.56 32.881 23.6096 31.8404 23.6096 30.5462C23.6096 29.252 22.5688 28.2115 21.2743 28.2115Z" fill={primaryColor}/>
</svg>

);

export default Credit_cardIcon;
