import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useCheckPermission from '../../hooks/useCheckPermission';
import contentService from '../../services/contentService';
import ContentForm from './ContentForm';
import publisherService from '../../services/publisherService';
import tagService from '../../services/tagService';
import dayjs from 'dayjs';
import moment from 'moment';
import locationService from '../../services/locationService';

const ContentCreate = (props: any) => {
  const { onSave = null, navigateBack = true, handleCancel = null } = props;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const [publishers, setPublishers] = useState<any[]>([]);
  const [tags, setTags] = useState<any[]>([]);
  const [locations, setLocations] = useState<any[]>([]);
  const [now, setNow] = useState(moment());

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    publisherService
      .list()
      .then((response: any) => {
        if (!response.hasError) {
          setPublishers(response.records);
        } else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    locationService
      .list()
      .then((response: any) => {
        if (!response.hasError) setLocations(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    tagService
      .list()
      .then((response: any) => {
        if (!response.hasError) setTags(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  const onSubmit = (
    entity: any,
    setSubmitting: any,
    isDraft: boolean = false
  ) => {
    dispatch({ type: 'SHOW_SAVE' });
    console.log(entity);
    contentService
      .create({ ...entity, isDraft })
      .then((response) => {
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Success!', {
            variant: 'success',
          });

          if (onSave) onSave(response.result);
          if (navigateBack) navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        if (setSubmitting) setSubmitting(false);
      });
  };

  return (
    <ContentForm
      entity={{
        htmlContent: '',
        publicationDateUtc: now,
        shortTitle: '',
        title: '',
        description: '',
        shortDescription: '',
        publisherId: 0,
        imageId: '',
        image: { fileName: '', id: 0, url: '' },
        sourceUrl: '',
        isDraft: false,
        sendPushNotification: false,
        eventStartUtc: now,
        eventEndUtc: now,
        eventName: '',
        eventDescription: '',
        eventLocationId: 0,
        hasEvent: false,
        tagIds: [],
        primaryTagId: '',
      }}
      readonly={!checkPermission(['CreateContent'])}
      onSubmit={onSubmit}
      errors={errors}
      publishers={publishers}
      tags={tags}
      locations={locations}
      onLocationAdded={(location: any) => {
        setLocations([...locations, location]);
      }}
      onTagAdded={(tag: any) => {
        setTags([...tags, tag]);
      }}
      handleCancel={() =>
        handleCancel != null ? handleCancel() : navigate(-1)
      }
    />
  );
};

export default ContentCreate;
