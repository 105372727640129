import React from 'react';
const IncognitoIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="52" height="52" viewBox="0 0 52 52" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M46.523 17.7658L44.3802 9.19631C42.902 3.34632 37.1032 -0.466212 31.1382 0.521902L27.2024 1.19521C26.424 1.31763 25.6368 1.31763 24.8846 1.19521L20.9138 0.521902C14.9576 -0.466212 9.15875 3.34633 7.68062 9.20506L5.53779 17.7745C2.50282 18.8064 0.377472 20.2842 0.377472 22.4091C0.377472 28.8974 20.0129 29.4046 26.0304 29.4046C32.0479 29.4046 51.6833 28.8974 51.6833 22.4091C51.6833 20.2842 49.5667 18.8151 46.523 17.7745V17.7658ZM12.1937 10.3331C13.0771 6.81785 16.5581 4.51808 20.1266 5.1127L24.115 5.79476C25.3832 5.99588 26.6689 5.99588 27.9546 5.79476L31.9079 5.12143C35.4764 4.5443 38.9662 6.8266 39.8496 10.3331L41.3977 16.5241C35.6688 15.5447 29.0828 15.4048 26.0216 15.4048C22.9604 15.4048 16.3745 15.536 10.6456 16.5241L12.1937 10.3331ZM26.0216 24.7263C15.8584 24.7263 9.05382 23.4409 6.20252 22.3916C9.05382 21.3422 15.8584 20.0568 26.0216 20.0568C36.1849 20.0568 42.9895 21.3422 45.8408 22.3916C42.9895 23.4409 36.1849 24.7263 26.0216 24.7263Z" fill={secondaryColor}/>
<path d="M37.6892 33.016C34.8992 33.016 32.424 34.2752 30.7097 36.2164C28.4969 35.4294 26.6426 35.3507 26.0304 35.3507C24.3948 35.3507 22.8205 35.648 21.3336 36.2077C19.6194 34.2664 17.1529 33.0248 14.3716 33.0248C9.22874 33.0248 5.03925 37.2133 5.03925 42.355C5.03925 47.4967 9.22874 51.6852 14.3716 51.6852C19.5144 51.6852 23.7039 47.4967 23.7039 42.355C23.7039 41.6817 23.6252 41.0346 23.494 40.405C24.3074 40.1602 25.1558 40.0203 26.0392 40.0203C26.1966 40.0203 27.2374 40.0465 28.5756 40.4312C28.4444 41.0521 28.3744 41.6904 28.3744 42.355C28.3744 47.4967 32.5639 51.6852 37.7067 51.6852C42.8496 51.6852 47.039 47.4967 47.039 42.355C47.039 37.2133 42.8496 33.0248 37.7067 33.0248L37.6892 33.016ZM14.3628 47.007C11.7914 47.007 9.70105 44.9171 9.70105 42.3462C9.70105 39.7754 11.7914 37.6855 14.3628 37.6855C16.9342 37.6855 19.0246 39.7754 19.0246 42.3462C19.0246 44.9171 16.9342 47.007 14.3628 47.007ZM37.6892 47.007C35.1178 47.007 33.0274 44.9171 33.0274 42.3462C33.0274 39.7754 35.1178 37.6855 37.6892 37.6855C40.2607 37.6855 42.351 39.7754 42.351 42.3462C42.351 44.9171 40.2607 47.007 37.6892 47.007Z" fill={secondaryColor}/>
<path d="M28.3569 12.0295H35.354C36.6397 12.0295 37.6893 10.9889 37.6893 9.69474C37.6893 8.40057 36.6484 7.35998 35.354 7.35998H28.3569C27.0712 7.35998 26.0217 8.40057 26.0217 9.69474C26.0217 10.9889 27.0625 12.0295 28.3569 12.0295Z" fill={primaryColor}/>
</svg>

);

export default IncognitoIcon;
