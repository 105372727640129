import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/material';

export default function InviteDialog(props: any) {
  const { open, handleClose, onSubmit } = props;
  const [email, setEmail] = React.useState<string>('');

  return (
    <Dialog
      open={open}
      style={{ minHeight: 400 }}
      fullWidth
      onClose={handleClose}
    >
      <DialogTitle>Felhasználó meghívása</DialogTitle>
      <DialogContent>
        <Box py={3}>
          <TextField
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label="Email"
            name="Email"
            fullWidth
            autoFocus
            required
            type="email"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            onSubmit(email).then((x: { hasError: any }) => {
              if (!x.hasError) setEmail('');
            });
          }}
        >
          Meghívó küldése
        </Button>
        <Button
          color="error"
          variant="outlined"
          onClick={() => {
            handleClose();

            setEmail('');
          }}
        >
          Mégse
        </Button>
      </DialogActions>
    </Dialog>
  );
}
