import React from 'react';
const CleverIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="55" viewBox="0 0 57 55" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M51.851 28.6696C51.781 28.6696 51.6673 28.6696 51.5799 28.6521C49.2708 28.5122 47.4778 26.6059 47.4778 24.2974C47.4778 21.9889 49.437 19.9252 51.851 19.9252C54.265 19.9252 56.2242 21.884 56.2242 24.2974C56.2242 26.7109 54.265 28.6696 51.851 28.6696ZM51.8073 25.1631C51.8073 25.1631 51.8685 25.1631 51.9122 25.1631C52.367 25.1282 52.7256 24.7521 52.7256 24.2887C52.7256 23.8252 52.332 23.4143 51.851 23.4143C51.37 23.4143 50.9764 23.8078 50.9764 24.2887C50.9764 24.7696 51.3437 25.1281 51.8073 25.1544V25.1631Z" fill={secondaryColor}/>
<path d="M42.23 47.164C41.7402 47.164 41.2592 46.9629 40.9093 46.5606C40.2709 45.8348 40.3496 44.7243 41.0843 44.0947C46.2009 39.6438 49.3583 33.4878 49.9793 26.7459C50.0667 25.784 50.9239 25.0757 51.886 25.1631C52.8481 25.2506 53.5565 26.1075 53.469 27.0694C52.7606 34.7032 49.1834 41.69 43.3845 46.7268C43.0522 47.0153 42.6411 47.1552 42.2388 47.1552L42.23 47.164Z" fill={secondaryColor}/>
<path d="M38.7315 54.9027H8.99406C6.09904 54.9027 3.74628 52.5505 3.74628 49.6561V45.2839C3.74628 42.3895 6.09904 40.0373 8.99406 40.0373H10.7433C11.7054 40.0373 12.4926 40.8243 12.4926 41.7862C12.4926 42.748 11.7054 43.535 10.7433 43.535H8.99406C8.03197 43.535 7.2448 44.322 7.2448 45.2839V49.6561C7.2448 50.618 8.03197 51.405 8.99406 51.405H38.7315C39.6936 51.405 40.4808 50.618 40.4808 49.6561V45.2839C40.4808 44.322 39.6936 43.535 38.7315 43.535H36.9823C36.0202 43.535 35.233 42.748 35.233 41.7862C35.233 40.8243 36.0202 40.0373 36.9823 40.0373H38.7315C41.6266 40.0373 43.9793 42.3895 43.9793 45.2839V49.6561C43.9793 52.5505 41.6266 54.9027 38.7315 54.9027Z" fill={secondaryColor}/>
<path d="M23.8628 47.9072C10.8395 47.9072 0.247742 37.3178 0.247742 24.2974C0.247742 11.2771 10.8395 0.687622 23.8628 0.687622C36.886 0.687622 47.4778 11.2771 47.4778 24.2974C47.4778 37.3178 36.886 47.9072 23.8628 47.9072ZM23.8628 4.18537C12.7725 4.18537 3.74627 13.2096 3.74627 24.2974C3.74627 35.3853 12.7725 44.4095 23.8628 44.4095C34.9531 44.4095 43.9793 35.3853 43.9793 24.2974C43.9793 13.2096 34.9531 4.18537 23.8628 4.18537Z" fill={primaryColor}/>
<path d="M8.09318 20.7997C7.90951 20.7997 7.72583 20.7735 7.54216 20.7122C6.6238 20.4062 6.12526 19.4181 6.43138 18.4999C8.37306 12.6499 13.0873 8.19905 19.0348 6.58134C19.9707 6.32775 20.9328 6.87864 21.1777 7.80554C21.4313 8.74119 20.8803 9.70308 19.9532 9.94793C15.1427 11.2596 11.3293 14.8622 9.75498 19.6017C9.51008 20.3362 8.82787 20.7997 8.09318 20.7997Z" fill={primaryColor}/>
<path d="M23.8628 32.1674C19.5246 32.1674 15.9911 28.6346 15.9911 24.2974C15.9911 23.5279 16.1048 22.7759 16.3147 22.0501C16.5946 21.1232 17.5654 20.5986 18.4925 20.8696C19.4196 21.1495 19.9444 22.1201 19.6733 23.047C19.5508 23.4492 19.4896 23.869 19.4896 24.2974C19.4896 26.7109 21.4488 28.6696 23.8628 28.6696C26.2768 28.6696 28.2359 26.7109 28.2359 24.2974C28.2359 21.884 26.2768 19.9252 23.8628 19.9252C23.4342 19.9252 23.0144 19.9865 22.612 20.1089C21.6849 20.38 20.7141 19.8553 20.4342 18.9284C20.1631 18.0015 20.6879 17.0309 21.615 16.7511C22.3409 16.5324 23.1018 16.4275 23.8628 16.4275C28.2009 16.4275 31.7345 19.9602 31.7345 24.2974C31.7345 28.6346 28.2009 32.1674 23.8628 32.1674Z" fill={primaryColor}/>
<path d="M23.8628 26.0463C24.8289 26.0463 25.6121 25.2633 25.6121 24.2974C25.6121 23.3316 24.8289 22.5486 23.8628 22.5486C22.8967 22.5486 22.1135 23.3316 22.1135 24.2974C22.1135 25.2633 22.8967 26.0463 23.8628 26.0463Z" fill={primaryColor}/>
</svg>

);

export default CleverIcon;
