import React from 'react';
const BankIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M51.055 44.6785H6.73746C3.51882 44.6785 0.903687 47.2931 0.903687 50.511C0.903687 53.729 3.51882 56.3435 6.73746 56.3435H51.055C54.2737 56.3435 56.8888 53.729 56.8888 50.511C56.8888 47.2931 54.2737 44.6785 51.055 44.6785ZM51.055 51.674H6.73746C6.09024 51.674 5.57419 51.1494 5.57419 50.511C5.57419 49.8727 6.09898 49.348 6.73746 49.348H51.055C51.7022 49.348 52.2183 49.8727 52.2183 50.511C52.2183 51.1494 51.6935 51.674 51.055 51.674Z" fill={secondaryColor}/>
<path d="M5.57419 33.0223V40.0178C5.57419 41.3032 6.61504 42.3525 7.90949 42.3525C9.20395 42.3525 10.2447 41.312 10.2447 40.0178V33.0223C10.2447 31.7369 9.20395 30.6875 7.90949 30.6875C6.61504 30.6875 5.57419 31.7281 5.57419 33.0223Z" fill={secondaryColor}/>
<path d="M26.5653 33.0223V40.0178C26.5653 41.3032 27.6062 42.3525 28.9006 42.3525C30.1951 42.3525 31.2359 41.312 31.2359 40.0178V33.0223C31.2359 31.7369 30.1951 30.6875 28.9006 30.6875C27.6062 30.6875 26.5653 31.7281 26.5653 33.0223Z" fill={secondaryColor}/>
<path d="M47.5565 33.0223V40.0178C47.5565 41.3032 48.5973 42.3525 49.8918 42.3525C51.1862 42.3525 52.227 41.312 52.227 40.0178V33.0223C52.227 31.7369 51.1862 30.6875 49.8918 30.6875C48.5973 30.6875 47.5565 31.7281 47.5565 33.0223Z" fill={secondaryColor}/>
<path d="M6.58882 28.3615H51.195C54.3262 28.3615 56.8801 25.8082 56.8801 22.6777C56.8801 21.2349 56.3553 19.8708 55.3932 18.8302C52.6731 15.8658 49.7694 12.9977 46.7781 10.3044C44.2417 8.01339 41.574 5.80107 38.8627 3.72865C33.2301 -0.591069 24.545 -0.591069 18.9123 3.72865C16.201 5.80107 13.5421 8.02213 10.9969 10.3044C8.0057 12.9977 5.11069 15.8658 2.38184 18.8302C1.41975 19.8708 0.894958 21.2349 0.894958 22.6777C0.894958 25.8082 3.44888 28.3615 6.58006 28.3615H6.58882ZM5.82789 21.9869C8.45179 19.1362 11.2506 16.3643 14.1281 13.7672C16.5771 11.5636 19.1397 9.42997 21.7549 7.42751C23.7578 5.8885 26.2943 5.04031 28.8919 5.04031C31.4896 5.04031 34.026 5.8885 36.0289 7.42751C38.644 9.42997 41.2067 11.5636 43.6557 13.7672C46.542 16.3643 49.332 19.1275 51.9559 21.9869C52.1658 22.2143 52.2096 22.4853 52.2096 22.6777C52.2096 23.2373 51.7548 23.7008 51.1863 23.7008H6.58006C6.0203 23.7008 5.55676 23.2461 5.55676 22.6777C5.55676 22.4941 5.60047 22.2143 5.81038 21.9869H5.82789Z" fill={secondaryColor}/>
<path d="M17.233 19.0313H40.5594C41.8451 19.0313 42.8947 17.9907 42.8947 16.6965C42.8947 15.4024 41.8539 14.3618 40.5594 14.3618H17.233C15.9473 14.3618 14.8978 15.4024 14.8978 16.6965C14.8978 17.9907 15.9386 19.0313 17.233 19.0313Z" fill={primaryColor}/>
</svg>

);

export default BankIcon;
