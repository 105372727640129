import {
  Configuration,
  PopupRequest,
  RedirectRequest,
} from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: 'd35f29d9-059e-4e96-8b12-c7162b82790a',
    authority:
      'https://login.microsoftonline.com/b00f09fb-461d-4f31-b7d9-55ded6dead85/',
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: 'localStorage',
  },
};

export const loginRequest: RedirectRequest = {
  scopes: ['api://d35f29d9-059e-4e96-8b12-c7162b82790a/access_as_user'],
};
