import React from 'react';
const EnvelopeIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="43" viewBox="0 0 57 43" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M46.2236 1.91403C34.4948 0.462467 22.4512 0.471229 10.6787 1.93154C4.89734 2.64858 0.532898 7.62411 0.532898 13.5003V30.1321C0.532898 36.0171 4.94979 40.9927 10.8186 41.7184C16.6611 42.4442 22.6261 42.8027 28.5211 42.8027C34.4161 42.8027 40.4598 42.4355 46.3636 41.7009C52.1449 40.9839 56.5093 36.0084 56.5093 30.1321V13.5003C56.5093 7.61537 52.0924 2.63982 46.2236 1.91403ZM51.8388 30.1321C51.8388 33.6561 49.2324 36.638 45.7776 37.0664C34.3812 38.4743 22.7311 38.483 11.3783 37.0839C7.85358 36.6467 5.18594 33.6561 5.18594 30.1234V13.4916C5.18594 9.96762 7.79234 6.98577 11.2471 6.55729C16.9585 5.849 22.766 5.49048 28.5123 5.49048C34.2587 5.49048 39.9875 5.84027 45.6464 6.53982C49.1712 6.97703 51.8388 9.9676 51.8388 13.5003V30.1321Z" fill={secondaryColor}/>
<path d="M41.9292 10.5185L32.2733 16.657C29.9905 18.1086 27.043 18.1086 24.7602 16.657L15.1043 10.5185C14.011 9.82767 12.5766 10.1512 11.8856 11.2355C11.1947 12.3198 11.5183 13.7626 12.6028 14.4534L22.2588 20.592C24.1567 21.7987 26.3433 22.4108 28.5211 22.4108C30.6989 22.4108 32.8768 21.8075 34.7835 20.592L44.4394 14.4534C45.5239 13.7626 45.8476 12.3198 45.1566 11.2355C44.4656 10.1512 43.0313 9.82767 41.938 10.5185H41.9292Z" fill={primaryColor}/>
</svg>

);

export default EnvelopeIcon;
