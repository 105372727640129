import React from 'react';
const PacifierIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="59" height="60" viewBox="0 0 59 60" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M28.3521 37.091C28.1509 36.7324 27.941 36.3827 27.7048 36.0329C27.4774 35.6919 27.2325 35.3596 26.9701 35.0273C26.9089 35.1147 26.8477 35.1935 26.769 35.2722C26.1567 35.8843 25.1597 35.8843 24.5387 35.2722C23.9264 34.6601 23.9264 33.6632 24.5387 33.0423C24.6174 32.9636 24.6961 32.9024 24.7836 32.8412C24.4599 32.5789 24.1276 32.3341 23.7777 32.1067C23.4366 31.8706 23.078 31.6608 22.7194 31.4596C17.2442 28.4253 10.186 29.2298 5.54167 33.8731C-0.0734608 39.487 -0.0734608 48.6335 5.54167 54.2474C11.1568 59.8613 20.3055 59.8613 25.9206 54.2474C30.5649 49.6042 31.3695 42.5562 28.3346 37.0735L28.3521 37.091ZM7.25595 52.5685C2.57668 47.8902 2.57668 40.2739 7.25595 35.5957C11.1656 31.687 17.1218 31.0398 21.7136 33.6631C22.1159 33.8905 22.5095 34.1528 22.8944 34.4326C23.3667 34.7824 23.8127 35.1672 24.2413 35.5957C24.6699 36.0242 25.0547 36.4701 25.4046 36.9423C25.6844 37.327 25.9468 37.7206 26.1742 38.1228C28.7981 42.7049 28.1509 48.6597 24.2413 52.5772C19.562 57.2555 11.944 57.2555 7.2647 52.5772L7.25595 52.5685Z" fill={secondaryColor} stroke={secondaryColor} strokeWidth="2" strokeMiterlimit="10"/>
<path d="M27.7048 36.0418C27.766 35.9718 27.8273 35.9018 27.8885 35.8231C28.1596 35.4646 28.3258 35.0536 28.3958 34.6252C28.5357 33.7682 28.2821 32.8675 27.6261 32.2117C26.9701 31.5559 26.0605 31.2935 25.2034 31.4335C24.7835 31.5034 24.3637 31.6695 24.0051 31.9406C23.9264 31.9931 23.8564 32.0543 23.7865 32.1243C23.7515 32.1505 23.7252 32.1855 23.6903 32.2117C23.0868 32.8151 22.8156 33.637 22.8856 34.4327C22.9468 35.0536 23.2092 35.6657 23.6903 36.1379C24.1626 36.6101 24.7748 36.8812 25.3958 36.9337C26.1917 37.0037 27.0139 36.7413 27.6173 36.1292C27.6523 36.1029 27.6786 36.068 27.7048 36.033V36.0418ZM24.871 32.8063L27.0226 34.9575C27.0226 34.9575 26.9876 35.0099 26.9701 35.0361C26.9089 35.1236 26.8477 35.2023 26.769 35.281C26.1567 35.8931 25.1596 35.8931 24.5386 35.281C23.9264 34.6689 23.9264 33.672 24.5386 33.0511C24.6174 32.9724 24.6961 32.9112 24.7835 32.85C24.8098 32.8326 24.836 32.815 24.8623 32.7975L24.871 32.8063Z" fill={secondaryColor} stroke={secondaryColor} strokeWidth="2" strokeMiterlimit="10"/>
<path d="M28.7456 36.68C29.0168 36.3478 29.2267 35.9893 29.3666 35.6045C29.5416 35.1498 29.629 34.6601 29.629 34.1704L25.6494 30.1917C25.1596 30.1917 24.6786 30.2792 24.215 30.4541C23.8302 30.6027 23.4716 30.8125 23.1392 31.0749C23.0343 31.1623 22.9293 31.2498 22.8331 31.346C22.7981 31.381 22.7544 31.4247 22.7194 31.4597C22.1422 32.0893 21.8011 32.85 21.6961 33.6458C21.5387 34.8263 21.9235 36.0679 22.8244 36.9686C23.7252 37.8692 24.9672 38.254 26.148 38.0966C26.9351 37.9917 27.7048 37.6506 28.3346 37.0735C28.3695 37.0385 28.4133 37.0035 28.4483 36.9598C28.5445 36.8636 28.6407 36.7587 28.7194 36.6538L28.7456 36.68ZM26.769 35.281C26.1567 35.8931 25.1596 35.8931 24.5387 35.281C23.9264 34.6689 23.9264 33.672 24.5387 33.0511C24.6174 32.9724 24.6961 32.9112 24.7836 32.85C24.8098 32.8325 24.836 32.815 24.8623 32.7975C25.4658 32.4477 26.2442 32.5352 26.7602 33.0511C27.2762 33.567 27.355 34.3453 27.0139 34.9487C26.9964 34.9749 26.9789 35.0011 26.9614 35.0273C26.9002 35.1148 26.8389 35.1935 26.7602 35.2722L26.769 35.281Z" fill={secondaryColor} stroke={secondaryColor} strokeWidth="2" strokeMiterlimit="10"/>
<path d="M57.8884 14.8104C57.8884 11.3738 56.5414 8.14714 54.1187 5.71621L54.1012 5.6987C51.6697 3.27651 48.4336 1.94744 44.9876 1.95619C41.5415 1.95619 38.3054 3.30278 35.8826 5.73371C33.7136 7.91106 32.3929 10.8055 32.1655 13.866C32.078 15.1077 31.8069 18.6754 27.285 23.7734C27.0314 24.0619 26.7602 24.3592 26.4716 24.6566C26.2792 24.8577 26.078 25.0675 25.8681 25.2774L25.7282 25.4173C25.7282 25.4173 25.6669 25.4873 25.632 25.5222L34.3083 34.1967C34.3083 34.1967 34.3783 34.1354 34.4133 34.1004L34.5532 33.9606C34.7631 33.7507 34.973 33.5495 35.1742 33.3572C35.4716 33.0686 35.7689 32.7976 36.0576 32.544C41.1479 28.0319 44.7339 27.7608 45.9846 27.6646C49.0546 27.4285 51.9409 26.108 54.1187 23.9307C56.5589 21.491 57.8971 18.2557 57.8884 14.8104ZM45.801 25.2686C44.3316 25.3823 40.0984 25.6971 34.352 30.8475C34.0634 31.1099 33.766 31.381 33.4687 31.6608C33.3025 31.8182 33.1275 31.993 32.9526 32.1592L27.6699 26.8777C27.836 26.7028 28.0022 26.5365 28.1684 26.3617C28.4483 26.0644 28.7281 25.7758 28.9905 25.4785C32.4541 21.6222 34.2821 17.8709 34.5619 14.0496C34.7456 11.5575 35.8214 9.20518 37.5882 7.43007C39.5561 5.45384 42.18 4.36964 44.9876 4.36089C47.7951 4.36089 50.4278 5.43643 52.4044 7.40392L52.4219 7.42142C54.3986 9.39765 55.4831 12.0209 55.4919 14.8191C55.4919 17.6173 54.4073 20.2581 52.4219 22.2343C50.6552 24.0007 48.3024 25.085 45.8097 25.2686H45.801Z" fill={primaryColor} stroke={primaryColor} strokeWidth="2" strokeMiterlimit="10"/>
<path d="M43.5707 40.0641L19.7632 16.262C18.1889 14.688 15.6175 14.688 14.0431 16.262C12.4688 17.836 12.4688 20.4067 14.0431 21.9807L37.8506 45.783C39.4249 47.357 41.9963 47.357 43.5707 45.783C45.145 44.209 45.145 41.6381 43.5707 40.0641ZM39.5474 44.0778L15.7399 20.2756C15.1014 19.6373 15.1014 18.5967 15.7399 17.9583C16.3784 17.32 17.4192 17.32 18.0577 17.9583L41.8651 41.7605C42.5036 42.3988 42.5036 43.4394 41.8651 44.0778C41.2267 44.7161 40.1858 44.7161 39.5474 44.0778Z" fill={secondaryColor}/>
<path d="M40.7106 47.8379C39.4512 47.8379 38.1917 47.357 37.2296 46.4038L13.4221 22.6017C11.5067 20.6866 11.5067 17.5648 13.4221 15.6411C15.3376 13.726 18.46 13.726 20.3754 15.6411L44.1829 39.4433C46.0984 41.3584 46.0984 44.4801 44.1829 46.3951C43.2208 47.357 41.9613 47.8292 40.7019 47.8292L40.7106 47.8379ZM16.9032 15.9559C16.0898 15.9559 15.2763 16.262 14.6641 16.8828C13.4309 18.1158 13.4309 20.127 14.6641 21.3687L38.4716 45.1708C39.7048 46.4125 41.7164 46.4038 42.9584 45.1708C44.2004 43.9379 44.1917 41.9267 42.9584 40.685L19.151 16.8828C18.53 16.262 17.7166 15.9559 16.9119 15.9559H16.9032ZM40.7106 45.4332C40.0372 45.4332 39.4074 45.1708 38.9351 44.6986L15.1277 20.8964C14.6554 20.4242 14.393 19.786 14.393 19.1127C14.393 18.4393 14.6554 17.8097 15.1277 17.3375C15.6 16.8653 16.2297 16.603 16.9032 16.603C17.5766 16.603 18.2064 16.8653 18.6787 17.3375L42.4861 41.1397C42.9584 41.6119 43.2208 42.2503 43.2208 42.9148C43.2208 43.5881 42.9584 44.2178 42.4861 44.69C42.0138 45.1622 41.3841 45.4245 40.7106 45.4245V45.4332ZM16.9032 18.3519C16.702 18.3519 16.5096 18.4305 16.3609 18.5792C16.2122 18.7278 16.1335 18.9114 16.1335 19.1213C16.1335 19.3224 16.2122 19.5237 16.3609 19.6636L40.1683 43.4657C40.457 43.7543 40.9643 43.7543 41.2529 43.4657C41.4016 43.3171 41.4803 43.1334 41.4803 42.9236C41.4803 42.7225 41.4016 42.5213 41.2529 42.3814L17.4454 18.5792C17.2967 18.4305 17.1043 18.3519 16.9032 18.3519Z" fill={secondaryColor}/>
</svg>

);

export default PacifierIcon;
