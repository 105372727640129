import {
  ChonkyActions,
  FileBrowser,
  FileContextMenu,
  FileData,
  FileNavbar,
  FileToolbar,
  FileList,
} from '@aperturerobotics/chonky';
import { useMemo, useState } from 'react';
import useGalleryFileMap, { CustomFileData } from './useGalleryFileMap';
import { useFileActionHandler } from './useGalleryFileHandler';
import { useFolderChain } from './useFolderChain';
import { useFiles } from './useFiles';
import { ChonkyIconFA } from '@aperturerobotics/chonky-icon-fontawesome';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import useImageSelect from '../../hooks/useImageSelect';

type ImageBrowserGalleryProps = {
  publisherId: number;
  handleSelect: (item: any) => void;
};

const ImageBrowserGallery = (props: ImageBrowserGalleryProps) => {
  const { publisherId, handleSelect } = props;

  const [openFileUploadDialog, setOpenFileUploadDialog] =
    useState<boolean>(false);

  const {
    fileBrowserData,
    currentFolderId,
    setCurrentFolderId,
    resetFileMap,
    deleteFiles,
    setTimeStamp,
    moveFiles,
    createFolder,
  } = useGalleryFileMap(publisherId);

  const selectFile = (fileData: CustomFileData) => {
    handleSelect(fileData.image);
  };

  const files = useFiles(fileBrowserData.fileMap, currentFolderId);
  const folderChain = useFolderChain(fileBrowserData.fileMap, currentFolderId);
  const handleFileAction = useFileActionHandler(
    setCurrentFolderId,
    deleteFiles,
    moveFiles,
    createFolder,
    selectFile,
    () => {
      setOpenFileUploadDialog(true);
    }
  );

  const { ImageSelectComponent, uploadImage, uploading, error, uploadedFile } =
    useImageSelect();

  const fileActions = useMemo(
    () => [
      ChonkyActions.CreateFolder,
      ChonkyActions.DeleteFiles,
      ChonkyActions.MoveFiles,
      ChonkyActions.OpenFiles,
      ChonkyActions.EnableCompactView,
      ChonkyActions.EnableGridView,
      ChonkyActions.EnableListView,
      ChonkyActions.DeleteFiles,
      ChonkyActions.UploadFiles,
    ],
    []
  );

  return (
    <div style={{ height: '600px' }}>
      <FileBrowser
        instanceId={'gallery'}
        files={files}
        folderChain={folderChain}
        onFileAction={handleFileAction}
        fileActions={fileActions}
        defaultFileViewActionId="enable_grid_view"
        iconComponent={ChonkyIconFA}
        thumbnailGenerator={(file: FileData) =>
          file.thumbnailUrl ?? file.image.url1_1
        }
      >
        <FileNavbar />
        <FileToolbar />
        <FileList />
        <FileContextMenu />
      </FileBrowser>
      <Dialog
        maxWidth="lg"
        fullWidth
        open={openFileUploadDialog}
        onClose={() => setOpenFileUploadDialog(false)}
      >
        <DialogTitle>Kép feltöltése</DialogTitle>
        <DialogContent>
          <ImageSelectComponent
            hideBrowser={true}
            onSelect={(image: any) => {}}
            alt="kép"
            onUploaded={(image: any) => {
              setTimeStamp(new Date());
              setOpenFileUploadDialog(false);
            }}
            publisherId={publisherId}
            currentFolderId={currentFolderId}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenFileUploadDialog(false)}>Mégse</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ImageBrowserGallery;
