import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import parkingPlaceService from '../../../services/parking/parkingPlaceService';
import publisherService from '../../../services/publisherService';
import ParkingPlaceForm from './ParkingPlaceForm';
import { ParkingPlaceType } from '../../../types/ParkingPlaceType';
import parkingZoneService from '../../../services/parking/parkingZoneService';

const ParkingPlaceCreate = (props: any) => {
  const { onSave = null, navigateBack = true, handleCancel = null } = props;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);

  const { checkPermission } = useCheckPermission();
  const [zones, setZones] = useState<any[]>([]);

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    parkingZoneService
      .list()
      .then((response: any) => {
        if (!response.hasError) {
          setZones(response.records);
        } else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    parkingPlaceService
      .create(entity)
      .then((response) => {
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Success!', {
            variant: 'success',
          });

          if (onSave) onSave(response.result);
          if (navigateBack) navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        if (setSubmitting) setSubmitting(false);
      });
  };

  return (
    <ParkingPlaceForm
      entity={{
        name: '',
        latitude: 0,
        longitude: 0,
        type: ParkingPlaceType.Normal,
      }}
      readonly={!checkPermission(['CreateParkingPlace'])}
      onSubmit={onSubmit}
      errors={errors}
      handleCancel={() =>
        handleCancel != null ? handleCancel() : navigate(-1)
      }
      zones={zones}
    />
  );
};

export default ParkingPlaceCreate;
