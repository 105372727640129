import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ParkingPlaceForm from './ParkingPlaceForm';
import { useNavigate, useParams } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import parkingPlaceService from '../../../services/parking/parkingPlaceService';
import publisherService from '../../../services/publisherService';
import { ParkingPlaceType } from '../../../types/ParkingPlaceType';
import parkingZoneService from '../../../services/parking/parkingZoneService';

const ParkingPlaceEdit = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const params = useParams();
  const [errors, setErrors] = useState<string[]>([]);
  const [entity, setEntity] = useState<any>({
    name: '',
    latitude: 0,
    longitude: 0,
    type: ParkingPlaceType.Normal,
  });

  const { checkPermission } = useCheckPermission();
  const [zones, setZones] = useState<any[]>([]);

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    parkingZoneService
      .list()
      .then((response: any) => {
        if (!response.hasError) {
          setZones(response.records);
        } else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  useEffect(() => {
    parkingPlaceService.get(params.id).then((response: any) => {
      if (response.hasError) {
        setErrors(response.errorMessages);
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setEntity(response.result);
      }
    });
  }, [params.id]);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    parkingPlaceService
      .update(entity)
      .then((response) => {
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Success!', {
            variant: 'success',
          });

          navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        if (setSubmitting) setSubmitting(false);
      });
  };

  return (
    <ParkingPlaceForm
      entity={entity}
      readonly={!checkPermission(['EditParkingPlace'])}
      onSubmit={onSubmit}
      handleCancel={() => navigate(-1)}
      errors={errors}
      zones={zones}
    />
  );
};

export default ParkingPlaceEdit;
