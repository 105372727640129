import { Collapse, Grid } from '@mui/material';
import { useState } from 'react';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

export const CollapseMenu = (props: any) => {
  const [open, setOpen] = useState(!props.collapsed);

  return (
    <Box sx={{ bgcolor: '#FFFFFFF' }}>
      <ListItem>
        <ListItemText
          onClick={() => setOpen((x) => !x)}
          sx={{ color: '#000000', userSelect: 'none' }}
        >
          <Grid container justifyContent="space-between">
            <Grid item>{props.minimal ? props.icon : props.id}</Grid>
            <Grid> {open ? <ExpandLess /> : <ExpandMore />}</Grid>
          </Grid>
        </ListItemText>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {props.children}
      </Collapse>
      <Divider sx={{ mt: 2 }} />
    </Box>
  );
};
