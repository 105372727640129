import React from 'react';
import { Alert, Badge, Box, Chip, Typography } from '@mui/material';
import { StringDiff } from 'react-string-diff';

interface InputDiffProps {
  oldValue: string;
  newValue: string;
  hideUnchanged?: boolean;
}

export const InputDiff: React.FC<InputDiffProps> = ({
  oldValue,
  newValue,
  hideUnchanged = true,
}) => {
  if (hideUnchanged == true && oldValue === newValue) {
    return null;
  }

  return (
    <Box pt={1}>
      <Alert severity="warning">
        <StringDiff oldValue={oldValue} newValue={newValue} />
      </Alert>
    </Box>
  );
};
