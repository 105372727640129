import React from 'react';
const Person_circleIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="56" height="57" viewBox="0 0 56 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M28.0216 0.630005C12.5932 0.630005 0.0334473 13.1869 0.0334473 28.612C0.0334473 44.0371 12.5932 56.594 28.0216 56.594C43.4501 56.594 56.0098 44.0371 56.0098 28.612C56.0098 13.1869 43.4501 0.630005 28.0216 0.630005ZM28.0216 51.9333C15.1646 51.9333 4.69525 41.475 4.69525 28.612C4.69525 15.749 15.1558 5.29076 28.0216 5.29076C40.8875 5.29076 51.3481 15.749 51.3481 28.612C51.3481 41.475 40.8875 51.9333 28.0216 51.9333Z" fill={secondaryColor}/>
<path d="M28.0217 26.2773C33.1645 26.2773 37.354 22.0887 37.354 16.947C37.354 11.8053 33.1645 7.61676 28.0217 7.61676C22.8788 7.61676 18.6894 11.8053 18.6894 16.947C18.6894 22.0887 22.8788 26.2773 28.0217 26.2773ZM28.0217 12.2863C30.5931 12.2863 32.6835 14.3762 32.6835 16.947C32.6835 19.5179 30.5931 21.6078 28.0217 21.6078C25.4502 21.6078 23.3599 19.5179 23.3599 16.947C23.3599 14.3762 25.4502 12.2863 28.0217 12.2863Z" fill={primaryColor}/>
<path d="M44.4472 29.2329C41.4822 26.251 36.9779 25.5165 33.2257 27.3878C29.9808 29.0143 26.045 29.0143 22.8001 27.3791C19.0654 25.499 14.5698 26.2248 11.6136 29.1717C8.85848 31.9174 8.22874 35.7649 9.96051 39.2189C10.1967 39.6911 10.4765 40.1546 10.7914 40.6093C14.736 46.2407 21.182 49.5985 28.0216 49.5985C34.8613 49.5985 41.3335 46.2319 45.2344 40.6093C45.5405 40.1721 45.8116 39.7261 46.039 39.2714C47.7883 35.8436 47.1761 31.9961 44.4385 29.2416L44.4472 29.2329ZM41.8933 37.1378C41.7534 37.4089 41.5959 37.6712 41.4123 37.9335C38.3773 42.3144 33.3744 44.929 28.0304 44.929C22.6864 44.929 17.6923 42.3144 14.6223 37.9248C14.4386 37.6624 14.2812 37.3914 14.1412 37.1203C12.978 34.7943 14.2987 33.0891 14.9284 32.4683C15.7768 31.6288 17.0625 30.9992 18.4882 30.9992C19.2141 30.9992 19.9663 31.1566 20.7185 31.5414C25.2753 33.8324 30.768 33.8411 35.3336 31.5589C37.5814 30.4396 39.8817 31.2266 41.1586 32.512C41.7796 33.1329 43.0915 34.8293 41.9108 37.1378H41.8933Z" fill={primaryColor}/>
</svg>

);

export default Person_circleIcon;
