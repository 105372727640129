import { useMap } from 'react-leaflet';
import { useEffect } from 'react';
const UpdateMapCenter = ({ center }: { center: [number, number] }) => {
  const map = useMap();

  useEffect(() => {
    if (center[0] !== 0 && center[1] !== 0) {
      map.setView(center, map.getZoom());
    }
  }, [center, map]);

  return null;
};
export default UpdateMapCenter;
