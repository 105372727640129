export const getHtmlContent = async (
  htmlContentUrl: string
): Promise<string> => {
  if (!htmlContentUrl) return '';
  const response = await fetch(htmlContentUrl);
  if (!response.ok) {
    return 'Tartalom letöltése sikertelen!';
  }

  return await response.text();
};
