const initialState = {
  pendingItemsApprovalCount: 0,
  functionDescriptors: [],
};

export const appReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "LOAD_APP_STATUS":
      return {
        ...state,
        pendingItemsApprovalCount: action.payload.pendingItemsApprovalCount,
      };
    case "LOAD_FUNCTION_DESCRIPTORS":
      return {
        ...state,
        functionDescriptors: action.payload,
      };
    case "DECREASE_APPROVAL_COUNT":
      return {
        ...state,
        pendingItemsApprovalCount: state.pendingItemsApprovalCount - 1,
      };
    default:
      return state;
  }
};
