import React from 'react';
const MagnifyIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M55.0613 11.2807C54.3354 6.19143 50.2421 2.13403 45.108 1.40825C33.9565 -0.165741 22.4988 -0.165732 11.3385 1.417C6.24817 2.14278 2.18984 6.23514 1.4639 11.3681C0.676732 16.9295 0.274414 22.5871 0.274414 28.201C0.274414 33.8149 0.67674 39.5337 1.47265 45.1301C2.1986 50.2193 6.29188 54.2767 11.426 55.0025C16.9886 55.7895 22.6475 56.1918 28.2626 56.1918C33.8777 56.1918 39.5978 55.7895 45.1955 54.9938C50.2858 54.268 54.3441 50.1756 55.0701 45.0427C55.8573 39.4813 56.2596 33.8237 56.2596 28.2098C56.2596 22.5959 55.8572 16.8771 55.0613 11.2807ZM50.452 44.3869C50.0147 47.4824 47.5832 49.9395 44.5395 50.3767C33.8165 51.8983 22.8049 51.907 12.0819 50.3855C8.98574 49.9483 6.52805 47.5173 6.09073 44.4743C5.32106 39.0965 4.93621 33.6313 4.93621 28.201C4.93621 22.7708 5.32104 17.3667 6.07322 12.0239C6.51054 8.92841 8.94202 6.47125 11.9857 6.03403C17.3647 5.26452 22.8312 4.87976 28.2626 4.87976C33.6941 4.87976 39.0993 5.26452 44.4433 6.01654C47.5395 6.45375 49.9972 8.88469 50.4346 11.9277C51.2042 17.3055 51.589 22.7708 51.589 28.201C51.589 33.6313 51.2042 39.0353 50.452 44.3781V44.3869Z" fill={secondaryColor}/>
<path d="M44.6007 9.55801H35.2684C33.9827 9.55801 32.9332 10.5986 32.9332 11.8928C32.9332 13.1869 33.9739 14.2275 35.2684 14.2275H38.9681L31.2888 21.9051C30.3792 22.8145 30.3792 24.2923 31.2888 25.2017C32.1985 26.1111 33.6765 26.1111 34.5862 25.2017L42.2655 17.5241V21.223C42.2655 22.5084 43.3062 23.5578 44.6007 23.5578C45.8952 23.5578 46.936 22.5172 46.936 21.223V11.8928C46.936 10.6073 45.8952 9.55801 44.6007 9.55801Z" fill={primaryColor}/>
<path d="M21.9566 31.2266L14.2773 38.9041V35.2053C14.2773 33.9199 13.2365 32.8705 11.942 32.8705C10.6476 32.8705 9.60674 33.9111 9.60674 35.2053V44.5355C9.60674 45.8209 10.6476 46.8703 11.942 46.8703H21.2743C22.56 46.8703 23.6096 45.8297 23.6096 44.5355C23.6096 43.2414 22.5688 42.2008 21.2743 42.2008H17.5747L25.2539 34.5232C26.1635 33.6138 26.1635 32.136 25.2539 31.2266C24.3443 30.3172 22.8662 30.3172 21.9566 31.2266Z" fill={primaryColor}/>
</svg>

);

export default MagnifyIcon;
