import React from 'react';
const Map_pinIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="43" height="56" viewBox="0 0 43 56" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M21.5211 0.0360107C9.94975 0.0360107 0.529968 9.4537 0.529968 21.0225C0.529968 24.9925 1.22095 28.8312 2.57663 32.4251C3.98479 36.1764 5.76903 39.8141 7.87689 43.2244C10.1509 46.9145 12.8098 50.3773 15.7835 53.5253C17.2704 55.0905 19.3608 55.9913 21.5211 55.9913C23.6815 55.9913 25.7718 55.0905 27.2587 53.5253C30.2412 50.3773 32.9001 46.9058 35.1654 43.2244C37.2645 39.8053 39.0488 36.1764 40.4657 32.4251C41.8213 28.8224 42.5123 24.9837 42.5123 21.0225C42.5123 9.4537 33.0925 0.0360107 21.5211 0.0360107ZM36.1013 30.7812C34.7893 34.2527 33.1362 37.6193 31.1945 40.7848C29.0954 44.2038 26.629 47.413 23.8651 50.3249C22.6319 51.6278 20.4016 51.6278 19.1684 50.3249C16.4045 47.4043 13.9468 44.1951 11.8389 40.7848C9.89726 37.6281 8.24422 34.2614 6.93228 30.7812C5.77776 27.7119 5.19177 24.4241 5.19177 21.0225C5.19177 12.0245 12.5124 4.69672 21.5211 4.69672C30.5298 4.69672 37.8505 12.0245 37.8505 21.0225C37.8505 24.4241 37.2645 27.7119 36.11 30.7812H36.1013Z" fill={secondaryColor}/>
<path d="M21.5211 9.35742C15.0926 9.35742 9.8623 14.5866 9.8623 21.0137C9.8623 27.4409 15.0926 32.67 21.5211 32.67C27.9496 32.67 33.1799 27.4409 33.1799 21.0137C33.1799 14.5866 27.9496 9.35742 21.5211 9.35742ZM21.5211 28.018C17.664 28.018 14.524 24.8788 14.524 21.0225C14.524 17.1662 17.664 14.027 21.5211 14.027C25.3782 14.027 28.5181 17.1662 28.5181 21.0225C28.5181 24.8788 25.3782 28.018 21.5211 28.018Z" fill={primaryColor}/>
</svg>

);

export default Map_pinIcon;
