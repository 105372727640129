import {
  GridColDef,
  GridActionsCellItem,
  GridActionsColDef,
} from '@mui/x-data-grid';
import {
  Paper,
  IconButton,
  Grid,
  Tooltip,
  Select,
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
  DialogContent,
  useMediaQuery,
  Box,
  Tab,
  Tabs,
  Badge,
  SpeedDial,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Visibility,
  Publish,
  Image,
  HideImage,
} from '@mui/icons-material';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useConfirmDeleteDialog from '../../hooks/useConfirmDeleteDialog';
import useCheckPermission from '../../hooks/useCheckPermission';
import DataList from '../../components/DataList';
import pageService from '../../services/pageService';
import ApproveDialog from '../contents/ApproveDialog';
import CheckIcon from '@mui/icons-material/Check';
import { ApprovementStatus } from '../contents/ContentPage';
import contentService from '../../services/contentService';

const PagePage = () => {
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [rows, setRows] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const [filters, setFilters] = useState<any>(ApprovementStatus.Draft);
  const [tabValue, setTabValue] = useState(0);
  const isSmallScreen = useMediaQuery('(max-width:900px)');
  const [originalRows, setOriginalRows] = useState<any>({
    draftPages: [],
    sendToApprovementPages: [],
    rejectedPages: [],
    approvedPages: [],
    deletedPages: [],
  });
  const [filteredRows, setFilteredRows] = useState<any[]>([]);

  const [openApproveDialog, setOpenApproveDialog] = useState({
    open: false,
    id: '',
    comment: '',
    isSubmitting: false,
    status: ApprovementStatus.Draft,
  });

  const { checkPermission } = useCheckPermission();

  const handleRecover = (id: any) => {
    dispatch({ type: 'SHOW_QUERY' });
    pageService
      .recover(id)
      .then((response) => {
        if (!response.hasError) {
          enqueueSnackbar('Sikeres visszaállítás!', {
            variant: 'success',
          });
          fetchRows();
        } else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  };

  useEffect(() => {
    if (originalRows) {
      switch (filters) {
        case ApprovementStatus.Draft:
          setFilteredRows(originalRows.draftPages ?? []);
          break;
        case ApprovementStatus.SentToApproval:
          setFilteredRows(originalRows.sendToApprovementPages ?? []);
          break;
        case ApprovementStatus.Rejected:
          setFilteredRows(originalRows.rejectedPages ?? []);
          break;
        case ApprovementStatus.ApprovedSecondLevel:
          setFilteredRows(originalRows.approvedPages ?? []);
          break;
        case ApprovementStatus.DeletedSecondLevel:
          setFilteredRows(originalRows.deletedPages ?? []);
          break;
      }
    }
  }, [filters, originalRows]);

  useEffect(() => {
    fetchRows();
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    switch (newValue) {
      case 0:
        setFilters(ApprovementStatus.Draft);
        break;
      case 1:
        setFilters(ApprovementStatus.SentToApproval);
        break;
      case 2:
        setFilters(ApprovementStatus.ApprovedSecondLevel);
        break;
      case 3:
        setFilters(ApprovementStatus.Rejected);
        break;
      case 4:
        setFilters(ApprovementStatus.DeletedSecondLevel);
        break;
    }
  };

  const fetchRows = async () => {
    dispatch({ type: 'SHOW_QUERY' });
    pageService
      .listAllStatus()
      .then((response) => {
        if (!response.hasError) {
          setOriginalRows({
            draftPages: response.draftPages,
            sendToApprovementPages: response.sendToApprovementPages,
            rejectedPages: response.rejectedPages,
            approvedPages: response.approvedPages,
            deletedPages: response.deletedPages,
          });
        } else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  };

  const handleApprove = (id: any, approve: boolean) => {
    setOpenApproveDialog({ ...openApproveDialog, isSubmitting: true });
    if (
      openApproveDialog.status === ApprovementStatus.ApprovedFirstLevel ||
      openApproveDialog.status === ApprovementStatus.SentToApproval ||
      openApproveDialog.status === ApprovementStatus.Rejected ||
      !approve
    ) {
      pageService
        .updateStatus({
          id: id,
          approverComment: openApproveDialog.comment,
          status: approve
            ? checkPermission(['ApprovePageSecondLevel'])
              ? ApprovementStatus.ApprovedSecondLevel
              : ApprovementStatus.ApprovedFirstLevel
            : ApprovementStatus.Rejected,
        })
        .then((response) => {
          if (response.hasError) {
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
          } else {
            enqueueSnackbar('Sikeres jóváhagyás!', {
              variant: 'success',
            });
            fetchRows();
            setOpenApproveDialog({
              open: false,
              id: '',
              comment: '',
              isSubmitting: false,
              status: ApprovementStatus.Draft,
            });
          }
        });
    } else if (
      openApproveDialog.status === ApprovementStatus.DeletedFirstLevel ||
      openApproveDialog.status === ApprovementStatus.SentToDelete
    ) {
      pageService.delete(id).then((response) => {
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres törlés!', {
            variant: 'success',
          });
          fetchRows();
          setOpenApproveDialog({
            open: false,
            id: '',
            comment: '',
            isSubmitting: false,
            status: ApprovementStatus.Draft,
          });
        }
      });
    }
  };

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    if (checkPermission(['RecoverPage']) && params.row.isDeleted) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Visszaállítás">
              <AutorenewIcon />
            </Tooltip>
          }
          label={'Visszaállítás'}
          onClick={() => handleRecover(params.row.id)}
        />
      );
    }

    if (
      (checkPermission(['ApprovePageFirstLevel']) &&
        params.row.status === ApprovementStatus.SentToApproval) ||
      (checkPermission(['ApprovePageSecondLevel']) &&
        params.row.status === ApprovementStatus.ApprovedFirstLevel) ||
      (checkPermission(['DeletePageSecondLevel']) && params.row.status === 7) ||
      (checkPermission(['DeletePageFirstLevel']) && params.row.status === 6)
    ) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Jóváhagyás">
              <CheckIcon />
            </Tooltip>
          }
          label={'Jóváhagyás'}
          onClick={() =>
            setOpenApproveDialog({
              open: true,
              id: params.row.id,
              comment: '',
              isSubmitting: false,
              status: params.row.status,
            })
          }
        />
      );
    }

    let readonly = !checkPermission(['ViewPage']) || params.row.isDeleted;
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          readonly ? (
            <Tooltip title="Megtekintés">
              <Visibility />
            </Tooltip>
          ) : (
            <Tooltip title="Szerkesztés">
              <EditIcon />
            </Tooltip>
          )
        }
        label={readonly ? 'Megtekintés' : 'Szerkesztés'}
        onClick={() => navigate(`/pages/edit/${params.row.id}`)}
      />
    );

    if (
      checkPermission(['DeletePage']) &&
      (params.row.isDeleted ? checkPermission(['DeletePagePermanently']) : true)
    ) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip
              title={params.row.isDeleted ? 'Végleges törlés' : 'Törlés'}
            >
              <DeleteIcon />
            </Tooltip>
          }
          label={params.row.isDeleted ? 'Végleges törlés' : 'Törlés'}
          onClick={() =>
            setParams({
              open: true,
              name: 'Biztosan törölni szeretné ezt az elemet?',
              onConfirm: async () => handleDelete(params.row.id),
            })
          }
        />
      );
    }

    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'imageId',
      headerName: 'Kép?',
      renderCell: (params) => {
        return !!params.value ? (
          <Image color="primary" />
        ) : (
          <HideImage color="disabled" />
        );
      },
      flex: 40,
    },
    {
      field: 'name',
      headerName: 'Megnevezés',
      flex: 100,
    },
    { field: 'publisherName', headerName: 'Publikáló', flex: 100 },
    { field: 'version', headerName: 'Verzió', flex: 100 },
    {
      field: 'status',
      headerName: 'Státusz',
      flex: 100,
      valueFormatter: (params) =>
        params.value === ApprovementStatus.Draft
          ? 'Piszkozat'
          : params.value === ApprovementStatus.Rejected
          ? 'Elutasítva'
          : params.value === ApprovementStatus.ApprovedSecondLevel
          ? 'Elfogadva'
          : params.value === ApprovementStatus.SentToApproval ||
            params.value === ApprovementStatus.ApprovedFirstLevel
          ? 'Jóváhagyásra vár'
          : params.value === ApprovementStatus.DeletedFirstLevel ||
            params.value === ApprovementStatus.SentToDelete
          ? 'Törlésre vár'
          : 'Archivált',
    },
    {
      field: 'approverComment',
      headerName: 'Jóváhagyói megjegyzés',
      flex: 100,
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 100,
      align: 'right',
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const handleDelete = (id: number) => {
    pageService.delete(id).then((response) => {
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés!', {
          variant: 'success',
        });
        fetchRows();
      }
    });
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              pb: 2,
              maxWidth: isSmallScreen ? '280px' : '100%',
            }}
          >
            <Tabs
              value={tabValue}
              onChange={handleChange}
              TabIndicatorProps={{ style: { background: '#1976d2' } }}
              sx={{
                maxWidth: isSmallScreen ? '280px' : '100%',
              }}
              variant={isSmallScreen ? 'scrollable' : 'fullWidth'}
            >
              <Tab
                value={0}
                label={
                  <Badge
                    badgeContent={originalRows.draftPages?.length ?? 0}
                    color="primary"
                    max={9999}
                  >
                    <Box alignContent={'center'} sx={{ marginRight: '0.5rem' }}>
                      Piszkozat{' '}
                    </Box>
                  </Badge>
                }
                sx={{ fontWeight: 'bold' }}
              />
              <Tab
                value={1}
                label={
                  <Badge
                    badgeContent={
                      originalRows.sendToApprovementPages?.length ?? 0
                    }
                    color="primary"
                    max={9999}
                  >
                    <Box alignContent={'center'} sx={{ marginRight: '0.5rem' }}>
                      Jóváhagyásra vár{' '}
                    </Box>
                  </Badge>
                }
                sx={{ fontWeight: 'bold' }}
              />
              <Tab
                value={2}
                label={
                  <Badge
                    badgeContent={originalRows.approvedPages?.length ?? 0}
                    color="primary"
                    max={9999}
                  >
                    <Box alignContent={'center'} sx={{ marginRight: '0.5rem' }}>
                      Elfogadva{' '}
                    </Box>
                  </Badge>
                }
                sx={{ fontWeight: 'bold' }}
              />
              <Tab
                value={3}
                label={
                  <Badge
                    badgeContent={originalRows.rejectedPages?.length ?? 0}
                    color="primary"
                    max={9999}
                  >
                    <Box alignContent={'center'} sx={{ marginRight: '0.5rem' }}>
                      Elutasítva{' '}
                    </Box>
                  </Badge>
                }
                sx={{ fontWeight: 'bold' }}
              />
              <Tab
                value={4}
                label={
                  <Badge
                    badgeContent={originalRows.deletedPages?.length ?? 0}
                    color="primary"
                  >
                    <Box alignContent={'center'} sx={{ marginRight: '0.5rem' }}>
                      Archivált{' '}
                    </Box>
                  </Badge>
                }
                sx={{ fontWeight: 'bold' }}
              />
            </Tabs>
          </Box>
          <Grid item xs={12}>
            <h2>Aloldalak</h2>
          </Grid>
          {checkPermission(['CreatePage']) && (
            <Grid container item xs={12} spacing={2} justifyContent="flex-end">
              <Grid item sx={{ display: { xs: 'none', sm: 'fixed' } }}>
                <Tooltip title="Tartalom létrehozása">
                  <IconButton
                    component={RouterLink}
                    to={`/pages/create`}
                    color="primary"
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <DataList
              rows={filteredRows}
              columns={columns.filter((g) =>
                tabValue !== 3 ? g.field !== 'approverComment' : true
              )}
            />
          </Grid>
          <ConfirmDeleteDialog />
          <ApproveDialog
            openApproveDialog={openApproveDialog}
            setOpenApproveDialog={setOpenApproveDialog}
            handleApprove={handleApprove}
          />{' '}
          <SpeedDial
            onClick={() => {
              navigate(`/pages/create`);
            }}
            sx={{
              display: { xs: 'fixed', sm: 'none' },
              position: 'fixed',
              bottom: 16,
              right: 16,
            }}
            icon={<AddIcon />}
            ariaLabel={'Hozzáadás'}
          ></SpeedDial>
        </Box>
      </Grid>
    </Paper>
  );
};

export default PagePage;
