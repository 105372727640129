import React from 'react';
const RadioIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M46.6032 18.2443C37.4633 17.1163 28.1397 16.8802 18.9036 17.5185L34.9968 5.00532C36.0114 4.21833 36.1951 2.74927 35.4079 1.73492C34.6207 0.720574 33.1513 0.536941 32.1367 1.32394L10.0698 18.4804C4.79578 19.6172 0.921143 24.3041 0.921143 29.8218V44.1189C0.921143 50.0039 5.33804 54.9794 11.2068 55.7052C17.0581 56.431 23.0143 56.7895 28.9093 56.7895C34.8044 56.7895 40.8481 56.4222 46.7518 55.6877C52.5332 54.9707 56.8975 49.9951 56.8975 44.1189V29.8218C56.8975 23.9369 52.4807 18.9613 46.6119 18.2356L46.6032 18.2443ZM52.2183 44.1277C52.2183 47.6516 49.6119 50.6335 46.1571 51.0619C34.7694 52.4785 23.1105 52.4785 11.7578 51.0794C8.22433 50.6422 5.56543 47.6516 5.56543 44.1189V29.8218C5.56543 26.2979 8.17188 23.316 11.6267 22.8876C17.338 22.1793 23.1456 21.8208 28.8919 21.8208C34.6382 21.8208 40.367 22.1705 46.0259 22.8701C49.5594 23.3073 52.2183 26.2979 52.2183 29.8306V44.1277Z" fill={secondaryColor}/>
<path d="M45.2212 42.8073H35.8889C34.6032 42.8073 33.5536 43.8478 33.5536 45.142C33.5536 46.4362 34.5945 47.4767 35.8889 47.4767H45.2212C46.5069 47.4767 47.5565 46.4362 47.5565 45.142C47.5565 43.8478 46.5157 42.8073 45.2212 42.8073Z" fill={secondaryColor}/>
<path d="M35.8889 33.477H45.2212C46.5069 33.477 47.5565 32.4364 47.5565 31.1423C47.5565 29.8481 46.5157 28.8075 45.2212 28.8075H35.8889C34.6032 28.8075 33.5536 29.8481 33.5536 31.1423C33.5536 32.4364 34.5945 33.477 35.8889 33.477Z" fill={secondaryColor}/>
<path d="M47.5565 35.8118H33.5624C32.2767 35.8118 31.2271 36.8523 31.2271 38.1465C31.2271 39.4407 32.2679 40.4812 33.5624 40.4812H47.5565C48.8422 40.4812 49.8918 39.4407 49.8918 38.1465C49.8918 36.8523 48.8509 35.8118 47.5565 35.8118Z" fill={secondaryColor}/>
<path d="M21.8948 24.1468H14.8978C11.0407 24.1468 7.90074 27.286 7.90074 31.1423C7.90074 34.9985 11.0407 38.1378 14.8978 38.1378H21.8948C25.752 38.1378 28.8919 34.9985 28.8919 31.1423C28.8919 27.286 25.752 24.1468 21.8948 24.1468ZM21.8948 33.477H14.8978C13.6121 33.477 12.5625 32.4277 12.5625 31.1423C12.5625 29.8568 13.6121 28.8075 14.8978 28.8075H21.8948C23.1805 28.8075 24.2301 29.8568 24.2301 31.1423C24.2301 32.4277 23.1805 33.477 21.8948 33.477Z" fill={primaryColor}/>
<path d="M18.3963 39.3095C15.1777 39.3095 12.5625 41.9241 12.5625 45.142C12.5625 48.3599 15.1777 50.9745 18.3963 50.9745C21.615 50.9745 24.2301 48.3599 24.2301 45.142C24.2301 41.9241 21.615 39.3095 18.3963 39.3095ZM18.3963 46.305C17.7491 46.305 17.233 45.7804 17.233 45.142C17.233 44.5037 17.7578 43.979 18.3963 43.979C19.0348 43.979 19.5595 44.5037 19.5595 45.142C19.5595 45.7804 19.0348 46.305 18.3963 46.305Z" fill={secondaryColor}/>
</svg>

);

export default RadioIcon;
