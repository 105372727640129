import React from 'react';
const GlassesIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="39" viewBox="0 0 57 39" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M42.5152 21.9442C41.2295 21.9442 40.1799 22.9848 40.1799 24.279C40.1799 25.5731 41.2207 26.6137 42.5152 26.6137C42.5152 27.8991 43.556 28.9485 44.8505 28.9485C46.1449 28.9485 47.1857 27.9079 47.1857 26.6137C47.1857 24.0429 45.0954 21.953 42.5239 21.953L42.5152 21.9442Z" fill={primaryColor}/>
<path d="M14.527 21.9442C13.2413 21.9442 12.1917 22.9848 12.1917 24.279C12.1917 25.5731 13.2325 26.6137 14.527 26.6137C14.527 27.8991 15.5678 28.9485 16.8623 28.9485C18.1567 28.9485 19.1975 27.9079 19.1975 26.6137C19.1975 24.0429 17.1072 21.953 14.5357 21.953L14.527 21.9442Z" fill={primaryColor}/>
<path d="M54.174 24.279H53.9379C52.8533 18.9624 48.1391 14.9487 42.5152 14.9487C38.4044 14.9487 34.8009 17.0999 32.7281 20.3178C30.7252 19.6795 29.0896 19.6095 28.5211 19.6095C27.0954 19.6095 25.6698 19.8631 24.3141 20.309C22.2325 17.0911 18.6378 14.9487 14.527 14.9487C8.89437 14.9487 4.18885 18.9624 3.10431 24.279H2.86819C1.58249 24.279 0.532891 25.3195 0.532891 26.6137C0.532891 27.9079 1.57374 28.9485 2.86819 28.9485H3.10431C4.18885 34.2651 8.90311 38.2787 14.527 38.2787C20.9555 38.2787 26.1858 33.0496 26.1858 26.6225C26.1858 25.9579 26.0984 25.3108 25.9847 24.6725C26.8068 24.4276 27.6465 24.2877 28.5123 24.2877C28.6698 24.2877 29.7018 24.314 31.04 24.6987C30.9351 25.3283 30.8476 25.9579 30.8476 26.6225C30.8476 33.0496 36.0779 38.2787 42.5064 38.2787C48.1391 38.2787 52.8446 34.2651 53.9291 28.9485H54.1653C55.451 28.9485 56.5005 27.9079 56.5005 26.6137C56.5005 25.3195 55.4597 24.279 54.1653 24.279H54.174ZM14.527 33.6005C10.6699 33.6005 7.52994 30.4613 7.52994 26.605C7.52994 22.7487 10.6699 19.6095 14.527 19.6095C18.3841 19.6095 21.524 22.7487 21.524 26.605C21.524 30.4613 18.3841 33.6005 14.527 33.6005ZM42.5152 33.6005C38.6581 33.6005 35.5181 30.4613 35.5181 26.605C35.5181 22.7487 38.6581 19.6095 42.5152 19.6095C46.3723 19.6095 49.5122 22.7487 49.5122 26.605C49.5122 30.4613 46.3723 33.6005 42.5152 33.6005Z" fill={secondaryColor}/>
<path d="M2.85941 19.6095C4.14512 19.6095 5.19466 18.5689 5.19466 17.2747C5.19466 10.8476 10.425 5.61849 16.8535 5.61849C18.1392 5.61849 19.1888 4.57788 19.1888 3.28371C19.1888 1.98954 18.148 0.948975 16.8535 0.948975C7.85356 0.948975 0.524109 8.268 0.524109 17.2747C0.524109 18.5601 1.56496 19.6095 2.85941 19.6095Z" fill={secondaryColor}/>
<path d="M40.1799 5.61851C46.6085 5.61851 51.8388 10.8476 51.8388 17.2747C51.8388 18.5601 52.8796 19.6095 54.174 19.6095C55.4685 19.6095 56.5093 18.5689 56.5093 17.2747C56.5093 8.27676 49.1799 0.94899 40.1799 0.94899C38.8942 0.94899 37.8447 1.98956 37.8447 3.28372C37.8447 4.57789 38.8855 5.61851 40.1799 5.61851Z" fill={secondaryColor}/>
</svg>

);

export default GlassesIcon;
