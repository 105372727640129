import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  useMediaQuery,
  Autocomplete,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';

const FaqForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    readonly = false,
    categories,
  } = props;

  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};
        if (!values.question) {
          errors.name = 'Required';
        }
        if (!values.faqCategoryId) {
          errors.name = 'Required';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        setFieldValue,
        handleChange,
        errors: validationErrors,
      }) => (
        <Form>
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>GY.I.K. {values.id > 0 ? 'szerkesztés' : 'létrehozás'}</h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error: string) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="faqCategoryId"
                  disabled={readonly}
                  value={values.faqCategoryId}
                  onChange={(event, value) => {
                    setFieldValue('faqCategoryId', value);
                  }}
                  getOptionLabel={(option) =>
                    categories?.find((g: any) => g.id === option)?.name ?? ''
                  }
                  options={categories?.map((g: any) => g.id)}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label="Kategória" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.question}
                  onChange={handleChange}
                  label="Kérdés"
                  name="question"
                  required
                  disabled={readonly}
                  fullWidth
                  autoFocus
                  multiline
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={!!touched.question && !!validationErrors.question}
                  helperText={
                    !!touched.question &&
                    !!validationErrors.question &&
                    (validationErrors.question as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.answer}
                  onChange={handleChange}
                  label="Válasz"
                  name="answer"
                  required
                  disabled={readonly}
                  fullWidth
                  autoFocus
                  multiline
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={!!touched.answer && !!validationErrors.answer}
                  helperText={
                    !!touched.answer &&
                    !!validationErrors.answer &&
                    (validationErrors.answer as string)
                  }
                />
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item xs={12} sm={isSmallScreen ? 12 : 'auto'} p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth={isSmallScreen}
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12} sm={isSmallScreen ? 12 : 'auto'} p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth={isSmallScreen}
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default FaqForm;
