import React from 'react';
const ImagesIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M55.0613 11.4911C54.3354 6.40188 50.2421 2.34451 45.108 1.61873C33.9565 0.0447392 22.4726 0.0447478 11.3385 1.62748C6.24817 2.35326 2.18984 6.44563 1.4639 11.5786C0.676732 17.14 0.274414 22.8064 0.274414 28.4115C0.274414 34.0166 0.67674 39.7442 1.47265 45.3318C2.1986 50.4211 6.29188 54.4784 11.426 55.2042C16.9886 55.9912 22.6475 56.3935 28.2626 56.3935C33.8777 56.3935 39.6066 55.9913 45.1955 55.1955C50.2858 54.4697 54.3441 50.3774 55.0701 45.2444C55.8573 39.683 56.2596 34.0166 56.2596 28.4115C56.2596 22.8064 55.8572 17.0788 55.0613 11.4911ZM50.452 44.5973C50.0147 47.6841 47.5832 50.15 44.5395 50.5785C33.8428 52.1 22.8049 52.1087 12.0819 50.5872C8.98574 50.15 6.52805 47.7191 6.09073 44.676C5.32106 39.307 4.93621 33.833 4.93621 28.4115C4.93621 22.99 5.32104 17.5772 6.07322 12.2344C6.51054 9.1389 8.94202 6.6817 11.9857 6.24448C17.3472 5.47498 22.8224 5.09022 28.2539 5.09022C33.6853 5.09022 39.0905 5.47501 44.4345 6.22703C47.5307 6.66425 49.9885 9.09514 50.4258 12.1382C51.1955 17.5072 51.5803 22.9812 51.5803 28.4028C51.5803 33.8243 51.1954 39.237 50.4433 44.5886L50.452 44.5973Z" fill={secondaryColor}/>
<path d="M42.1256 30.9998C40.0702 27.9218 36.6329 26.0854 32.9332 26.0854H23.6008C19.9011 26.0854 16.4639 27.9218 14.4085 30.9998L7.66508 41.1083C6.94788 42.1838 7.2365 43.6267 8.3123 44.3437C9.37935 45.052 10.8312 44.7634 11.5484 43.6966L18.2919 33.5881C19.4814 31.8043 21.4667 30.7462 23.6008 30.7462C25.7349 30.7462 27.7291 31.8043 28.9099 33.5881L35.6533 43.6966C36.0993 44.3699 36.8428 44.7372 37.5949 44.7372C38.041 44.7372 38.4871 44.606 38.8894 44.3437C39.9652 43.6267 40.2538 42.1838 39.5366 41.1083L32.7932 30.9998C32.732 30.9123 32.662 30.8337 32.6008 30.7462H32.9332C35.076 30.7462 37.0615 31.8043 38.2422 33.5881L44.9856 43.6966C45.4316 44.3699 46.1751 44.7372 46.9273 44.7372C47.3734 44.7372 47.8194 44.606 48.2217 44.3437C49.2975 43.6267 49.5861 42.1838 48.8689 41.1083L42.1256 30.9998Z" fill={primaryColor}/>
<path d="M38.767 21.416C41.9856 21.416 44.6007 18.8014 44.6007 15.5835C44.6007 12.3655 41.9856 9.75098 38.767 9.75098C35.5483 9.75098 32.9332 12.3655 32.9332 15.5835C32.9332 18.8014 35.5483 21.416 38.767 21.416ZM38.767 14.4205C39.4142 14.4205 39.9302 14.9451 39.9302 15.5835C39.9302 16.2218 39.4054 16.7465 38.767 16.7465C38.1285 16.7465 37.6037 16.2218 37.6037 15.5835C37.6037 14.9451 38.1285 14.4205 38.767 14.4205Z" fill={secondaryColor}/>
</svg>

);

export default ImagesIcon;
