import { styled } from "@mui/system";

import { LinearProgress, Typography } from "@mui/material";

import { connect } from "react-redux";

const Root = styled("div")({
  width: "100%",
  height: "100%",
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 99999,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  "& #message": {
    position: "absolute",
    top: "50%",
    left: "50%",
    color: "white",
    transform: "translate(-50%)",
  },
});

const WaitLayer = ({ state }: any) => {
  return state.waitLayerVisible ? (
    <Root>
      <LinearProgress />
      <Typography id="message" component="div" variant="h5">
        {state.message}
      </Typography>
    </Root>
  ) : null;
};

const mapStateToProps = (state: any) => {
  return {
    state: state.waitLayer,
  };
};

export default connect(mapStateToProps)(WaitLayer);
