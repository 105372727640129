import React from 'react';
const LoginIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M32.9332 28.8137C32.9332 22.8763 32.5658 16.8776 31.8311 10.9752C31.1139 5.19515 26.1373 0.831726 20.2598 0.831726H12.9478C7.07029 0.831726 2.1024 5.19515 1.37646 10.9752C0.641768 16.8776 0.274414 22.8763 0.274414 28.8137C0.274414 34.7512 0.641768 40.7498 1.37646 46.6523C2.09366 52.4323 7.07029 56.7957 12.9478 56.7957H20.2598C26.1373 56.7957 31.1052 52.4323 31.8311 46.6523C32.5658 40.7498 32.9332 34.7512 32.9332 28.8137ZM27.2043 46.0751C26.7758 49.5291 23.7932 52.135 20.2685 52.135H12.9566C9.4318 52.135 6.44932 49.5291 6.02075 46.0751C5.3123 40.365 4.95372 34.5588 4.95372 28.8137C4.95372 23.0687 5.3123 17.2624 6.02075 11.5523C6.44932 8.09831 9.4318 5.49249 12.9566 5.49249H20.2685C23.7932 5.49249 26.7758 8.09831 27.2043 11.5523C27.9128 17.2624 28.2714 23.0687 28.2714 28.8137C28.2714 34.5588 27.9128 40.365 27.2043 46.0751Z" fill={secondaryColor}/>
<path d="M55.175 11.1151C54.4491 5.25639 49.4724 0.831726 43.5862 0.831726H32.9419C31.6562 0.831726 30.6066 1.87229 30.6066 3.16646C30.6066 4.46063 31.6475 5.50119 32.9419 5.50119H43.5862C47.1197 5.50119 50.1109 8.1595 50.5482 11.6922C51.2479 17.3498 51.5978 23.1124 51.5978 28.8225C51.5978 34.5326 51.2392 40.3738 50.5308 46.0839C50.1022 49.5379 47.1197 52.1437 43.5949 52.1437H32.9419C31.6562 52.1437 30.6066 53.1843 30.6066 54.4784C30.6066 55.7726 31.6475 56.8132 32.9419 56.8132H43.5949C49.4725 56.8132 54.4403 52.4498 55.1663 46.6697C55.901 40.7673 56.2683 34.7687 56.2683 28.8312C56.2683 22.8938 55.901 16.9738 55.1838 11.1326L55.175 11.1151Z" fill={secondaryColor}/>
<path d="M18.9391 21.8182C15.082 21.8182 11.942 24.9575 11.942 28.8137C11.942 32.67 15.082 35.8092 18.9391 35.8092C22.7962 35.8092 25.9361 32.67 25.9361 28.8137C25.9361 24.9575 22.7962 21.8182 18.9391 21.8182ZM18.9391 31.1485C17.6534 31.1485 16.6038 30.0992 16.6038 28.8137C16.6038 27.5283 17.6534 26.479 18.9391 26.479C20.2248 26.479 21.2743 27.5283 21.2743 28.8137C21.2743 30.0992 20.2248 31.1485 18.9391 31.1485Z" fill={primaryColor}/>
<path d="M43.9098 22.5002C43.0002 21.5908 41.522 21.5908 40.6124 22.5002L35.9506 27.161C35.041 28.0704 35.041 29.5482 35.9506 30.4577L40.6124 35.1184C41.0672 35.5731 41.662 35.8005 42.2655 35.8005C42.869 35.8005 43.455 35.5731 43.9185 35.1184C44.8281 34.209 44.8281 32.7312 43.9185 31.8218L43.2363 31.1397H46.936C48.2217 31.1397 49.2713 30.0991 49.2713 28.805C49.2713 27.5108 48.2305 26.4702 46.936 26.4702H43.2363L43.9185 25.7881C44.8281 24.8787 44.8281 23.401 43.9185 22.4915L43.9098 22.5002Z" fill={secondaryColor}/>
</svg>

);

export default LoginIcon;
