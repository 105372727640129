import React from 'react';
const OwlIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="69" height="68" viewBox="0 0 69 68" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M34.5217 67.9585C34.2243 67.9585 33.9182 67.8798 33.6471 67.7224C31.2244 66.3321 23.2477 61.7151 22.032 60.4996C21.3498 59.8175 21.3498 58.707 22.032 58.0249C22.7142 57.3429 23.825 57.3429 24.5072 58.0249C25.1719 58.6458 29.9911 61.5664 34.5655 64.2072C39.5946 61.5926 44.9298 58.6633 45.6733 58.0074C46.3555 57.3254 47.4575 57.3341 48.1397 58.0074C48.8219 58.6808 48.8132 59.8088 48.1397 60.4908C46.9415 61.6888 38.7899 65.9648 35.3351 67.7487C35.0815 67.8798 34.8103 67.9411 34.5305 67.9411L34.5217 67.9585Z" fill={secondaryColor}/>
<path d="M51.6032 52.0175C51.2621 52.0175 50.9123 51.9126 50.6061 51.7027C49.8102 51.1518 49.6178 50.0588 50.1601 49.2718C53.0988 45.0308 54.402 41.122 54.402 36.6012C54.402 32.0803 52.2505 28.1366 50.1513 25.0586C49.6091 24.2629 49.8102 23.1698 50.6061 22.6277C51.4021 22.0855 52.4954 22.2867 53.0376 23.0824C55.3029 26.3965 57.9006 30.9261 57.9006 36.5924C57.9006 41.8041 56.3525 46.4561 53.0376 51.2568C52.6965 51.7465 52.1542 52.0088 51.5945 52.0088L51.6032 52.0175Z" fill={secondaryColor}/>
<path d="M17.4314 52.0175C16.8804 52.0175 16.3294 51.7552 15.9883 51.2655C12.6647 46.4736 11.1166 41.8128 11.1166 36.6012C11.1166 30.9348 13.7142 26.4053 15.9795 23.0911C16.5218 22.2954 17.6151 22.0855 18.411 22.6364C19.2069 23.1786 19.4168 24.2716 18.8658 25.0674C16.7667 28.1454 14.6151 31.923 14.6151 36.6099C14.6151 41.2969 15.927 45.0395 18.8571 49.2805C19.4081 50.0763 19.2069 51.1606 18.411 51.7115C18.1049 51.9213 17.7638 52.0263 17.4139 52.0263L17.4314 52.0175Z" fill={secondaryColor}/>
<path d="M23.1253 28.1017C17.7638 28.1017 13.3994 23.7382 13.3994 18.3779C13.3994 13.0176 17.7638 8.65417 23.1253 8.65417C28.4867 8.65417 32.8512 13.0176 32.8512 18.3779C32.8512 23.7382 28.4867 28.1017 23.1253 28.1017ZM23.1253 12.1607C19.6967 12.1607 16.8979 14.9501 16.8979 18.3867C16.8979 21.8232 19.688 24.6127 23.1253 24.6127C26.5626 24.6127 29.3526 21.8232 29.3526 18.3867C29.3526 14.9501 26.5626 12.1607 23.1253 12.1607Z" fill={secondaryColor}/>
<path d="M45.9094 28.1017C40.5479 28.1017 36.1835 23.7382 36.1835 18.3779C36.1835 13.0176 40.5479 8.65417 45.9094 8.65417C51.2709 8.65417 55.6353 13.0176 55.6353 18.3779C55.6353 23.7382 51.2709 28.1017 45.9094 28.1017ZM45.9094 12.1607C42.4808 12.1607 39.682 14.9501 39.682 18.3867C39.682 21.8232 42.4721 24.6127 45.9094 24.6127C49.3467 24.6127 52.1367 21.8232 52.1367 18.3867C52.1367 14.9501 49.3467 12.1607 45.9094 12.1607Z" fill={secondaryColor}/>
<path d="M37.9415 22.4091C36.9794 22.4091 36.1922 21.6221 36.1922 20.6602C36.1922 19.6983 35.44 18.99 34.5217 18.99C33.6033 18.99 32.8511 19.742 32.8511 20.6602C32.8511 21.6221 32.064 22.4091 31.1019 22.4091C30.1398 22.4091 29.3526 21.6221 29.3526 20.6602C29.3526 17.8095 31.6704 15.4923 34.5217 15.4923C37.373 15.4923 39.6907 17.8095 39.6907 20.6602C39.6907 21.6221 38.9036 22.4091 37.9415 22.4091Z" fill={secondaryColor}/>
<path d="M15.1573 20.1268H12.8833C11.9212 20.1268 11.134 19.3398 11.134 18.3779C11.134 17.4161 11.9212 16.6291 12.8833 16.6291H15.1573C16.1194 16.6291 16.9066 17.4161 16.9066 18.3779C16.9066 19.3398 16.1194 20.1268 15.1573 20.1268Z" fill={secondaryColor}/>
<path d="M56.16 20.1268H53.886C52.9239 20.1268 52.1367 19.3398 52.1367 18.3779C52.1367 17.4161 52.9239 16.6291 53.886 16.6291H56.16C57.1221 16.6291 57.9093 17.4161 57.9093 18.3779C57.9093 19.3398 57.1221 20.1268 56.16 20.1268Z" fill={secondaryColor}/>
<path d="M23.1252 20.6515C24.3812 20.6515 25.3993 19.6336 25.3993 18.3779C25.3993 17.1223 24.3812 16.1044 23.1252 16.1044C21.8693 16.1044 20.8512 17.1223 20.8512 18.3779C20.8512 19.6336 21.8693 20.6515 23.1252 20.6515Z" fill={secondaryColor}/>
<path d="M45.9094 20.6515C47.1653 20.6515 48.1834 19.6336 48.1834 18.3779C48.1834 17.1223 47.1653 16.1044 45.9094 16.1044C44.6534 16.1044 43.6353 17.1223 43.6353 18.3779C43.6353 19.6336 44.6534 20.6515 45.9094 20.6515Z" fill={secondaryColor}/>
<path d="M34.5217 14.4342C34.0056 14.4342 33.5071 14.2069 33.1747 13.8046L30.5508 10.6566C28.9328 8.71539 26.545 7.59611 24.0173 7.59611C20.7462 7.59611 17.7287 6.34566 15.4634 4.08086L15.0524 3.66989C14.3702 2.98783 14.3702 1.87729 15.0524 1.19523C15.7346 0.51317 16.8454 0.51317 17.5276 1.19523L17.9387 1.60622C19.548 3.21518 21.6821 4.09836 23.9474 4.09836C27.5858 4.09836 30.9444 5.67235 33.236 8.41808L34.5129 9.94835L35.7899 8.41808C38.0814 5.67235 41.44 4.09836 45.0085 4.09836C47.3438 4.09836 49.4779 3.21518 51.0872 1.60622L51.4983 1.19523C52.1805 0.51317 53.2912 0.51317 53.9735 1.19523C54.6557 1.87729 54.6557 2.98783 53.9735 3.66989L53.5624 4.08086C51.2971 6.34566 48.2796 7.59611 45.0785 7.59611C42.4808 7.59611 40.1018 8.70664 38.4837 10.6566L35.8598 13.8046C35.5275 14.2069 35.0377 14.4342 34.5129 14.4342H34.5217Z" fill={secondaryColor}/>
<path d="M37.9327 52.0175H31.1019C30.1398 52.0175 29.3526 51.2305 29.3526 50.2687C29.3526 49.3068 30.1398 48.5198 31.1019 48.5198H37.9327C38.8948 48.5198 39.682 49.3068 39.682 50.2687C39.682 51.2305 38.8948 52.0175 37.9327 52.0175Z" fill={primaryColor}/>
<path d="M58.4429 61.1292H10.6005C10.2944 61.1292 9.99704 61.0505 9.73465 60.9018L1.75801 56.346C1.21574 56.0312 0.874634 55.4541 0.874634 54.8245C0.874634 54.1949 1.20699 53.6177 1.75801 53.3029L9.73465 48.7471C9.99704 48.5985 10.2944 48.5198 10.6005 48.5198H21.9882C22.9503 48.5198 23.7375 49.3068 23.7375 50.2687C23.7375 51.2305 22.9503 52.0175 21.9882 52.0175H11.0641L6.14866 54.8245L11.0641 57.6314H58.4429C59.405 57.6314 60.1921 58.4184 60.1921 59.3803C60.1921 60.3422 59.405 61.1292 58.4429 61.1292Z" fill={primaryColor}/>
<path d="M40.2156 61.1292H28.8279C27.8658 61.1292 27.0786 60.3422 27.0786 59.3803C27.0786 58.4184 27.8658 57.6314 28.8279 57.6314H40.2156C41.1777 57.6314 41.9648 58.4184 41.9648 59.3803C41.9648 60.3422 41.1777 61.1292 40.2156 61.1292Z" fill={primaryColor}/>
<path d="M64.1367 61.1292H44.7724C43.8103 61.1292 43.0231 60.3422 43.0231 59.3803C43.0231 58.4184 43.8103 57.6314 44.7724 57.6314H64.1367C64.4254 57.6314 64.6615 57.3953 64.6615 57.1067V52.5509C64.6615 52.2624 64.4254 52.0263 64.1367 52.0263H47.0464C46.0843 52.0263 45.2972 51.2393 45.2972 50.2774C45.2972 49.3155 46.0843 48.5285 47.0464 48.5285H64.1367C66.3583 48.5285 68.16 50.3386 68.16 52.5509V57.1067C68.16 59.3278 66.3496 61.1292 64.1367 61.1292Z" fill={primaryColor}/>
<path d="M11.7375 61.1292C10.7754 61.1292 9.98828 60.3422 9.98828 59.3803V50.2687C9.98828 49.3068 10.7754 48.5198 11.7375 48.5198C12.6996 48.5198 13.4868 49.3068 13.4868 50.2687V59.3803C13.4868 60.3422 12.6996 61.1292 11.7375 61.1292Z" fill={primaryColor}/>
<path d="M57.3058 61.1292C56.3437 61.1292 55.5566 60.3422 55.5566 59.3803V50.2687C55.5566 49.3068 56.3437 48.5198 57.3058 48.5198C58.2679 48.5198 59.0551 49.3068 59.0551 50.2687V59.3803C59.0551 60.3422 58.2679 61.1292 57.3058 61.1292Z" fill={primaryColor}/>
<path d="M40.2156 61.1292C39.6296 61.1292 39.0873 60.8406 38.7637 60.3509L37.443 58.3659C36.6208 57.1417 36.1923 55.7076 36.1923 54.2386V51.5453C36.1923 49.5254 37.6354 47.759 39.5421 47.4355C40.7141 47.2343 41.9123 47.5666 42.822 48.3274C43.7316 49.0969 44.2564 50.2162 44.2564 51.4054V54.2386C44.2564 55.7164 43.8278 57.1417 43.0056 58.3659L41.6849 60.3509C41.3613 60.8406 40.8103 61.1292 40.2331 61.1292H40.2156ZM40.1194 50.8895C39.8832 50.9245 39.6908 51.2305 39.6908 51.5453V54.2386C39.6908 54.9381 39.8745 55.6115 40.2156 56.2148C40.5654 55.6115 40.7403 54.9381 40.7403 54.2386V51.4054C40.7403 51.1868 40.6091 51.0469 40.5479 51.0032C40.4605 50.9332 40.3205 50.8545 40.1106 50.8895H40.1194Z" fill={secondaryColor}/>
<path d="M44.7724 61.1292C44.1864 61.1292 43.6441 60.8406 43.3205 60.3509L41.9998 58.3659C41.1777 57.1417 40.7491 55.7164 40.7491 54.2386V51.5453C40.7491 49.5254 42.1922 47.759 44.0989 47.4355C45.2709 47.2343 46.4692 47.5666 47.3788 48.3274C48.2884 49.0969 48.8132 50.2162 48.8132 51.4054V54.2386C48.8132 55.7164 48.3846 57.1417 47.5625 58.3659L46.2418 60.3509C45.9182 60.8406 45.3671 61.1292 44.7899 61.1292H44.7724ZM44.6762 50.8895C44.44 50.9245 44.2476 51.2305 44.2476 51.5453V54.2386C44.2476 54.9381 44.4313 55.6115 44.7724 56.2148C45.1222 55.6115 45.2972 54.9381 45.2972 54.2386V51.4054C45.2972 51.1868 45.166 51.0469 45.1047 51.0032C45.0173 50.9332 44.8773 50.8545 44.6674 50.8895H44.6762Z" fill={secondaryColor}/>
<path d="M24.271 61.1292C23.685 61.1292 23.1428 60.8406 22.8192 60.3509L21.4985 58.3659C20.6763 57.1417 20.2477 55.7164 20.2477 54.2386V51.5453C20.2477 49.5254 21.6909 47.759 23.5976 47.4355C24.7783 47.2343 25.9678 47.5666 26.8774 48.3274C27.7871 49.0969 28.3118 50.2162 28.3118 51.4054V54.2386C28.3118 55.7164 27.8833 57.1417 27.0611 58.3659L25.7404 60.3509C25.4168 60.8406 24.8658 61.1292 24.2885 61.1292H24.271ZM24.1661 50.8895C23.9299 50.9245 23.7375 51.2305 23.7375 51.5453V54.2386C23.7375 54.9381 23.9212 55.6115 24.2623 56.2148C24.6122 55.6115 24.7871 54.9381 24.7871 54.2386V51.4054C24.7871 51.1868 24.6559 51.0469 24.5947 51.0032C24.5072 50.9332 24.3673 50.8545 24.1573 50.8895H24.1661Z" fill={secondaryColor}/>
<path d="M28.8279 61.1292C28.2419 61.1292 27.6996 60.8406 27.376 60.3509L26.0553 58.366C25.2331 57.1417 24.8046 55.7077 24.8046 54.2386V51.5454C24.8046 49.5254 26.2477 47.759 28.1544 47.4355C29.3352 47.2431 30.5247 47.5667 31.4343 48.3274C32.3439 49.0969 32.8687 50.2162 32.8687 51.4054V54.2386C32.8687 55.7164 32.4401 57.1417 31.6179 58.366L30.2972 60.3509C29.9736 60.8406 29.4226 61.1292 28.8454 61.1292H28.8279ZM28.8191 50.8808C28.8191 50.8808 28.7579 50.8808 28.7229 50.8808C28.4868 50.9157 28.2943 51.2218 28.2943 51.5366V54.2299C28.2943 54.9294 28.478 55.6027 28.8191 56.2061C29.169 55.6027 29.3439 54.9294 29.3439 54.2299V51.3967C29.3439 51.1781 29.2127 51.0382 29.1515 50.9945C29.0815 50.9332 28.9678 50.872 28.8104 50.872L28.8191 50.8808Z" fill={secondaryColor}/>
<path d="M34.5217 31.5207C33.857 31.5207 33.2535 31.1447 32.9561 30.5588C32.1165 28.8799 30.3847 26.2479 29.1777 25.6445C28.3118 25.216 27.962 24.158 28.3993 23.301C28.8279 22.4353 29.8862 22.0855 30.7433 22.5228C32.204 23.2485 33.5421 24.875 34.5217 26.3266C35.5013 24.875 36.8395 23.2485 38.2914 22.5228C39.1573 22.0855 40.2068 22.4441 40.6354 23.301C41.064 24.1667 40.7141 25.216 39.857 25.6445C38.65 26.2479 36.927 28.8799 36.0786 30.5588C35.7812 31.1535 35.1777 31.5207 34.513 31.5207H34.5217Z" fill={secondaryColor}/>
<path d="M26.5451 46.325C26.1515 46.325 25.7579 46.1938 25.4343 45.9227C24.6908 45.3106 24.5771 44.2088 25.1981 43.4568C25.2681 43.3694 27.0786 41.0346 27.0786 36.5925C27.0786 32.9636 24.5334 29.1073 21.4022 27.988C20.4926 27.6645 20.0203 26.6589 20.3439 25.7495C20.6675 24.84 21.6734 24.3678 22.583 24.6914C27.0698 26.2916 30.5771 31.5207 30.5771 36.5925C30.5771 42.355 28.0057 45.5467 27.9007 45.6779C27.5509 46.0976 27.0524 46.3162 26.5451 46.3162V46.325Z" fill={secondaryColor}/>
<path d="M42.4809 46.3162C41.9823 46.3162 41.4838 46.0976 41.1427 45.6866C41.0377 45.5554 38.4663 42.3725 38.4663 36.6012C38.4663 31.5295 41.9823 26.3091 46.4604 24.7089C47.3701 24.3853 48.3671 24.8575 48.6995 25.7669C49.0231 26.6763 48.5508 27.6732 47.6412 28.0055C44.51 29.1248 41.9648 32.981 41.9648 36.61C41.9648 41.0434 43.7753 43.3869 43.854 43.483C44.4488 44.2351 44.3351 45.3368 43.5829 45.9402C43.2593 46.2025 42.8657 46.3337 42.4809 46.3337V46.3162Z" fill={secondaryColor}/>
</svg>

);

export default OwlIcon;
