import React from 'react';
const PrintIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M48.8018 20.649C48.7493 19.6871 48.7055 18.7252 48.6443 17.7896C48.4082 14.6678 47 11.8259 44.691 9.79721L36.8543 2.91539C34.729 1.05284 32.0088 0.0297546 29.1838 0.0297546H19.7116C13.8253 0.0297546 8.83988 4.44566 8.11393 10.3044C7.68536 13.7672 7.40548 17.2125 7.2393 20.6053C2.98859 22.3454 0.0323486 26.5165 0.0323486 31.3434V33.9754C0.0323486 39.5631 4.03819 44.2938 9.46965 45.3868C10.0119 51.3155 14.9536 55.9938 21.0235 55.9938H35.0176C41.0788 55.9938 46.0204 51.3243 46.5714 45.3956C51.9942 44.39 56.0088 39.6418 56.0088 34.0016V31.3696C56.0088 26.5864 53.0613 22.4328 48.8106 20.649H48.8018ZM39.7668 11.6947H37.3354C36.0497 11.6947 35.0001 10.6454 35.0001 9.35999V7.51494L39.7668 11.6947ZM12.7408 10.8815C13.1781 7.35754 16.178 4.69049 19.7116 4.69049H29.1838C29.5774 4.69049 29.971 4.73421 30.3471 4.79542V9.35126C30.3471 13.2075 33.487 16.3468 37.3442 16.3468H43.6152C43.8076 16.9151 43.9563 17.501 44.0001 18.1131C44.0351 18.5941 44.0525 19.11 44.0788 19.5909C33.2421 18.3929 22.3005 18.4104 11.9711 19.5909C12.146 16.714 12.3822 13.8021 12.7408 10.8728V10.8815ZM42.0059 44.3375C42.0059 48.1938 38.866 51.333 35.0088 51.333H21.0147C17.1576 51.333 14.0177 48.1938 14.0177 44.3375V32.6812H42.0059V44.3375ZM51.3295 34.0016C51.3295 37.0447 49.3965 39.6593 46.6676 40.5949V32.6725C47.9534 32.6725 49.0029 31.6319 49.0029 30.3377C49.0029 29.0436 47.9621 28.003 46.6676 28.003H9.34719C8.06149 28.003 7.01189 29.0436 7.01189 30.3377C7.01189 31.6319 8.05274 32.6725 9.34719 32.6725V40.5599C6.6096 39.5718 4.68534 36.9747 4.68534 33.9754V31.3434C4.68534 27.8106 7.28303 24.8375 10.7378 24.4178C21.7494 23.01 33.5394 22.975 45.137 24.3916C48.6618 24.8288 51.3295 27.8281 51.3295 31.3609V33.9929V34.0016Z" fill={secondaryColor}/>
<path d="M21.0147 39.6767H25.6765C26.9622 39.6767 28.0118 38.6361 28.0118 37.342C28.0118 36.0478 26.9709 35.0072 25.6765 35.0072H21.0147C19.729 35.0072 18.6794 36.0478 18.6794 37.342C18.6794 38.6361 19.7203 39.6767 21.0147 39.6767Z" fill={primaryColor}/>
<path d="M21.0147 46.6722H30.3471C31.6328 46.6722 32.6823 45.6316 32.6823 44.3375C32.6823 43.0433 31.6416 42.0027 30.3471 42.0027H21.0147C19.729 42.0027 18.6794 43.0433 18.6794 44.3375C18.6794 45.6316 19.7203 46.6722 21.0147 46.6722Z" fill={primaryColor}/>
</svg>

);

export default PrintIcon;
