import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import RoleForm from './RoleForm';
import { useNavigate, useParams } from 'react-router-dom';
import roleService from '../../../services/authority/roleService';
import permissionService from '../../../services/authority/permissionService';

const RoleEdit = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const params = useParams();
  const [errors, setErrors] = useState<string[]>([]);
  const [entity, setEntity] = useState<any>({
    name: '',
    description: '',
    permissions: [],
  });
  const [permissions, setPermissions] = useState<string[]>([]);

  useEffect(() => {
    permissionService.list().then((response) => {
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setPermissions(response);
      }
    });
  }, []);

  useEffect(() => {
    roleService.get(params.id).then((response) => {
      if (response.hasError) {
        setErrors(response.errorMessages);
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setEntity(response.result);
      }
    });
  }, [params.id]);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    roleService
      .update(entity)
      .then((response) => {
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });

          navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        if (setSubmitting) setSubmitting(false);
      });
  };

  return (
    <RoleForm
      entity={entity}
      permissions={permissions}
      onSubmit={onSubmit}
      handleCancel={() => navigate(-1)}
      errors={errors}
    />
  );
};

export default RoleEdit;
