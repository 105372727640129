import axios from "axios";
import { postData } from "./axiosUtils";

const faqService = {
  list: () => {
    return axios.get(`/api/faq/list`).then((response) => {
      return response.data;
    });
  },
  get: (id: any) => {
    return axios.get(`/api/faq/details/${id}`).then((response) => {
      return response.data;
    });
  },
  create: (entity: any) => {
    return postData({
      url: `/api/faq/create`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  update: (entity: any) => {
    return postData({
      url: `/api/faq/update`,
      data: entity,
      method: "PUT",
    }).then((response) => {
      return response;
    });
  },
  delete: (id: any) => {
    return postData({
      url: `/api/faq/delete/${id}`,
      method: "DELETE",
    }).then((response) => {
      return response;
    });
  },
};

export default faqService;
