import React from 'react';
const MagnetIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="56" height="57" viewBox="0 0 56 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M30.3482 14.6272C31.6339 14.6272 32.6835 13.5866 32.6835 12.2925V9.95774C32.6835 8.67232 31.6426 7.62301 30.3482 7.62301C29.0537 7.62301 28.0129 8.66357 28.0129 9.95774V12.2925C28.0129 13.5779 29.0537 14.6272 30.3482 14.6272Z" fill={primaryColor}/>
<path d="M30.3482 49.596C31.6339 49.596 32.6835 48.5554 32.6835 47.2612V44.9265C32.6835 43.6411 31.6426 42.5918 30.3482 42.5918C29.0537 42.5918 28.0129 43.6323 28.0129 44.9265V47.2612C28.0129 48.5466 29.0537 49.596 30.3482 49.596Z" fill={primaryColor}/>
<path d="M28.0129 21.614H34.319C39.8379 21.614 44.3335 17.1281 44.3335 11.6104C44.3335 6.64364 40.765 2.48131 35.8409 1.70306C30.9342 0.924809 25.9575 0.575014 20.7535 0.644969C9.33078 0.828601 0.0422058 10.3862 0.0422058 21.9463V35.2902C0.0422058 46.8502 9.33079 56.3991 20.9809 56.5915C21.3745 56.5915 21.7768 56.5915 22.1704 56.5915C26.736 56.5915 31.3365 56.233 35.8409 55.5159C40.765 54.7377 44.3335 50.5754 44.3335 45.6086C44.3335 40.0909 39.8379 35.5963 34.319 35.5963H28.0129C24.1558 35.5963 21.0159 32.457 21.0159 28.6008C21.0159 24.7445 24.1558 21.6053 28.0129 21.6053V21.614ZM28.0129 40.2657H34.319C37.2665 40.2657 39.6717 42.6617 39.6717 45.6173C39.6717 48.2668 37.7563 50.4966 35.1149 50.9164C30.4794 51.6509 25.7476 51.9832 20.8322 51.922C11.9372 51.7733 4.70401 44.3144 4.70401 35.2902V21.9463C4.70401 12.9221 11.9372 5.45439 21.0421 5.30574C21.4182 5.30574 21.7943 5.30574 22.1704 5.30574C26.4911 5.30574 30.8467 5.64674 35.1149 6.32006C37.7563 6.73979 39.6717 8.96966 39.6717 11.6192C39.6717 14.5661 37.2665 16.9708 34.319 16.9708H28.0129C21.5844 16.9708 16.3541 22.1999 16.3541 28.627C16.3541 35.0541 21.5844 40.2832 28.0129 40.2832V40.2657Z" fill={secondaryColor}/>
<path d="M49.0041 9.95772C49.3539 9.95772 49.7125 9.879 50.0449 9.71285L54.7067 7.37812C55.8612 6.80099 56.3247 5.40188 55.7475 4.24762C55.1702 3.09336 53.7708 2.62991 52.6163 3.20704L47.9545 5.54182C46.8 6.11895 46.3364 7.51801 46.9137 8.67227C47.3248 9.4855 48.1469 9.95772 49.0041 9.95772Z" fill={secondaryColor}/>
<path d="M49.0041 16.9532H53.6658C54.9516 16.9532 56.0011 15.9127 56.0011 14.6185C56.0011 13.3243 54.9603 12.2837 53.6658 12.2837H49.0041C47.7184 12.2837 46.6688 13.3243 46.6688 14.6185C46.6688 15.9127 47.7096 16.9532 49.0041 16.9532Z" fill={secondaryColor}/>
<path d="M54.7154 49.8408L50.0536 47.5061C48.8991 46.929 47.4997 47.4012 46.9225 48.5467C46.3452 49.7009 46.8175 51.1 47.9633 51.6772L52.625 54.0119C52.9574 54.1781 53.316 54.2567 53.6659 54.2567C54.523 54.2567 55.3452 53.7846 55.7562 52.9713C56.3335 51.8171 55.8612 50.418 54.7154 49.8408Z" fill={secondaryColor}/>
<path d="M53.6746 40.2657H49.0128C47.7271 40.2657 46.6776 41.3063 46.6776 42.6005C46.6776 43.8947 47.7183 44.9352 49.0128 44.9352H53.6746C54.9603 44.9352 56.0099 43.8947 56.0099 42.6005C56.0099 41.3063 54.9691 40.2657 53.6746 40.2657Z" fill={secondaryColor}/>
</svg>

);

export default MagnetIcon;
