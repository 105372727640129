import {
  GridColDef,
  GridActionsCellItem,
  GridActionsColDef,
} from '@mui/x-data-grid';
import {
  Paper,
  IconButton,
  Grid,
  Tooltip,
  TextField,
  Autocomplete,
  Portal,
  DialogContent,
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Visibility,
} from '@mui/icons-material';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useConfirmDeleteDialog from '../../hooks/useConfirmDeleteDialog';
import useCheckPermission from '../../hooks/useCheckPermission';
import DataList from '../../components/DataList';
import ImageBrowserGallery from './ImageBrowserGallery';
import imageService from '../../services/imageService';
import publisherService from '../../services/publisherService';
import React from 'react';
import ImagePreview from '../../components/ImagePreview';
import { set } from 'date-fns';

export interface Gallery {
  id: number;
  latitude: string;
  longitude: string;
  name: string;
  fullAddress: string;
}

const GalleryPage = () => {
  const [publisherId, setPublisherId] = useState<number>(0);
  const [preview, setPreview] = useState<any>({ open: false, image: {} });
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();

  const [publishers, setPublishers] = useState<any[]>([]);
  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    publisherService
      .list()
      .then((response: any) => {
        if (!response.hasError) {
          setPublishers(response.records);
        } else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Galéria</h2>
        </Grid>
        <Grid item xs={6} pb={2}>
          <Autocomplete
            disablePortal
            id="publisherId"
            value={publisherId}
            onChange={(event, value) => {
              setPublisherId(value);
            }}
            getOptionLabel={(option) =>
              publishers?.find((g: any) => g.id === option)?.name ?? ''
            }
            options={publishers?.map((g: any) => g.id)}
            renderInput={(params) => (
              <TextField {...params} required fullWidth label="Publikáló" />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          {publisherId > 0 && (
            <ImageBrowserGallery
              publisherId={publisherId}
              handleSelect={(image) => {
                setPreview({ open: true, image: image });
              }}
            />
          )}
        </Grid>
        <Dialog
          open={preview.open}
          onClose={() => setPreview({ open: false, image: {} })}
        >
          <DialogTitle>Kép megtekintése</DialogTitle>
          <DialogContent>
            <ImagePreview image={preview.image} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setPreview({ open: false, image: {} })}>
              Bezárás
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Paper>
  );
};

export default GalleryPage;
