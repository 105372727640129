import React from 'react';
const LightningIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="52" height="57" viewBox="0 0 52 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M50.8358 16.16C48.8767 12.0852 44.8446 9.55804 40.3228 9.55804C39.0371 9.55804 37.9875 10.5986 37.9875 11.8928C37.9875 13.187 39.0283 14.2275 40.3228 14.2275C43.0342 14.2275 45.4481 15.7491 46.6289 18.1887C47.8009 20.6372 47.486 23.4703 45.7892 25.5865C44.9846 26.5921 45.1507 28.0611 46.1566 28.8656C46.5851 29.2067 47.1012 29.3728 47.6085 29.3728C48.2907 29.3728 48.9729 29.0755 49.4364 28.4984C52.2615 24.9656 52.795 20.2437 50.8358 16.1688V16.16Z" fill={primaryColor}/>
<path d="M11.7923 32.8793C9.29961 32.8793 7.0518 31.6026 5.77484 29.4602C4.49788 27.3179 4.45413 24.7295 5.64362 22.5434C6.26461 21.4154 5.84478 19.9988 4.70776 19.378C3.57949 18.7659 2.16259 19.1769 1.5416 20.3049C-0.452558 23.9513 -0.365103 28.2623 1.7515 31.8387C3.87686 35.4152 7.6203 37.54 11.7748 37.54C13.0605 37.54 14.11 36.4995 14.11 35.2053C14.11 33.9111 13.0693 32.8706 11.7748 32.8706L11.7923 32.8793Z" fill={primaryColor}/>
<path d="M40.3228 16.5535H32.3899L36.5968 6.7336C37.2178 5.29078 37.0691 3.63808 36.2033 2.32643C35.3374 1.01477 33.8855 0.227783 32.3111 0.227783H23.2412C21.5269 0.227783 19.9613 1.16343 19.1479 2.65872L7.69905 23.6452C6.90313 25.1055 6.9381 26.8282 7.78649 28.2622C8.63488 29.6963 10.1305 30.5445 11.8011 30.5445H21.1421L14.9497 53.2449C14.6611 54.3117 15.1596 55.4398 16.1392 55.9382C16.4803 56.1131 16.8389 56.1918 17.1975 56.1918C17.8885 56.1918 18.562 55.8857 19.0168 55.3173L43.97 24.1262C45.0983 22.7096 45.3169 20.8208 44.5298 19.1943C43.7426 17.5679 42.1333 16.5535 40.3228 16.5535ZM22.4016 43.6174L25.629 31.7775C26.0138 30.3609 25.7252 28.8831 24.833 27.7201C23.9409 26.5571 22.594 25.8838 21.1246 25.8838H11.7836L23.2325 4.89728H32.3024L28.0954 14.7172C27.4744 16.16 27.6231 17.8127 28.489 19.1244C29.3549 20.436 30.8068 21.223 32.3811 21.223H40.314L22.3928 43.6174H22.4016Z" fill={secondaryColor}/>
</svg>

);

export default LightningIcon;
