import React from 'react';
const MuteIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="53" height="53" viewBox="0 0 53 53" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M51.6129 48.08L46.4788 42.9733C47.2397 37.4994 47.6333 31.9379 47.6333 26.4202C47.6333 20.9025 47.231 15.0525 46.4263 9.44738C45.7179 4.50681 41.4059 0.772977 36.3768 0.772977C34.0765 0.772977 31.8287 1.55995 30.0357 2.99403L23.0736 8.56419C21.8317 9.55231 20.2836 10.1032 18.7005 10.1032H17.6422C16.4352 10.1032 15.2894 10.3306 14.1874 10.6716L4.96879 1.45501C4.05917 0.545595 2.58103 0.545595 1.67141 1.45501C0.761793 2.36443 0.761793 3.84224 1.67141 4.75166L9.93669 13.0151C7.91629 14.7815 6.49938 17.2299 6.1058 20.0281C5.80842 22.1442 5.651 24.2953 5.651 26.4202C5.651 28.5451 5.79967 30.705 6.1058 32.8124C6.91046 38.47 11.8696 42.7372 17.6422 42.7372H18.7005C20.2836 42.7372 21.8317 43.2794 23.0736 44.2762L30.0357 49.8464C31.8287 51.2805 34.0765 52.0675 36.3768 52.0675C39.7616 52.0675 42.7966 50.3535 44.6508 47.739L48.3155 51.3942C48.7703 51.8489 49.3651 52.0762 49.9598 52.0762C50.5546 52.0762 51.1581 51.8489 51.6129 51.3942C52.5225 50.4848 52.5225 49.0069 51.6129 48.0975V48.08ZM36.3855 47.398C35.1436 47.398 33.9278 46.9695 32.957 46.1913L25.9949 40.6211C23.9308 38.9772 21.3419 38.0677 18.7092 38.0677H17.6509C14.1874 38.0677 11.2136 35.5231 10.7326 32.1478C10.4615 30.2503 10.3303 28.3178 10.3303 26.4115C10.3303 24.5052 10.4702 22.5727 10.7326 20.6752C11.2136 17.2999 14.1874 14.7552 17.6509 14.7552H18.7092C21.3419 14.7552 23.9308 13.8458 25.9949 12.2019L32.957 6.63172C33.9278 5.85347 35.1435 5.43369 36.3768 5.43369C39.0881 5.43369 41.4234 7.44492 41.8083 10.1032C42.5779 15.4897 42.9715 20.9812 42.9715 26.4202C42.9715 31.8592 42.5779 37.3507 41.8083 42.7372C41.4322 45.3955 39.0969 47.4067 36.3768 47.4067L36.3855 47.398Z" fill={secondaryColor}/>
<path d="M23.6334 20.1067C22.7238 19.1973 21.2457 19.1973 20.3361 20.1067C19.4265 21.0162 19.4265 22.494 20.3361 23.4034L31.9949 35.0596C32.9045 35.969 34.3826 35.969 35.2923 35.0596C36.2019 34.1502 36.2019 32.6724 35.2923 31.763L23.6334 20.1067Z" fill={primaryColor}/>
</svg>

);

export default MuteIcon;
