import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FileData } from '@aperturerobotics/chonky';
import fileService from './galleryService';

export interface FileBrowserData {
  rootFolderId: string;
  fileMap: CustomFileMap;
}

export interface CustomFileData extends FileData {
  parentId?: string;
  childrenIds?: string[];
  image?: any;
}

export interface CustomFileMap {
  [fileId: string]: CustomFileData;
}

const useGalleryFileMap = (publisherId: number) => {
  const [timeStamp, setTimeStamp] = useState(new Date());

  const [fileBrowserData, setFileBrowserData] = useState<FileBrowserData>({
    rootFolderId: '-1',
    fileMap: {
      '-1': {
        id: '-1',
        name: 'root',
        isDir: true,
        childrenIds: [],
        childrenCount: 0,
      },
    },
  });

  useEffect(() => {
    if (publisherId)
      fileService.list(publisherId).then((data: any) => {
        if (data.hasError) return;
        setFileBrowserData({
          rootFolderId: '-1',
          fileMap: data.records.reduce((acc: any, file: any) => {
            acc[file.id] = { ...file, thumbnailUrl: file.image?.thumbnailUrl };
            return acc;
          }, {}),
        });
      });
  }, [timeStamp, publisherId]);

  const [currentFolderId, setCurrentFolderId] = useState(
    fileBrowserData.rootFolderId
  );

  // Setup the function used to reset our file map to its initial value. Note that
  // here and below we will always use `useCallback` hook for our functions - this is
  // a crucial React performance optimization, read more about it here:
  // https://reactjs.org/docs/hooks-reference.html#usecallback
  const resetFileMap = useCallback(() => {
    setCurrentFolderId(fileBrowserData.rootFolderId);
  }, [fileBrowserData.rootFolderId]);

  // Setup logic to listen to changes in current folder ID without having to update
  // `useCallback` hooks. Read more about it here:
  // https://reactjs.org/docs/hooks-faq.html#is-there-something-like-instance-variables
  const currentFolderIdRef = useRef(currentFolderId);
  useEffect(() => {
    currentFolderIdRef.current = currentFolderId;
  }, [currentFolderId]);

  // Function that will be called when user deletes files either using the toolbar
  // button or `Delete` key.
  const deleteFiles = useCallback((files: CustomFileData[]) => {
    for (const file of files) {
      fileService.delete(file.id).then((data) => {
        setTimeStamp(new Date());
      });
    }
  }, []);
  // Function that will be called when files are moved from one folder to another
  // using drag & drop.
  const moveFiles = useCallback(
    (
      files: CustomFileData[],
      source: CustomFileData,
      destination: CustomFileData
    ) => {
      for (const file of files) {
        fileService
          .update({
            id: file.id,
            parentId: destination.id,
            name: file.name,
            isDir: file.isDir,
          })
          .then((data) => {
            setTimeStamp(new Date());
          });
      }
    },
    []
  );
  const createFolder = useCallback(
    (folderName: string) => {
      const newFolder = {
        name: folderName,
        isDir: true,
        parentId: currentFolderIdRef.current,
        publisherId: publisherId,
      };
      fileService.create(newFolder).then((data) => {
        setTimeStamp(new Date());
      });
    },
    [publisherId]
  );

  return {
    fileBrowserData,
    currentFolderId,
    setCurrentFolderId,
    resetFileMap,
    deleteFiles,
    moveFiles,
    createFolder,
    setTimeStamp,
  };
};
export default useGalleryFileMap;
