import React from 'react';
const TransactionIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="43" height="57" viewBox="0 0 43 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M42.0151 18.5916C41.7964 15.4698 40.3882 12.6279 38.0617 10.5817L30.2338 3.71738C28.1084 1.85483 25.3796 0.822998 22.5458 0.822998H13.0735C7.18728 0.822998 2.21062 5.23892 1.48468 11.1064C0.758737 16.9651 0.400146 22.92 0.400146 28.805C0.400146 34.69 0.7675 40.7498 1.50219 46.6435C2.21939 52.4236 7.19602 56.787 13.0735 56.787H29.709C35.5953 56.787 40.572 52.3711 41.2979 46.5036C42.0239 40.6449 42.3824 34.69 42.3824 28.805C42.3824 25.4122 42.26 21.9669 42.0238 18.5828L42.0151 18.5916ZM33.155 12.488H30.7148C29.4291 12.488 28.3796 11.4387 28.3796 10.1532V8.29943L33.155 12.4793V12.488ZM36.6623 45.944C36.225 49.4767 33.2338 52.135 29.7003 52.135H13.0648C9.54003 52.135 6.55749 49.5292 6.12892 46.0751C5.42047 40.3738 5.06189 34.5675 5.06189 28.8137C5.06189 23.06 5.42045 17.3499 6.11141 11.6835C6.54872 8.15077 9.54003 5.49252 13.0735 5.49252H22.5458C22.9393 5.49252 23.3329 5.53624 23.7265 5.59745V10.1532C23.7265 14.0095 26.8664 17.1487 30.7235 17.1487H36.9947C37.1871 17.7171 37.327 18.303 37.3707 18.9151C37.5981 22.1943 37.7206 25.5171 37.7206 28.805C37.7206 34.5063 37.362 40.2689 36.6711 45.9352L36.6623 45.944Z" fill={secondaryColor}/>
<path d="M30.0327 31.8305C29.1231 30.9211 27.645 30.9211 26.7353 31.8305C25.8257 32.7399 25.8257 34.2177 26.7353 35.1271L27.4176 35.8092H12.059C10.7733 35.8092 9.72369 36.8498 9.72369 38.1439C9.72369 39.4381 10.7645 40.4787 12.059 40.4787H27.4176L26.7353 41.1608C25.8257 42.0702 25.8257 43.548 26.7353 44.4574C27.1901 44.9121 27.7849 45.1394 28.3884 45.1394C28.9919 45.1394 29.5779 44.9121 30.0415 44.4574L34.7032 39.7966C35.6128 38.8872 35.6128 37.4094 34.7032 36.5L30.0415 31.8393L30.0327 31.8305Z" fill={primaryColor}/>
<path d="M14.3855 31.1484C14.9803 31.1484 15.575 30.9211 16.0386 30.4664C16.9482 29.557 16.9482 28.0792 16.0386 27.1698L15.3564 26.4877H30.7148C32.0005 26.4877 33.0501 25.4471 33.0501 24.1529C33.0501 22.8588 32.0093 21.8182 30.7148 21.8182H15.3564L16.0386 21.1361C16.9482 20.2267 16.9482 18.7489 16.0386 17.8395C15.129 16.9301 13.6508 16.9301 12.7412 17.8395L8.07937 22.5002C7.16975 23.4097 7.16975 24.8875 8.07937 25.7969L12.7412 30.4577C13.196 30.9124 13.7908 31.1397 14.3943 31.1397L14.3855 31.1484Z" fill={primaryColor}/>
</svg>

);

export default TransactionIcon;
