import React from 'react';
const HouseIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M52.9783 19.5703L38.0046 4.59995C35.4769 2.07283 31.9784 0.630005 28.4012 0.630005C24.8239 0.630005 21.3254 2.07283 18.7977 4.59995L3.82402 19.5703C1.61995 21.7739 0.412964 24.6945 0.412964 27.8075V44.9378C0.412964 51.3649 5.64326 56.594 12.0718 56.594H16.7336C20.5907 56.594 23.7306 53.4548 23.7306 49.5985V44.9378C23.7306 42.3669 25.821 40.277 28.3924 40.277C30.9638 40.277 33.0542 42.3669 33.0542 44.9378V49.5985C33.0542 53.4548 36.1941 56.594 40.0513 56.594H44.713C51.1416 56.594 56.3719 51.3649 56.3719 44.9378V27.8075C56.3719 24.6945 55.1561 21.7739 52.9608 19.5703H52.9783ZM51.7276 44.9378C51.7276 48.794 48.5877 51.9333 44.7305 51.9333H40.0687C38.783 51.9333 37.7335 50.8839 37.7335 49.5985V44.9378C37.7335 39.7961 33.544 35.6075 28.4012 35.6075C23.2583 35.6075 19.0688 39.7961 19.0688 44.9378V49.5985C19.0688 50.8839 18.0193 51.9333 16.7336 51.9333H12.0718C8.21468 51.9333 5.07475 48.794 5.07475 44.9378V27.8075C5.07475 25.945 5.80069 24.1873 7.12138 22.8669L22.0951 7.89658C23.7481 6.24389 26.0484 5.29076 28.4012 5.29076C30.7539 5.29076 33.0542 6.24389 34.7072 7.89658L49.6809 22.8669C51.0016 24.1873 51.7276 25.9362 51.7276 27.8075V44.9378Z" fill={secondaryColor}/>
<path d="M34.0338 13.458C31.0251 10.4499 25.7773 10.4499 22.7685 13.458L18.5878 17.6378C17.6782 18.5472 17.6782 20.025 18.5878 20.9345C19.4974 21.8439 20.9755 21.8439 21.8851 20.9345L26.0659 16.7546C27.2904 15.5304 29.5032 15.5304 30.7277 16.7546L34.9084 20.9345C35.3632 21.3892 35.958 21.6165 36.5615 21.6165C37.1649 21.6165 37.751 21.3892 38.2145 20.9345C39.1241 20.025 39.1241 18.5472 38.2145 17.6378L34.0338 13.458Z" fill={primaryColor}/>
</svg>

);

export default HouseIcon;
