import React from 'react';
const SendIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M51.9456 3.43383C49.1468 0.626882 45.176 -0.553607 41.3451 0.259619C36.6483 1.25648 31.9604 2.52441 27.4035 4.02844C20.1878 6.40691 13.1208 9.43247 6.40363 13.0177C2.5465 15.0813 0.141235 19.0513 0.141235 23.3797C0.141235 28.0667 2.92255 32.2903 7.23448 34.1353L14.4502 37.2308C16.112 37.9391 17.4153 39.2508 18.1237 40.9035L21.2198 48.1176C23.0653 52.4286 27.2898 55.2093 31.9778 55.2093C36.3073 55.2093 40.2781 52.8133 42.3422 48.9483C45.9282 42.2239 48.9544 35.1584 51.3334 27.9531C52.8378 23.3972 54.106 18.7102 55.1118 14.0145C55.9252 10.1845 54.7444 6.22328 51.9369 3.41634L51.9456 3.43383ZM50.555 13.0614C49.5929 17.5822 48.3684 22.1118 46.9165 26.5015C44.625 33.4533 41.7038 40.2739 38.2402 46.7622C36.9895 49.0969 34.593 50.5485 31.9866 50.5485C29.1703 50.5485 26.6251 48.8696 25.5143 46.2813L22.4181 39.0672C21.2373 36.3039 19.0595 34.1266 16.2957 32.9461L9.07994 29.8506C6.49103 28.74 4.81174 26.1954 4.81174 23.3797C4.81174 20.7739 6.26369 18.378 8.59896 17.1363C15.0887 13.6735 21.902 10.7529 28.8641 8.46184C33.2635 7.01028 37.7853 5.78606 42.3072 4.82418C44.59 4.3345 46.9602 5.05154 48.6395 6.73046C50.3188 8.40938 51.036 10.7791 50.5462 13.0614H50.555Z" fill={secondaryColor}/>
<path d="M28.8204 23.2573L24.1585 27.9181C23.2489 28.8275 23.2489 30.3053 24.1585 31.2147C25.0681 32.1241 26.5463 32.1241 27.4559 31.2147L32.1178 26.5539C33.0274 25.6445 33.0274 24.1667 32.1178 23.2573C31.2081 22.3479 29.73 22.3479 28.8204 23.2573Z" fill={primaryColor}/>
</svg>

);

export default SendIcon;
