import React from 'react';
const HeadphonesIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M48.4438 13.4904L45.1465 16.7871C49.5459 21.1855 51.9774 27.0442 51.9774 33.279V37.9397C51.9774 39.6799 51.0065 41.1926 49.5808 41.9884C49.2572 37.1353 45.2514 33.279 40.3097 33.279H37.9745C34.1174 33.279 30.9774 36.4182 30.9774 40.2745V49.6047C30.9774 53.461 34.1174 56.6002 37.9745 56.6002H40.3097C45.4526 56.6002 49.642 52.4117 49.642 47.27V46.9377C53.6566 45.8971 56.6391 42.2769 56.6391 37.9397V33.279C56.6391 25.8025 53.7266 18.7721 48.4438 13.4904ZM44.9715 47.27C44.9715 49.8408 42.8812 51.9307 40.3097 51.9307H37.9745C36.6888 51.9307 35.6392 50.8814 35.6392 49.596V40.2657C35.6392 38.9803 36.6888 37.931 37.9745 37.931H40.3097C42.8812 37.931 44.9715 40.0209 44.9715 42.5918V47.2525V47.27Z" fill={secondaryColor}/>
<path d="M19.3186 33.279H16.9833C12.0504 33.279 8.03586 37.144 7.71224 41.9884C6.29534 41.1926 5.31573 39.6799 5.31573 37.9397V33.279C5.31573 27.0442 7.73846 21.1855 12.1466 16.7871L8.84925 13.4904C3.56648 18.7721 0.653961 25.8025 0.653961 33.279V37.9397C0.653961 42.2769 3.63645 45.8971 7.65101 46.9377V47.27C7.65101 52.4117 11.8405 56.6002 16.9833 56.6002H19.3186C23.1757 56.6002 26.3156 53.461 26.3156 49.6047V40.2745C26.3156 36.4182 23.1757 33.279 19.3186 33.279ZM21.6539 49.596C21.6539 50.8814 20.6043 51.9307 19.3186 51.9307H16.9833C14.4119 51.9307 12.3215 49.8408 12.3215 47.27V42.6092C12.3215 40.0384 14.4119 37.9485 16.9833 37.9485H19.3186C20.6043 37.9485 21.6539 38.9978 21.6539 40.2832V49.6135V49.596Z" fill={secondaryColor}/>
<path d="M43.1785 19.1568C41.8054 19.1568 40.4322 18.7546 39.2427 17.9239C32.9978 13.5954 24.3127 13.5954 18.0679 17.9239C15.304 19.8301 11.5693 19.4891 9.1816 17.1019C7.70347 15.6241 6.96002 13.5692 7.14369 11.488C7.32736 9.44182 8.38568 7.6055 10.0562 6.45124C15.5402 2.64744 21.9687 0.636208 28.6509 0.636208C35.3331 0.636208 41.7616 2.64744 47.2455 6.45124C48.9161 7.6055 49.9744 9.44182 50.1581 11.488C50.3418 13.5779 49.607 15.6241 48.1202 17.1019C46.7645 18.4573 44.9715 19.1568 43.1785 19.1568ZM28.6509 9.95774C33.4089 9.95774 37.9832 11.3918 41.8928 14.0938C42.7937 14.7234 44.0269 14.601 44.8228 13.8053C45.3213 13.3069 45.575 12.6073 45.5138 11.899C45.4788 11.5055 45.3126 10.7797 44.5867 10.2725C39.8812 7.01087 34.3797 5.28822 28.6509 5.28822C22.9221 5.28822 17.4206 7.01087 12.7151 10.2725C11.9892 10.7797 11.823 11.5055 11.788 11.899C11.7268 12.6073 11.9717 13.2981 12.4789 13.8053C13.2749 14.601 14.5081 14.7147 15.409 14.0938C19.3186 11.3918 23.8929 9.95774 28.6509 9.95774Z" fill={primaryColor}/>
</svg>

);

export default HeadphonesIcon;
