import React from 'react';
const HourglassIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="53" height="57" viewBox="0 0 53 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M49.3753 49.4417C48.7368 49.5728 48.0808 49.6603 47.4424 49.7827C47.2849 47.2556 46.3491 44.8421 44.7048 42.9359L34.4716 30.9998C33.8506 30.2827 33.5182 29.3646 33.5182 28.4115C33.5182 27.4583 33.8593 26.5401 34.4716 25.8231L44.7048 13.887C46.3491 11.972 47.2849 9.55859 47.4424 7.04021C48.0896 7.16263 48.7368 7.25003 49.3753 7.3812C50.6435 7.63479 51.868 6.82159 52.1216 5.55365C52.3753 4.28572 51.5618 3.06149 50.2936 2.80791C34.6639 -0.348811 18.3434 -0.348811 2.72245 2.80791C1.46298 3.06149 0.640804 4.29446 0.894447 5.55365C1.12185 6.66419 2.09268 7.42496 3.17722 7.42496C3.32591 7.42496 3.48337 7.40743 3.6408 7.3812C4.27928 7.25003 4.93522 7.16263 5.5737 7.04021C5.73114 9.56733 6.667 11.9807 8.3113 13.887L18.5445 25.8231C19.1655 26.5401 19.4979 27.4583 19.4979 28.4115C19.4979 29.3646 19.1567 30.2827 18.5445 30.9998L8.3113 42.9359C6.667 44.8509 5.73114 47.2643 5.5737 49.7827C4.92648 49.6603 4.27928 49.5728 3.6408 49.4417C2.36384 49.1793 1.14809 50.0013 0.894447 51.2692C0.640804 52.5372 1.45423 53.7614 2.72245 54.015C10.5329 55.589 18.5357 56.3935 26.5124 56.3935C34.489 56.3935 42.4919 55.5977 50.3024 54.015C51.5618 53.7614 52.384 52.5284 52.1304 51.2692C51.8767 50.01 50.661 49.1881 49.384 49.4417H49.3753ZM10.1918 50.5435V50.5172C10.1918 48.8383 10.7865 47.2293 11.8711 45.9701L22.113 34.0341C23.4512 32.4688 24.1859 30.4751 24.1859 28.4115C24.1859 26.3478 23.4512 24.3541 22.1043 22.7888L11.8623 10.844C10.7865 9.59354 10.1918 7.97582 10.1918 6.29691V6.2707C20.9935 4.73169 32.0401 4.73169 42.8418 6.2707V6.29691C42.8418 7.97582 42.247 9.5848 41.1625 10.844L30.9206 22.7801C29.5824 24.3453 28.8477 26.339 28.8477 28.4027C28.8477 30.4664 29.5824 32.4601 30.9293 34.0253L41.1712 45.9701C42.247 47.2206 42.8418 48.8383 42.8418 50.5172V50.5435C32.0401 52.0825 20.9935 52.0825 10.1918 50.5435Z" fill={secondaryColor}/>
<path d="M35.8447 44.7285H17.1888C15.9031 44.7285 14.8535 45.769 14.8535 47.0632C14.8535 48.3574 15.8943 49.398 17.1888 49.398H35.8447C37.1304 49.398 38.1799 48.3574 38.1799 47.0632C38.1799 45.769 37.1391 44.7285 35.8447 44.7285Z" fill={primaryColor}/>
<path d="M33.5181 14.4205C33.5181 13.1351 32.4773 12.0857 31.1829 12.0857H21.8506C20.5649 12.0857 19.5153 13.1263 19.5153 14.4205C19.5153 15.7146 20.5561 16.7552 21.8506 16.7552H31.1829C32.4686 16.7552 33.5181 15.7146 33.5181 14.4205Z" fill={primaryColor}/>
</svg>

);

export default HourglassIcon;
