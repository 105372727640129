import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useCheckPermission from '../../hooks/useCheckPermission';
import pageService from '../../services/pageService';
import PageForm from './PageForm';
import publisherService from '../../services/publisherService';
import tagService from '../../services/tagService';
import dayjs from 'dayjs';
import moment from 'moment';
import locationService from '../../services/locationService';
import { getHtmlContent } from '../../utils/htmContentLoader';
import contentService from '../../services/contentService';

const PageEdit = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const params = useParams();
  const [errors, setErrors] = useState<string[]>([]);
  const [publishers, setPublishers] = useState<any[]>([]);
  const [now, setNow] = useState(moment());
  const [versions, setVersions] = useState<any[]>([]);

  const [entity, setEntity] = useState<any>({
    id: 0,
    contentUrl: '',
    name: '',
    publisherId: '',
    isDraft: false,
    imageId: '',
  });

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    publisherService
      .list()
      .then((response: any) => {
        if (!response.hasError) setPublishers(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    pageService
      .get(params.id)
      .then((response: any) => {
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          getHtmlContent(response.result.contentUrl)
            .then((htmlText: any) => {
              setEntity({
                ...response.result,
                contentUrl: htmlText,
              });
            })
            .catch((error: any) => {
              console.error('Error fetching HTML page:', error);
            });
        }
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, [params.id]);

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    pageService
      .listVersions(params.id)
      .then((response: any) => {
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setVersions(response.records);
        }
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, [params.id]);

  const onSubmit = (entity: any, setSubmitting: any, isDraft: boolean) => {
    dispatch({ type: 'SHOW_SAVE' });
    pageService
      .update({ ...entity, isDraft })
      .then((response) => {
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Success!', {
            variant: 'success',
          });

          navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        if (setSubmitting) setSubmitting(false);
      });
  };

  return (
    <PageForm
      entity={entity}
      readonly={!checkPermission(['EditPage']) || entity.isDeleted}
      onSubmit={onSubmit}
      versions={versions}
      publishers={publishers}
      handleCancel={() => navigate(-1)}
      errors={errors}
    />
  );
};

export default PageEdit;
