import { Grid, Typography, Button, Alert } from '@mui/material';
import { useState } from 'react';

type CropImagePreviewProps = {
  crop1_1: {
    image: {
      url: string;
      data: string;
    };
  };
  crop4_3: {
    image: {
      url: string;
      data: string;
    };
  };
  crop16_9: {
    image: {
      url: string;
      data: string;
    };
  };

  imageUploaded: boolean;
  setOpenCrop1_1: (value: boolean) => void;
  setOpenCrop4_3: (value: boolean) => void;
  setOpenCrop16_9: (value: boolean) => void;
  uploadImage: () => void;
  uploading: boolean;
};

const CropImagePreview = ({
  crop1_1,
  crop4_3,
  crop16_9,
  imageUploaded,
  setOpenCrop1_1,
  setOpenCrop4_3,
  setOpenCrop16_9,
  uploadImage,
  uploading,
}: CropImagePreviewProps) => {
  return (
    <Grid pt={5} container spacing={3} justifyContent="center">
      <Grid item>
        {crop1_1.image?.url ? (
          <img
            width={150}
            height={150}
            onClick={() => {
              setOpenCrop1_1(true);
            }}
            src={crop1_1.image.url}
            alt=""
          />
        ) : (
          <div
            style={{
              backgroundColor: 'gray',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '150px',
              cursor: 'pointer',
              width: '150px',
            }}
            onClick={() => {
              setOpenCrop1_1(true);
            }}
          >
            <Typography variant="body1" color="white">
              1:1
            </Typography>
          </div>
        )}
      </Grid>
      <Grid item>
        {crop4_3.image?.url ? (
          <img
            width={(150 * 4) / 3}
            height={150}
            onClick={() => {
              setOpenCrop4_3(true);
            }}
            src={crop4_3.image.url}
            alt=""
          />
        ) : (
          <div
            style={{
              backgroundColor: 'gray',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 150,
              cursor: 'pointer',
              width: (150 * 4) / 3,
            }}
            onClick={() => {
              setOpenCrop4_3(true);
            }}
          >
            <Typography variant="body1" color="white">
              4:3
            </Typography>
          </div>
        )}
      </Grid>
      <Grid item>
        {crop16_9.image?.url ? (
          <img
            width={(150 * 16) / 9}
            height={150}
            onClick={() => {
              setOpenCrop16_9(true);
            }}
            src={crop16_9.image.url}
            alt=""
          />
        ) : (
          <div
            style={{
              backgroundColor: 'gray',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 150,
              cursor: 'pointer',
              width: (150 * 16) / 9,
            }}
            onClick={() => {
              setOpenCrop16_9(true);
            }}
          >
            <Typography variant="body1" color="white">
              16:9
            </Typography>
          </div>
        )}
      </Grid>
      {!imageUploaded && !uploading && (
        <Grid item container xs={12} justifyContent="center">
          <Grid item>
            <Alert
              severity="warning"
              children="Figyelem! A képek még nem kerültek feltöltésre!"
            />
            <Button
              onClick={() => {
                uploadImage();
              }}
              variant="contained"
            >
              Kiválasztott képek feltöltése
            </Button>
          </Grid>
        </Grid>
      )}
      {uploading && (
        <Grid item container xs={12} justifyContent="center">
          <Grid item>
            <Alert severity="info" children="Feltöltés folyamatban..." />
          </Grid>
        </Grid>
      )}
      {!uploading && imageUploaded && (
        <Grid item container xs={12} justifyContent="center">
          <Grid item>
            <Alert severity="success" children="Képek feltöltésre kerültek!" />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default CropImagePreview;
