import React from 'react';
const BoxIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M55.552 11.2869C54.8261 6.19768 50.7328 2.14031 45.5987 1.41453C34.4472 -0.159458 22.9808 -0.159503 11.838 1.42323C6.74762 2.14901 2.68929 6.24138 1.96335 11.3743C1.17618 16.9358 0.773865 22.5934 0.773865 28.2073C0.773865 33.8211 1.17619 39.5487 1.97211 45.1364C2.69805 50.2256 6.79133 54.283 11.9254 55.0088C17.4881 55.7958 23.1557 56.198 28.7621 56.198C34.3685 56.198 40.0974 55.7958 45.6863 55C50.7766 54.2742 54.8348 50.1819 55.5608 45.0489C56.3479 39.4875 56.7503 33.8299 56.7503 28.216C56.7503 22.6021 56.3479 16.8746 55.552 11.2869ZM50.9427 44.3931C50.5054 47.4886 48.074 49.9458 45.0303 50.383C34.3161 51.9133 23.2956 51.9132 12.5726 50.3917C9.47644 49.9545 7.01874 47.5236 6.58143 44.4806C5.81175 39.1203 5.42696 33.6463 5.42696 28.216C5.42696 22.7857 5.81174 17.3817 6.56392 12.0389C7.00124 8.9434 9.43272 6.48626 12.4764 6.04904C17.8467 5.27953 23.3219 4.89477 28.7447 4.89477C34.1674 4.89477 39.5813 5.27952 44.9253 6.03153C48.0215 6.46875 50.4792 8.89969 50.9165 11.9427C51.6862 17.303 52.071 22.777 52.071 28.2073C52.071 33.6375 51.6862 39.0415 50.9341 44.3843L50.9427 44.3931Z" fill={secondaryColor}/>
<path d="M38.0944 7.22072C36.8087 7.22072 35.7591 8.26134 35.7591 9.5555V20.6608C35.7591 21.8326 34.9808 22.4359 34.6484 22.6458C34.316 22.8469 33.4238 23.2754 32.383 22.7507L31.8933 22.5059C29.9341 21.5266 27.5901 21.5266 25.6309 22.5059L25.141 22.7507C24.1002 23.2754 23.1994 22.8557 22.8758 22.6458C22.5434 22.4359 21.765 21.8326 21.765 20.6608V9.5555C21.765 8.27008 20.7242 7.22072 19.4298 7.22072C18.1353 7.22072 17.0945 8.26134 17.0945 9.5555V20.6608C17.0945 23.1005 18.3365 25.3304 20.4094 26.607C22.4822 27.8925 25.0362 28.0061 27.214 26.9131L27.7038 26.6683C28.351 26.336 29.1382 26.336 29.7941 26.6683L30.2839 26.9131C31.281 27.4115 32.3481 27.6563 33.4151 27.6563C34.6921 27.6563 35.9603 27.3066 37.0885 26.607C39.1614 25.3216 40.4034 23.1005 40.4034 20.6608V9.5555C40.4034 8.27008 39.3626 7.22072 38.0681 7.22072H38.0944Z" fill={primaryColor}/>
</svg>

);

export default BoxIcon;
