import React from 'react';
const Abc_cubeIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="71" height="65" viewBox="0 0 71 65" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M29.4366 64.2784H1.82452C0.862424 64.2784 0.0752563 63.4914 0.0752563 62.5296V34.9236C0.0752563 33.9617 0.862424 33.1747 1.82452 33.1747H29.4366C30.3987 33.1747 31.1859 33.9617 31.1859 34.9236V62.5296C31.1859 63.4914 30.3987 64.2784 29.4366 64.2784ZM3.58253 60.7807H27.6961V36.6724H3.58253V60.7807Z" fill={secondaryColor}/>
<path d="M57.0487 64.2784H29.4454C28.4833 64.2784 27.6961 63.4914 27.6961 62.5296V34.9236C27.6961 33.9617 28.4833 33.1747 29.4454 33.1747H57.0487C58.0108 33.1747 58.798 33.9617 58.798 34.9236V62.5296C58.798 63.4914 58.0108 64.2784 57.0487 64.2784ZM31.1859 60.7807H55.2907V36.6724H31.1859V60.7807Z" fill={secondaryColor}/>
<path d="M43.2471 36.6812H15.6349C14.6728 36.6812 13.8857 35.8942 13.8857 34.9323V7.32633C13.8857 6.36445 14.6728 5.57745 15.6349 5.57745H43.2471C44.2092 5.57745 44.9963 6.36445 44.9963 7.32633V34.9323C44.9963 35.8942 44.2092 36.6812 43.2471 36.6812ZM17.3842 33.1835H41.4978V9.0752H17.3842V33.1835Z" fill={secondaryColor}/>
<path d="M43.2471 36.6812C42.9672 36.6812 42.696 36.6113 42.4424 36.4801C41.8651 36.1828 41.4978 35.5794 41.4978 34.9323V7.32632C41.4978 6.60928 41.9351 5.97095 42.5998 5.69988L54.1012 1.10033C54.6435 0.881718 55.247 0.951684 55.728 1.27523C56.2091 1.59877 56.4977 2.1409 56.4977 2.72677V26.8787C56.4977 27.4471 56.2178 27.9805 55.7543 28.3128L44.2529 36.3664C43.9555 36.5763 43.6057 36.6812 43.2471 36.6812ZM44.9963 8.51554V31.5745L52.9992 25.9693V5.31512L44.9963 8.51554Z" fill={secondaryColor}/>
<path d="M57.0487 64.2784C56.8213 64.2784 56.5939 64.2347 56.384 64.1472C55.728 63.8762 55.3082 63.2378 55.3082 62.5296V34.9236C55.3082 34.3552 55.5881 33.8218 56.0517 33.4895L67.5531 25.4359C68.0866 25.0599 68.7863 25.0162 69.3635 25.3135C69.9408 25.6108 70.3081 26.2142 70.3081 26.8613V51.0132C70.3081 51.4767 70.1245 51.9226 69.7921 52.2462L58.2907 63.745C57.9584 64.0773 57.5123 64.2609 57.0575 64.2609L57.0487 64.2784ZM58.798 35.8417V58.3148L66.8009 50.3136V30.2453L58.798 35.8505V35.8417Z" fill={secondaryColor}/>
<path d="M68.5501 28.6276H59.349C58.3869 28.6276 57.5997 27.8406 57.5997 26.8787C57.5997 25.9168 58.3869 25.1299 59.349 25.1299H68.5501C69.5122 25.1299 70.2994 25.9168 70.2994 26.8787C70.2994 27.8406 69.5122 28.6276 68.5501 28.6276Z" fill={secondaryColor}/>
<path d="M15.635 9.0752C14.9178 9.0752 14.2531 8.63798 13.9907 7.92094C13.6583 7.01153 14.1306 6.00592 15.0402 5.68238L27.6961 1.08283C27.8886 1.01288 28.0897 0.977905 28.2909 0.977905H54.7485C55.7106 0.977905 56.4977 1.7649 56.4977 2.72678C56.4977 3.68866 55.7106 4.47566 54.7485 4.47566H28.597L16.2297 8.97028C16.0286 9.04023 15.8274 9.0752 15.635 9.0752Z" fill={secondaryColor}/>
<path d="M1.83329 36.6812C1.17732 36.6812 0.547586 36.3051 0.250212 35.6668C-0.160865 34.7924 0.223967 33.7518 1.0986 33.3408L10.2997 29.0474C11.1743 28.6364 12.2152 29.0211 12.6262 29.8955C13.0373 30.77 12.6525 31.8106 11.7778 32.2216L2.57673 36.515C2.34058 36.6287 2.08694 36.6812 1.84204 36.6812H1.83329Z" fill={secondaryColor}/>
<path d="M45.5473 56.2248C41.0955 56.2248 37.4745 52.8583 37.4745 48.7222C37.4745 44.5861 41.0955 41.2195 45.5473 41.2195C46.282 41.2195 47.008 41.307 47.7077 41.4906C48.6435 41.7354 49.2033 42.6886 48.9671 43.6242C48.731 44.5599 47.7689 45.1195 46.833 44.8834C46.4132 44.7785 45.9846 44.7173 45.5473 44.7173C43.0196 44.7173 40.973 46.5099 40.973 48.7222C40.973 50.9345 43.0284 52.7271 45.5473 52.7271C45.9846 52.7271 46.422 52.6746 46.833 52.5697C47.7689 52.3336 48.7222 52.8932 48.9671 53.8289C49.2033 54.7645 48.6435 55.7177 47.7077 55.9625C47.008 56.1461 46.282 56.2336 45.5473 56.2336V56.2248Z" fill={primaryColor}/>
<path d="M34.0372 28.6276C33.3462 28.6276 32.6903 28.2079 32.4104 27.5258L29.4366 20.0844L26.4629 27.5258C26.1043 28.4265 25.081 28.855 24.1889 28.4965C23.288 28.138 22.8594 27.1236 23.218 26.2229L27.8186 14.7241C28.081 14.0595 28.7282 13.6223 29.4454 13.6223C30.1626 13.6223 30.8011 14.0595 31.0722 14.7241L35.6728 26.2229C36.0314 27.1236 35.5941 28.138 34.7019 28.4965C34.492 28.5839 34.2734 28.6189 34.0547 28.6189L34.0372 28.6276Z" fill={primaryColor}/>
<path d="M32.8914 25.7507H25.9906C25.0285 25.7507 24.2413 24.9637 24.2413 24.0019C24.2413 23.04 25.0285 22.253 25.9906 22.253H32.8914C33.8535 22.253 34.6407 23.04 34.6407 24.0019C34.6407 24.9637 33.8535 25.7507 32.8914 25.7507Z" fill={primaryColor}/>
<path d="M12.1802 55.945C11.2181 55.945 10.4309 55.158 10.4309 54.1962V43.2657C10.4309 42.3038 11.2181 41.5168 12.1802 41.5168C13.1423 41.5168 13.9294 42.3038 13.9294 43.2657V54.1962C13.9294 55.158 13.1423 55.945 12.1802 55.945Z" fill={primaryColor}/>
<path d="M14.2531 50.4798C13.3522 50.4798 12.4951 50.3574 11.6904 50.1213C10.7633 49.8502 10.2385 48.8709 10.5096 47.9527C10.7808 47.0258 11.7604 46.5011 12.6787 46.7722C13.151 46.9121 13.702 46.9908 14.2531 46.9908C17.3318 46.9908 17.3318 46.8159 17.3318 45.8628C17.3318 44.9096 17.3318 44.7348 14.2531 44.7348C13.702 44.7348 13.1598 44.8047 12.6787 44.9534C11.7516 45.2245 10.7808 44.6998 10.5096 43.7729C10.2385 42.846 10.7633 41.8754 11.6904 41.6043C12.4951 41.3682 13.3609 41.2457 14.2531 41.2457C16.7108 41.2457 20.8303 41.2458 20.8303 45.8715C20.8303 50.4973 16.7108 50.4973 14.2531 50.4973V50.4798Z" fill={primaryColor}/>
<path d="M14.253 56.2248C13.3522 56.2248 12.495 56.1024 11.6904 55.8663C10.9469 55.6477 10.4396 54.9657 10.4396 54.1874V49.0107C10.4396 48.2325 10.9469 47.5504 11.6904 47.3318C12.495 47.0957 13.3609 46.9733 14.253 46.9733C16.7107 46.9733 20.8303 46.9733 20.8303 51.5991C20.8303 56.2249 16.7107 56.2248 14.253 56.2248ZM13.9294 52.7183C14.0344 52.7183 14.1393 52.7183 14.253 52.7183C17.3317 52.7183 17.3317 52.5435 17.3317 51.5903C17.3317 50.6372 17.3317 50.4623 14.253 50.4623C14.1481 50.4623 14.0344 50.4623 13.9294 50.4623V52.7009V52.7183Z" fill={primaryColor}/>
</svg>

);

export default Abc_cubeIcon;
