import React from 'react';
const SuitcaseIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="52" viewBox="0 0 57 52" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M46.4646 10.581C45.0127 10.4061 43.5608 10.2487 42.1002 10.1088C40.3509 4.25881 35.0332 0.166443 28.7621 0.166443C22.491 0.166443 17.1644 4.25881 15.4152 10.1088C13.9108 10.2487 12.4153 10.4061 10.9197 10.5985C5.13836 11.3155 0.773865 16.2911 0.773865 22.1673V38.7991C0.773865 44.684 5.19076 49.6596 11.0595 50.3854C16.9021 51.1112 22.8671 51.4697 28.7621 51.4697C34.6571 51.4697 40.7008 51.1025 46.6046 50.3679C52.3859 49.6509 56.7503 44.6753 56.7503 38.7991V22.1673C56.7503 16.2824 52.3334 11.3068 46.4646 10.581ZM28.7621 4.82721C32.2956 4.82721 35.383 6.77719 36.9574 9.73279C31.5084 9.42674 26.0245 9.42674 20.558 9.73279C22.1324 6.77719 25.2198 4.82721 28.7533 4.82721H28.7621ZM52.0885 38.7991C52.0885 42.3231 49.4821 45.3049 46.0273 45.7334C34.6308 47.15 22.9808 47.15 11.6281 45.7509C8.1033 45.3137 5.43572 42.3231 5.43572 38.7903V22.1586C5.43572 18.6346 8.04206 15.6528 11.4969 15.2243C17.2082 14.516 23.0157 14.1574 28.7621 14.1574C34.5084 14.1574 40.2373 14.5072 45.8962 15.2068C49.4209 15.644 52.0885 18.6346 52.0885 22.1673V38.7991Z" fill={secondaryColor}/>
<path d="M14.7679 16.4835C13.4822 16.4835 12.4327 17.5241 12.4327 18.8182C12.4327 20.1124 11.3831 21.153 10.0974 21.153C8.81173 21.153 7.76213 22.1935 7.76213 23.4877C7.76213 24.7819 8.80298 25.8225 10.0974 25.8225C13.9546 25.8225 17.0945 22.6832 17.0945 18.827C17.0945 17.5416 16.0536 16.4922 14.7592 16.4922L14.7679 16.4835Z" fill={primaryColor}/>
<path d="M47.418 21.153C46.1323 21.153 45.0827 20.1036 45.0827 18.8182C45.0827 17.5328 44.0418 16.4835 42.7474 16.4835C41.4529 16.4835 40.4122 17.5241 40.4122 18.8182C40.4122 22.6745 43.5521 25.8137 47.4092 25.8137C48.6949 25.8137 49.7444 24.7732 49.7444 23.479C49.7444 22.1848 48.7037 21.1442 47.4092 21.1442L47.418 21.153Z" fill={primaryColor}/>
<path d="M10.1062 35.144C8.82049 35.144 7.77089 36.1845 7.77089 37.4787C7.77089 38.7729 8.81174 39.8135 10.1062 39.8135C11.4006 39.8135 12.4415 40.8628 12.4415 42.1482C12.4415 43.4336 13.4822 44.4829 14.7767 44.4829C16.0711 44.4829 17.112 43.4424 17.112 42.1482C17.112 38.2919 13.9721 35.1527 10.1149 35.1527L10.1062 35.144Z" fill={primaryColor}/>
<path d="M47.418 35.1439C43.5609 35.1439 40.4209 38.2832 40.4209 42.1394C40.4209 43.4249 41.4617 44.4742 42.7561 44.4742C44.0506 44.4742 45.0914 43.4336 45.0914 42.1394C45.0914 40.8453 46.141 39.8047 47.4267 39.8047C48.7125 39.8047 49.7619 38.7641 49.7619 37.47C49.7619 36.1758 48.7212 35.1352 47.4267 35.1352L47.418 35.1439Z" fill={primaryColor}/>
</svg>

);

export default SuitcaseIcon;
