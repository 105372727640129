import React from 'react';
const School_busIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="60" height="59" viewBox="0 0 60 59" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M54.1654 53.381H6.10437C3.69039 53.381 1.72247 51.4135 1.72247 49.0001V46.1319C1.72247 44.829 2.78077 43.7709 4.08397 43.7709H56.1858C57.489 43.7709 58.5473 44.829 58.5473 46.1319V49.0001C58.5473 51.4135 56.5794 53.381 54.1654 53.381ZM5.08981 47.1463V49.0001C5.08981 49.5597 5.54461 50.0144 6.10437 50.0144H54.1654C54.7251 50.0144 55.1799 49.5597 55.1799 49.0001V47.1463H5.09855H5.08981Z" fill={secondaryColor}/>
<path d="M14.0985 58.7238H8.7545C6.83906 58.7238 5.29095 57.1673 5.29095 55.261C5.29095 54.3341 6.04313 53.5734 6.97899 53.5734C7.91484 53.5734 8.66703 54.3254 8.66703 55.261C8.66703 55.3135 8.71076 55.3572 8.76324 55.3572H14.1072C14.1597 55.3572 14.2034 55.3135 14.2034 55.261C14.2034 54.3341 14.9556 53.5734 15.8915 53.5734C16.8273 53.5734 17.5795 54.3254 17.5795 55.261C17.5795 57.1673 16.0227 58.7238 14.116 58.7238H14.0985Z" fill={secondaryColor}/>
<path d="M51.5064 58.7238H46.1624C44.247 58.7238 42.6989 57.1673 42.6989 55.261C42.6989 54.3341 43.4511 53.5734 44.3869 53.5734C45.3228 53.5734 46.075 54.3254 46.075 55.261C46.075 55.3135 46.1187 55.3572 46.1712 55.3572H51.5152C51.5677 55.3572 51.6114 55.3135 51.6114 55.261C51.6114 54.3341 52.3636 53.5734 53.2994 53.5734C54.2353 53.5734 54.9875 54.3254 54.9875 55.261C54.9875 57.1673 53.4306 58.7238 51.5239 58.7238H51.5064Z" fill={secondaryColor}/>
<path d="M55.075 43.5785C54.1479 43.5785 53.3869 42.8265 53.3869 41.8909V38.3319C53.3869 36.6268 52.5735 35.0003 51.2091 33.9772L49.075 32.377C47.317 31.0566 46.2674 28.9579 46.2674 26.7544V26.6582H14.0023V26.7544C14.0023 28.9579 12.9527 31.0566 11.1947 32.377L9.06061 33.9772C7.69619 35.0003 6.88279 36.6268 6.88279 38.3319V41.8909C6.88279 42.8178 6.1306 43.5785 5.19475 43.5785C4.25889 43.5785 3.50671 42.8265 3.50671 41.8909V38.3319C3.50671 35.5774 4.82741 32.9366 7.03148 31.284L9.16557 29.6837C10.0839 28.9929 10.6262 27.8999 10.6262 26.7544V26.562C10.6262 24.7607 12.0956 23.2916 13.8973 23.2916H46.3636C48.1654 23.2916 49.6348 24.7607 49.6348 26.562V26.7544C49.6348 27.8999 50.1858 28.9929 51.0954 29.6837L53.2295 31.284C55.4336 32.9366 56.7543 35.5687 56.7543 38.3319V41.8909C56.7543 42.8178 56.0021 43.5785 55.0662 43.5785H55.075Z" fill={secondaryColor}/>
<path d="M35.4745 41.8034H24.7865C23.8594 41.8034 23.0984 41.0514 23.0984 40.1158C23.0984 39.1801 23.8506 38.4281 24.7865 38.4281H35.4745C36.4016 38.4281 37.1625 39.1801 37.1625 40.1158C37.1625 41.0514 36.4103 41.8034 35.4745 41.8034Z" fill={secondaryColor}/>
<path d="M14.9906 43.5785C14.3696 43.5785 13.7749 43.2375 13.4862 42.6516L12.2005 40.072C12.2005 40.072 12.148 40.0196 12.1131 40.0196H6.08685C5.15974 40.0196 4.3988 39.2676 4.3988 38.3319C4.3988 37.3963 5.15099 36.6443 6.08685 36.6443H12.1131C13.4338 36.6443 14.6232 37.3788 15.2092 38.5593L16.495 41.1389C16.9148 41.9696 16.5737 42.9839 15.7428 43.3949C15.4979 43.5173 15.2442 43.5698 14.9906 43.5698V43.5785Z" fill={secondaryColor}/>
<path d="M45.2704 43.5786C45.0167 43.5786 44.7631 43.5173 44.5182 43.4037C43.6873 42.9839 43.3462 41.9783 43.766 41.1476L45.0517 38.568C45.6465 37.3875 46.8272 36.653 48.1479 36.653H54.1741C55.1012 36.653 55.8622 37.405 55.8622 38.3407C55.8622 39.2763 55.11 40.0283 54.1741 40.0283H48.1479C48.1479 40.0283 48.0779 40.0458 48.0604 40.0808L46.7747 42.6604C46.4773 43.2463 45.8826 43.5873 45.2704 43.5873V43.5786Z" fill={secondaryColor}/>
<path d="M17.6583 36.4519C16.7311 36.4519 15.9702 35.6999 15.9702 34.7729C15.9702 33.1028 15.1393 31.5638 13.7574 30.6368L11.3784 29.0454C10.6087 28.5295 10.3988 27.4801 10.9148 26.7106C11.4309 25.9411 12.4717 25.7313 13.2501 26.2472L15.6291 27.8387C17.9556 29.3864 19.3463 31.9835 19.3463 34.7729C19.3463 35.6999 18.5941 36.4606 17.6583 36.4606V36.4519Z" fill={secondaryColor}/>
<path d="M42.6027 36.4519C41.6756 36.4519 40.9147 35.6999 40.9147 34.7729C40.9147 31.9747 42.3053 29.3864 44.6319 27.8387L47.0109 26.2472C47.7805 25.7313 48.8301 25.9411 49.3461 26.7106C49.8622 27.4801 49.6522 28.5295 48.8826 29.0454L46.5036 30.6368C45.1216 31.5638 44.2908 33.1028 44.2908 34.7729C44.2908 35.6999 43.5386 36.4606 42.6027 36.4606V36.4519Z" fill={secondaryColor}/>
<path d="M52.3986 27.5501C51.4715 27.5501 50.7106 26.7981 50.7106 25.8624V15.1768C50.7106 14.6347 50.2645 14.1887 49.7222 14.1887H10.5213C9.97899 14.1887 9.53294 14.6347 9.53294 15.1768V25.8624C9.53294 26.7893 8.78075 27.5501 7.84489 27.5501C6.90904 27.5501 6.15686 26.7981 6.15686 25.8624V15.1768C6.15686 12.7721 8.10728 10.8221 10.5125 10.8221H49.7135C52.1187 10.8221 54.0692 12.7721 54.0692 15.1768V25.8624C54.0692 26.7893 53.317 27.5501 52.3811 27.5501H52.3986Z" fill={primaryColor}/>
<path d="M52.3986 14.1887C51.6902 14.1887 51.0342 13.7427 50.798 13.0345C49.8447 10.1663 46.696 3.49434 38.1421 3.49434H22.1101C13.5562 3.49434 10.4076 10.1663 9.4542 13.0345C9.15683 13.9176 8.20349 14.3898 7.32011 14.1013C6.43674 13.804 5.96444 12.8508 6.25306 11.9676C8.72827 4.55241 14.6495 0.127747 22.1014 0.127747H38.1334C45.5852 0.127747 51.5152 4.55241 53.9817 11.9676C54.2791 12.8508 53.798 13.804 52.9146 14.1013C52.7397 14.1625 52.556 14.1887 52.3811 14.1887H52.3986Z" fill={primaryColor}/>
<path d="M36.3666 9.7378H23.8943C23.1684 9.7378 22.5299 9.27434 22.2938 8.58354L21.4016 5.90776C21.1042 5.02458 21.5853 4.07144 22.4687 3.77413C23.3521 3.47682 24.3054 3.95776 24.6028 4.84095L25.1101 6.36247H35.1508L35.6581 4.84095C35.9555 3.95776 36.9088 3.48557 37.7922 3.77413C38.6756 4.07144 39.1479 5.02458 38.8593 5.90776L37.9671 8.58354C37.7397 9.27434 37.0925 9.7378 36.3666 9.7378Z" fill={primaryColor}/>
<path d="M42.6027 8.94207C43.5881 8.94207 44.387 8.14341 44.387 7.15822C44.387 6.17302 43.5881 5.37436 42.6027 5.37436C41.6173 5.37436 40.8185 6.17302 40.8185 7.15822C40.8185 8.14341 41.6173 8.94207 42.6027 8.94207Z" fill={secondaryColor}/>
<path d="M17.6582 8.94207C18.6436 8.94207 19.4424 8.14341 19.4424 7.15822C19.4424 6.17302 18.6436 5.37436 17.6582 5.37436C16.6728 5.37436 15.874 6.17302 15.874 7.15822C15.874 8.14341 16.6728 8.94207 17.6582 8.94207Z" fill={secondaryColor}/>
<path d="M55.9671 24.8743C55.04 24.8743 54.2791 24.1223 54.2791 23.1867C54.2791 22.251 55.0313 21.499 55.9671 21.499C56.0196 21.499 56.0633 21.4553 56.0633 21.4028V14.2761C56.0633 14.2237 56.0196 14.18 55.9671 14.18H54.0866V15.1681C54.0866 16.095 53.3345 16.8557 52.3986 16.8557C51.4628 16.8557 50.7106 16.1037 50.7106 15.1681V12.4923C50.7106 11.5654 51.4628 10.8046 52.3986 10.8046H55.9584C57.8651 10.8046 59.4219 12.3611 59.4219 14.2674V21.3941C59.4219 23.3003 57.8651 24.8568 55.9584 24.8568L55.9671 24.8743Z" fill={primaryColor}/>
<path d="M4.29387 24.8743C2.38717 24.8743 0.830322 23.3178 0.830322 21.4115V14.2849C0.830322 12.3786 2.38717 10.8221 4.29387 10.8221H7.85361C8.78072 10.8221 9.54166 11.5741 9.54166 12.5098V15.1855C9.54166 16.1125 8.78947 16.8732 7.85361 16.8732C6.91776 16.8732 6.16558 16.1212 6.16558 15.1855V14.1974H4.28513C4.23265 14.1974 4.18892 14.2412 4.18892 14.2936V21.4203C4.18892 21.4728 4.23265 21.5165 4.28513 21.5165C5.21224 21.5165 5.97316 22.2685 5.97316 23.2041C5.97316 24.1398 5.22098 24.8918 4.28513 24.8918L4.29387 24.8743Z" fill={primaryColor}/>
<path d="M30.1305 20.4234C29.2034 20.4234 28.4424 19.6714 28.4424 18.7358V13.3929C28.4424 12.466 29.1946 11.7053 30.1305 11.7053C31.0663 11.7053 31.8185 12.4573 31.8185 13.3929V18.7358C31.8185 19.6627 31.0663 20.4234 30.1305 20.4234Z" fill={primaryColor}/>
</svg>

);

export default School_busIcon;
