import React from 'react';
const LayoutIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M55.6819 11.2869C54.9559 6.19768 50.8627 2.14031 45.7286 1.41453C34.577 -0.159458 23.1193 -0.159503 11.959 1.42323C6.86866 2.14901 2.81039 6.24138 2.08444 11.3743C1.29728 16.9358 0.894958 22.5934 0.894958 28.2073C0.894958 33.8211 1.29728 39.54 2.0932 45.1364C2.81914 50.2256 6.91242 54.283 12.0465 55.0088C17.6092 55.7958 23.268 56.198 28.8832 56.198C34.4983 56.198 40.2184 55.7958 45.816 55C50.9064 54.2742 54.9646 50.1819 55.6906 45.0489C56.4777 39.4875 56.8801 33.8299 56.8801 28.216C56.8801 22.6021 56.4778 16.8833 55.6819 11.2869ZM51.0813 44.3931C50.644 47.4886 48.2125 49.9458 45.1688 50.383C34.4458 51.9133 23.4342 51.9132 12.7112 50.3917C9.61504 49.9545 7.1573 47.5236 6.71998 44.4806C5.9503 39.1028 5.56546 33.6288 5.56546 28.2073C5.56546 22.7857 5.95034 17.373 6.70252 12.0302C7.13984 8.93465 9.57127 6.4775 12.615 6.04028C17.994 5.27078 23.4605 4.88602 28.8919 4.88602C34.3234 4.88602 39.7286 5.27076 45.0726 6.02278C48.1688 6.46 50.6265 8.89094 51.0638 11.934C51.8335 17.3118 52.2183 22.777 52.2183 28.2073C52.2183 33.6375 51.8335 39.0415 51.0813 44.3843V44.3931Z" fill={secondaryColor}/>
<path d="M47.5565 25.8812H31.2271V9.5555C31.2271 8.27008 30.1863 7.22072 28.8919 7.22072C27.5974 7.22072 26.5566 8.26134 26.5566 9.5555V46.8677C26.5566 48.1532 27.5974 49.2025 28.8919 49.2025C30.1863 49.2025 31.2271 48.1619 31.2271 46.8677V30.542H47.5565C48.8422 30.542 49.8918 29.5014 49.8918 28.2072C49.8918 26.913 48.8509 25.8725 47.5565 25.8725V25.8812Z" fill={primaryColor}/>
</svg>

);

export default LayoutIcon;
