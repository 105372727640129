import React from 'react';
const First_aidIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M33.5624 30.9442H31.2271V28.6095C31.2271 27.3241 30.1863 26.2747 28.8919 26.2747C27.5974 26.2747 26.5566 27.3153 26.5566 28.6095V30.9442H24.2213C22.9356 30.9442 21.8861 31.9848 21.8861 33.279C21.8861 34.5731 22.9269 35.6137 24.2213 35.6137H26.5566V37.9485C26.5566 39.2339 27.5974 40.2832 28.8919 40.2832C30.1863 40.2832 31.2271 39.2427 31.2271 37.9485V35.6137H33.5624C34.8481 35.6137 35.8977 34.5731 35.8977 33.279C35.8977 31.9848 34.8568 30.9442 33.5624 30.9442Z" fill={primaryColor}/>
<path d="M56.0404 23.2142C55.192 18.16 51.2037 14.2687 46.1308 13.5342C45.0288 13.3768 43.918 13.2456 42.8072 13.1145C42.055 6.11023 36.1076 0.63623 28.9006 0.63623C21.6937 0.63623 15.7461 6.11023 14.994 13.1145C13.8569 13.2456 12.7112 13.3855 11.5742 13.5517C6.54504 14.2862 2.60041 18.1862 1.74327 23.258C1.19225 26.5634 0.912415 29.9387 0.912415 33.2878C0.912415 36.6368 1.19226 40.0384 1.75203 43.3525C2.38176 47.0776 4.71703 50.1644 7.93568 51.8258C7.93568 51.8696 7.90946 51.9045 7.90946 51.9483V54.283C7.90946 55.5684 8.95026 56.6177 10.2447 56.6177C11.5392 56.6177 12.58 55.5772 12.58 54.283V53.155C17.9764 53.8983 23.4604 54.283 28.9093 54.283C34.3583 54.283 39.8335 53.8983 45.2387 53.155V54.283C45.2387 55.5684 46.2795 56.6177 47.574 56.6177C48.8684 56.6177 49.9092 55.5772 49.9092 54.283V51.9483C49.9092 51.9483 49.8918 51.8871 49.8918 51.8433C53.1017 50.1819 55.4456 47.0777 56.0754 43.3176C56.6264 40.0122 56.9063 36.6368 56.9063 33.2878C56.9063 29.9387 56.6264 26.5371 56.0667 23.223L56.0404 23.2142ZM28.8919 5.297C33.3525 5.297 37.0871 8.45375 37.9967 12.651C31.9443 12.1963 25.8394 12.1963 19.7782 12.651C20.6878 8.45375 24.4225 5.297 28.8831 5.297H28.8919ZM51.4486 42.5306C50.9325 45.5998 48.5623 47.9521 45.5536 48.3893C34.577 49.9895 23.2855 49.9895 12.3351 48.4068C9.27389 47.9608 6.86863 45.6173 6.3526 42.5655C5.83656 39.505 5.57416 36.3832 5.57416 33.279C5.57416 30.1747 5.83655 27.0705 6.34384 24.0187C6.85987 20.9495 9.23011 18.5972 12.2388 18.16C17.7403 17.3555 23.3467 16.9533 28.8919 16.9533C34.437 16.9533 39.9909 17.3555 45.4574 18.1512C48.5186 18.5972 50.9238 20.9407 51.4398 23.9925C51.9559 27.053 52.2183 30.1747 52.2183 33.279C52.2183 36.3832 51.9559 39.4875 51.4486 42.5393V42.5306Z" fill={secondaryColor}/>
<path d="M28.8919 19.288C21.1776 19.288 14.8978 25.5664 14.8978 33.279C14.8978 40.9915 21.1776 47.27 28.8919 47.27C36.6061 47.27 42.886 40.9915 42.886 33.279C42.886 25.5664 36.6061 19.288 28.8919 19.288ZM28.8919 42.6005C23.7491 42.6005 19.5595 38.4119 19.5595 33.2702C19.5595 28.1285 23.7491 23.94 28.8919 23.94C34.0347 23.94 38.2242 28.1285 38.2242 33.2702C38.2242 38.4119 34.0347 42.6005 28.8919 42.6005Z" fill={secondaryColor}/>
</svg>

);

export default First_aidIcon;
