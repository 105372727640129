import React from 'react';
const CrosshairIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M28.7621 0.429504C13.3248 0.429504 0.773865 12.9777 0.773865 28.4115C0.773865 43.8453 13.3248 56.3935 28.7621 56.3935C44.1993 56.3935 56.7503 43.8453 56.7503 28.4115C56.7503 12.9777 44.1993 0.429504 28.7621 0.429504ZM28.7621 51.7327C15.905 51.7327 5.43572 41.2745 5.43572 28.4115C5.43572 15.5485 15.8962 5.09022 28.7621 5.09022C41.6279 5.09022 52.0885 15.5485 52.0885 28.4115C52.0885 41.2745 41.6279 51.7327 28.7621 51.7327Z" fill={secondaryColor}/>
<path d="M47.418 26.0854H45.0827C43.797 26.0854 42.7474 27.126 42.7474 28.4202C42.7474 29.7143 43.7882 30.755 45.0827 30.755H47.418C48.7037 30.755 49.7532 29.7143 49.7532 28.4202C49.7532 27.126 48.7125 26.0854 47.418 26.0854Z" fill={secondaryColor}/>
<path d="M28.762 14.4205C30.0478 14.4205 31.0974 13.3799 31.0974 12.0857V9.75096C31.0974 8.46554 30.0565 7.41623 28.762 7.41623C27.4676 7.41623 26.4268 8.45679 26.4268 9.75096V12.0857C26.4268 13.3711 27.4676 14.4205 28.762 14.4205Z" fill={secondaryColor}/>
<path d="M12.4327 26.0854H10.0974C8.81173 26.0854 7.76213 27.126 7.76213 28.4202C7.76213 29.7143 8.80298 30.755 10.0974 30.755H12.4327C13.7184 30.755 14.7679 29.7143 14.7679 28.4202C14.7679 27.126 13.7272 26.0854 12.4327 26.0854Z" fill={secondaryColor}/>
<path d="M28.762 42.4025C27.4763 42.4025 26.4268 43.4431 26.4268 44.7372V47.072C26.4268 48.3574 27.4676 49.4067 28.762 49.4067C30.0565 49.4067 31.0974 48.3661 31.0974 47.072V44.7372C31.0974 43.4518 30.0565 42.4025 28.762 42.4025Z" fill={secondaryColor}/>
<path d="M28.762 21.416C24.9049 21.416 21.765 24.5552 21.765 28.4115C21.765 32.2678 24.9049 35.407 28.762 35.407C32.6192 35.407 35.7591 32.2678 35.7591 28.4115C35.7591 24.5552 32.6192 21.416 28.762 21.416ZM28.762 30.7462C27.4763 30.7462 26.4268 29.6969 26.4268 28.4115C26.4268 27.1261 27.4763 26.0767 28.762 26.0767C30.0477 26.0767 31.0973 27.1261 31.0973 28.4115C31.0973 29.6969 30.0477 30.7462 28.762 30.7462Z" fill={primaryColor}/>
</svg>

);

export default CrosshairIcon;
