import axios from 'axios';
import { postData } from '../axiosUtils';

const parkingStatisticService = {
  list: () => {
    return axios.get(`/api/parkingStatistic/list`).then((response) => {
      return response.data;
    });
  },
  get: (id: any) => {
    return axios.get(`/api/parkingStatistic/details/${id}`).then((response) => {
      return response.data;
    });
  },
};

export default parkingStatisticService;
