import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MsalProvider } from '@azure/msal-react';

import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import { Provider } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { SnackbarProvider } from 'notistack';
import store from './stateManagement/store';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { IconButton, Tooltip } from '@mui/material';
import { Close } from '@mui/icons-material';
import { setChonkyDefaults } from '@aperturerobotics/chonky';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import axios from 'axios';

const DNDProvider = DndProvider as any;
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const msalInstance = new PublicClientApplication(msalConfig);
const notistackRef = React.createRef<any>();
const onClickDismiss = (key: any) => {
  notistackRef.current.closeSnackbar(key);
};

axios.interceptors.response.use(
  (resp) => {
    return resp;
  },
  (error) => {
    if (error.name === 'AxiosError') {
      throw error;
    }
    return {
      data: {
        canceled: error.name === 'CanceledError',
        hasError: true,
        errorMessages: [error.message],
      },
    };
  }
);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <SnackbarProvider
            maxSnack={3}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            ref={notistackRef}
            action={(key: any) => (
              <Tooltip title={<span>Close</span>}>
                <IconButton
                  aria-label="delete"
                  size="small"
                  color="inherit"
                  onClick={() => onClickDismiss(key)}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              </Tooltip>
            )}
          >
            <DNDProvider backend={HTML5Backend}>
              <App />
            </DNDProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </Provider>
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
