import { Grid, IconButton, Tooltip } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import CreateEntityDialog from './CreateEntityDialog';
import { useState } from 'react';

const CreateEntityDecorator = (props: {
  AutocompleteComponent: any;
  onSave: any;
  CreateEntityComponent: any;
  hideAdd?: boolean;
}) => {
  const {
    AutocompleteComponent,
    onSave,
    CreateEntityComponent,
    hideAdd = false,
  } = props;
  const [open, setOpen] = useState(false);

  return (
    <Grid container>
      <Grid item xs={hideAdd === true ? 12 : 10}>
        {AutocompleteComponent}
      </Grid>
      {hideAdd !== true ? (
        <Grid item xs={2}>
          <Tooltip title="Új hozzáadása">
            <IconButton onClick={() => setOpen(true)}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      ) : null}

      <CreateEntityDialog
        CreateEntityComponent={CreateEntityComponent}
        open={open}
        handleCancel={() => setOpen(false)}
        navigateBack={false}
        onSave={(item: any) => {
          onSave(item);
          setOpen(false);
        }}
      />
    </Grid>
  );
};
export default CreateEntityDecorator;
