import React from 'react';
const Power_buttonIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="56" viewBox="0 0 57 56" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M28.2714 0.0360107C12.8429 0.0360107 0.283203 12.5842 0.283203 28.018C0.283203 43.4518 12.8429 56 28.2714 56C43.6999 56 56.2596 43.4518 56.2596 28.018C56.2596 12.5842 43.6999 0.0360107 28.2714 0.0360107ZM28.2714 51.3304C15.4143 51.3304 4.945 40.8722 4.945 28.0093C4.945 15.1463 15.4056 4.68797 28.2714 4.68797C41.1372 4.68797 51.5978 15.1463 51.5978 28.0093C51.5978 40.8722 41.1372 51.3304 28.2714 51.3304Z" fill={secondaryColor}/>
<path d="M36.4317 13.8784C35.3209 13.2313 33.8953 13.616 33.248 14.7265C32.6008 15.8458 32.9856 17.2712 34.0964 17.9095C37.6911 19.9907 39.9302 23.8557 39.9302 28.0093C39.9302 34.4364 34.6999 39.6655 28.2714 39.6655C21.8428 39.6655 16.6125 34.4364 16.6125 28.0093C16.6125 23.8557 18.8429 19.9907 22.4464 17.9095C23.5571 17.2624 23.942 15.8371 23.2947 14.7265C22.6475 13.616 21.2218 13.2313 20.1111 13.8784C15.0732 16.7902 11.9508 22.203 11.9508 28.0181C11.9508 37.016 19.2714 44.3437 28.2801 44.3437C37.2888 44.3437 44.6095 37.016 44.6095 28.0181C44.6095 22.2118 41.4783 16.7902 36.4492 13.8784H36.4317Z" fill={primaryColor}/>
<path d="M28.2714 21.0225C29.5571 21.0225 30.6066 19.9819 30.6066 18.6877V11.6922C30.6066 10.4068 29.5658 9.35742 28.2714 9.35742C26.9769 9.35742 25.9361 10.398 25.9361 11.6922V18.6877C25.9361 19.9731 26.9769 21.0225 28.2714 21.0225Z" fill={secondaryColor}/>
</svg>

);

export default Power_buttonIcon;
