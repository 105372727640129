import {
  GridColDef,
  GridActionsCellItem,
  GridActionsColDef,
} from '@mui/x-data-grid';
import {
  Paper,
  IconButton,
  Grid,
  Tooltip,
  Box,
  Tab,
  Tabs,
  Badge,
  Checkbox,
  FormControlLabel,
  useMediaQuery,
  SpeedDial,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Visibility,
  HideImage,
  Image,
} from '@mui/icons-material';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import DataList from '../../../components/DataList';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';
import parkingZoneService from '../../../services/parking/parkingZoneService';
import ParkingZonePageMap from './ParkingZonePageMap';
import systemConfigurationService from '../../../services/systemConfigurationService';
import ParkingZonePageList from './ParkingZonePageList';
import ApproveDialog from '../../contents/ApproveDialog';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { ApprovementStatus } from '../../contents/ContentPage';

export interface ParkingZone {
  id: string;
  imageId: string;
  name: string;
}

const ParkingZonesPage = () => {
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [zones, setZones] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const [tabValue, setTabValue] = useState(0);
  const isSmallScreen = useMediaQuery('(max-width:900px)');
  const tabLocalStorage = useLocalStorage('ParkingZonesPageTabValue');

  const { checkPermission } = useCheckPermission();
  const [defaultLat, setDefaultLat] = useState<any>(1);
  const [defaultLong, setDefaultLong] = useState<any>(1);
  const [selectedZoneId, setSelectedZoneId] = useState<number | null>(null);

  const handleChange = (newValue: number) => {
    tabLocalStorage.setLocalStorageValue(newValue);

    setTabValue(newValue);
  };

  useEffect(() => {
    if (tabLocalStorage.value) {
      setTabValue(tabLocalStorage.value);
    }
  }, [tabLocalStorage]);

  useEffect(() => {
    systemConfigurationService
      .getByKey('MapCenterLatitude')
      .then((response) => {
        if (!response.hasError) {
          setDefaultLat(parseFloat(response.result.value));
        }
      });
    systemConfigurationService
      .getByKey('MapCenterLongitude')
      .then((response) => {
        if (!response.hasError) {
          setDefaultLong(parseFloat(response.result.value));
        }
      });
  }, []);
  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    parkingZoneService
      .list()
      .then((response) => {
        if (!response.hasError) setZones(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  const handleDelete = (id: number) => {
    parkingZoneService.delete(id).then((response) => {
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés!', {
          variant: 'success',
        });
        setZones(zones.filter((row) => row.id !== id));
      }
    });
  };

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    let readonly = !checkPermission(['EditParkingZone']);
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          readonly ? (
            <Tooltip title="View">
              <Visibility />
            </Tooltip>
          ) : (
            <Tooltip title="Edit">
              <EditIcon />
            </Tooltip>
          )
        }
        label={readonly ? 'View' : 'Edit'}
        onClick={() => navigate(`/parkingZone/edit/${params.row.id}`)}
      />
    );

    if (checkPermission(['DeleteParkingZone'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Delete">
              <DeleteIcon />
            </Tooltip>
          }
          label="Delete"
          onClick={() =>
            setParams({
              open: true,
              name: 'Are you sure to delete this item?',
              onConfirm: async () => handleDelete(params.row.id),
            })
          }
        />
      );
    }

    return actions;
  };

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Megnevezés', flex: 100 },
    { field: 'description', headerName: 'Leírás', flex: 100 },
    {
      field: 'actions',
      type: 'actions',
      align: 'right',
      flex: 100,
      getActions: getActions,
    } as GridActionsColDef,
  ];

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12} textAlign={{ xs: 'center', sm: 'left' }}>
          <h2>Parkoló zónák</h2>
        </Grid>
        {checkPermission(['CreateParkingZone']) && (
          <Grid container item xs={12} p={2} justifyContent="end">
            <Grid item sx={{ display: { xs: 'none', sm: 'fixed' } }}>
              <Tooltip title="Parkoló zóna létrehozása">
                <IconButton
                  component={RouterLink}
                  to={`/parkingZone/create`}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        )}
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              pb: 2,
              maxWidth: isSmallScreen ? '280px' : '100%',
            }}
          >
            <Tabs
              value={tabValue}
              onChange={(e, value) => handleChange(value)}
              TabIndicatorProps={{ style: { background: '#1976d2' } }}
              sx={{
                maxWidth: isSmallScreen ? '280px' : '100%',
              }}
              variant="fullWidth"
            >
              <Tab
                value={0}
                label={'Térkép nézet'}
                sx={{ fontWeight: 'bold' }}
              />
              <Tab
                value={1}
                label={'Lista nézet'}
                sx={{ fontWeight: 'bold' }}
              />
            </Tabs>
          </Box>
          {tabValue === 0 && (
            <Grid container>
              {' '}
              <Grid item xs={12} sm={9}>
                <ParkingZonePageMap
                  zones={zones}
                  defaultLong={defaultLong}
                  defaultLat={defaultLat}
                  selectedZoneId={selectedZoneId}
                  setSelectedZoneId={(zoneId: any) => {
                    setSelectedZoneId(zoneId);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <ParkingZonePageList
                  handleDelete={handleDelete}
                  zones={zones}
                  setParams={setParams}
                  selectedZoneId={selectedZoneId}
                  setSelectedZoneId={(zoneId: any) => {
                    setSelectedZoneId(zoneId);
                  }}
                />
              </Grid>
            </Grid>
          )}
          {tabValue === 1 && (
            <Grid item xs={12}>
              <DataList rows={zones} columns={columns} />
            </Grid>
          )}
        </Box>
        <ConfirmDeleteDialog />{' '}
        <SpeedDial
          ariaLabel="Okos kereső"
          onClick={() => {
            navigate(`/parkingZone/create`);
          }}
          sx={{
            display: { xs: 'fixed', sm: 'none' },
            position: 'fixed',
            bottom: 16,
            right: 16,
          }}
          icon={<AddIcon />}
        ></SpeedDial>
      </Grid>
    </Paper>
  );
};

export default ParkingZonesPage;
