import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";

const DataCard = (props: any) => {
  const { row, headers, ...other } = props;

  if (!headers || headers.length === 0 || !row) {
    return <div>Nincs adat</div>;
  }

  var actions = headers.find((h: any) => h.field === "actions");

  return (
    <Card elevation={10}>
      <CardHeader
        sx={{
          bgcolor: "primary.main",
          display: "block",
          alignItems: "center",
          boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
          color: "#fff",
        }}
        action={
          <Grid
            container
            justifyContent="center"
            display={"flex"}
            sx={{ width: "100%", textAlign: "center" }}
          >
            {actions
              ?.getActions({ row }, "contrastText")
              .map((x: any, index: any) => {
                console.log(x);
                return (
                  <Grid item key={`${row.id}-${index}`}>
                    <IconButton onClick={x.props.onClick} color="inherit">
                      {x.props.icon}
                    </IconButton>
                  </Grid>
                );
              })}
          </Grid>
        }
      />
      <CardContent>
        <Grid container spacing={0.5}>
          {headers.map(
            (h: any) =>
              h.field !== "actions" && (
                <React.Fragment key={h.field}>
                  <Grid item xs={12} container justifyContent="space-between">
                    <Grid item>
                      <Typography
                        variant="subtitle2"
                        component="span"
                        color="textSecondary"
                      >
                        {h.headerName}:
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" component="span">
                        {!h.valueFormatter
                          ? !h.valueGetter
                            ? !h.renderCell
                              ? row[h.field]
                              : h.renderCell({ row })
                            : h.valueGetter({ row })
                          : h.valueFormatter({
                              id: row.id ? row.id : other.getRowId(row),
                              value: row[h.field],
                            })}
                      </Typography>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DataCard;
