import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { ApprovementStatus } from './ContentPage';
import React from 'react';

const ApproveDialog = (props: any) => {
  const { openApproveDialog, setOpenApproveDialog, handleApprove } = props;
  const [rejectDialogOpen, setRejectDialogOpen] = React.useState(false);

  return (
    <>
      <Dialog
        open={openApproveDialog.open}
        onClose={() =>
          setOpenApproveDialog({
            open: false,
            id: '',
            comment: '',
            isSubmitting: false,
            status: ApprovementStatus.Draft,
          })
        }
      >
        <DialogTitle>Jóváhagyás</DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button
            color="success"
            disabled={openApproveDialog.isSubmitting}
            variant="contained"
            onClick={() => handleApprove(openApproveDialog.id, true)}
          >
            Jóváhagyás
          </Button>
          <Button
            color="error"
            disabled={openApproveDialog.isSubmitting}
            variant="contained"
            onClick={() => setRejectDialogOpen(true)}
          >
            Elutasítás
          </Button>
          <Button
            variant="outlined"
            onClick={() =>
              setOpenApproveDialog({
                open: false,
                id: '',
                comment: '',
                isSubmitting: false,
                status: ApprovementStatus.Draft,
              })
            }
          >
            Mégse
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={rejectDialogOpen}
        onClose={() => setRejectDialogOpen(false)}
      >
        <DialogTitle>Elutasítás</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Megjegyzés"
            fullWidth
            value={openApproveDialog.comment}
            onChange={(e) =>
              setOpenApproveDialog({
                ...openApproveDialog,
                comment: e.target.value,
              })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            disabled={openApproveDialog.isSubmitting}
            variant="contained"
            onClick={() => {
              handleApprove(openApproveDialog.id, false);
              setRejectDialogOpen(false);
            }}
          >
            Elutasítás
          </Button>
          <Button variant="outlined" onClick={() => setRejectDialogOpen(false)}>
            Mégse
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ApproveDialog;
