import L from 'leaflet';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import UpdateMapCenter from '../../../components/UpdateMapCenter';
import '../../../css/zoneTooltip.css';
const MapContent = (props: any) => {
  const { zones, selectedZoneId, setSelectedZoneId } = props;
  const map = useMap();
  const polygonsRef = useRef<any>([]);

  useEffect(() => {
    if (!zones || zones.length === 0) return;
    if (selectedZoneId) {
      let selectedZone = zones.find((zone: any) => zone.id === selectedZoneId);
      if (selectedZone && selectedZone.area) {
        let area = JSON.parse(selectedZone.area);
        map.fitBounds(
          area.geometry.coordinates[0].map((coord: any) => [coord[1], coord[0]])
        );
      }
    }
  }, [zones, selectedZoneId]);
  const calculateCentroid = (coords: any) => {
    let xSum = 0;
    let ySum = 0;
    let n = coords.length;

    coords.forEach((coord: any) => {
      xSum += coord[0];
      ySum += coord[1];
    });

    return [xSum / n, ySum / n];
  };

  useEffect(() => {
    if (!zones || zones.length === 0) return;

    // Clear existing polygons
    polygonsRef.current.forEach((polygon: any) => {
      map.removeLayer(polygon);
    });
    polygonsRef.current = [];

    zones.forEach((zone: any) => {
      const area = zone.area ? JSON.parse(zone.area) : null;
      if (area) {
        const coords = area.geometry.coordinates[0].map((coord: any) => [
          coord[1],
          coord[0],
        ]);

        const polygon = L.polygon(coords, {
          color: zone.color ?? 'blue',
        }).addTo(map);

        const tooltip = L.tooltip({
          direction: 'center',
          permanent: true,
          className: 'zone-tooltip',
        }).setContent(zone.name);
        polygon.bindTooltip(tooltip);

        polygonsRef.current.push(polygon);
      }
    });
  }, [map, zones]);

  return (
    <>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
    </>
  );
};

const ParkingZonePageMap = (props: any) => {
  const {
    zones = [],
    defaultLat,
    defaultLong,
    selectedZoneId = null,
    setSelectedZoneId = () => {},
  } = props;

  const [center, setCenter] = useState<[number, number]>([0, 0]);

  useEffect(() => {
    if (defaultLat && defaultLong && !selectedZoneId) {
      setCenter([defaultLat, defaultLong]);
    }
  }, [defaultLat, defaultLong, selectedZoneId]);

  return (
    <MapContainer
      center={center}
      zoom={16}
      style={{ height: '100%', width: '100%', minHeight: '500px' }}
    >
      <UpdateMapCenter center={center} />
      <MapContent
        zones={zones}
        selectedZoneId={selectedZoneId}
        setSelectedZoneId={setSelectedZoneId}
      />
    </MapContainer>
  );
};

export default ParkingZonePageMap;
