import React from 'react';
const SpeakerIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="53" viewBox="0 0 57 53" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M31.6382 0.764221C29.3379 0.764221 27.0901 1.5512 25.2971 2.98527L18.3351 8.55543C17.0931 9.54355 15.545 10.0945 13.9619 10.0945H12.9036C7.13101 10.0945 2.17185 14.3617 1.36719 20.0194C1.06981 22.1355 0.912415 24.2866 0.912415 26.4115C0.912415 28.5364 1.06107 30.6962 1.36719 32.8036C2.17185 38.4612 7.13101 42.7285 12.9036 42.7285H13.9619C15.545 42.7285 17.0931 43.2706 18.3351 44.2675L25.2971 49.8376C27.0901 51.2717 29.3379 52.0587 31.6382 52.0587C36.6585 52.0587 40.9793 48.3249 41.6877 43.3843C42.4924 37.7791 42.8947 32.0691 42.8947 26.4115C42.8947 20.7539 42.4924 15.0438 41.6877 9.43863C40.9793 4.49805 36.6673 0.764221 31.6382 0.764221ZM37.0609 42.7285C36.6848 45.3868 34.3496 47.398 31.6295 47.398C30.3875 47.398 29.1717 46.9695 28.2009 46.1913L21.2388 40.6211C19.1747 38.9771 16.5858 38.0677 13.9531 38.0677H12.8948C9.43128 38.0677 6.45756 35.5231 5.97651 32.1478C5.70537 30.2503 5.57416 28.3178 5.57416 26.4115C5.57416 24.5052 5.71412 22.5727 5.97651 20.6752C6.45756 17.2998 9.43128 14.7552 12.8948 14.7552H13.9531C16.5858 14.7552 19.1747 13.8458 21.2388 12.2019L28.2009 6.63172C29.1717 5.85347 30.3875 5.43369 31.6207 5.43369C34.3321 5.43369 36.6673 7.44492 37.0521 10.1032C37.8218 15.4897 38.2154 20.9812 38.2154 26.4202C38.2154 31.8592 37.8218 37.3507 37.0521 42.7372L37.0609 42.7285Z" fill={secondaryColor}/>
<path d="M46.437 10.112C45.16 10.2694 44.2417 11.4236 44.3991 12.709C44.9414 17.2386 45.2125 21.8556 45.2125 26.4202C45.2125 30.9848 44.9326 35.5931 44.3903 40.1314C44.2416 41.4081 45.1513 42.5711 46.4282 42.7285C46.5245 42.7372 46.6119 42.746 46.7081 42.746C47.8714 42.746 48.8772 41.8803 49.0172 40.691C49.5857 35.9691 49.8655 31.1684 49.8655 26.4202C49.8655 21.672 49.5769 16.8714 49.0172 12.1494C48.8685 10.8727 47.7052 9.97206 46.4282 10.112H46.437Z" fill={secondaryColor}/>
<path d="M56.0317 12.1494C55.883 10.8727 54.711 9.97206 53.4428 10.112C52.1658 10.2694 51.2475 11.4236 51.4049 12.709C51.9472 17.2386 52.2183 21.8556 52.2183 26.4202C52.2183 30.9848 51.9384 35.5931 51.3961 40.1314C51.2475 41.4081 52.1571 42.5711 53.434 42.7285C53.5303 42.7372 53.6177 42.746 53.7139 42.746C54.8772 42.746 55.883 41.8803 56.0229 40.691C56.5914 35.9691 56.8713 31.1684 56.8713 26.4202C56.8713 21.672 56.5827 16.8714 56.0229 12.1494H56.0317Z" fill={secondaryColor}/>
<path d="M17.233 17.09C15.9473 17.09 14.8978 18.1305 14.8978 19.4247V33.4157C14.8978 34.7011 15.9386 35.7505 17.233 35.7505C18.5275 35.7505 19.5683 34.7099 19.5683 33.4157V19.4247C19.5683 18.1393 18.5275 17.09 17.233 17.09Z" fill={primaryColor}/>
</svg>

);

export default SpeakerIcon;
