import React from 'react';
const FolderIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="56" height="52" viewBox="0 0 56 52" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M44.351 2.88907H23.9634C21.803 1.48122 19.2141 0.807892 16.564 1.01776C14.3512 1.19264 12.2171 1.42 10.1792 1.67358C4.39786 2.37313 0.0334473 7.33996 0.0334473 13.2249V39.1782C0.0334473 45.0719 4.45908 50.0562 10.3366 50.7733C16.2316 51.4991 22.1791 51.8576 28.0216 51.8576C33.8642 51.8576 40.0916 51.4815 45.8641 50.747C51.6455 50.0475 56.0098 45.0807 56.0098 39.1957V14.5453C56.0098 8.11822 50.7796 2.88907 44.351 2.88907ZM51.3481 14.5453V14.6065C49.3889 13.1287 46.9837 12.2193 44.351 12.2193H36.5493C34.7038 12.2193 32.9021 11.4761 31.5989 10.1731L28.9837 7.55859H44.3423C48.1994 7.55859 51.3393 10.6978 51.3393 14.5541L51.3481 14.5453ZM51.3481 39.1957C51.3481 42.7285 48.7504 45.7015 45.2869 46.1125C34.3015 47.5203 22.529 47.5553 10.8964 46.1388C7.36287 45.7015 4.69525 42.7109 4.69525 39.1695V13.2162C4.69525 9.68344 7.29289 6.71038 10.7477 6.29939C12.7331 6.05455 14.7972 5.83591 16.9401 5.66977C17.1325 5.65228 17.3337 5.64357 17.5261 5.64357C19.4065 5.64357 21.2083 6.37806 22.529 7.68972L28.3015 13.461C30.5056 15.6646 33.4356 16.8801 36.5493 16.8801H44.351C48.2081 16.8801 51.3481 20.0193 51.3481 23.8756V39.1957Z" fill={secondaryColor}/>
<path d="M46.6863 35.5319H9.36579C8.08009 35.5319 7.03052 36.5724 7.03052 37.8666C7.03052 39.1608 8.07134 40.2013 9.36579 40.2013H46.6863C47.972 40.2013 49.0216 39.1608 49.0216 37.8666C49.0216 36.5724 47.9808 35.5319 46.6863 35.5319Z" fill={primaryColor}/>
</svg>

);

export default FolderIcon;
