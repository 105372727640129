import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  useMediaQuery,
  Autocomplete,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { useState, useEffect } from 'react';
import { useLocationPicker } from '../../hooks/useLocationPicker';
import useImageSelect from '../../hooks/useImageSelect';
import systemConfigurationService from '../../services/systemConfigurationService';

const LocationForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    publishers,
    readonly = false,
  } = props;

  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );

  const { LocationPickerButton, LocationPickerDialog } = useLocationPicker();

  const { ImageSelectComponent, uploadImage, uploading, error, uploadedFile } =
    useImageSelect();

  const handleUploaded = (image: any, setFieldValue: any) => {
    setFieldValue('imageId', image.id);
    setFieldValue('image', image);
  };

  const [publisherId, setPublisherId] = useState<number | null>(1);
  const [defaultLong, setDefaultLong] = useState<any>(1);
  const [defaultLat, setDefaultLat] = useState<any>(1);

  useEffect(() => {
    systemConfigurationService
      .getByKey('MapCenterLatitude')
      .then((response) => {
        if (!response.hasError) {
          setDefaultLat(parseFloat(response.result.value));
        }
      });
    systemConfigurationService
      .getByKey('MapCenterLongitude')
      .then((response) => {
        if (!response.hasError) {
          setDefaultLong(parseFloat(response.result.value));
        }
      });
  }, []);

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};
        if (!values.name) {
          errors.name = 'Required';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting, setFieldValue }) => {
        let newValues = { ...values };
        if (publisherId) {
          uploadImage(
            (image: any) =>
              (newValues = { ...values, image: image, imageId: image.id }),
            'kép',
            publisherId,
            '-1'
          )?.then(() => {
            onSubmit(newValues, setSubmitting);
          });
        }
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        setFieldValue,
        handleChange,
        errors: validationErrors,
      }) => (
        <Form>
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>Helyszín {values.id > 0 ? 'szerkesztés' : 'létrehozás'}</h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error: string) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.name}
                  onChange={handleChange}
                  label="Név"
                  name="name"
                  required
                  disabled={readonly}
                  fullWidth
                  autoFocus
                  error={!!touched.name && !!validationErrors.name}
                  helperText={
                    !!touched.name &&
                    !!validationErrors.name &&
                    (validationErrors.name as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.fullAddress}
                  onChange={handleChange}
                  label="Cím"
                  name="fullAddress"
                  required
                  disabled={readonly}
                  fullWidth
                  error={
                    !!touched.fullAddress && !!validationErrors.fullAddress
                  }
                  helperText={
                    !!touched.fullAddress &&
                    !!validationErrors.fullAddress &&
                    (validationErrors.fullAddress as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.latitude}
                  onChange={handleChange}
                  label="Szélesség"
                  name="latitude"
                  type="number"
                  required
                  disabled={readonly}
                  fullWidth
                  error={!!touched.latitude && !!validationErrors.latitude}
                  helperText={
                    !!touched.latitude &&
                    !!validationErrors.latitude &&
                    (validationErrors.latitude as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.longitude}
                  onChange={handleChange}
                  label="Hosszúság"
                  name="longitude"
                  required
                  type="number"
                  disabled={readonly}
                  fullWidth
                  error={!!touched.longitude && !!validationErrors.longitude}
                  helperText={
                    !!touched.longitude &&
                    !!validationErrors.longitude &&
                    (validationErrors.longitude as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <LocationPickerButton />
                <LocationPickerDialog
                  defaultLong={defaultLong}
                  defaultLat={defaultLat}
                  onLocationSelected={(location: {
                    lat: number;
                    lng: number;
                    address: string;
                  }) => {
                    setFieldValue('longitude', location.lng);
                    setFieldValue('latitude', location.lat);
                    if (!values.fullAddress)
                      setFieldValue('fullAddress', location.address);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  disablePortal
                  id="publisherId"
                  disabled={readonly}
                  value={publisherId}
                  onChange={(event, value) => {
                    setPublisherId(value);
                  }}
                  getOptionLabel={(option) =>
                    publishers?.find((g: any) => g.id === option)?.name ?? ''
                  }
                  options={publishers?.map((g: any) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      fullWidth
                      label="Publikáló"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <ImageSelectComponent
                  image={values.image}
                  onSelect={(image: any) => {
                    setFieldValue('imageId', image.id);
                    setFieldValue('image', image);
                  }}
                  alt="kép"
                  onUploaded={(image: any) =>
                    handleUploaded(image, setFieldValue)
                  }
                  publisherId={publisherId}
                  currentFolderId={'-1'}
                />
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item xs={12} sm={isSmallScreen ? 12 : 'auto'} p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth={isSmallScreen}
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12} sm={isSmallScreen ? 12 : 'auto'} p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth={isSmallScreen}
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default LocationForm;
