import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import { useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-draw';
import * as turf from '@turf/turf';
import { useSnackbar } from 'notistack';
import UpdateMapCenter from './UpdateMapCenter';
import { set } from 'date-fns';

const DrawControl = (props: any) => {
  const { selectedArea, setSelectedArea, zones, editingZoneId = null } = props;
  const { enqueueSnackbar } = useSnackbar();
  const map = useMap();
  const [drawnItems, setDrawnItems] = useState<any>(new L.FeatureGroup());
  const [beforeEdit, setBeforeEdit] = useState<any>(null);

  const checkIntersection = (newLayer: any) => {
    const newPolygon = newLayer.toGeoJSON();
    const newTurfPolygon = turf.polygon(newPolygon.geometry.coordinates);
    for (let zone of zones?.filter((z: any) => z.id !== editingZoneId)) {
      const existingArea = zone.area ? JSON.parse(zone.area) : null;
      if (!existingArea) continue;
      const existingTurfPolygon = turf.polygon(
        existingArea.geometry.coordinates
      );
      if (
        turf.intersect(
          turf.featureCollection([newTurfPolygon, existingTurfPolygon])
        )
      ) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    map.addLayer(drawnItems);
    const drawControl = new L.Control.Draw({
      edit: {
        featureGroup: drawnItems,
      },
      draw: {
        polyline: false,
        circle: false,
        marker: false,
        circlemarker: false,
        rectangle: false,
      },
    });
    map.addControl(drawControl);
    return () => {
      map.removeControl(drawControl);
      map.removeLayer(drawnItems);
    };
  }, [map, drawnItems]);

  useEffect(() => {
    if (selectedArea) {
      const geoJsonData = selectedArea ? JSON.parse(selectedArea) : null;
      const newLayer = L.geoJSON(geoJsonData, {
        onEachFeature(feature, layer) {
          drawnItems.clearLayers();
          drawnItems.addLayer(layer);
        },
      });

      map.fitBounds(newLayer.getBounds());
    }
  }, [selectedArea, map, drawnItems]);

  useEffect(() => {
    if (!L.Draw) {
      console.error('Leaflet.draw plugin not loaded');
      return;
    }

    map.on(L.Draw.Event.CREATED, (event) => {
      const layer = event.layer;
      if (checkIntersection(layer)) {
        enqueueSnackbar('A területek között nem lehet átfedés! ', {
          variant: 'error',
        });
      } else {
        let newDrawnItems = new L.FeatureGroup();
        newDrawnItems.addLayer(layer);
        map.removeLayer(drawnItems);

        setDrawnItems(newDrawnItems);
        const geoJsonData = layer.toGeoJSON();
        setSelectedArea(JSON.stringify(geoJsonData));
      }
    });
    map.on(L.Draw.Event.EDITSTART, (event) => {
      setBeforeEdit(selectedArea);
    });
    map.on(L.Draw.Event.EDITVERTEX, (event) => {
      const layer = (event as any).poly;
      debugger;
      // layers.eachLayer((layer: any) => {
      if (checkIntersection(layer)) {
        enqueueSnackbar('A területek között nem lehet átfedés! ', {
          variant: 'error',
        });
        drawnItems.removeLayer(layer);
        const geoJsonData = JSON.parse(selectedArea);
        const newLayer = L.geoJSON(geoJsonData, {
          onEachFeature(feature, layer) {
            drawnItems.clearLayers();
            drawnItems.addLayer(layer);
          },
        });

        map.fitBounds(newLayer.getBounds());
      } else {
        const geoJsonData = layer.toGeoJSON();
        setSelectedArea(JSON.stringify(geoJsonData));
      }
      // });
      map.on(L.Draw.Event.DELETED, (event) => {
        let newDrawnItems = new L.FeatureGroup();
        map.removeLayer(drawnItems);

        setDrawnItems(newDrawnItems);
        setSelectedArea('');
      });
    });

    return () => {
      map.removeEventListener(L.Draw.Event.CREATED);
      map.removeEventListener(L.Draw.Event.EDITVERTEX);
      map.removeEventListener(L.Draw.Event.EDITSTART);
    };
  }, [map, drawnItems, zones, editingZoneId]);

  return null;
};

const MapContent = (props: any) => {
  const { selectedArea, setSelectedArea, zones, editingZoneId } = props;
  const map = useMap();
  const polygonsRef = useRef<any>([]);

  map.options.drawControl = true;

  useEffect(() => {
    if (!zones || zones.length === 0) return;

    // Clear existing polygons
    polygonsRef.current.forEach((polygon: any) => {
      map.removeLayer(polygon);
    });
    polygonsRef.current = [];

    zones.forEach((zone: any) => {
      if (editingZoneId && editingZoneId > 0 && editingZoneId === zone.id) {
      } else {
        const area = zone.area ? JSON.parse(zone.area) : null;
        if (area) {
          const coords = area.geometry.coordinates[0].map((coord: any) => [
            coord[1],
            coord[0],
          ]);

          const polygon = L.polygon(coords, {
            color: zone.color ?? 'blue',
          }).addTo(map);
          polygon.bindTooltip(zone.name);

          polygonsRef.current.push(polygon);
        }
      }
    });
  }, [map, zones, selectedArea, editingZoneId]);

  return (
    <>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <DrawControl
        selectedArea={selectedArea}
        setSelectedArea={setSelectedArea}
        zones={zones?.filter((z: any) => z.id !== editingZoneId)}
        editingZoneId={editingZoneId}
      />
    </>
  );
};

const MapAreaPicker = (props: any) => {
  const {
    selectedArea,
    setSelectedArea,
    zones = [],
    defaultLat,
    defaultLong,
    editingZoneId = null,
  } = props;

  const [center, setCenter] = useState<[number, number]>([0, 0]);

  useEffect(() => {
    if (defaultLat && defaultLong && !editingZoneId) {
      setCenter([defaultLat, defaultLong]);
    }
  }, [defaultLat, defaultLong, editingZoneId]);

  return (
    <MapContainer
      center={center}
      zoom={16}
      style={{ height: '400px', width: '100%' }}
    >
      <UpdateMapCenter center={center} />
      <MapContent
        selectedArea={selectedArea}
        setSelectedArea={setSelectedArea}
        zones={zones}
        editingZoneId={editingZoneId}
      />
    </MapContainer>
  );
};

export default MapAreaPicker;
