import axios from "axios";

export async function postData({ url = "", data = {}, method = "POST" }) {
  if (method === "POST") {
    const response = await axios.post(url, data);

    return response.data;
  } else if (method === "PUT") {
    const response = await axios.put(url, data);

    return response.data;
  } else if (method === "DELETE") {
    const response = await axios.delete(url);

    return response.data;
  } else if (method === "PATCH") {
    const response = await axios.patch(url);

    return response.data;
  }
}
