import axios from 'axios';
import { postData } from './axiosUtils';
import { ApprovementStatus } from '../pages/contents/ContentPage';

const pageService = {
  list: (status: ApprovementStatus | null) => {
    return axios.get(`/api/page/list?status=${status}`).then((response) => {
      return response.data;
    });
  },
  listLastApprovedPages: () => {
    return axios.get(`/api/page/listLastApprovedPages`).then((response) => {
      return response.data;
    });
  },
  listAllStatus: () => {
    return axios.get(`/api/page/listAllStatus`).then((response) => {
      return response.data;
    });
  },
  get: (id: any) => {
    return axios.get(`/api/page/details/${id}`).then((response) => {
      return response.data;
    });
  },
  listVersions: (id: any) => {
    return axios.get(`/api/page/listVersions?id=${id}`).then((response) => {
      return response.data;
    });
  },
  updateStatus: (entity: any) => {
    return postData({
      url: `/api/page/updateStatus`,
      data: entity,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  create: (entity: any) => {
    return postData({
      url: `/api/page/create`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  update: (entity: any) => {
    return postData({
      url: `/api/page/update`,
      data: entity,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  delete: (id: any) => {
    return postData({
      url: `/api/page/delete/${id}`,
      method: 'DELETE',
    }).then((response) => {
      return response;
    });
  },
  recover: (id: any) => {
    return postData({
      url: `/api/page/recover/${id}`,
      method: 'DELETE',
    }).then((response) => {
      return response;
    });
  },
};

export default pageService;
