import React from 'react';
const GamepadIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="52" viewBox="0 0 57 52" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M51.8388 14.1575H47.177C45.0516 14.1575 43.0487 14.9882 41.5444 16.4922L37.8447 20.1911C36.3403 21.6951 35.5094 23.6976 35.5094 25.8225C35.5094 27.9474 36.3403 29.9498 37.8447 31.4539L41.5444 35.1527C43.0487 36.6568 45.0516 37.4875 47.177 37.4875H51.8388C54.4102 37.4875 56.5005 35.3976 56.5005 32.8267V18.8357C56.5005 16.2649 54.4102 14.175 51.8388 14.175V14.1575ZM51.8388 32.8092H47.177C46.2936 32.8092 45.4715 32.4682 44.8417 31.8474L41.142 28.1485C40.521 27.5276 40.1799 26.6969 40.1799 25.8137C40.1799 24.9306 40.521 24.1086 41.142 23.479L44.8417 19.7801C45.4627 19.1592 46.2936 18.8095 47.177 18.8095H51.8388V32.8005V32.8092Z" fill={secondaryColor}/>
<path d="M21.524 25.8137C21.524 23.6888 20.6932 21.6864 19.1888 20.1824L15.4891 16.4835C13.9848 14.9795 11.9818 14.1487 9.85649 14.1487H5.19469C2.62327 14.1487 0.532891 16.2386 0.532891 18.8095V32.8005C0.532891 35.3713 2.62327 37.4612 5.19469 37.4612H9.85649C11.9818 37.4612 13.9848 36.6305 15.4891 35.1265L19.1888 31.4276C20.6932 29.9236 21.524 27.9211 21.524 25.7962V25.8137ZM15.8914 28.1485L12.1917 31.8474C11.5708 32.4682 10.7399 32.818 9.85649 32.818H5.19469V18.827H9.85649C10.7399 18.827 11.562 19.168 12.1917 19.7888L15.8914 23.4877C16.5124 24.1085 16.8535 24.9393 16.8535 25.8225C16.8535 26.7057 16.5124 27.5276 15.8914 28.1572V28.1485Z" fill={secondaryColor}/>
<path d="M22.8885 18.8182C24.3928 20.3223 26.3957 21.153 28.5211 21.153C30.6464 21.153 32.6494 20.3223 34.1537 18.8182L37.8534 15.1194C39.3578 13.6153 40.1887 11.6129 40.1887 9.48799V4.82722C40.1887 2.25638 38.0983 0.166458 35.5269 0.166458H21.5328C18.9614 0.166458 16.871 2.25638 16.871 4.82722V9.48799C16.871 11.6129 17.7019 13.6153 19.2063 15.1194L22.906 18.8182H22.8885ZM21.524 4.82722H35.5181V9.48799C35.5181 10.3712 35.1771 11.1931 34.5561 11.8227L30.8564 15.5216C29.6057 16.772 27.4366 16.772 26.1946 15.5216L22.4949 11.8227C21.8739 11.2019 21.5328 10.3712 21.5328 9.48799V4.82722H21.524Z" fill={secondaryColor}/>
<path d="M34.145 32.8092C31.1362 29.8011 25.8972 29.8011 22.8885 32.8092L19.1888 36.5081C17.6844 38.0121 16.8535 40.0146 16.8535 42.1394V46.8002C16.8535 49.3711 18.9439 51.461 21.5153 51.461H35.5094C38.0808 51.461 40.1712 49.3711 40.1712 46.8002V42.1394C40.1712 40.0146 39.3403 38.0121 37.8359 36.5081L34.1363 32.8092H34.145ZM35.5181 46.8002H21.524V42.1394C21.524 41.2563 21.8652 40.4343 22.4862 39.8047L26.1858 36.1059C26.8068 35.485 27.6377 35.1352 28.5211 35.1352C29.4045 35.1352 30.2267 35.4763 30.8564 36.0971L34.5561 39.796C35.1771 40.4168 35.5181 41.2475 35.5181 42.1307V46.7915V46.8002Z" fill={secondaryColor}/>
<path d="M44.8417 23.479V28.1397C44.8417 29.4251 45.8825 30.4745 47.177 30.4745C48.4714 30.4745 49.5122 29.4339 49.5122 28.1397V23.479C49.5122 22.1936 48.4714 21.1442 47.177 21.1442C45.8825 21.1442 44.8417 22.1848 44.8417 23.479Z" fill={primaryColor}/>
<path d="M26.1858 11.8227H30.8476C32.1333 11.8227 33.1829 10.7822 33.1829 9.48798C33.1829 8.19382 32.1421 7.1532 30.8476 7.1532H26.1858C24.9001 7.1532 23.8506 8.19382 23.8506 9.48798C23.8506 10.7822 24.8914 11.8227 26.1858 11.8227Z" fill={primaryColor}/>
<path d="M9.85649 21.153C8.57078 21.153 7.52119 22.1935 7.52119 23.4877V28.1485C7.52119 29.4339 8.56204 30.4832 9.85649 30.4832C11.1509 30.4832 12.1917 29.4426 12.1917 28.1485V23.4877C12.1917 22.2023 11.1509 21.153 9.85649 21.153Z" fill={primaryColor}/>
<path d="M30.8476 39.8047H26.1858C24.9001 39.8047 23.8506 40.8453 23.8506 42.1395C23.8506 43.4336 24.8914 44.4742 26.1858 44.4742H30.8476C32.1333 44.4742 33.1829 43.4336 33.1829 42.1395C33.1829 40.8453 32.1421 39.8047 30.8476 39.8047Z" fill={primaryColor}/>
</svg>

);

export default GamepadIcon;
