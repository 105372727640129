import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  useMediaQuery,
} from '@mui/material';
import { Form, Formik } from 'formik';
import MapAreaPicker from '../../../components/MapAreaPicker';
import { useEffect, useState } from 'react';
import systemConfigurationService from '../../../services/systemConfigurationService';

const ParkingZoneForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    readonly = false,
    zones,
  } = props;
  const [defaultLat, setDefaultLat] = useState<any>(1);
  const [defaultLong, setDefaultLong] = useState<any>(1);
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );

  useEffect(() => {
    systemConfigurationService
      .getByKey('MapCenterLatitude')
      .then((response) => {
        if (!response.hasError) {
          setDefaultLat(parseFloat(response.result.value));
        }
      });
    systemConfigurationService
      .getByKey('MapCenterLongitude')
      .then((response) => {
        if (!response.hasError) {
          setDefaultLong(parseFloat(response.result.value));
        }
      });
  }, []);

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};
        if (!values.name) {
          errors.name = 'Required';
        }
        if (!values.area) {
          errors.area = 'Kérem válasszon területet a térképen!';
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting, setFieldValue }) => {
        let newValues = { ...values };

        onSubmit(newValues, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        setFieldValue,
        handleChange,
        errors: validationErrors,
      }) => (
        <Form>
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>
                Parkoló zóna {values.id > 0 ? 'szerkesztése' : 'létrehozása'}{' '}
              </h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error: string) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.name}
                  onChange={handleChange}
                  label="Name"
                  name="name"
                  required
                  disabled={readonly}
                  fullWidth
                  autoFocus
                  error={!!touched.name && !!validationErrors.name}
                  helperText={
                    !!touched.name &&
                    !!validationErrors.name &&
                    (validationErrors.name as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.description}
                  onChange={handleChange}
                  label="description"
                  name="description"
                  disabled={readonly}
                  fullWidth
                  error={
                    !!touched.description && !!validationErrors.description
                  }
                  helperText={
                    !!touched.description &&
                    !!validationErrors.description &&
                    (validationErrors.description as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Box color="red">
                  {validationErrors.area && (
                    <li>{validationErrors.area as string}</li>
                  )}
                </Box>
                <MapAreaPicker
                  zones={zones}
                  editingZoneId={values.id}
                  selectedArea={values.area}
                  defaultLong={defaultLong}
                  defaultLat={defaultLat}
                  setSelectedArea={(area: any) => {
                    setFieldValue('area', area);
                  }}
                />
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item xs={12} sm={isSmallScreen ? 12 : 'auto'} p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth={isSmallScreen}
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12} sm={isSmallScreen ? 12 : 'auto'} p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth={isSmallScreen}
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default ParkingZoneForm;
