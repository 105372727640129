import {
  Button,
  TextField,
  Box,
  Grid,
  Paper,
  Autocomplete,
  Stack,
  Switch,
  Typography,
  Chip,
  Checkbox,
  FormControlLabel,
  useMediaQuery,
} from '@mui/material';
import { Form, Formik } from 'formik';
import useImageSelect from '../../hooks/useImageSelect';
import { useEffect, useState } from 'react';
import IconPicker from '../../components/IconPicker';
import IconPickerToggle from '../../components/IconPickerToggle';
import CreateEntityDecorator from '../../components/CreateEntityDecorator';
import TagCreate from '../tags/TagCreate';
import useCheckPermission from '../../hooks/useCheckPermission';
import { ParkingPlaceType } from '../../types/ParkingPlaceType';
import { translateParkingPlaceType } from '../../utils/enumTranslaters';
import { Languages } from '../../types/Languages';

const RssFeedForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    publishers,
    readonly = false,
    tags,
    onTagAdded,
  } = props;

  const { checkPermission } = useCheckPermission();
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};
        if (!values.name) {
          errors.name = 'A megnevezés megadása kötelező';
        }
        if (!values.publisherId) {
          errors.publisherId = 'A publikáló megadása kötelező';
        }
        if (!values.tagIds || values.tagIds.length === 0) {
          errors.tagIds = 'A címkék megadása kötelező';
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        setFieldValue,
        handleChange,
        errors: validationErrors,
      }) => (
        <Form>
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>
                RSS feed {values.id > 0 ? 'szerkesztése' : 'létrehozása'}{' '}
              </h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error: string) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  disablePortal
                  id="publisherId"
                  disabled={readonly}
                  value={values.publisherId}
                  onChange={(event, value) => {
                    setFieldValue('publisherId', value);
                  }}
                  getOptionLabel={(option) =>
                    publishers?.find((g: any) => g.id === option)?.name ?? ''
                  }
                  options={publishers?.map((g: any) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      fullWidth
                      label="Publikáló"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.name}
                  onChange={handleChange}
                  label="Megnevezés"
                  name="name"
                  required
                  disabled={readonly}
                  fullWidth
                  error={!!touched.name && !!validationErrors.name}
                  helperText={
                    !!touched.name &&
                    !!validationErrors.name &&
                    (validationErrors.name as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.description}
                  onChange={handleChange}
                  label="Leírás"
                  name="description"
                  disabled={readonly}
                  fullWidth
                  minRows={3}
                  multiline
                  error={
                    !!touched.description && !!validationErrors.description
                  }
                  helperText={
                    !!touched.description &&
                    !!validationErrors.description &&
                    (validationErrors.description as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  multiple
                  id="tagIds"
                  disabled={readonly || entity.rssSettingsId > 0}
                  value={values.tagIds}
                  onChange={(event, value) => {
                    var tag = tags.find((x: any) => x.id == value);
                    if (tag && tag.publisherId != values.publisherId) return;

                    setFieldValue('tagIds', value);
                    if (values.tagIds.length === 0) {
                      setFieldValue('primaryTagId', value[0]);
                    }
                  }}
                  getOptionLabel={(option) =>
                    tags
                      .filter((x: any) => x.publisherId == values.publisherId)
                      .find((g: any) => g.id === option)?.content ?? ''
                  }
                  options={tags
                    .filter((x: any) => x.publisherId == values.publisherId)
                    .map((g: any) => g.id)}
                  renderTags={(value: any, getTagProps: any) =>
                    value.map((option: any, index: any) => (
                      <Chip
                        key={option}
                        variant={'outlined'}
                        color="primary"
                        label={tags.find((g: any) => g.id === option)?.content}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Címkék"
                      fullWidth
                      error={!!touched.tagIds && !!validationErrors.tagIds}
                      helperText={
                        !!touched.tagIds &&
                        !!validationErrors.tagIds &&
                        (validationErrors.tagIds as string)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  disablePortal
                  id="numberOfContents"
                  disabled={readonly}
                  value={values.numberOfContents}
                  onChange={(event, value) => {
                    setFieldValue('numberOfContents', value);
                  }}
                  options={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      fullWidth
                      label="Cikkek darabszáma"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  disablePortal
                  id="blackListWords"
                  value={
                    values.blackListWords
                      ? values.blackListWords.split(';')
                      : []
                  }
                  disabled={readonly}
                  autoSelect
                  onChange={(event, value) => {
                    setFieldValue('blackListWords', value.join(';'));
                  }}
                  multiple
                  freeSolo
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label="Tiltott szavak" />
                  )}
                  options={[]}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  disablePortal
                  id="language"
                  disabled={readonly}
                  value={values.language}
                  onChange={(event, value) => {
                    setFieldValue('language', value);
                  }}
                  options={Object.values(Languages).filter((x) =>
                    Number.isFinite(x)
                  )}
                  getOptionLabel={(option) => {
                    return Languages[option];
                  }}
                  renderInput={(params) => (
                    <TextField {...params} required fullWidth label="Nyelv" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  label="Rss cikkek engedélyezése"
                  labelPlacement="start"
                  control={
                    <Checkbox
                      value={values.allowRssContent}
                      checked={values.allowRssContent}
                      onChange={(e) => {
                        setFieldValue('allowRssContent', e.target.checked);
                      }}
                    ></Checkbox>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  label="Külsős cikkek engedélyezése"
                  labelPlacement="start"
                  control={
                    <Checkbox
                      value={values.allowExternalContent}
                      checked={values.allowExternalContent}
                      onChange={(e) => {
                        setFieldValue('allowExternalContent', e.target.checked);
                      }}
                    ></Checkbox>
                  }
                />
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item xs={12} sm={isSmallScreen ? 12 : 'auto'} p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth={isSmallScreen}
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12} sm={isSmallScreen ? 12 : 'auto'} p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth={isSmallScreen}
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default RssFeedForm;
