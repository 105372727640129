import React from 'react';
const RocketIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="56" viewBox="0 0 57 56" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M53.5895 3.00847C51.5166 0.927308 48.5866 0.0441292 45.7353 0.647491C38.4409 2.19525 31.6624 5.11587 25.575 9.32191H16.1553C13.0416 9.32191 10.1116 10.5374 7.9075 12.7322L2.26617 18.3723C1.22536 19.4129 0.630615 20.8645 0.630615 22.351C0.630615 25.4465 3.14949 27.9737 6.25443 27.9737H9.51679C8.9133 31.1741 9.64806 34.5669 11.7734 37.2864C12.8667 38.6855 14.0561 40.0322 15.3068 41.2826C16.5401 42.5243 17.8783 43.696 19.2602 44.7891C21.403 46.4593 23.9482 47.3075 26.5022 47.3075C27.2106 47.3075 27.9191 47.22 28.6188 47.0889V50.3418C28.6188 53.4373 31.1377 55.9644 34.2426 55.9644C35.7295 55.9644 37.1814 55.3698 38.2223 54.3292L43.8636 48.6891C46.0676 46.4855 47.2834 43.5561 47.2834 40.4431V31.0255C51.4904 24.9306 54.4203 18.1537 55.9597 10.8609C56.5544 8.02774 55.6711 5.08963 53.5982 3.00847H53.5895ZM5.29236 22.3598C5.29236 22.0974 5.38852 21.8526 5.56344 21.6777L11.2049 16.0376C12.5256 14.7172 14.2836 13.9914 16.1553 13.9914H19.7588C16.6888 16.7809 13.9162 19.8851 11.511 23.3216H6.24568C5.7209 23.3216 5.29236 22.8932 5.29236 22.3685V22.3598ZM40.5662 45.3837L34.9249 51.0238C34.4088 51.5485 33.2893 51.1288 33.2893 50.3418V45.0777C36.7266 42.6817 39.8315 39.9097 42.6216 36.8405V40.4431C42.6216 42.2882 41.8782 44.0895 40.575 45.3925L40.5662 45.3837ZM51.3941 9.89904C49.9335 16.8158 47.126 23.2255 43.0502 28.9618C39.5867 33.8324 35.3272 38.0209 30.3942 41.4313C27.9015 43.1539 24.5867 43.0227 22.1465 41.1165C20.9045 40.1458 19.715 39.0965 18.613 37.986C17.4935 36.8667 16.4352 35.6687 15.4556 34.4183C13.6014 32.0398 13.4789 28.6645 15.167 26.216C18.578 21.2755 22.7763 17.017 27.6392 13.5542C33.3681 9.47931 39.7878 6.67237 46.7061 5.21206C46.9685 5.15959 47.2309 5.12461 47.4933 5.12461C48.5166 5.12461 49.5312 5.54434 50.2921 6.3051C51.2542 7.26698 51.6653 8.60487 51.3941 9.89029V9.89904Z" fill={secondaryColor}/>
<path d="M9.28056 47.3162C8.37094 46.4068 6.8929 46.4068 5.98329 47.3162L1.32143 51.977C0.411817 52.8864 0.411817 54.3642 1.32143 55.2736C1.77624 55.7283 2.371 55.9557 2.9745 55.9557C3.57799 55.9557 4.16401 55.7283 4.62756 55.2736L9.28931 50.6128C10.1989 49.7034 10.1989 48.2256 9.28931 47.3162H9.28056Z" fill={secondaryColor}/>
<path d="M2.96574 46.6341C3.56049 46.6341 4.15526 46.4068 4.61881 45.9521L9.28056 41.2913C10.1902 40.3819 10.1902 38.9041 9.28056 37.9947C8.37094 37.0853 6.8929 37.0853 5.98329 37.9947L1.32143 42.6555C0.411817 43.5649 0.411817 45.0427 1.32143 45.9521C1.77624 46.4068 2.371 46.6341 2.9745 46.6341H2.96574Z" fill={secondaryColor}/>
<path d="M15.3068 47.3162L10.645 51.977C9.73539 52.8864 9.73539 54.3642 10.645 55.2736C11.0998 55.7283 11.6946 55.9557 12.2981 55.9557C12.9016 55.9557 13.4876 55.7283 13.9511 55.2736L18.6129 50.6128C19.5225 49.7034 19.5225 48.2256 18.6129 47.3162C17.7033 46.4068 16.2251 46.4068 15.3155 47.3162H15.3068Z" fill={secondaryColor}/>
<path d="M20.9395 26.3297C20.0298 25.4203 18.5517 25.4203 17.6421 26.3297C16.7325 27.2391 16.7325 28.7169 17.6421 29.6263L26.9744 38.9566C27.4292 39.4113 28.0239 39.6386 28.6274 39.6386C29.2309 39.6386 29.8169 39.4113 30.2805 38.9566C31.1901 38.0472 31.1901 36.5694 30.2805 35.66L20.9482 26.3297H20.9395Z" fill={primaryColor}/>
<path d="M37.951 11.6566C34.0939 11.6566 30.9539 14.7959 30.9539 18.6521C30.9539 22.5084 34.0939 25.6476 37.951 25.6476C41.8081 25.6476 44.948 22.5084 44.948 18.6521C44.948 14.7959 41.8081 11.6566 37.951 11.6566ZM37.951 20.9869C36.6653 20.9869 35.6158 19.9376 35.6158 18.6521C35.6158 17.3667 36.6653 16.3174 37.951 16.3174C39.2367 16.3174 40.2863 17.3667 40.2863 18.6521C40.2863 19.9376 39.2367 20.9869 37.951 20.9869Z" fill={primaryColor}/>
</svg>

);

export default RocketIcon;
