import React from 'react';
const CopyIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="52" height="57" viewBox="0 0 52 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M47.208 10.1151L39.0652 3.32948C36.9749 1.58935 34.3247 0.627502 31.5959 0.627502H21.8349C17.077 0.627502 12.9312 3.55684 11.1382 7.76289C5.98664 8.43621 1.7709 12.4761 1.06245 17.7402C0.458956 22.3048 0.152865 26.6157 0.109133 30.918C0.0654017 36.1996 0.388985 41.4375 1.06245 46.5005C1.84087 52.2543 6.81751 56.5915 12.6338 56.5915H29.4268C34.176 56.5915 38.3743 53.7233 40.176 49.4561C45.3451 48.7653 49.5521 44.7079 50.2255 39.4175C50.7678 35.0978 51.1352 30.7082 51.3101 26.3709C51.4063 23.905 51.4413 21.4041 51.415 18.9382C51.38 15.5279 49.8494 12.31 47.2168 10.1151H47.208ZM42.5287 12.2925H39.7561C38.4704 12.2925 37.4209 11.2432 37.4209 9.95774V8.03396L42.5287 12.2837V12.2925ZM29.4268 51.9307H12.6338C9.14403 51.9307 6.15284 49.3336 5.68929 45.8883C5.04206 41.044 4.7359 36.0247 4.77963 30.9704C4.82337 26.8606 5.12077 22.7333 5.68929 18.3698C6.03914 15.799 7.77091 13.7091 10.0624 12.7997C9.64262 16.4898 9.36276 20.2236 9.31903 23.9312C9.2578 29.1341 9.58139 34.372 10.2723 39.5225C11.042 45.2763 16.0186 49.6135 21.8349 49.6135H34.6396C33.3451 51.0563 31.4647 51.9482 29.418 51.9482L29.4268 51.9307ZM46.6482 26.1873C46.482 30.3933 46.1235 34.6431 45.5987 38.8317C45.1527 42.3119 42.1702 44.9352 38.6454 44.9352H21.8437C18.3539 44.9352 15.3627 42.3381 14.9079 38.8928C14.2432 33.9698 13.9458 28.9505 13.9983 23.9749C14.042 19.7689 14.3482 15.5279 14.9079 11.3656C15.3715 7.90279 18.3539 5.29697 21.8525 5.29697H31.6134C32.007 5.29697 32.3918 5.33194 32.7766 5.4019V9.96649C32.7766 13.8228 35.9165 16.962 39.7736 16.962H46.3947C46.6046 17.6178 46.7532 18.2911 46.7619 18.9907C46.7882 21.3779 46.7532 23.8001 46.657 26.196L46.6482 26.1873Z" fill={secondaryColor}/>
<path d="M37.4297 23.9487H23.4356C22.1499 23.9487 21.1003 24.9893 21.1003 26.2835C21.1003 27.5776 22.1411 28.6182 23.4356 28.6182H37.4297C38.7154 28.6182 39.7649 27.5776 39.7649 26.2835C39.7649 24.9893 38.7242 23.9487 37.4297 23.9487Z" fill={primaryColor}/>
<path d="M37.4297 30.9442H23.4356C22.1499 30.9442 21.1003 31.9848 21.1003 33.279C21.1003 34.5731 22.1411 35.6137 23.4356 35.6137H37.4297C38.7154 35.6137 39.7649 34.5731 39.7649 33.279C39.7649 31.9848 38.7242 30.9442 37.4297 30.9442Z" fill={primaryColor}/>
</svg>

);

export default CopyIcon;
