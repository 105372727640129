import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import publisherService from '../../../services/publisherService';
import useCheckPermission from '../../../hooks/useCheckPermission';
import parkingZoneConfigService from '../../../services/parking/parkingZoneConfigService';
import ParkingZoneConfigForm from './ParkingZoneConfigForm';
import parkingZoneService from '../../../services/parking/parkingZoneService';
import moment from 'moment';

const ParkingZoneConfigEdit = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const params = useParams();
  const [errors, setErrors] = useState<string[]>([]);
  const [now, setNow] = useState(new Date());
  const [entity, setEntity] = useState<any>({
    name: '',
    color: '#1146CC',
    zoneId: 0,
    parkingLimitInMinutes: 0,
    extensionLengthInMinutes: 0,
    startOfOperatingTime: now.setHours(8, 0, 0, 0),
    endOfOperatingTime: now.setHours(18, 0, 0, 0),
    startOfValidity: now,
    endOfValidity: null,
    numberOfExtensions: 0,
    numberOfParkingPlaces: 0,
    parkingPrice: 0,
    isPR: false,
    isPrivate: false,
    isPermissionRequired: false,
    includedDays: 0,
    isException: false,
  });

  const { checkPermission } = useCheckPermission();
  const [zones, setZones] = useState<any[]>([]);
  const [zoneConfigs, setZoneConfigs] = useState<any[]>([]);

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    parkingZoneService
      .list()
      .then((response: any) => {
        if (!response.hasError) {
          setZones(response.records);
        } else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    parkingZoneConfigService
      .list()
      .then((response: any) => {
        if (!response.hasError) {
          setZoneConfigs(response.records);
        } else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  useEffect(() => {
    parkingZoneConfigService.get(params.id).then((response: any) => {
      if (response.hasError) {
        setErrors(response.errorMessages);
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setEntity({
          ...response.result,
          startOfOperatingTime: moment(response.result.startOfOperatingTime),
          endOfOperatingTime: moment(response.result.endOfOperatingTime),
          startOfValidity: now,
        });
      }
    });
  }, [params.id]);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    parkingZoneConfigService
      .update({
        ...entity,
        startOfOperatingTime: moment(entity.startOfOperatingTime).toDate(),
        endOfOperatingTime: moment(entity.endOfOperatingTime).toDate(),
      })
      .then((response) => {
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Success!', {
            variant: 'success',
          });

          navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        if (setSubmitting) setSubmitting(false);
      });
  };

  return (
    <ParkingZoneConfigForm
      entity={entity}
      readonly={
        !checkPermission(['EditParkingZoneConfig']) || entity.isModified
      }
      onSubmit={onSubmit}
      handleCancel={() => navigate(-1)}
      errors={errors}
      zones={zones}
      zoneConfigs={zoneConfigs}
    />
  );
};

export default ParkingZoneConfigEdit;
