import { ParkingCarType } from '../types/ParkingCarType';
import { ParkingPlaceType } from '../types/ParkingPlaceType';
export const translateParkingPlaceType = (type: ParkingPlaceType) => {
  switch (type) {
    case ParkingPlaceType.Normal:
      return 'Normál';
    case ParkingPlaceType.Bicycle:
      return 'Bicikli';
    case ParkingPlaceType.Disabled:
      return 'Mozgássérült';
    case ParkingPlaceType.Bus:
      return 'Busz';
    case ParkingPlaceType.Emergency:
      return 'Vészhelyzet';
    case ParkingPlaceType.Extraordinary:
      return 'Különleges';
    case ParkingPlaceType.Family:
      return 'Családi';
    case ParkingPlaceType.Taxi:
      return 'Taxi';
    default:
      return '';
  }
};
export const translateParkingCarType = (type: ParkingCarType) => {
  switch (type) {
    case ParkingCarType.passengerCar:
      return 'Személyautó';
    case ParkingCarType.lightTruck:
      return 'Kisteherautó';
    case ParkingCarType.heavyTruck:
      return 'Teherautó';
    case ParkingCarType.bus:
      return 'Busz';
    case ParkingCarType.motorcycle:
      return 'Motorkerékpár';
    case ParkingCarType.motorhome:
      return 'Lakóautó';
    case ParkingCarType.trailer:
      return 'Pótkocsi';
    default:
      return '';
  }
};
