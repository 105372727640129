import React from 'react';
const DumbbellIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="66" height="44" viewBox="0 0 66 44" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M5.47862 24.8534H0V19.1465H5.47862V24.8534ZM49.2218 30.332H16.4073V24.6252H49.2218V30.332ZM49.2218 19.3749H16.4073V13.668H49.2218V19.3749ZM65.6291 24.8534H60.1505V19.1466H65.6291V24.8534ZM11.5849 0H13.0402C17.986 0.00640909 21.9935 4.01401 22 8.95914V35.0403C21.9936 39.986 17.986 43.9935 13.0408 44H11.5849C6.63917 43.9936 2.63164 39.986 2.62516 35.0409V8.95971C2.63157 4.01401 6.63982 0.00647318 11.5849 0ZM13.0402 38.2932C14.836 38.2916 16.2915 36.8361 16.2931 35.0404V8.95991C16.2915 7.16395 14.836 5.70858 13.0403 5.70691H11.5848C9.78899 5.70851 8.33361 7.16395 8.33194 8.95965V35.0402C8.33355 36.8361 9.78899 38.2915 11.5847 38.2932H13.0402ZM52.5889 0H54.0441C58.9899 0.00640909 62.9974 4.01401 63.0039 8.95914V35.0403C62.9975 39.986 58.9899 43.9935 54.0448 44H52.5889C47.6431 43.9936 43.6356 39.986 43.6291 35.0409V8.95971C43.6355 4.01401 47.6438 0.00647318 52.5889 0ZM54.0441 38.2932C55.84 38.2916 57.2955 36.8361 57.2971 35.0404V8.95991C57.2955 7.16395 55.84 5.70858 54.0443 5.70691H52.5888C50.793 5.70851 49.3376 7.16395 49.3359 8.95965V35.0402C49.3376 36.8361 50.793 38.2915 52.5887 38.2932H54.0441Z" fill={secondaryColor}/>
<rect y="19" width="3" height="7" fill={primaryColor}/>
<rect x="63" y="18" width="3" height="7" fill={primaryColor}/>
</svg>

);

export default DumbbellIcon;
