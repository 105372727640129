import React from 'react';
const PerformanceIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="56" height="57" viewBox="0 0 56 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M28.0118 0.431976C12.5745 0.431976 0.0235596 12.9889 0.0235596 28.414C0.0235596 43.8391 12.5745 56.396 28.0118 56.396C43.449 56.396 56 43.8391 56 28.414C56 12.9889 43.449 0.431976 28.0118 0.431976ZM28.0118 51.7352C15.1547 51.7352 4.68531 41.277 4.68531 28.414C4.68531 15.551 15.1459 5.09274 28.0118 5.09274C40.8776 5.09274 51.3382 15.551 51.3382 28.414C51.3382 41.277 40.8776 51.7352 28.0118 51.7352Z" fill={secondaryColor}/>
<path d="M28.0118 7.42749C16.4404 7.42749 7.02066 16.8452 7.02066 28.414C7.02066 29.6994 8.06151 30.7487 9.35596 30.7487C10.6504 30.7487 11.6912 29.7082 11.6912 28.414C11.6912 19.416 19.0119 12.0883 28.0206 12.0883C37.0293 12.0883 44.35 19.4073 44.35 28.414C44.35 29.6994 45.3907 30.7487 46.6852 30.7487C47.9796 30.7487 49.0205 29.7082 49.0205 28.414C49.0205 16.8452 39.6007 7.42749 28.0293 7.42749H28.0118Z" fill={secondaryColor}/>
<path d="M35.6911 17.4398L30.1285 23.0012C29.4725 22.7389 28.7552 22.5903 28.0118 22.5903C24.7932 22.5903 22.178 25.2048 22.178 28.4227C22.178 31.6407 24.7932 34.2552 28.0118 34.2552C31.2305 34.2552 33.8456 31.6407 33.8456 28.4227C33.8456 27.6707 33.6881 26.9624 33.4345 26.3066L38.9972 20.7452C39.9068 19.8358 39.9068 18.358 38.9972 17.4486C38.0876 16.5391 36.6095 16.5391 35.6999 17.4486L35.6911 17.4398ZM28.0118 29.577C27.3733 29.577 26.8486 29.0523 26.8486 28.414C26.8486 27.7756 27.3733 27.251 28.0118 27.251C28.3354 27.251 28.624 27.3821 28.8339 27.592C29.0438 27.8019 29.175 28.0904 29.175 28.414C29.175 29.0611 28.6503 29.577 28.0118 29.577Z" fill={primaryColor}/>
<path d="M21.0147 37.7442C19.729 37.7442 18.6794 38.7848 18.6794 40.079V44.7397C18.6794 46.0252 19.7203 47.0745 21.0147 47.0745C22.3092 47.0745 23.3501 46.0339 23.3501 44.7397V40.079C23.3501 38.7936 22.3092 37.7442 21.0147 37.7442Z" fill={secondaryColor}/>
<path d="M28.0118 37.7442C26.7261 37.7442 25.6765 38.7848 25.6765 40.079V44.7397C25.6765 46.0252 26.7173 47.0745 28.0118 47.0745C29.3062 47.0745 30.3471 46.0339 30.3471 44.7397V40.079C30.3471 38.7936 29.3062 37.7442 28.0118 37.7442Z" fill={secondaryColor}/>
<path d="M35.0089 37.7442C33.7231 37.7442 32.6736 38.7848 32.6736 40.079V44.7397C32.6736 46.0252 33.7144 47.0745 35.0089 47.0745C36.3033 47.0745 37.3442 46.0339 37.3442 44.7397V40.079C37.3442 38.7936 36.3033 37.7442 35.0089 37.7442Z" fill={secondaryColor}/>
</svg>

);

export default PerformanceIcon;
