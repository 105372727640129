import {
  GridColDef,
  GridActionsCellItem,
  GridActionsColDef,
} from '@mui/x-data-grid';
import {
  Paper,
  IconButton,
  Grid,
  Tooltip,
  SpeedDial,
  Button,
  TextField,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Visibility,
  HideImage,
  Image,
  QrCodeScanner,
} from '@mui/icons-material';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { enqueueSnackbar, useSnackbar } from 'notistack';
import useCheckPermission from '../../hooks/useCheckPermission';
import { useBarcodeScanner } from '../../components/barcode-scanner/useBarcodeScanner';
import parkingService from '../../services/parking/parkingService';
import { parseJSON } from 'date-fns';
import { translateParkingCarType } from '../../utils/enumTranslaters';

const ParkingRead = () => {
  const [entity, setEntity] = useState<any>({
    id: '',
    emailAddress: '',
    licensePlate: '',
    zoneName: '',
    numberOfExtensions: 0,
  });
  const dispatch = useDispatch<any>();

  const { checkPermission } = useCheckPermission();

  const onQrCodeScanned = (id: any) => {
    dispatch({ type: 'SHOW_QUERY' });
    parkingService
      .getParking({ id: id })
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) {
          setEntity(response.result);
        } else {
          enqueueSnackbar('Nem található', { variant: 'error' });
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
      });
  };

  const handleBarcodeRead = (id: any) => {
    if (id && id.data.length === 36) {
      onQrCodeScanned(id.data);
    }
  };

  useBarcodeScanner(handleBarcodeRead, 1);

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12} textAlign={{ xs: 'center', sm: 'left' }}>
          <h2>Parkolás lekérdezés</h2>
        </Grid>
        {!entity.id && (
          <Grid item xs={12} textAlign={'center'}>
            <h2>Kérem olvassa be a parkolójegy QR kódját!</h2>
          </Grid>
        )}
        {entity.id && (
          <Grid item container xs={12} textAlign={'center'} spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Email cím"
                value={entity.emailAddress}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Rendszám"
                value={entity.licensePlate}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Zóna" value={entity.zoneName} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Hosszabbítások száma"
                value={entity.numberOfExtensions}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Parkolás kezdete"
                value={parseJSON(entity.startOfParking).toLocaleString()}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Parkolás vége"
                value={parseJSON(entity.endOfParking).toLocaleString()}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Cím" value={entity.address} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Jármű típusa"
                value={translateParkingCarType(entity.vehicleType)}
                fullWidth
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default ParkingRead;
