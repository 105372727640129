import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useCheckPermission from '../../hooks/useCheckPermission';
import officialService from '../../services/officialService';
import OfficialForm from './OfficialForm';
import publisherService from '../../services/publisherService';
import pageService from '../../services/pageService';
import { ApprovementStatus } from '../contents/ContentPage';

const OfficialEdit = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const params = useParams();
  const [errors, setErrors] = useState<string[]>([]);
  const [publishers, setPublishers] = useState<any[]>([]);
  const [pages, setPages] = useState<any[]>([]);

  const [entity, setEntity] = useState<any>({
    id: 0,
    imageId: '',
    title: '',
    firstName: '',
    lastName: '',
    role: '',
    membership: '',
    area: '',
    introduction: '',
    phone: '',
    email: '',
    fax: '',
    order: 0,
    pageId: '',
  });

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    publisherService
      .list()
      .then((response: any) => {
        if (!response.hasError) {
          setPublishers(response.records);
        } else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    pageService
      .list(ApprovementStatus.ApprovedSecondLevel)
      .then((response: any) => {
        if (!response.hasError) {
          setPages(response.records);
        } else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    officialService
      .get(params.id)
      .then((response: any) => {
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setEntity(response.result);
        }
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, [params.id]);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    officialService
      .update(entity)
      .then((response) => {
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Success!', {
            variant: 'success',
          });

          navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        if (setSubmitting) setSubmitting(false);
      });
  };

  return (
    <OfficialForm
      entity={entity}
      readonly={!checkPermission(['EditOfficial'])}
      onSubmit={onSubmit}
      handleCancel={() => navigate(-1)}
      errors={errors}
      pages={pages}
      publishers={publishers}
    />
  );
};

export default OfficialEdit;
