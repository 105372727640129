import React from 'react';
const CompassIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="56" viewBox="0 0 57 56" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M28.4012 0.0297546C12.9727 0.0297546 0.412964 12.5867 0.412964 28.0118C0.412964 43.4368 12.9727 55.9938 28.4012 55.9938C43.8297 55.9938 56.3894 43.4368 56.3894 28.0118C56.3894 12.5867 43.8297 0.0297546 28.4012 0.0297546ZM28.4012 51.333C15.5441 51.333 5.07475 40.8747 5.07475 28.0118C5.07475 15.1488 15.5353 4.69049 28.4012 4.69049C41.267 4.69049 51.7276 15.1488 51.7276 28.0118C51.7276 40.8747 41.267 51.333 28.4012 51.333Z" fill={secondaryColor}/>
<path d="M42.8763 13.5398C41.4507 12.1145 39.2291 11.8172 37.4711 12.814L24.7889 20.0631C22.9785 21.095 21.4829 22.599 20.4508 24.4003L13.2001 37.0797C12.1943 38.8373 12.5004 41.0583 13.926 42.4837C14.7919 43.3494 15.9377 43.7953 17.1097 43.7953C17.8706 43.7953 18.6403 43.603 19.3313 43.2095L32.0134 35.9604C33.8239 34.9285 35.3195 33.4245 36.3516 31.6232L43.6023 18.9438C44.6081 17.1862 44.302 14.9651 42.8763 13.5398ZM17.5645 38.846L24.2467 27.1548L29.2583 32.1653L17.5645 38.846ZM32.5557 28.8687L27.544 23.8582L39.2379 17.1775L32.5557 28.8687Z" fill={primaryColor}/>
<path d="M28.4012 14.0207C29.6869 14.0207 30.7364 12.9802 30.7364 11.686V9.35125C30.7364 8.06583 29.6956 7.01649 28.4012 7.01649C27.1067 7.01649 26.0659 8.05709 26.0659 9.35125V11.686C26.0659 12.9714 27.1067 14.0207 28.4012 14.0207Z" fill={secondaryColor}/>
<path d="M14.407 28.0117C14.407 26.7263 13.3662 25.677 12.0718 25.677H9.73651C8.4508 25.677 7.40125 26.7176 7.40125 28.0117C7.40125 29.3059 8.44205 30.3465 9.73651 30.3465H12.0718C13.3575 30.3465 14.407 29.3059 14.407 28.0117Z" fill={secondaryColor}/>
<path d="M28.4012 42.0027C27.1155 42.0027 26.0659 43.0433 26.0659 44.3375V46.6722C26.0659 47.9576 27.1067 49.007 28.4012 49.007C29.6956 49.007 30.7364 47.9664 30.7364 46.6722V44.3375C30.7364 43.0521 29.6956 42.0027 28.4012 42.0027Z" fill={secondaryColor}/>
<path d="M47.057 25.6857H44.7218C43.4361 25.6857 42.3865 26.7263 42.3865 28.0205C42.3865 29.3147 43.4273 30.3552 44.7218 30.3552H47.057C48.3427 30.3552 49.3923 29.3147 49.3923 28.0205C49.3923 26.7263 48.3515 25.6857 47.057 25.6857Z" fill={secondaryColor}/>
</svg>

);

export default CompassIcon;
