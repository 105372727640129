import { combineReducers } from 'redux';
import { appReducer } from './appReducer';
import { userReducer } from './userReducer';
import { waitLayerReducer } from './waitLayerReducer';

const rootReducer = combineReducers({
  waitLayer: waitLayerReducer,
  app: appReducer,
  user: userReducer,
});

export default rootReducer;
