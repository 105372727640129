import React from 'react';
const CalculatorIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M54.9315 11.4849C54.2056 6.39565 50.1123 2.33825 44.9782 1.61247C33.8267 0.0384831 22.3603 0.0384917 11.2175 1.62122C6.12713 2.34701 2.06881 6.43938 1.34287 11.5723C0.555699 17.1337 0.153381 22.7914 0.153381 28.4052C0.153381 34.0191 0.555707 39.7467 1.35162 45.3343C2.07757 50.4236 6.17084 54.481 11.3049 55.2067C16.8676 55.9937 22.5352 56.396 28.1416 56.396C33.748 56.396 39.4768 55.9937 45.0657 55.198C50.156 54.4722 54.2144 50.3798 54.9403 45.2469C55.7275 39.6855 56.1298 34.0279 56.1298 28.414C56.1298 22.8001 55.7275 17.0725 54.9315 11.4849ZM50.3222 44.5911C49.8849 47.6866 47.4534 50.1437 44.4097 50.581C33.6955 52.1112 22.6839 52.1112 11.9521 50.5897C8.85595 50.1525 6.39826 47.7216 5.96095 44.6785C5.19127 39.3182 4.80643 33.8442 4.80643 28.414C4.80643 22.9837 5.19125 17.5797 5.94344 12.2369C6.38075 9.14137 8.81223 6.68421 11.856 6.25573C17.2262 5.49497 22.7014 5.10149 28.1241 5.10149C33.5468 5.10149 38.9608 5.48624 44.3048 6.23825C47.4009 6.67547 49.8587 9.10641 50.296 12.1495C51.0657 17.5098 51.4505 22.9837 51.4505 28.414C51.4505 33.8442 51.0656 39.2483 50.3135 44.5911H50.3222Z" fill={secondaryColor}/>
<path d="M46.7975 26.0793H30.4681V9.7535C30.4681 8.46807 29.4273 7.41876 28.1328 7.41876C26.8384 7.41876 25.7976 8.45933 25.7976 9.7535V26.0793H9.46824C8.18253 26.0793 7.13293 27.1198 7.13293 28.414C7.13293 29.7082 8.17378 30.7487 9.46824 30.7487H25.7976V47.0745C25.7976 48.3599 26.8384 49.4092 28.1328 49.4092C29.4273 49.4092 30.4681 48.3687 30.4681 47.0745V30.7487H46.7975C48.0832 30.7487 49.1327 29.7082 49.1327 28.414C49.1327 27.1198 48.0919 26.0793 46.7975 26.0793Z" fill={primaryColor}/>
<path d="M11.8122 19.0838H14.1475V21.4185C14.1475 22.7039 15.1883 23.7532 16.4827 23.7532C17.7772 23.7532 18.818 22.7127 18.818 21.4185V19.0838H21.1533C22.439 19.0838 23.4885 18.0432 23.4885 16.749C23.4885 15.4548 22.4478 14.4143 21.1533 14.4143H18.818V12.0795C18.818 10.7941 17.7772 9.74477 16.4827 9.74477C15.1883 9.74477 14.1475 10.7853 14.1475 12.0795V14.4143H11.8122C10.5265 14.4143 9.47694 15.4548 9.47694 16.749C9.47694 18.0432 10.5178 19.0838 11.8122 19.0838Z" fill={secondaryColor}/>
<path d="M35.1386 19.0838H44.4709C45.7566 19.0838 46.8062 18.0432 46.8062 16.749C46.8062 15.4548 45.7654 14.4143 44.4709 14.4143H35.1386C33.8529 14.4143 32.8034 15.4548 32.8034 16.749C32.8034 18.0432 33.8442 19.0838 35.1386 19.0838Z" fill={secondaryColor}/>
<path d="M44.4622 34.2377H35.1299C33.8442 34.2377 32.7946 35.2783 32.7946 36.5725C32.7946 37.8667 33.8354 38.9072 35.1299 38.9072H44.4622C45.7479 38.9072 46.7975 37.8667 46.7975 36.5725C46.7975 35.2783 45.7566 34.2377 44.4622 34.2377Z" fill={secondaryColor}/>
<path d="M44.4622 41.2332H35.1299C33.8442 41.2332 32.7946 42.2738 32.7946 43.568C32.7946 44.8622 33.8354 45.9027 35.1299 45.9027H44.4622C45.7479 45.9027 46.7975 44.8622 46.7975 43.568C46.7975 42.2738 45.7566 41.2332 44.4622 41.2332Z" fill={secondaryColor}/>
<path d="M21.4244 35.1209C20.5148 34.2115 19.0366 34.2115 18.127 35.1209L16.474 36.7736L14.821 35.1209C13.9113 34.2115 12.4332 34.2115 11.5236 35.1209C10.614 36.0303 10.614 37.5081 11.5236 38.4176L13.1766 40.0703L11.5236 41.7229C10.614 42.6323 10.614 44.1102 11.5236 45.0196C12.4332 45.929 13.9113 45.929 14.821 45.0196L16.474 43.3669L18.127 45.0196C19.0366 45.929 20.5148 45.929 21.4244 45.0196C22.334 44.1102 22.334 42.6323 21.4244 41.7229L19.7713 40.0703L21.4244 38.4176C22.334 37.5081 22.334 36.0303 21.4244 35.1209Z" fill={secondaryColor}/>
</svg>

);

export default CalculatorIcon;
