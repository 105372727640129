import axios from 'axios';
import { postData } from '../axiosUtils';

const parkingZoneConfigService = {
  list: (showAll: any = false) => {
    return axios
      .get(`/api/parkingZoneConfig/list?showAll=${showAll}`)
      .then((response) => {
        return response.data;
      });
  },
  get: (id: any) => {
    return axios
      .get(`/api/parkingZoneConfig/details/${id}`)
      .then((response) => {
        return response.data;
      });
  },
  create: (entity: any) => {
    return postData({
      url: `/api/parkingZoneConfig/create`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  update: (entity: any) => {
    return postData({
      url: `/api/parkingZoneConfig/update`,
      data: entity,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  delete: (id: any) => {
    return postData({
      url: `/api/parkingZoneConfig/delete/${id}`,
      method: 'DELETE',
    }).then((response) => {
      return response;
    });
  },
};

export default parkingZoneConfigService;
