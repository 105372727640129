import React from 'react';

import Abc_cubeIcon from './abc_cube.jsx';
import Alarm_clockIcon from './alarm_clock.jsx';
import ApprovedIcon from './approved.jsx';
import ArchiveIcon from './archive.jsx';
import BackpackIcon from './backpack.jsx';
import BankIcon from './bank.jsx';
import Bell_ringIcon from './bell_ring.jsx';
import Bell_silentIcon from './bell_silent.jsx';
import BookIcon from './book.jsx';
import BookmarkIcon from './bookmark.jsx';
import BoxIcon from './box.jsx';
import BrushIcon from './brush.jsx';
import BuoyIcon from './buoy.jsx';
import CalculatorIcon from './calculator.jsx';
import CameraIcon from './camera.jsx';
import CardiogramIcon from './cardiogram.jsx';
import ChatIcon from './Chat.jsx';
import CinemasIcon from './cinemas.jsx';
import CleverIcon from './clever.jsx';
import CompassIcon from './compass.jsx';
import ContrastIcon from './contrast.jsx';
import CopyIcon from './copy.jsx';
import Credit_cardIcon from './credit_card.jsx';
import Crossed_eyeIcon from './crossed_eye.jsx';
import CrosshairIcon from './crosshair.jsx';
import CrownIcon from './crown.jsx';
import DislikeIcon from './dislike.jsx';
import DumbbellIcon from './dumbbell.jsx';
import Dvd_playerIcon from './DVD_player.jsx';
import EditIcon from './edit.jsx';
import EnvelopeIcon from './envelope.jsx';
import EssayIcon from './essay.jsx';
import EyeIcon from './eye.jsx';
import Faulty_fileIcon from './faulty_file.jsx';
import FilterIcon from './filter.jsx';
import FingerprintIcon from './fingerprint.jsx';
import FireIcon from './fire.jsx';
import First_aidIcon from './first_aid.jsx';
import FloppyIcon from './Floppy.jsx';
import FolderIcon from './folder.jsx';
import GamepadIcon from './gamepad.jsx';
import GearIcon from './gear.jsx';
import GiftIcon from './gift.jsx';
import GlassesIcon from './glasses.jsx';
import HeadphonesIcon from './headphones.jsx';
import HeartIcon from './heart.jsx';
import HourglassIcon from './hourglass.jsx';
import HouseIcon from './house.jsx';
import ImagesIcon from './images.jsx';
import IncognitoIcon from './Incognito.jsx';
import IndexIcon from './index.jsx';
import InfinityIcon from './infinity.jsx';
import LaptopIcon from './Laptop.jsx';
import LayoutIcon from './layout.jsx';
import LightningIcon from './lightning.jsx';
import LikeIcon from './like.jsx';
import LoginIcon from './login.jsx';
import MagnetIcon from './magnet.jsx';
import MagnifyIcon from './magnify.jsx';
import Magnifying_glassIcon from './magnifying_glass.jsx';
import MailboxIcon from './mailbox.jsx';
import Mailbox_1Icon from './mailbox_1.jsx';
import Map_pinIcon from './map_pin.jsx';
import MegaphoneIcon from './Megaphone.jsx';
import MinimizeIcon from './minimize.jsx';
import MuteIcon from './mute.jsx';
import NotepadIcon from './notepad.jsx';
import Open_padlockIcon from './open_padlock.jsx';
import OwlIcon from './owl.jsx';
import PacifierIcon from './pacifier.jsx';
import PadlockIcon from './padlock.jsx';
import PaletteIcon from './palette.jsx';
import PerformanceIcon from './performance.jsx';
import Person_circleIcon from './person_circle.jsx';
import PhoneIcon from './phone.jsx';
import Pingpong_paddleIcon from './pingpong_paddle.jsx';
import Power_buttonIcon from './power_button.jsx';
import PrintIcon from './print.jsx';
import PushpinIcon from './pushpin.jsx';
import Question_markIcon from './question_mark.jsx';
import RadioIcon from './radio.jsx';
import RefreshIcon from './refresh.jsx';
import RejectedIcon from './rejected.jsx';
import RocketIcon from './rocket.jsx';
import RulerIcon from './ruler.jsx';
import SchoolIcon from './school.jsx';
import School_busIcon from './school_bus.jsx';
import ScreenshotIcon from './screenshot.jsx';
import SendIcon from './send.jsx';
import ShareIcon from './share.jsx';
import ShieldIcon from './shield.jsx';
import Shield_xIcon from './shield_X.jsx';
import Shopping_bagIcon from './shopping_bag.jsx';
import Shopping_basketIcon from './shopping_basket.jsx';
import Shopping_cartIcon from './shopping_cart.jsx';
import Shopping_cart_1Icon from './shopping_cart_1.jsx';
import SmartphoneIcon from './smartphone.jsx';
import SmileyIcon from './smiley.jsx';
import SpeakerIcon from './speaker.jsx';
import Spoon_forkIcon from './spoon_fork.jsx';
import StarIcon from './star.jsx';
import StoreIcon from './store.jsx';
import SuitcaseIcon from './suitcase.jsx';
import SwastikaIcon from './swastika.jsx';
import Teacher_classIcon from './teacher_class.jsx';
import Teacher_class_1Icon from './teacher_class_1.jsx';
import TempleIcon from './temple.jsx';
import Three_d_cubeIcon from './three_d_cube.jsx';
import TransactionIcon from './transaction.jsx';
import Trash_canIcon from './trash_can.jsx';
import VideoIcon from './video.jsx';
import Video_cameraIcon from './video_camera.jsx';
import Voice_messageIcon from './voice_message.jsx';
import Voice_recorderIcon from './voice_recorder.jsx';
import WalletIcon from './wallet.jsx';
import WarningIcon from './warning.jsx';
import WebsiteIcon from './website.jsx';
import World_gridIcon from './world_grid.jsx';

const icons = {
  abc_cube: Abc_cubeIcon,
  alarm_clock: Alarm_clockIcon,
  approved: ApprovedIcon,
  archive: ArchiveIcon,
  backpack: BackpackIcon,
  bank: BankIcon,
  bell_ring: Bell_ringIcon,
  bell_silent: Bell_silentIcon,
  book: BookIcon,
  bookmark: BookmarkIcon,
  box: BoxIcon,
  brush: BrushIcon,
  buoy: BuoyIcon,
  calculator: CalculatorIcon,
  camera: CameraIcon,
  cardiogram: CardiogramIcon,
  Chat: ChatIcon,
  cinemas: CinemasIcon,
  clever: CleverIcon,
  compass: CompassIcon,
  contrast: ContrastIcon,
  copy: CopyIcon,
  credit_card: Credit_cardIcon,
  crossed_eye: Crossed_eyeIcon,
  crosshair: CrosshairIcon,
  crown: CrownIcon,
  dislike: DislikeIcon,
  dumbbell: DumbbellIcon,
  DVD_player: Dvd_playerIcon,
  edit: EditIcon,
  envelope: EnvelopeIcon,
  essay: EssayIcon,
  eye: EyeIcon,
  faulty_file: Faulty_fileIcon,
  filter: FilterIcon,
  fingerprint: FingerprintIcon,
  fire: FireIcon,
  first_aid: First_aidIcon,
  Floppy: FloppyIcon,
  folder: FolderIcon,
  gamepad: GamepadIcon,
  gear: GearIcon,
  gift: GiftIcon,
  glasses: GlassesIcon,
  headphones: HeadphonesIcon,
  heart: HeartIcon,
  hourglass: HourglassIcon,
  house: HouseIcon,
  images: ImagesIcon,
  Incognito: IncognitoIcon,
  infinity: InfinityIcon,
  Laptop: LaptopIcon,
  layout: LayoutIcon,
  lightning: LightningIcon,
  like: LikeIcon,
  login: LoginIcon,
  magnet: MagnetIcon,
  magnify: MagnifyIcon,
  magnifying_glass: Magnifying_glassIcon,
  mailbox: MailboxIcon,
  mailbox_1: Mailbox_1Icon,
  map_pin: Map_pinIcon,
  Megaphone: MegaphoneIcon,
  minimize: MinimizeIcon,
  mute: MuteIcon,
  notepad: NotepadIcon,
  open_padlock: Open_padlockIcon,
  owl: OwlIcon,
  pacifier: PacifierIcon,
  padlock: PadlockIcon,
  palette: PaletteIcon,
  performance: PerformanceIcon,
  person_circle: Person_circleIcon,
  phone: PhoneIcon,
  pingpong_paddle: Pingpong_paddleIcon,
  power_button: Power_buttonIcon,
  print: PrintIcon,
  pushpin: PushpinIcon,
  question_mark: Question_markIcon,
  radio: RadioIcon,
  refresh: RefreshIcon,
  rejected: RejectedIcon,
  rocket: RocketIcon,
  ruler: RulerIcon,
  school: SchoolIcon,
  school_bus: School_busIcon,
  screenshot: ScreenshotIcon,
  send: SendIcon,
  share: ShareIcon,
  shield: ShieldIcon,
  shield_X: Shield_xIcon,
  shopping_bag: Shopping_bagIcon,
  shopping_basket: Shopping_basketIcon,
  shopping_cart: Shopping_cartIcon,
  shopping_cart_1: Shopping_cart_1Icon,
  smartphone: SmartphoneIcon,
  smiley: SmileyIcon,
  speaker: SpeakerIcon,
  spoon_fork: Spoon_forkIcon,
  star: StarIcon,
  store: StoreIcon,
  suitcase: SuitcaseIcon,
  swastika: SwastikaIcon,
  teacher_class: Teacher_classIcon,
  teacher_class_1: Teacher_class_1Icon,
  temple: TempleIcon,
  three_d_cube: Three_d_cubeIcon,
  transaction: TransactionIcon,
  trash_can: Trash_canIcon,
  video: VideoIcon,
  video_camera: Video_cameraIcon,
  voice_message: Voice_messageIcon,
  voice_recorder: Voice_recorderIcon,
  wallet: WalletIcon,
  warning: WarningIcon,
  website: WebsiteIcon,
  world_grid: World_gridIcon,
};

export default icons;
