import React from 'react';
const ArchiveIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="56" height="57" viewBox="0 0 56 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M55.7726 36.7798L50.4549 10.2057C49.3703 4.77541 44.5598 0.831726 39.0146 0.831726H16.9915C11.4551 0.831726 6.64453 4.77541 5.55124 10.2057L0.233516 36.7798C0.0848289 37.5319 0.00610352 38.3013 0.00610352 39.0621V44.1251C0.00610352 50.0101 4.423 54.9856 10.2918 55.7114C16.1081 56.4285 22.0643 56.7957 27.9943 56.7957C33.9243 56.7957 39.9505 56.4285 45.8368 55.6939C51.6181 54.9769 55.9825 50.0014 55.9825 44.1251V39.0621C55.9825 38.3013 55.9038 37.5319 55.7551 36.7798H55.7726ZM10.1344 11.1239C10.7816 7.86222 13.6679 5.50119 17.0002 5.50119H39.0234C42.347 5.50119 45.2333 7.86222 45.8892 11.1239L49.8164 30.7725C49.0905 30.4839 48.347 30.2566 47.551 30.1429L47.175 30.0904C43.143 29.5395 39.1634 31.6381 37.3267 35.302L37.2916 35.372C36.4345 37.0772 34.7115 38.144 32.8048 38.144H23.2276C21.3122 38.144 19.5979 37.0772 18.7407 35.372C16.8865 31.6556 12.8632 29.5307 8.75242 30.1079L8.42005 30.1516C7.64163 30.2565 6.91566 30.4839 6.20721 30.7637L10.1344 11.1326V11.1239ZM51.3295 44.1339C51.3295 47.6579 48.7231 50.6397 45.2683 51.0682C33.8981 52.4848 22.1431 52.4847 10.8691 51.0856C7.33555 50.6484 4.6766 47.6579 4.6766 44.1251V39.7617C4.6766 37.2521 6.54841 35.1009 9.04111 34.7686C9.04111 34.7686 9.8282 34.6725 10.0556 34.6725C11.9361 34.6725 13.6941 35.7305 14.5512 37.4532C16.2043 40.7498 19.5192 42.8047 23.2101 42.8047H32.7873C36.4782 42.8047 39.7931 40.7586 41.4374 37.4707L41.4724 37.4007C41.4724 37.4007 41.4724 37.4007 41.4724 37.392C42.417 35.5119 44.4637 34.4538 46.519 34.7162L46.8951 34.7686C49.414 35.1009 51.312 37.2695 51.312 39.8054V44.1339H51.3295Z" fill={secondaryColor}/>
<path d="M32.6735 45.1395H23.3413C22.0556 45.1395 21.006 46.18 21.006 47.4742C21.006 48.7684 22.0468 49.809 23.3413 49.809H32.6735C33.9592 49.809 35.0088 48.7684 35.0088 47.4742C35.0088 46.18 33.968 45.1395 32.6735 45.1395Z" fill={primaryColor}/>
</svg>

);

export default ArchiveIcon;
