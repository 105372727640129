export enum ParkingPlaceType {
  Normal = 1,
  Disabled = 2,
  Bicycle = 3,
  Family = 4,
  Bus = 5,
  Extraordinary = 6,
  Emergency = 7,
  Taxi = 8,
}
