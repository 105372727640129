import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import EmailTemplateForm from './EmailTemplateForm';
import { useNavigate, useParams } from 'react-router-dom';
import useCheckPermission from '../../hooks/useCheckPermission';
import emailtemplateService from '../../services/emails/emailtemplateService';

const EmailTemplateEdit = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const params = useParams();
  const [errors, setErrors] = useState<string[]>([]);
  const [entity, setEntity] = useState<any>({
    name: '',
    description: '',
    subject: '',
    body: '',
    type: '',
    cc: '',
    replyTo: '',
  });

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    emailtemplateService
      .get(params.id)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setEntity({
            ...response.result,
          });
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
      });
  }, []);

  const onSubmit = (entity: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    emailtemplateService
      .update({ ...entity })
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });

          navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
      });
  };

  return (
    <EmailTemplateForm
      entity={entity}
      readonly={!checkPermission(['EmailTemplateEdit'])}
      onSubmit={onSubmit}
      handleCancel={() => navigate(-1)}
      errors={errors}
    />
  );
};

export default EmailTemplateEdit;
