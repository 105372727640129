import axios from 'axios';
import { postData } from './axiosUtils';

const rssSettingsService = {
  list: () => {
    return axios.get(`/api/rssSettings/list`).then((response) => {
      return response.data;
    });
  },
  get: (id: any) => {
    return axios.get(`/api/rssSettings/details/${id}`).then((response) => {
      return response.data;
    });
  },
  create: (entity: any) => {
    return postData({
      url: `/api/rssSettings/create`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  update: (entity: any) => {
    return postData({
      url: `/api/rssSettings/update`,
      data: entity,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  delete: (id: any) => {
    return postData({
      url: `/api/rssSettings/delete/${id}`,
      method: 'DELETE',
    }).then((response) => {
      return response;
    });
  },
};

export default rssSettingsService;
