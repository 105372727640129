import React from 'react';
const PadlockIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="48" height="57" viewBox="0 0 48 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M37.2786 24.6339C28.5672 23.4621 19.6197 23.4621 10.8997 24.6513C5.15333 25.4383 0.823944 30.4052 0.823944 36.2114V43.9414C0.823944 49.7477 5.20581 54.7233 11.0134 55.5103C15.3515 56.0961 19.7684 56.3935 24.1503 56.3935C28.5322 56.3935 33.0191 56.0874 37.3923 55.4928C43.1386 54.7058 47.468 49.739 47.468 43.9327V36.2027C47.468 30.3964 43.0861 25.4209 37.2786 24.6339ZM42.7975 43.9414C42.7975 47.4217 40.1998 50.4123 36.7538 50.8758C28.436 52.0125 19.9259 52.0125 11.6256 50.8932C8.11835 50.421 5.47694 47.4304 5.47694 43.9414V36.2114C5.47694 32.7312 8.07462 29.7406 11.5207 29.2771C15.6927 28.7088 19.9346 28.4202 24.1328 28.4202C28.3311 28.4202 32.5118 28.7 36.6401 29.2596C40.1473 29.7318 42.7887 32.7224 42.7887 36.2114V43.9414H42.7975Z" fill={secondaryColor}/>
<path d="M24.1416 30.7462C20.2845 30.7462 17.1445 33.8854 17.1445 37.7417C17.1445 40.776 19.1037 43.3381 21.8063 44.3087V47.0719C21.8063 48.3574 22.8471 49.4067 24.1416 49.4067C25.4361 49.4067 26.4768 48.3661 26.4768 47.0719V44.3087C29.1882 43.3469 31.1386 40.776 31.1386 37.7417C31.1386 33.8854 27.9987 30.7462 24.1416 30.7462ZM24.1416 40.0764C22.8559 40.0764 21.8063 39.0271 21.8063 37.7417C21.8063 36.4563 22.8559 35.407 24.1416 35.407C25.4273 35.407 26.4768 36.4563 26.4768 37.7417C26.4768 39.0271 25.4273 40.0764 24.1416 40.0764Z" fill={primaryColor}/>
<path d="M10.1475 21.416C11.4332 21.416 12.4827 20.3754 12.4827 19.0812V12.0857C12.4827 8.22945 15.6227 5.09022 19.4798 5.09022H28.8121C32.6693 5.09022 35.8092 8.22945 35.8092 12.0857V19.0812C35.8092 20.3666 36.85 21.416 38.1444 21.416C39.4389 21.416 40.4797 20.3754 40.4797 19.0812V12.0857C40.4797 5.6586 35.2494 0.429504 28.8208 0.429504H19.4885C13.06 0.429504 7.8297 5.6586 7.8297 12.0857V19.0812C7.8297 20.3666 8.87055 21.416 10.165 21.416H10.1475Z" fill={secondaryColor}/>
</svg>

);

export default PadlockIcon;
