import React from 'react';
const Shopping_basketIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="56" height="57" viewBox="0 0 56 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M49.0128 10.1558H45.7942L41.7709 2.11968C41.1936 0.96542 39.7942 0.501966 38.6397 1.07909C37.4852 1.65622 37.0216 3.05532 37.5989 4.20958L40.5726 10.1645H15.4707L18.4445 4.20958C19.0217 3.05532 18.5494 1.65622 17.4036 1.07909C16.2491 0.501966 14.8497 0.974164 14.2724 2.11968L10.2492 10.1558H7.0305C3.17337 10.1558 0.0334473 13.295 0.0334473 17.1513C0.0334473 19.0575 0.78565 20.8414 2.15882 22.1705C2.90226 22.8963 3.47076 23.9107 3.78562 25.1086L9.92555 48.1413C11.29 53.2393 15.9168 56.7983 21.1908 56.7983H34.8438C40.1178 56.7983 44.7534 53.2393 46.109 48.1413L52.2489 25.1086C52.5726 23.9107 53.1323 22.8963 53.8758 22.1705C55.2489 20.8414 56.0011 19.0575 56.0011 17.1513C56.0011 13.295 52.8612 10.1558 49.0041 10.1558H49.0128ZM41.6134 46.9346C40.8 49.9951 38.0187 52.1288 34.8525 52.1288H21.1995C18.0334 52.1288 15.252 49.9951 14.4386 46.9346L8.35995 24.1468H47.6921L41.6134 46.9346ZM50.6396 18.8302C50.4297 19.0313 50.246 19.2674 50.0536 19.486H5.98971C5.79729 19.2586 5.61361 19.0313 5.4037 18.8214C4.94889 18.3755 4.69525 17.7896 4.69525 17.1513C4.69525 15.8658 5.74479 14.8165 7.0305 14.8165H49.0128C50.2985 14.8165 51.3481 15.8658 51.3481 17.1513C51.3481 17.7896 51.0944 18.3842 50.6396 18.8214V18.8302Z" fill={secondaryColor}/>
<path d="M18.6981 28.8163C17.4124 28.8163 16.3628 29.8568 16.3628 31.151V40.4812C16.3628 41.7667 17.4036 42.816 18.6981 42.816C19.9926 42.816 21.0334 41.7754 21.0334 40.4812V31.151C21.0334 29.8656 19.9926 28.8163 18.6981 28.8163Z" fill={primaryColor}/>
<path d="M28.0217 28.8163C26.7359 28.8163 25.6864 29.8568 25.6864 31.151V40.4812C25.6864 41.7667 26.7272 42.816 28.0217 42.816C29.3161 42.816 30.3569 41.7754 30.3569 40.4812V31.151C30.3569 29.8656 29.3161 28.8163 28.0217 28.8163Z" fill={primaryColor}/>
<path d="M37.354 28.8163C36.0683 28.8163 35.0187 29.8568 35.0187 31.151V40.4812C35.0187 41.7667 36.0595 42.816 37.354 42.816C38.6484 42.816 39.6893 41.7754 39.6893 40.4812V31.151C39.6893 29.8656 38.6484 28.8163 37.354 28.8163Z" fill={primaryColor}/>
</svg>

);

export default Shopping_basketIcon;
