import React from 'react';
const NotepadIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="47" height="57" viewBox="0 0 47 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M45.6069 10.9777C44.8897 5.19771 39.9131 0.834259 34.0356 0.834259H15.0736C10.3594 0.834259 6.26608 3.69367 4.4381 7.82976H2.40025C1.11454 7.82976 0.0649414 8.87035 0.0649414 10.1645C0.0649414 11.4587 1.10579 12.4993 2.40025 12.4993H3.33605C3.07366 14.8253 2.85503 17.1513 2.70635 19.4948H2.40025C1.11454 19.4948 0.0649414 20.5353 0.0649414 21.8295C0.0649414 23.1237 1.10579 24.1642 2.40025 24.1642H2.47893C2.42645 25.7207 2.40025 27.2773 2.40025 28.825C2.40025 30.3728 2.42645 31.9293 2.47893 33.4858H2.40025C1.11454 33.4858 0.0649414 34.5263 0.0649414 35.8205C0.0649414 37.1147 1.10579 38.1552 2.40025 38.1552H2.70635C2.85503 40.4987 3.07366 42.8335 3.33605 45.1507H2.40025C1.11454 45.1507 0.0649414 46.1913 0.0649414 47.4855C0.0649414 48.7797 1.10579 49.8202 2.40025 49.8202H4.42059C6.23107 53.9563 10.3419 56.8157 15.0736 56.8157H34.0356C39.9219 56.8157 44.8985 52.3998 45.6245 46.5324C46.3504 40.6736 46.709 34.7187 46.709 28.8337C46.709 22.9488 46.3416 16.8889 45.6069 10.9952V10.9777ZM40.989 45.9465C40.5517 49.4792 37.5603 52.1375 34.0268 52.1375H15.0649C11.5401 52.1375 8.55759 49.5317 8.12902 46.0776C7.42057 40.3763 7.06199 34.5701 7.06199 28.8163C7.06199 23.0625 7.42055 17.3524 8.11151 11.686C8.54882 8.1533 11.5401 5.49501 15.0736 5.49501H34.0356C37.5604 5.49501 40.5429 8.10084 40.9715 11.5549C41.6799 17.2562 42.0385 23.0625 42.0385 28.8163C42.0385 34.5701 41.6799 40.2801 40.989 45.9465Z" fill={secondaryColor}/>
<path d="M16.3855 7.82974C15.0998 7.82974 14.0502 8.87033 14.0502 10.1645V47.4767C14.0502 48.7622 15.0911 49.8115 16.3855 49.8115C17.68 49.8115 18.7207 48.7709 18.7207 47.4767V10.1645C18.7207 8.87908 17.68 7.82974 16.3855 7.82974Z" fill={primaryColor}/>
</svg>

);

export default NotepadIcon;
