import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useCheckPermission from '../../hooks/useCheckPermission';
import pageService from '../../services/pageService';
import PageForm from './PageForm';
import publisherService from '../../services/publisherService';

const PageCreate = (props: any) => {
  const { onSave = null, navigateBack = true, handleCancel = null } = props;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const [publishers, setPublishers] = useState<any[]>([]);
  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    publisherService
      .list()
      .then((response: any) => {
        if (!response.hasError) {
          setPublishers(response.records);
        } else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  const onSubmit = (entity: any, setSubmitting: any, isDraft: boolean) => {
    dispatch({ type: 'SHOW_SAVE' });
    console.log(entity);
    pageService
      .create({ ...entity, isDraft })
      .then((response) => {
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Success!', {
            variant: 'success',
          });

          if (onSave) onSave(response.result);
          if (navigateBack) navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        if (setSubmitting) setSubmitting(false);
      });
  };

  return (
    <PageForm
      entity={{
        contentUrl: '',
        name: '',
        publisherId: 0,
        isDraft: false,
        imageId: '',
      }}
      readonly={!checkPermission(['CreatePage'])}
      onSubmit={onSubmit}
      errors={errors}
      publishers={publishers}
      handleCancel={() =>
        handleCancel != null ? handleCancel() : navigate(-1)
      }
    />
  );
};

export default PageCreate;
