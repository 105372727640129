import { Box, Button, Typography, Modal, Grid } from '@mui/material';
import axios from 'axios';
import { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import ImageGallery from 'react-image-gallery';
import imageService from '../services/imageService';
import 'react-image-gallery/styles/css/image-gallery.css';
import CropImageModal from '../components/CropImageModal';
import CropImagePreview from '../components/CropImagePreview';
import ImagePreview from '../components/ImagePreview';
import ImageBrowserGallery from '../pages/gallery/ImageBrowserGalleryDialog';
import { getRadianAngle, rotateSize } from '../components/CropImageModal';
import imageCompression from 'browser-image-compression';

function ImageSelect({
  uploadImage,
  onSelect,
  publisherId,
  image,
  hideBrowser = false,
  imageUploaded,
  setImageUploaded,
  setOriginalCrop,
  setCrop1_1,
  setCrop4_3,
  setCrop16_9,
  originalCrop,
  crop1_1,
  crop4_3,
  crop16_9,
  openCrop1_1,
  setOpenCrop1_1,
  openCrop4_3,
  setOpenCrop4_3,
  openCrop16_9,
  setOpenCrop16_9,
  imageToUpload,
  setImageToUpload,
  uploading,
  open,
  setOpen,
}: any) {
  const accept = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/gif': ['.gif'],
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: useCallback((acceptedFiles: any) => {
      setOriginalCrop({
        crop: { x: 0, y: 0 },
        area: { x: 0, y: 0, width: 0, height: 0 },
        zoom: 1,
        aspect: 1,
        shape: 'rect',
        rotation: 0,
        image: { data: '', url: '' },
      });
      setCrop1_1({
        crop: { x: 0, y: 0 },
        area: {},
        zoom: 1,
        aspect: 1,
        shape: 'rect',
        rotation: 0,
        image: { data: '', url: '' },
      });
      setCrop4_3({
        crop: { x: 0, y: 0 },
        area: {},
        zoom: 1,
        shape: 'rect',
        rotation: 0,
        aspect: 4 / 3,
        image: { data: '', url: '' },
      });
      setCrop16_9({
        crop: { x: 0, y: 0 },
        area: {},
        zoom: 1,
        shape: 'rect',
        rotation: 0,
        aspect: 16 / 9,
        image: { data: '', url: '' },
      });

      const compressedImage = imageCompression(acceptedFiles[0], {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 768,
        useWebWorker: true,
      });
      compressedImage.then((compressedImage: any) => {
        setImageToUpload(compressedImage);
        setOpenCrop1_1(true);

        getImageData(
          originalCrop.area,
          originalCrop.shape,
          originalCrop.rotation,
          compressedImage,
          true
        ).then((data) => {
          setOriginalCrop((actual: any) => {
            return {
              ...actual,
              image: data,
            };
          });
        });
      });
    }, []),
    accept: accept,
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (image: any) => {
    onSelect(image);
    handleClose();
  };

  const getImageData = async (
    crop: any,
    shape: string,
    rotation: number,
    imageToUpload: any,
    isOriginal = false
  ) => {
    console.log('getImageData', crop, shape, rotation);
    // Create an offscreen canvas to render the cropped and rotated image
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return null;
    }
    const image = new Image();
    image.src = URL.createObjectURL(imageToUpload!);
    await new Promise((resolve) => (image.onload = resolve));

    const rotRad = getRadianAngle(rotation);

    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
      image.width,
      image.height,
      rotation
    );

    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.translate(-image.width / 2, -image.height / 2);

    // Draw the cropped and rotated portion of the image onto the canvas
    ctx.drawImage(image, 0, 0);

    if (!isOriginal) {
      const imageData = ctx.getImageData(
        crop.x,
        crop.y,
        crop.width,
        crop.height
      );

      canvas.width = crop.width;
      canvas.height = crop.height;

      ctx.putImageData(imageData, 0, 0);
    }

    if (shape === 'round') {
      // Draw a circle and make the clipped-out area invisible
      ctx!.globalCompositeOperation = 'destination-in';
      ctx!.beginPath();
      ctx!.arc(crop.width / 2, crop.height / 2, crop.width / 2, 0, 2 * Math.PI);
      ctx!.fill();
      ctx!.clip();
      ctx!.closePath();
      ctx!.globalCompositeOperation = 'source-over'; // Reset the global composite operation
    }

    // Convert the canvas data to a Uint8Array
    const croppedImageData = await new Promise((resolve) =>
      canvas.toBlob(resolve, 'image/png')
    );
    const data = await (croppedImageData as Blob).arrayBuffer();
    return { data, url: canvas.toDataURL('image/png') };
  };

  return (
    <Box>
      {!hideBrowser && (
        <Box p={2}>
          <Button
            variant="contained"
            disabled={!publisherId}
            onClick={handleOpen}
          >
            Galéria megnyitása
          </Button>
        </Box>
      )}
      {publisherId > 0 && (
        <div {...getRootProps()} style={{ cursor: 'pointer' }}>
          <input {...getInputProps()} />
          <Box
            border={2}
            borderColor="primary.main"
            borderRadius="5px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="150px"
          >
            <Typography variant="body1" color="primary">
              Húzd ide a képet, vagy kattints a kiválasztáshoz.
            </Typography>
          </Box>
        </div>
      )}
      <ImageBrowserGallery
        publisherId={publisherId}
        handleClose={() => setOpen(false)}
        handleSelect={handleSelect}
        open={open}
      />
      {imageToUpload ? (
        <CropImagePreview
          crop1_1={crop1_1}
          crop4_3={crop4_3}
          crop16_9={crop16_9}
          uploadImage={uploadImage}
          imageUploaded={imageUploaded}
          setOpenCrop1_1={setOpenCrop1_1}
          setOpenCrop4_3={setOpenCrop4_3}
          setOpenCrop16_9={setOpenCrop16_9}
          uploading={uploading}
        />
      ) : image ? (
        <ImagePreview image={image} />
      ) : (
        <Typography>Kérjük töltsön fel egy képet!</Typography>
      )}

      <CropImageModal
        open={openCrop1_1}
        crop={crop1_1}
        onClose={() => setOpenCrop1_1(false)}
        setCrop={setCrop1_1}
        getImageData={getImageData}
        imageToUpload={imageToUpload}
      />
      <CropImageModal
        open={openCrop4_3}
        crop={crop4_3}
        onClose={() => setOpenCrop4_3(false)}
        setCrop={setCrop4_3}
        imageToUpload={imageToUpload}
        getImageData={getImageData}
      />
      <CropImageModal
        open={openCrop16_9}
        crop={crop16_9}
        onClose={() => setOpenCrop16_9(false)}
        setCrop={setCrop16_9}
        imageToUpload={imageToUpload}
        getImageData={getImageData}
      />
    </Box>
  );
}

function useImageSelect() {
  const [uploadedFile, setUploadedFile] = useState<any>({});
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<string>('');

  const [imageUploaded, setImageUploaded] = useState(false);
  const [open, setOpen] = useState(false);
  const [imageToUpload, setImageToUpload] = useState<File>();
  const [imageId, setImageId] = useState<number | null>(null);
  const [originalCrop, setOriginalCrop] = useState({
    crop: { x: 0, y: 0 },
    area: {},
    zoom: 1,
    rotation: 0,
    aspect: 1,
    shape: 'rect',
    image: { data: '', url: '' },
  });
  const [crop1_1, setCrop1_1] = useState({
    crop: { x: 0, y: 0 },
    area: {},
    zoom: 1,
    rotation: 0,
    aspect: 1,
    shape: 'rect',
    image: { data: '', url: '' },
  });
  const [crop4_3, setCrop4_3] = useState({
    crop: { x: 0, y: 0 },
    area: {},
    zoom: 1,
    shape: 'rect',
    rotation: 0,
    aspect: 4 / 3,
    image: { data: '', url: '' },
  });
  const [crop16_9, setCrop16_9] = useState({
    crop: { x: 0, y: 0 },
    area: {},
    zoom: 1,
    aspect: 16 / 9,
    shape: 'rect',
    rotation: 0,
    image: { data: '', url: '' },
  });

  const [openCrop1_1, setOpenCrop1_1] = useState(false);
  const [openCrop4_3, setOpenCrop4_3] = useState(false);
  const [openCrop16_9, setOpenCrop16_9] = useState(false);

  const onUpload = useCallback(
    async (
      image: any,
      publisherId: any,
      alt: string,
      fileName: string,
      fileType: string,
      currentFolderId: string = '-1',
      onUploaded: (image: any) => void
    ) => {
      try {
        // Prepare the UpdateImageCommand JSON data
        console.log(image);
        const createImageCommand = {
          PublisherId: publisherId, // Replace with your PublisherId if needed
          FileName: fileName, // Include the file extension in the FileName
          Alt: alt, // Replace with your Alt Text
          ContentType: fileType,
          OriginalData: image.originalImage
            ? Array.from(new Uint8Array(image.originalImage))
            : undefined,
          Data1_1: image.image1_1
            ? Array.from(new Uint8Array(image.image1_1))
            : undefined,
          Data4_3: image.image4_3
            ? Array.from(new Uint8Array(image.image4_3))
            : undefined,
          Data16_9: image.image16_9
            ? Array.from(new Uint8Array(image.image16_9))
            : undefined,
          ParentId: parseInt(currentFolderId),
          Id: imageId,
        };

        setUploading(true);
        setError('');
        debugger;
        // Make the API call to upload the image
        let response;
        if (createImageCommand.OriginalData) {
          response = await axios.post('/api/image/create', createImageCommand, {
            headers: {
              'Content-Type': 'application/json',
            },
          });
        } else {
          if (imageId) {
            response = await axios.put(
              '/api/image/update',
              createImageCommand,
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              }
            );
          }
        }

        if (!response) return;
        setUploadedFile(response.data.result);
        if (onUploaded) onUploaded(response.data.result);
        setUploading(false);
      } catch (err) {
        setError('Error uploading file');
        setUploading(false);
      }
    },
    [imageId]
  );

  const uploadImage = (
    onUploaded: (image: any) => void,
    alt: any,
    publisherId: any,
    currentFolderId: any
  ) => {
    const data = {
      originalImage: originalCrop.image.data,
      image1_1: crop1_1.image.data,
      image4_3: crop4_3.image.data,
      image16_9: crop16_9.image.data,
    };
    if (!imageToUpload || !data || imageUploaded) return Promise.resolve();

    setUploading(true);
    return onUpload(
      data,
      publisherId,
      alt,
      imageToUpload.name,
      imageToUpload.type,
      currentFolderId,
      onUploaded
    )
      .then(() => setImageUploaded(true))
      .finally(() => setUploading(false));
  };

  const ImageSelectComponent = (props: any) => {
    const {
      onSelect,
      image,
      onUploaded,
      alt,
      hideBrowser = false,
      publisherId,
      currentFolderId,
    } = props;

    async function fetchImageBlob(
      url: string,
      fileName: string,
      contentType: string = 'image/png'
    ) {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const editedImageFile = new File([blob], fileName, {
          type: contentType,
        });
        return editedImageFile;
      } catch (error) {
        console.error('Error fetching image:', error);
      }
    }

    useEffect(() => {
      if (image && image.id > 0) {
        if (!imageId) {
          setImageId(image.id);
        }
        fetchImageBlob(
          image.originalUrl,
          image.fileName,
          image.contentType
        ).then((file) => {
          if (file && !imageToUpload) {
            setImageToUpload(file);
          }
        });
        if (image.url1_1 && crop1_1.image.url === '') {
          setCrop1_1({
            ...crop1_1,
            image: { data: '', url: image.url1_1 },
          });
        }
        if (image.url4_3 && crop4_3.image.url === '') {
          setCrop4_3({
            ...crop4_3,
            image: { data: '', url: image.url4_3 },
          });
        }
        if (image.url16_9 && crop16_9.image.url === '') {
          setCrop16_9({
            ...crop16_9,
            image: { data: '', url: image.url16_9 },
          });
        }
      }
    }, [image]);

    return (
      <ImageSelect
        uploadImage={() =>
          uploadImage(onUploaded, alt, publisherId, currentFolderId)
        }
        onSelect={onSelect}
        publisherId={publisherId}
        image={image}
        hideBrowser={hideBrowser}
        imageUploaded={imageUploaded}
        setImageUploaded={setImageUploaded}
        setOriginalCrop={setOriginalCrop}
        originalCrop={originalCrop}
        setCrop1_1={setCrop1_1}
        setCrop4_3={setCrop4_3}
        setCrop16_9={setCrop16_9}
        crop1_1={crop1_1}
        crop4_3={crop4_3}
        crop16_9={crop16_9}
        openCrop1_1={openCrop1_1}
        setOpenCrop1_1={setOpenCrop1_1}
        openCrop4_3={openCrop4_3}
        setOpenCrop4_3={setOpenCrop4_3}
        openCrop16_9={openCrop16_9}
        setOpenCrop16_9={setOpenCrop16_9}
        imageToUpload={imageToUpload}
        setImageToUpload={setImageToUpload}
        uploading={uploading}
        open={open}
        setOpen={setOpen}
      />
    );
  };

  return { ImageSelectComponent, uploadedFile, uploading, error, uploadImage };
}

export default useImageSelect;
