import React from 'react';
const WebsiteIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="56" height="57" viewBox="0 0 56 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M28.0118 0.627502C12.5745 0.627502 0.0235596 13.1844 0.0235596 28.6095C0.0235596 44.0346 12.5745 56.5915 28.0118 56.5915C43.449 56.5915 56 44.0346 56 28.6095C56 13.1844 43.449 0.627502 28.0118 0.627502ZM28.0118 51.9307C15.1547 51.9307 4.68531 41.4725 4.68531 28.6095C4.68531 15.7465 15.1459 5.28822 28.0118 5.28822C40.8776 5.28822 51.3382 15.7465 51.3382 28.6095C51.3382 41.4725 40.8776 51.9307 28.0118 51.9307Z" fill={secondaryColor}/>
<path d="M28.0118 9.95774C17.7261 9.95774 9.35596 18.3261 9.35596 28.6095C9.35596 38.8929 17.7261 47.2612 28.0118 47.2612C38.2975 47.2612 46.6676 38.8929 46.6676 28.6095C46.6676 18.3261 38.2975 9.95774 28.0118 9.95774ZM23.8223 33.279C23.5162 31.7575 23.3413 30.2097 23.3413 28.6182C23.3413 27.0267 23.5162 25.4703 23.8223 23.9575H32.1926C32.4987 25.479 32.6735 27.0267 32.6735 28.6182C32.6735 30.2097 32.4987 31.7662 32.1926 33.279H23.8223ZM30.7319 37.9397C30.0147 39.5662 29.1051 41.1139 28.0118 42.5742C26.9185 41.1227 26.0002 39.5749 25.2917 37.9397H30.7406H30.7319ZM14.0177 28.6095C14.0177 26.9743 14.3151 25.4091 14.8312 23.9487H19.0643C18.8107 25.4703 18.6794 27.0268 18.6794 28.6095C18.6794 30.1922 18.8107 31.7487 19.0643 33.2702H14.8312C14.3151 31.8099 14.0177 30.2447 14.0177 28.6095ZM25.283 19.288C26.0002 17.6615 26.9098 16.1138 28.003 14.6535C29.0963 16.105 30.0147 17.6528 30.7231 19.288H25.2742H25.283ZM36.9505 23.9487H41.1838C41.6998 25.4091 41.9971 26.9743 41.9971 28.6095C41.9971 30.2447 41.6998 31.8099 41.1838 33.2702H36.9505C37.2042 31.7487 37.3354 30.1922 37.3354 28.6095C37.3354 27.0268 37.2042 25.4703 36.9505 23.9487ZM38.4112 19.288H35.761C35.3849 18.2299 34.9301 17.1981 34.4316 16.1925C35.9447 16.9795 37.2917 18.0288 38.4199 19.288H38.4112ZM21.592 16.1925C21.0935 17.1981 20.6299 18.2299 20.2626 19.288H17.6124C18.7407 18.0288 20.0877 16.9795 21.6008 16.1925H21.592ZM17.6037 37.9397H20.2538C20.6299 38.9978 21.0847 40.0296 21.5833 41.0352C20.0702 40.2482 18.7232 39.1989 17.5949 37.9397H17.6037ZM34.4228 41.0352C34.9214 40.0296 35.3849 38.9978 35.7523 37.9397H38.4024C37.2742 39.1989 35.9272 40.2482 34.4141 41.0352H34.4228Z" fill={primaryColor}/>
</svg>

);

export default WebsiteIcon;
