import React from 'react';
const LikeIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="56" viewBox="0 0 57 56" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M56.8801 30.3465C56.8801 25.2048 52.6905 21.0163 47.5477 21.0163H39.4399C38.7052 21.0163 38.3204 20.5441 38.1892 20.3342C38.058 20.1331 37.7781 19.5822 38.0755 18.9176L41.4778 11.2663C42.5798 8.78288 42.3524 5.94096 40.8743 3.66742C39.3962 1.38514 36.886 0.0297546 34.1746 0.0297546C31.4633 0.0297546 29.1193 1.30643 27.6236 3.44006L15.3176 21.0163H12.5712C6.14267 21.0163 0.912415 26.2454 0.912415 32.6725V44.3287C0.912415 50.7558 6.14267 55.985 12.5712 55.985H40.5594C45.3349 55.985 49.2883 52.3823 49.8305 47.7478C52.7168 46.1126 54.5535 43.0433 54.5535 39.668C54.5535 38.7411 54.4136 37.8317 54.1337 36.9398C55.883 35.2084 56.8888 32.8561 56.8888 30.3377L56.8801 30.3465ZM5.57416 44.3375V32.6812C5.57416 28.8249 8.71409 25.6857 12.5712 25.6857H12.9736C12.7287 26.6389 12.5712 27.6183 12.5712 28.6239V43.4193C12.5712 46.34 13.5858 49.1119 15.3525 51.333H12.5712C8.71409 51.333 5.57416 48.1938 5.57416 44.3375ZM50.0404 34.2727C48.9909 34.9373 48.6498 36.3101 49.262 37.3945C49.6818 38.129 49.8918 38.8985 49.8918 39.6767C49.8918 41.6442 48.6323 43.4018 46.7518 44.0577C45.7635 44.3987 45.125 45.3606 45.1949 46.4099C45.1949 46.4711 45.2212 46.6198 45.23 46.6722C45.23 49.2431 43.1396 51.333 40.5682 51.333H24.7374C23.3554 51.333 22.0085 50.9308 20.854 50.1613C18.5974 48.6572 17.2505 46.1388 17.2505 43.4193V28.6239C17.2505 27.181 17.6878 25.7907 18.5187 24.6102L31.4633 6.11583C32.0843 5.22391 33.1076 4.69049 34.1921 4.69049C35.3379 4.69049 36.3612 5.2414 36.9822 6.20328C37.6119 7.16516 37.6994 8.31942 37.2358 9.36874L33.8335 17.0201C32.9851 18.9351 33.1601 21.1212 34.2971 22.8788C35.4341 24.6364 37.367 25.677 39.4574 25.677H47.5652C50.1366 25.677 52.227 27.7669 52.227 30.3377C52.227 31.9292 51.4136 33.3983 50.0492 34.264L50.0404 34.2727Z" fill={secondaryColor}/>
<path d="M42.886 32.6812H33.5536C32.2679 32.6812 31.2184 33.7218 31.2184 35.016C31.2184 36.3102 32.2592 37.3507 33.5536 37.3507H42.886C44.1717 37.3507 45.2212 36.3102 45.2212 35.016C45.2212 33.7218 44.1804 32.6812 42.886 32.6812Z" fill={primaryColor}/>
<path d="M38.2242 42.0027H28.8919C27.6062 42.0027 26.5566 43.0433 26.5566 44.3375C26.5566 45.6316 27.5974 46.6722 28.8919 46.6722H38.2242C39.5099 46.6722 40.5594 45.6316 40.5594 44.3375C40.5594 43.0433 39.5186 42.0027 38.2242 42.0027Z" fill={primaryColor}/>
</svg>

);

export default LikeIcon;
