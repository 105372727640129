import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useCheckPermission from '../../hooks/useCheckPermission';
import contentService from '../../services/contentService';
import ContentForm from './ContentForm';
import publisherService from '../../services/publisherService';
import tagService from '../../services/tagService';
import dayjs from 'dayjs';
import moment from 'moment';
import locationService from '../../services/locationService';
import { getHtmlContent } from '../../utils/htmContentLoader';

const ContentEdit = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const params = useParams();
  const [errors, setErrors] = useState<string[]>([]);
  const [publishers, setPublishers] = useState<any[]>([]);
  const [versions, setVersions] = useState<any[]>([]);
  const [tags, setTags] = useState<any[]>([]);
  const [locations, setLocations] = useState<any[]>([]);

  const [now, setNow] = useState(moment());

  const [entity, setEntity] = useState<any>({
    id: 0,
    htmlContent: '',
    publicationDateUtc: now,
    shortTitle: '',
    title: '',
    description: '',
    shortDescription: '',
    publisherId: '',
    imageId: '',
    image: { fileName: '', id: 0, url: '' },
    sourceUrl: '',
    isDraft: false,
    sendPushNotification: false,
    eventStartUtc: now,
    eventEndUtc: now,
    eventName: '',
    eventDescription: '',
    eventLocationId: 0,
    hasEvent: false,
    tagIds: [],
    primaryTagId: '',
  });

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    locationService
      .list()
      .then((response: any) => {
        if (!response.hasError) setLocations(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    publisherService
      .list()
      .then((response: any) => {
        if (!response.hasError) setPublishers(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    tagService
      .list()
      .then((response: any) => {
        if (!response.hasError) setTags(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    contentService
      .get(params.id)
      .then((response: any) => {
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          getHtmlContent(response.result.htmlContent)
            .then((htmlText) => {
              setEntity({
                ...response.result,
                publicationDateUtc: moment(response.result.publicationDateUtc),
                eventStartUtc: moment(response.result.eventStartUtc),
                eventEndUtc: moment(response.result.eventEndUtc),
                htmlContent: htmlText, // Set the fetched HTML content
              });
            })
            .catch((error) => {
              console.error('Error fetching HTML content:', error);
            });
        }
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, [params.id]);

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    contentService
      .listVersions(params.id)
      .then((response: any) => {
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setVersions(response.records);
        }
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, [params.id]);

  const onSubmit = (
    entity: any,
    setSubmitting: any,
    isDraft: boolean = false
  ) => {
    dispatch({ type: 'SHOW_SAVE' });
    contentService
      .update({ ...entity, isDraft })
      .then((response) => {
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Success!', {
            variant: 'success',
          });

          navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        if (setSubmitting) setSubmitting(false);
      });
  };

  return (
    <ContentForm
      entity={entity}
      readonly={
        !checkPermission(['EditContent']) ||
        entity.isDeleted ||
        entity.isExternalCmsContent
      }
      onSubmit={onSubmit}
      publishers={publishers}
      versions={versions}
      locations={locations}
      tags={tags}
      onLocationAdded={(location: any) => {
        setLocations([...locations, location]);
      }}
      onTagAdded={(tag: any) => {
        setTags([...tags, tag]);
      }}
      handleCancel={() => navigate(-1)}
      errors={errors}
    />
  );
};

export default ContentEdit;
