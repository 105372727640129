import React from 'react';
const DislikeIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="56" viewBox="0 0 57 56" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M53.7455 19.0838C54.0253 18.2006 54.1653 17.2911 54.1653 16.3555C54.1653 12.9802 52.3198 9.90215 49.4335 8.2757C48.8913 3.64118 44.9466 0.0385132 40.1712 0.0385132H12.183C5.75442 0.0385132 0.524109 5.26764 0.524109 11.6948V23.351C0.524109 29.7781 5.75442 35.0073 12.183 35.0073H14.9293L27.2354 52.5834C28.731 54.7171 31.18 55.9938 33.7864 55.9938C36.3928 55.9938 39.0079 54.6383 40.4948 52.3561C41.9729 50.0738 42.2003 47.2319 41.0983 44.7573L37.6959 37.1059C37.3986 36.4414 37.6785 35.8904 37.8096 35.6893C37.9408 35.4882 38.3257 35.0073 39.0604 35.0073H47.1682C52.311 35.0073 56.5005 30.8187 56.5005 25.677C56.5005 23.1586 55.4947 20.8064 53.7455 19.075V19.0838ZM5.19466 23.351V11.6948C5.19466 7.83848 8.33459 4.69925 12.1917 4.69925H14.973C13.2063 6.92032 12.1917 9.69228 12.1917 12.6129V27.4084C12.1917 28.414 12.3492 29.3934 12.5941 30.3465H12.1917C8.33459 30.3465 5.19466 27.2073 5.19466 23.351ZM47.177 30.3465H39.0691C36.9787 30.3465 35.0458 31.3958 33.9088 33.1447C32.7718 34.9023 32.5969 37.0884 33.4452 38.9947L36.8476 46.646C37.3111 47.6953 37.2237 48.8496 36.5939 49.8115C35.3782 51.6828 32.3607 51.744 31.075 49.9077L18.1217 31.4133C17.2908 30.2328 16.8535 28.8425 16.8535 27.3996V12.6042C16.8535 9.89342 18.2004 7.36628 20.457 5.86224C21.6115 5.09274 22.9497 4.6905 24.3404 4.6905H40.1712C42.7426 4.6905 44.833 6.78042 44.833 9.26382C44.8242 9.33377 44.798 9.58737 44.798 9.65732C44.7455 10.6892 45.384 11.6335 46.3636 11.9746C48.244 12.6304 49.5035 14.388 49.5035 16.3555C49.5035 17.1337 49.2936 17.8945 48.8738 18.629C48.2615 19.7133 48.6026 21.0862 49.6522 21.7508C51.0166 22.6165 51.83 24.0855 51.83 25.677C51.83 28.2478 49.7396 30.3377 47.1682 30.3377L47.177 30.3465Z" fill={secondaryColor}/>
<path d="M42.5152 18.6902H33.1829C31.8972 18.6902 30.8476 19.7308 30.8476 21.025C30.8476 22.3192 31.8884 23.3597 33.1829 23.3597H42.5152C43.8009 23.3597 44.8505 22.3192 44.8505 21.025C44.8505 19.7308 43.8096 18.6902 42.5152 18.6902Z" fill={primaryColor}/>
<path d="M40.1799 11.6947C40.1799 10.4093 39.1391 9.35998 37.8447 9.35998H28.5123C27.2266 9.35998 26.1771 10.4006 26.1771 11.6947C26.1771 12.9889 27.2179 14.0295 28.5123 14.0295H37.8447C39.1304 14.0295 40.1799 12.9889 40.1799 11.6947Z" fill={primaryColor}/>
</svg>

);

export default DislikeIcon;
