import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  useMediaQuery,
  Autocomplete,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import useImageSelect from '../../hooks/useImageSelect';
import { useLocalStorage } from '../../hooks/useLocalStorage';

const OfficialForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    readonly = false,
    publishers,
    pages,
  } = props;

  const { ImageSelectComponent, uploadImage, uploading, error, uploadedFile } =
    useImageSelect();

  const handleUploaded = (image: any, setFieldValue: any) => {
    setFieldValue('imageId', image.id);
    setFieldValue('image', image);
  };

  const publisherLocalStroage = useLocalStorage('OfficialForm_publisherId');

  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );

  const [publisherId, setPublisherId] = useState<any>(
    publisherLocalStroage.value
  );

  useEffect(() => {
    if (!publisherId) {
      setPublisherId(publisherLocalStroage.value);
    }
  }, [publisherLocalStroage.value]);

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};
        return errors;
      }}
      onSubmit={(values, { setSubmitting, setFieldValue }) => {
        let newValues = { ...values };
        if (publisherId) {
          uploadImage(
            (image: any) =>
              (newValues = { ...values, image: image, imageId: image.id }),
            'kép',
            publisherId,
            '-1'
          )?.then(() => {
            onSubmit(newValues, setSubmitting);
          });
        }
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        setFieldValue,
        handleChange,
        errors: validationErrors,
      }) => (
        <Form>
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>Képviselő {values.id > 0 ? 'szerkesztés' : 'létrehozás'}</h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error: string) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  value={values.order}
                  onChange={handleChange}
                  label="Sorrend"
                  name="order"
                  type="number"
                  disabled={readonly}
                  fullWidth
                  error={!!touched.order && !!validationErrors.order}
                  helperText={
                    !!touched.order &&
                    !!validationErrors.order &&
                    (validationErrors.order as string)
                  }
                />
              </Grid>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12} md={'auto'}>
                  <TextField
                    value={values.title}
                    onChange={handleChange}
                    label="Titulus"
                    name="title"
                    disabled={readonly}
                    fullWidth={isSmallScreen}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={!!touched.title && !!validationErrors.title}
                    helperText={
                      !!touched.title &&
                      !!validationErrors.title &&
                      (validationErrors.title as string)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={'auto'}>
                  <TextField
                    value={values.lastName}
                    onChange={handleChange}
                    label="Vezetéknév"
                    name="lastName"
                    required
                    disabled={readonly}
                    fullWidth={isSmallScreen}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={!!touched.LastName && !!validationErrors.LastName}
                    helperText={
                      !!touched.LastName &&
                      !!validationErrors.LastName &&
                      (validationErrors.LastName as string)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={'auto'}>
                  <TextField
                    value={values.firstName}
                    onChange={handleChange}
                    label="Keresztnév"
                    name="firstName"
                    required
                    disabled={readonly}
                    fullWidth={isSmallScreen}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={!!touched.FirstName && !!validationErrors.FirstName}
                    helperText={
                      !!touched.FirstName &&
                      !!validationErrors.FirstName &&
                      (validationErrors.FirstName as string)
                    }
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12} md={4}>
                  <TextField
                    value={values.role}
                    onChange={handleChange}
                    label="Szerepkör"
                    name="role"
                    disabled={readonly}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={!!touched.role && !!validationErrors.role}
                    helperText={
                      !!touched.role &&
                      !!validationErrors.role &&
                      (validationErrors.role as string)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    value={values.membership}
                    onChange={handleChange}
                    label="Tagság"
                    name="membership"
                    disabled={readonly}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={
                      !!touched.membership && !!validationErrors.membership
                    }
                    helperText={
                      !!touched.membership &&
                      !!validationErrors.membership &&
                      (validationErrors.membership as string)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    value={values.area}
                    onChange={handleChange}
                    label="Terület"
                    name="area"
                    disabled={readonly}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={!!touched.area && !!validationErrors.area}
                    helperText={
                      !!touched.area &&
                      !!validationErrors.area &&
                      (validationErrors.area as string)
                    }
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12} md={4}>
                  <TextField
                    value={values.phone}
                    onChange={handleChange}
                    label="Telefon"
                    name="phone"
                    disabled={readonly}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={!!touched.phone && !!validationErrors.phone}
                    helperText={
                      !!touched.phone &&
                      !!validationErrors.phone &&
                      (validationErrors.phone as string)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    value={values.email}
                    onChange={handleChange}
                    label="Email"
                    name="email"
                    disabled={readonly}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={!!touched.email && !!validationErrors.email}
                    helperText={
                      !!touched.email &&
                      !!validationErrors.email &&
                      (validationErrors.email as string)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    value={values.fax}
                    onChange={handleChange}
                    label="Fax"
                    name="fax"
                    disabled={readonly}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={!!touched.fax && !!validationErrors.fax}
                    helperText={
                      !!touched.fax &&
                      !!validationErrors.fax &&
                      (validationErrors.fax as string)
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.introdution}
                  onChange={handleChange}
                  label="Bemutatkozás"
                  name="introdution"
                  disabled={readonly}
                  fullWidth
                  multiline
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={
                    !!touched.introdution && !!validationErrors.introdution
                  }
                  helperText={
                    !!touched.phone &&
                    !!validationErrors.introdution &&
                    (validationErrors.introdution as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="pageId"
                  disabled={readonly}
                  value={values.pageId}
                  onChange={(event, value) => {
                    setFieldValue('pageId', value);
                  }}
                  getOptionLabel={(option) =>
                    pages?.find((g: any) => g.id === option)?.name ?? ''
                  }
                  options={pages?.map((g: any) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={!!touched.pageId && !!validationErrors.pageId}
                      helperText={
                        !!touched.pageId &&
                        !!validationErrors.pageId &&
                        (validationErrors.pageId as string)
                      }
                      label="Oldal"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="publisherId"
                  disabled={readonly}
                  value={publisherId}
                  onChange={(event, value) => {
                    setPublisherId(value);
                    publisherLocalStroage.setLocalStorageValue(value);
                  }}
                  getOptionLabel={(option) =>
                    publishers?.find((g: any) => g.id === option)?.name ?? ''
                  }
                  options={publishers?.map((g: any) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={
                        !!touched.publisherId && !!validationErrors.publisherId
                      }
                      helperText={
                        !!touched.publisherId &&
                        !!validationErrors.publisherId &&
                        (validationErrors.publisherId as string)
                      }
                      label="Publikáló"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <ImageSelectComponent
                  image={values.image}
                  onSelect={(image: any) => {
                    setFieldValue('imageId', image.id);
                    setFieldValue('image', image);
                  }}
                  alt="kép"
                  onUploaded={(image: any) =>
                    handleUploaded(image, setFieldValue)
                  }
                  publisherId={publisherId}
                  currentFolderId={'-1'}
                />
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item xs={12} sm={isSmallScreen ? 12 : 'auto'} p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth={isSmallScreen}
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12} sm={isSmallScreen ? 12 : 'auto'} p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth={isSmallScreen}
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default OfficialForm;
