import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useCheckPermission from '../../hooks/useCheckPermission';
import publisherService from '../../services/publisherService';
import rssFeedService from '../../services/rssFeedService';
import tagService from '../../services/tagService';
import RssFeedForm from './RssFeedForm';
import { Languages } from '../../types/Languages';

const RssFeedEdit = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const params = useParams();
  const [errors, setErrors] = useState<string[]>([]);
  const [tags, setTags] = useState<any[]>([]);
  const [entity, setEntity] = useState<any>({
    publisherId: 0,
    numberOfContents: 50,
    allowRssContent: false,
    allowExternalContent: false,
    description: '',
    name: '',
    language: Languages.hu,
    tagIds: [],
    blackListWords: '',
  });

  const { checkPermission } = useCheckPermission();
  const [publishers, setPublishers] = useState<any[]>([]);
  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    publisherService
      .list()
      .then((response: any) => {
        if (!response.hasError) {
          setPublishers(response.records);
        } else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  useEffect(() => {
    rssFeedService.get(params.id).then((response: any) => {
      if (response.hasError) {
        setErrors(response.errorMessages);
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setEntity(response.result);
      }
    });
  }, [params.id]);

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    tagService
      .list()
      .then((response: any) => {
        if (!response.hasError) setTags(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    rssFeedService
      .update(entity)
      .then((response) => {
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Success!', {
            variant: 'success',
          });

          navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        if (setSubmitting) setSubmitting(false);
      });
  };

  return (
    <RssFeedForm
      entity={entity}
      readonly={!checkPermission(['UpdateRssFeed'])}
      onSubmit={onSubmit}
      handleCancel={() => navigate(-1)}
      errors={errors}
      publishers={publishers}
      tags={tags}
      onTagAdded={(tag: any) => {
        setTags([...tags, tag]);
      }}
    />
  );
};

export default RssFeedEdit;
