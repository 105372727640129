import { Delete, Edit, Visibility } from '@mui/icons-material';
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Tooltip,
} from '@mui/material';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import { useState } from 'react';
import EditIcon from '../../../components/icons/edit';
import useCheckPermission from '../../../hooks/useCheckPermission';
import { useNavigate } from 'react-router-dom';

const ParkingZonePageList = (props: any) => {
  const {
    handleDelete = () => {},
    zones = [],
    setParams,
    selectedZoneId,
    setSelectedZoneId,
  } = props;
  const [searchTerm, setSearchTerm] = useState<string>('');

  const { checkPermission } = useCheckPermission();
  const navigate = useNavigate();

  const filteredZones = zones.filter((zone: any) =>
    zone.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <Grid container>
      <Grid item xs={12} p={1} pb={0}>
        <TextField
          label="Keresés"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} p={1} pt={0}>
        <Box sx={{ height: { xs: 300, sm: 700 }, overflow: 'auto' }}>
          <List>
            {filteredZones.map((zone: any) => (
              <ListItem
                key={zone.id}
                button
                selected={selectedZoneId === zone.id}
                onClick={() => {
                  setSelectedZoneId(zone.id);
                }}
              >
                <Tooltip title={zone.name}>
                  <ListItemText primary={zone.name} />
                </Tooltip>
                <ListItemSecondaryAction>
                  <Tooltip
                    title={
                      checkPermission(['EditParkingZone'])
                        ? 'Szerkesztés'
                        : 'Megtekintés'
                    }
                  >
                    <IconButton
                      edge="end"
                      onClick={() => navigate(`/parkingZone/edit/${zone.id}`)}
                    >
                      {checkPermission(['EditParkingZone']) ? (
                        <Edit color={'primary'} />
                      ) : (
                        <Visibility color={'primary'} />
                      )}
                    </IconButton>
                  </Tooltip>
                  {checkPermission(['DeleteParkingZone']) && (
                    <Tooltip title="Delete">
                      <IconButton
                        edge="end"
                        onClick={() =>
                          setParams({
                            open: true,
                            name: 'Are you sure to delete this item?',
                            onConfirm: async () => handleDelete(zone.id),
                          })
                        }
                      >
                        <Delete color={'primary'} />
                      </IconButton>
                    </Tooltip>
                  )}{' '}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ParkingZonePageList;
