import Dialog from "@mui/material/Dialog";

export default function CreateEntityDialog(props: any) {
  const { open, handleCancel, CreateEntityComponent, ...rest } = props;

  return (
    <Dialog
      open={open}
      style={{ minHeight: 500 }}
      fullWidth
      maxWidth="lg"
      onClose={handleCancel}
    >
      <CreateEntityComponent handleCancel={handleCancel} {...rest} />
    </Dialog>
  );
}
