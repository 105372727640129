import {
  GridColDef,
  GridActionsCellItem,
  GridActionsColDef,
} from '@mui/x-data-grid';
import { Box, Paper, IconButton, Grid, Tooltip } from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Sync,
  ForwardToInbox,
} from '@mui/icons-material';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import userService from '../../../../services/authority/userService';
import DataList from '../../../../components/DataList';
import accountService from '../../../../services/accountService';
import InviteDialog from './InviteDialog';
import useConfirmDeleteDialog from '../../../../hooks/useConfirmDeleteDialog';

export interface User {
  id: string;
  fullName: string;
  email: string;
}

const UsersPage = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const [inviteDialogOpen, setInviteDialogOpen] = useState<boolean>(false);
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();

  const loadUsers = () => {
    dispatch({ type: 'SHOW_QUERY' });
    userService
      .list()
      .then((response) => {
        if (!response.hasError) setRows(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  };

  useEffect(() => {
    loadUsers();
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'fullName',
      headerName: 'Megjelenítési név',
      flex: 100,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 100,
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 20,
      getActions: (params: GridRowParams, color: any) => [
        <GridActionsCellItem
          icon={
            <Tooltip title="Szerkesztés">
              <EditIcon />
            </Tooltip>
          }
          label="Edit"
          color={color ? color : 'primary'}
          onClick={() => navigate(`/authority/users/edit/${params.row.id}`)}
        />,
        <GridActionsCellItem
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon />
            </Tooltip>
          }
          label="Delete"
          color={color ? color : 'primary'}
          onClick={() =>
            setParams({
              open: true,
              name: '',
              onConfirm: async () => handleDelete(params.row.id),
            })
          }
        />,
      ],
    } as GridActionsColDef,
  ];

  const handleDelete = (email: string) => {
    userService.delete(email).then((response) => {
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés', {
          variant: 'success',
        });
        setRows(rows.filter((row) => row.email !== email));
      }
    });
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Felhasználók</h2>
        </Grid>
        <Grid container item xs={12} p={2} justifyContent="end">
          <Tooltip title="Felhasználó meghívása">
            <Grid item>
              <IconButton
                onClick={() => setInviteDialogOpen(true)}
                color="primary"
              >
                <ForwardToInbox />
              </IconButton>
            </Grid>
          </Tooltip>
          <Tooltip title="AAD felhasználók szinkronizálása">
            <Grid item>
              <IconButton
                onClick={() => {
                  accountService
                    .syncUsers()
                    .then((e) => {
                      enqueueSnackbar('Sikeres szinkronizáció!', {
                        variant: 'success',
                      });

                      setTimeout(() => loadUsers(), 2000);
                    })

                    .catch((e) => {
                      enqueueSnackbar('Sikertelen szinkronizáció!', {
                        variant: 'error',
                      });
                    });
                }}
                color="primary"
              >
                <Sync />
              </IconButton>
            </Grid>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <DataList rows={rows} columns={columns} />
        </Grid>
        <ConfirmDeleteDialog />
        <InviteDialog
          open={inviteDialogOpen}
          handleClose={() => setInviteDialogOpen(false)}
          onSubmit={(email: string) => {
            return accountService
              .invite(email)
              .then((e) => {
                if (e.hasError) {
                  enqueueSnackbar(e.errorMessages.join(','), {
                    variant: 'error',
                  });

                  return;
                }
                enqueueSnackbar('Meghívó kiküldve!', {
                  variant: 'success',
                });

                setInviteDialogOpen(false);
                setTimeout(() => loadUsers(), 2000);
              })
              .catch((e) => {
                enqueueSnackbar('Sikertelen meghívás!', {
                  variant: 'error',
                });
              });
          }}
        />
      </Grid>
    </Paper>
  );
};

export default UsersPage;
