import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import parkingZoneConfigService from '../../../services/parking/parkingZoneConfigService';
import publisherService from '../../../services/publisherService';
import ParkingZoneConfigForm from './ParkingZoneConfigForm';
import parkingZoneService from '../../../services/parking/parkingZoneService';
import moment from 'moment';

const ParkingZoneConfigCreate = (props: any) => {
  const { onSave = null, navigateBack = true, handleCancel = null } = props;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const [now, setNow] = useState(new Date());
  const [zoneConfigs, setZoneConfigs] = useState<any[]>([]);

  const { checkPermission } = useCheckPermission();
  const [zones, setZones] = useState<any[]>([]);

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    parkingZoneConfigService
      .list()
      .then((response: any) => {
        if (!response.hasError) {
          setZoneConfigs(response.records);
        } else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    parkingZoneService
      .list()
      .then((response: any) => {
        if (!response.hasError) {
          setZones(response.records);
        } else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    parkingZoneConfigService
      .create({
        ...entity,
        startOfOperatingTime: moment(entity.startOfOperatingTime).toDate(),
        endOfOperatingTime: moment(entity.endOfOperatingTime).toDate(),
      })
      .then((response) => {
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Success!', {
            variant: 'success',
          });

          if (onSave) onSave(response.result);
          if (navigateBack) navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        if (setSubmitting) setSubmitting(false);
      });
  };

  return (
    <ParkingZoneConfigForm
      entity={{
        name: '',
        id: 0,
        color: '#1146CC',
        zoneId: 0,
        parkingLimitInMinutes: 0,
        extensionLengthInMinutes: 0,
        startOfOperatingTime: moment(now.setHours(8, 0, 0, 0)),
        endOfOperatingTime: moment(now.setHours(18, 0, 0, 0)),
        startOfValidity: now,
        endOfValidity: null,
        numberOfExtensions: 0,
        numberOfParkingPlaces: 0,
        parkingPrice: 0,
        isPR: false,
        isPrivate: false,
        isPermissionRequired: false,
        includedDays: 0,
        isException: false,
      }}
      readonly={!checkPermission(['CreateParkingZoneConfig'])}
      onSubmit={onSubmit}
      errors={errors}
      handleCancel={() =>
        handleCancel != null ? handleCancel() : navigate(-1)
      }
      zones={zones}
      zoneConfigs={zoneConfigs}
    />
  );
};

export default ParkingZoneConfigCreate;
