import React from 'react';
const SwastikaIcon = ({ primaryColor = '#008B61', secondaryColor = '#5B5B5B', width, height }) => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill={null} xmlns="http://www.w3.org/2000/svg">
<path d="M54.9315 11.8871C54.2056 6.79788 50.1123 2.74051 44.9782 2.01473C33.8267 0.440742 22.3603 0.440737 11.2175 2.02347C6.12713 2.74925 2.06881 6.84161 1.34287 11.9746C0.555699 17.536 0.153381 23.1936 0.153381 28.8075C0.153381 34.4214 0.555707 40.1489 1.35162 45.7366C2.07757 50.8258 6.17084 54.8832 11.3049 55.609C16.8676 56.396 22.5352 56.7982 28.1416 56.7982C33.748 56.7982 39.4768 56.396 45.0657 55.6003C50.156 54.8745 54.2144 50.7821 54.9403 45.6492C55.7275 40.0877 56.1298 34.4301 56.1298 28.8162C56.1298 23.2023 55.7275 17.4748 54.9315 11.8871ZM50.3222 44.9846C49.8849 48.0801 47.4534 50.5373 44.4097 50.9745C33.6955 52.5047 22.6839 52.5047 11.9521 50.9832C8.85595 50.546 6.39826 48.1151 5.96095 45.072C5.19127 39.7117 4.80643 34.2377 4.80643 28.8075C4.80643 23.3772 5.19125 17.9732 5.94344 12.6304C6.38075 9.53488 8.81223 7.07771 11.856 6.64924C17.2262 5.88848 22.7014 5.49499 28.1241 5.49499C33.5468 5.49499 38.9608 5.87974 44.3048 6.63176C47.4009 7.06898 49.8587 9.49989 50.296 12.5429C51.0657 17.9032 51.4505 23.3772 51.4505 28.8075C51.4505 34.2377 51.0656 39.6418 50.3135 44.9846H50.3222Z" fill={secondaryColor}/>
<path d="M46.7975 26.4815L46.4389 26.6039C45.4768 23.7445 42.8092 21.8207 39.8004 21.8207C36.7917 21.8207 34.1241 23.7445 33.1532 26.4815H30.4681V23.797C30.4681 21.7595 29.2349 19.9757 27.4069 19.3636C26.4535 19.04 25.8063 18.1568 25.8063 17.1512C25.8063 16.1456 26.4535 15.2624 27.4069 14.9389C29.2349 14.3268 30.4681 12.5429 30.4681 10.5055V10.1557C30.4681 8.87031 29.4273 7.82098 28.1328 7.82098C26.8384 7.82098 25.7976 8.86156 25.7976 10.1557L25.92 10.5143C23.06 11.4761 21.1358 14.1432 21.1358 17.1512C21.1358 20.1593 23.06 22.8263 25.7976 23.797V26.4815H23.1125C21.0746 26.4815 19.2903 27.7144 18.6781 29.542C18.3545 30.5039 17.4624 31.1422 16.4653 31.1422C15.4682 31.1422 14.5761 30.4951 14.2524 29.542C13.6402 27.7144 11.8559 26.4815 9.81806 26.4815H9.46824C8.18253 26.4815 7.13293 27.5221 7.13293 28.8162C7.13293 30.1104 8.17378 31.151 9.46824 31.151L9.82681 31.0285C10.7889 33.888 13.4566 35.8117 16.4653 35.8117C19.474 35.8117 22.1416 33.888 23.1125 31.151H25.7976V33.8355C25.7976 35.8729 27.0308 37.6568 28.8588 38.2689C29.8121 38.5924 30.4594 39.4756 30.4594 40.4812C30.4594 41.4868 29.8121 42.37 28.8588 42.6935C27.0308 43.3056 25.7976 45.0895 25.7976 47.1269V47.4767C25.7976 48.7621 26.8384 49.8115 28.1328 49.8115C29.4273 49.8115 30.4681 48.7709 30.4681 47.4767L30.3457 47.1182C33.2057 46.1563 35.1299 43.4893 35.1299 40.4812C35.1299 37.4732 33.2057 34.8061 30.4681 33.8355V31.151H33.1532C35.1911 31.151 36.9754 29.918 37.5876 28.0905C37.9112 27.1286 38.8034 26.4902 39.8004 26.4902C40.7975 26.4902 41.6896 27.1373 42.0132 28.0905C42.6255 29.918 44.4097 31.151 46.4476 31.151H46.7975C48.0832 31.151 49.1327 30.1104 49.1327 28.8162C49.1327 27.5221 48.0919 26.4815 46.7975 26.4815Z" fill={primaryColor}/>
</svg>

);

export default SwastikaIcon;
